import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  dateFormat,
  dateIsToday,
  dateTimeFormat,
  fetchAsyncDropdown,
  focusVatidateField,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  isModalValidation,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../../_eaFruitsDms/helpers'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import clsx from 'clsx'
import {postRequest} from '../../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../../TenantVariables'
import DatePickerCustom from '../../../../_eaFruitsDms/helpers/datePicker'
import moment from 'moment'
import {AsyncPaginate} from 'react-select-async-paginate'
import $ from 'jquery'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import {Modal} from 'react-bootstrap'

let defaultDate = dateFormat(moment(), GLOBAL_DATE_FORMAT + ' hh:mm a')

const initialDateSetting: any = {
  alwaysShowCalendars: true,
  singleDatePicker: true,
  timePicker: true,
  cancelClass: 'btn-secondary',
  minDate: defaultDate.displayDate,
  // startDate: disableSurveyCreatedDate,
  startDate: moment(),
  drops: 'auto',
  autoUpdateInput: false,
  locale: {
    format: GLOBAL_DATE_FORMAT + ' hh:mm a',
  },
}

const userType = localStorage.getItem('user_type') || ''

const PaymentIssueHeader = (props: any) => {
  const momentObj = moment()
  const currentDisplayDateTime = dateTimeFormat(momentObj)

  const initialRow: any = {
    receivable_amount: '',
    received_amount: '',
    settlement_datetime: momentObj,
    reason_note: '',
    reason_id: 'parent',
  }

  const initialSettledValidation: any = {
    receivable_amount: '',
    received_amount: '',
    settlement_datetime: '',
    reason_note: '',
    reason_id: '',
  }
  const navigate = useNavigate()
  const location = useLocation()
  const paymentIssueId = localStorage.getItem('paymentIssueId')
  const {transactionsMeta, overviewMeta} = props

  const [settled, setSettled] = useState<any>(initialRow)
  const [settledValidation, setSettledValidation] = useState<any>(initialSettledValidation)
  const [dateShow, setDateShow] = useState(currentDisplayDateTime)
  const [initialSettings, setInitialSettings] = useState<any>(initialDateSetting)
  const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
  const [disableBtn, setDisable] = useState(false)
  const [paymentReasonOffSet, setPaymentReasonOffSet] = useState<any>('')
  const [paymentReasonOption, setPaymentReasonOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectReason, setSelectReason] = useState<any>([])
  const [isSettledAlert, setIsSettledAlert] = useState(false)

  useEffect(() => {
    let updatedSettledDetails: any = {...settled}
    updatedSettledDetails.receivable_amount =
      !transactionsMeta || isEmpty(transactionsMeta.unsettled_cash_amount)
        ? '-'
        : numberFormat(transactionsMeta.unsettled_cash_amount).displayFormat
    updatedSettledDetails.received_amount =
      !transactionsMeta || isEmpty(transactionsMeta.unsettled_cash_amount)
        ? '-'
        : transactionsMeta.unsettled_cash_amount
    setSettled(updatedSettledDetails)
  }, [transactionsMeta])

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let newDate = dateFormat(start._d, GLOBAL_DATE_FORMAT + ' hh:mm a')
          setDateShow(newDate.displayDate)
          setInitialSettings((pre: any) => ({...pre, startDate: start._d}))
          let newApiDate = dateFormat(start._d, 'YYYY-MM-DD hh:mm')
          setSettled({...settled, settlement_datetime: newApiDate.displayDate})
          setSettledValidation((pre: any) => ({...pre, settlement_datetime: ''}))
        }}
        initialSettings={initialSettings}
        dateValidation={settledValidation.settlement_datetime}
        dateValue={dateShow}
      />
    )
  }

  const fetchReason = async (search?: any) => {
    let response = await fetchAsyncDropdown(
      paymentReasonOffSet,
      setPaymentReasonOffSet,
      paymentReasonOption,
      setPaymentReasonOption,
      `master/reasons/cash_settlement/drop-down?`,
      search,
      setSearchSelect,
      'reason_name'
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value
    setSettledValidation({...settledValidation, [inputName]: ''})
    let updatedSellted: any = {...settled}
    switch (inputName) {
      case 'reason_id':
        setSelectReason(e)
        updatedSellted['reason_id'] = inputValue
        break
      default:
        setDisable(false)
        setErrorMessage('')
        updatedSellted[inputName] = inputValue
    }
    setSettled(updatedSellted)
  }

  const validateForm = () => {
    let navigateField = {
      receivable_amount: settled.receivable_amount,
      received_amount: settled.received_amount,
      settlement_datetime: settled.settlement_datetime,
      reason_note: settled.reason_note,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])

    let validations = {...settledValidation}
    let isValid = true

    if (settled.received_amount === '') {
      validations.received_amount = 'is-invalid'
      isValid = false
    }
    if (settled.receivable_amount === '') {
      validations.receivable_amount = 'is-invalid'
      isValid = false
    }
    if (settled.settlement_datetime === '') {
      validations.settlement_datetime = 'is-invalid'
      isValid = false
    }
    if (settled.reason_id === 'parent') {
      validations.reason_id = 'is-invalid'
      isValid = false
    }
    if (!settled.reason_id && settled.reason_note === '') {
      validations.reason_note = 'is-invalid'
      isValid = false
    }

    setSettledValidation(validations)
    return isValid
  }

  const clearFunction = () => {
    let updatedSellted: any = {...settled}
    updatedSellted['settlement_datetime'] = momentObj
    updatedSellted['reason_id'] = 'parent'
    updatedSellted['reason_note'] = ''
    updatedSellted['received_amount'] = transactionsMeta.unsettled_cash_amount
    setSettled(updatedSellted)
    setSettledValidation(initialSettledValidation)
    setDateShow(currentDisplayDateTime)
    setIsButtonLoader('Submit')
    setDisable(false)
    setPaymentReasonOption('')
    setErrorMessage('')
    setSelectReason('')
  }

  const verifySettled = () => {
    const isValid = validateForm()

    isValid && setIsSettledAlert(true)
  }

  const submitSettled = () => {
    const isValid = validateForm()
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }

    if (isValid) {
      const settledInfo = {...settled}
      setIsButtonLoader('Please Wait...')
      setDisable(true)
      delete settledInfo.receivable_amount
      postRequest(
        `${apiBaseURL()}payment/issues/${paymentIssueId}/transactions/settle`,
        settledInfo,
        true
      ).then((response: any) => {
        setIsSettledAlert(false)
        setIsButtonLoader('Submit')
        if (response.status === 200) {
          notify.success = true
        }
        if (response.statusText === 'Bad Request') {
          notify.failed = true
          notify.errorField = response.data.error.field
        }
        if (notify.errorField == 'invalid_received_amount') {
          setErrorMessage(
            'Your added Received Amount is greater than Total Receivable Amount! Please try again'
          )
        }
        setDisable(false)
        if (notify.success) {
          localStorage.setItem('SettlementMessage', 'SettlementMessage')
          setTimeout(() => {
            setIsAlertSuccess(false)
          }, 2000)
          setIsAlertSuccess(true)

          $('#closeEaModalPaymentSettlement').trigger('click')
          navigate('/payment-issues')
        }
      })
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }
  return (
    <>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={FormatMessage('PAYMENT_SETTLEMENT')}
      />
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {!transactionsMeta || isEmpty(transactionsMeta.sr_user_name)
                        ? '-'
                        : transactionsMeta.sr_user_name}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                      {!transactionsMeta || isEmpty(transactionsMeta.date)
                        ? '-'
                        : dateFormat(transactionsMeta.date).displayDate}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='ms-auto'>
              {transactionsMeta &&
              transactionsMeta.unsettled_cash_amount === 0 &&
              transactionsMeta.unsettled_pay_later === 0 ? (
                <>
                  <span className='badge badge-light-success text-dark cursor-pointer'>
                    Settled
                  </span>
                </>
              ) : (
                <>
                  <div>
                    <span className='text-danger font-medium mx-2'>
                      {`${
                        numberFormatWithCode(
                          transactionsMeta && !isEmpty(transactionsMeta.unsettled_cash_amount)
                            ? transactionsMeta.unsettled_cash_amount
                            : 0
                        ).displayFormat
                      } `}
                      Unsettled Cash
                    </span>
                    {transactionsMeta &&
                      transactionsMeta.unsettled_cash_amount > 0 &&
                      !['wm', 'sa'].includes(userType.toLowerCase()) && (
                        <button
                          className='btn btn-primary cursor-pointer'
                          data-bs-target='#ea_modal_payment_settlement'
                          data-bs-toggle='modal'
                        >
                          Settle Cash
                        </button>
                      )}
                  </div>
                  {transactionsMeta &&
                    transactionsMeta.unsettled_pay_later > 0 &&
                    !isEmpty(transactionsMeta.date) &&
                    dateIsToday(transactionsMeta.date) && (
                      <div className='mt-4'>
                        <span className='text-danger font-medium mx-2'>
                          {transactionsMeta &&
                            transactionsMeta.unsettled_pay_later &&
                            `${tenantConfiguration.currencyCode} ${
                              numberFormat(transactionsMeta.unsettled_pay_later).displayFormat
                            } `}
                          Unsettled Pay Later Today
                        </span>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/payment-issues/payment-issue-detail/transactions' &&
                      'active')
                  }
                  to='/payment-issues/payment-issue-detail/transactions'
                >
                  Transactions
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/payment-issues/payment-issue-detail/overview' &&
                      'active')
                  }
                  to='/payment-issues/payment-issue-detail/overview'
                >
                  Overview
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <EaModal
        id='ea_modal_payment_settlement'
        modalTitle='Payment Settlement'
        onSubmit={verifySettled}
        cancel={clearFunction}
        closeAuto='closeEaModalPaymentSettlement'
        actionBtnName={isButtonLoader}
        btnDisabled={disableBtn}
      >
        <div className='row'>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>
                Total Receivable<span className='text-danger'>*</span>
              </label>
              <div
                className={clsx(
                  'input-group',
                  settledValidation.receivable_amount === 'is-invalid' ? 'is-invalid-grp' : ''
                )}
              >
                <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-50px'>
                  {tenantConfiguration.currencyCode}
                </span>
                <input
                  type='text'
                  id='receivable_amount'
                  className={clsx('form-control', settledValidation.receivable_amount)}
                  name='receivable_amount'
                  placeholder='Type here...'
                  onChange={(e) => handleChange(e, 'receivable_amount')}
                  value={settled.receivable_amount}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='mb-4'>
              <label className='form-label'>
                Total Received<span className='text-danger'>*</span>
              </label>
              <div
                className={clsx(
                  'input-group',
                  settledValidation.received_amount === 'is-invalid' ? 'is-invalid-grp' : ''
                )}
              >
                <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-50px'>
                  {tenantConfiguration.currencyCode}
                </span>
                <input
                  type='text'
                  id='received_amount'
                  className={clsx('form-control', settledValidation.received_amount)}
                  name='received_amount'
                  onChange={(e) => handleChange(e, 'received_amount')}
                  value={settled.received_amount}
                  placeholder='Type here...'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <label className='form-label' id='settlement_datetime'>
            Payment Date & Time<span className='text-danger'>*</span>
          </label>
          <CustomDatePicker />
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Select Reason<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchReason}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container w-100', settledValidation.reason_id)}
            classNamePrefix='react-select'
            name='reason_id'
            onChange={(e) => handleChange(e, 'reason_id')}
            value={selectReason}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        {settled.reason_id == '' && (
          <div className='mb-4'>
            <label className='form-label'>
              Comments<span className='text-danger'>*</span>
            </label>
            <textarea
              id='reason_note'
              className={clsx('form-control', settledValidation.reason_note)}
              rows={4}
              placeholder='Type here…'
              onChange={(e) => handleChange(e, 'reason_note')}
              value={settled.reason_note}
              name='reason_note'
            ></textarea>
          </div>
        )}
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>

      <Modal show={isSettledAlert} centered>
        <Modal.Header
          closeButton
          onClick={() => {
            if (isButtonLoader === 'Submit') {
              setIsSettledAlert(false)
            }
          }}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-5'>
            <b>
              Kindly confirm that you have reviewed this cash settlement entry properly, as this
              entry will NOT be editable after this point!
            </b>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setIsSettledAlert(false)
            }}
            className='btn btn-light'
            disabled={isButtonLoader === 'Submit' ? false : true}
          >
            Review again
          </button>

          <button
            onClick={() => {
              submitSettled()
            }}
            className='btn btn-primary'
            disabled={isButtonLoader === 'Submit' ? false : true}
          >
            {isButtonLoader === 'Submit' ? 'Yes, proceed!' : 'Please Wait'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PaymentIssueHeader
