import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  checkPermissions,
  dateFormat,
  isAdmin,
  isEmpty,
  numberFormat,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageLink, PageTitle} from '../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../TenantVariables'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'

const PreOrders: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location: any = useLocation()
  const userType = localStorage.getItem('user_type') || ''
  let ordersFiltered: any = retrieveFilter(filters.ts_orders)
  let todayDate = dateFormat(moment())
  const currentWarehouseId = userType.toLowerCase() === 'wm' ? localStorage.getItem('w_id') : ''
  const currentWarehouseName =
    userType.toLowerCase() === 'wm' ? localStorage.getItem('warehouse_name') : 'All Warehouses'

  const preOrderTitle: Array<PageLink> = [
    {
      title: 'Orders',
      path: '/orders',
      isSeparator: false,
      isActive: false,
    },
  ]

  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [currentOffSet, setCurrentOffSet] = useState<any>(ordersFiltered?.currentOffSet || '0')
  const [preOrders, setPreOrders] = useState<any>([])
  const [count, setCount] = useState<string>('1')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(ordersFiltered?.selected || 0)
  const [searchByName, setSearchByName] = useState<string>(ordersFiltered?.search || '')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [warehouses, setWarehouses] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [warehouseOffSet, setWarehouseOffSet] = useState('')
  const [orderBy, setOrderBy] = useState('pre_order_date')
  const [date, setDate] = useState<any>({
    startDate: ordersFiltered?.startDate || todayDate,
    endDate: ordersFiltered?.endDate || todayDate,
  })
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [messageHandler, setMessageHandler] = useState<string>('')
  const [messageTitle, setMessageTitle] = useState<any>('')
  const [orderStatus, setOrderStatus] = useState<any>(
    ordersFiltered?.status || {
      label: 'All Status',
      value: '',
    }
  )
  const [warehouse, setWarehouseId] = useState<any>(
    ordersFiltered?.warehouse || {
      label: 'All Warehouses',
      value: '',
    }
  )
  const [showAddOrderButton, setAddOrderButton] = useState<boolean>(false)
  const initialState = {
    startDate: ordersFiltered?.startDate || todayDate,
    endDate: ordersFiltered?.endDate || todayDate,
    warehouse: ordersFiltered?.warehouse || {label: 'All Warehouses', value: ''},
    status: ordersFiltered?.status || '',
    search: ordersFiltered?.search || '',
    selected: ordersFiltered?.selected || '',
    currentOffSet: ordersFiltered?.currentOffSet || '',
    buttonDisable: true,
  }
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    ordersFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    localStorage.removeItem('oId')
    if (location.state) {
      let state: any = location.state
      setWarehouseId(state.warehouse)
      setIsAlertSuccess(true)
      setOrderBy('')
      setDate({
        startDate: state.date,
        endDate: state.date,
      })

      if (state.type === 'add') {
        setMessageHandler('successAdd')
      }

      if (state.type === 'edit') {
        setMessageHandler('successEdit')
      }

      setMessageTitle({
        date: state.date.displayDate,
        name: state.customerName,
      })

      setTimeout(() => {
        setIsAlertSuccess(false)
        //If you want to clear state after page then put below line
        navigate(location.pathname, {replace: true})
      }, 1500)
    }
    if (userType.toLowerCase() !== 'ts') {
      setIsLoading(true)
      fetchWarehouses('').then(() => {
        setWarehouseOffSet('')
        fetchPreOrders().then()
      })
    }
    checkPermission().then()
  }, [])

  useEffect(() => {
    fetchPreOrders().then()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffSet
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.ts_orders, filterStates)

    if (
      warehouse &&
      warehouse.value == currentWarehouseId &&
      orderStatus.value == '' &&
      searchByName == '' &&
      date.endDate.apiDate === todayDate.apiDate &&
      date.startDate.apiDate === todayDate.apiDate
    ) {
      setButtonDisable(false)
    }
  }, [warehouse, orderStatus, date, currentOffSet, debounceSearch])

  useEffect(() => {
    setWarehouseOffSet('')
  }, [searchSelect])

  const checkPermission = async () => {
    const res = await checkPermissions('orders')

    if ((res && res.write) || userType.toLowerCase() === 'ts') {
      setAddOrderButton(true)
    }
  }

  const fetchPreOrders: any = async () => {
    let preOrderListAPI = `${apiBaseURL()}preorder/pre_order/list?limit=${limit}&offset=${currentOffSet}&ordering=&search=${debounceSearch}`

    if (!isAdmin()) {
      preOrderListAPI += `&start_date=${date.startDate.apiDate}&end_date=${date.endDate.apiDate}`
    } else {
      preOrderListAPI += `&undelivered=true`
    }

    if (warehouse.value !== '') {
      preOrderListAPI += `&warehouse_id=${warehouse.value}`
    } else {
      if (isAdmin() && location.pathname === '/warehouse/DC/singlewarehousename/orders') {
        const wId = localStorage.getItem('wId')
        preOrderListAPI += `&warehouse_id=${checkEmpty(wId, '')}`
      } else {
        preOrderListAPI += `&warehouse_id=${checkEmpty(currentWarehouseId, '')}`
      }
    }

    if (orderStatus.value !== '') {
      preOrderListAPI += `&status=${orderStatus.value}`
    }

    setDisplayLoader(true)

    const preOrderResponse = await getRequest(preOrderListAPI, true)

    setDisplayLoader(false)
    setPreOrders(preOrderResponse.results)
    setCount(preOrderResponse.count)
    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?search=${search}&ordering=name&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let options: any = warehouses.length === 0 ? [{label: 'All Warehouses', value: ''}] : []

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        if (currentWarehouseId == option.id) {
          if (!isEmpty(ordersFiltered?.warehouse)) {
            setWarehouseId(ordersFiltered?.warehouse)
          } else {
            setWarehouseId({
              label: option.name,
              value: option.id,
            })
          }
        }

        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const optionStatuses: any = [
    {
      label: 'All Status',
      value: '',
    },
    {
      label: 'Pending',
      value: 'Pending',
    },
    {
      label: 'Scheduled',
      value: 'Scheduled',
    },
    {
      label: 'Delivered',
      value: 'delivered',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
    {
      label: 'In Transist',
      value: 'in_transist',
    },
    {
      label: 'Delivery In Process',
      value: 'start_delivery',
    },
    // {
    //     label: 'Cancelled',
    //     value: 'Canceled'
    // }
  ]

  const fetchOrderStatus: any = () => {
    let options = optionStatuses
    if (isAdmin()) {
      options = optionStatuses.filter((option: any) => option.value !== 'delivered')
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const filterChangeHandler = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    setCurrentOffSet('')
    setSelected(0)
    setButtonDisable(true)
    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        setFilterStates({...filterStates, ['warehouse']: e})
        break
      case 'orderStatus':
        setOrderStatus(e)
        setFilterStates({...filterStates, ['status']: e})
        break
      case 'search':
        setSearchByName(e.target.value)
        setFilterStates({...filterStates, ['search']: e.target.value})
        break
    }
  }

  const goToDetailPage = (orderId: any) => {
    if (!isAdmin()) {
      localStorage.setItem('oId', orderId)
      saveFilter(filters.ts_orders, filterStates)
      navigate('order-detail')
    }
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setCurrentOffSet('')
            setSelected(0)
            setButtonDisable(true)
            const callBakeDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBakeDate.startDate
            updatedState['endDate'] = callBakeDate.endDate
            setFilterStates(updatedState)
            setDate(callBakeDate)
          }}
          initialSettings={{
            startDate: moment(date.startDate.apiDate).toDate(),
            endDate: moment(date.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={date}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }
  }

  const findStatus = (orderStatus: any) => {
    const filterOption: any = optionStatuses.filter((item: any) => item.value === orderStatus)

    if (filterOption.length > 0) {
      return filterOption[0].label
    }
  }

  const handleChangeReset = () => {
    setSearchByName('')
    setWarehouseId({label: 'All Warehouses', value: ''})
    setOrderStatus({label: 'All Status', value: ''})
    setWarehouseId({label: currentWarehouseName, value: currentWarehouseId})
    setCurrentOffSet(0)
    setSelected(0)
    let updatedDateState: any = {...date}
    updatedDateState['startDate'] = todayDate
    updatedDateState['endDate'] = todayDate
    setDate(updatedDateState)

    let updatedState: any = {...filterStates}
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    updatedState['warehouse'] = ''
    updatedState['status'] = ''
    updatedState['search'] = ''
    updatedState['selected'] = ''
    updatedState['currentOffSet'] = ''
    updatedState['buttonDisable'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Orders' />
      <PageTitle breadcrumbs={preOrderTitle}>{intl.formatMessage({id: 'Orders'})}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('PRE_ORDER.CREATE.SUCCESS', '', messageTitle)
            : messageHandler === 'successEdit'
            ? FormatMessage('PRE_ORDER.UPDATE.SUCCESS', '', messageTitle)
            : ''
        }
      />
      <div className={`card ${className}`}>
        {displayLoader && <IsLoader />}
        {/* {displayLoader && <IsLoader />} */}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar
            onChange={filterChangeHandler}
            value={searchByName}
            isDisable={displayLoader}
          />
          <div className='ms-auto'>
            {/* <div className='card-toolbar d-flex my-0'> */}
            <form
              className='card-toolbar d-flex my-0'
              onSubmit={(e) => e.preventDefault()}
              autoComplete='off'
            >
              {!isAdmin() && (
                <>
                  <CustomDatePickerComponent />

                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    onChange={(e) => filterChangeHandler(e, 'warehouse')}
                    isSearchable
                    isDisabled={displayLoader}
                    value={warehouse}
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </>
              )}

              <AsyncPaginate
                loadOptions={fetchOrderStatus}
                onChange={(e) => filterChangeHandler(e, 'orderStatus')}
                className='react-select-container my-1 me-3 w-160px'
                classNamePrefix='react-select'
                isDisabled={displayLoader}
                value={orderStatus}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              {showAddOrderButton && (
                <button
                  className='btn btn-primary me-3'
                  disabled={displayLoader}
                  onClick={() => navigate('manage-order')}
                >
                  Add Order
                </button>
              )}
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
            </form>
            {/* </div> */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          {preOrders.length == 0 ? (
            <NoRecords />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-hover table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-100px w-100px'>Order No.</th>
                      <th className='min-w-160px'>Brand</th>
                      <th className='min-w-160px'>Customer Name</th>
                      <th className='min-w-150px'>Warehouse Name</th>
                      <th className='w-120px min-w-130px'>Total Amount</th>
                      <th className='w-120px min-w-130px'>Delivery Date</th>
                      <th className='w-120px min-w-190px'>Created By</th>
                      <th className='min-w-100px'>Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {preOrders.map((preOrder: any) => {
                      return (
                        <tr>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            #{preOrder.pre_order_brand_group_id}
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {preOrder.brand && preOrder.brand.name}
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {preOrder.customer.business_name + ', ' + preOrder.customer.street.name}
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {preOrder.warehouse.length > 0 && preOrder.warehouse[0].name}
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {numberFormat(preOrder.total_payable).displayFormat}
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {
                            preOrder.order_status === 'rejected' ? 
                            isEmpty(preOrder.rejection_date) ? "-" :
                            dateFormat(preOrder.rejection_date).displayDate :
                            dateFormat(preOrder.pre_order_date).displayDate
                            }
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {preOrder.created_by
                              ? preOrder.created_by.type.toUpperCase() +
                                ' - ' +
                                preOrder.created_by.first_name +
                                ' ' +
                                preOrder.created_by.last_name
                              : '-'}
                          </td>
                          <td
                            onClick={() => goToDetailPage(preOrder.id)}
                            style={{cursor: isAdmin() ? 'default' : 'pointer'}}
                          >
                            {preOrder.order_status == 'Scheduled' && (
                              <span className='badge badge-light-primary text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                            {preOrder.order_status == 'delivered' && (
                              <span className='badge badge-light-primary text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                            {preOrder.order_status == 'in_transist' && (
                              <span className='badge badge-light-primary text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                            {preOrder.order_status == 'start_delivery' && (
                              <span className='badge badge-light-primary text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                            {preOrder.order_status == 'Delivered' && (
                              <span className='badge badge-light text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                            {preOrder.order_status == 'Pending' && (
                              <span className='badge badge-light-success text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                            {preOrder.order_status == 'rejected' && (
                              <span className='badge badge-light-danger text-dark'>
                                {findStatus(preOrder.order_status)}
                              </span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
            </>
          )}
        </div>
      </div>

      <CustomPaginate
        data={preOrders}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffSet}
        totalRecords={count}
        limit={limit}
        saveFilterName={filters.ts_orders}
        filterStates={filterStates}
        isDisabled={displayLoader}
      />
    </>
  )
}

export default PreOrders
