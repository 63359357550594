/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import moment from 'moment/moment'
import {FC, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {
  apiBaseURL,
  checkEmpty,
  checkRoleName,
  checkUserType,
  dateFormat,
  dateTimeFormat,
  isEmpty,
  KTSVG,
} from '../../../helpers'
import {blobXlsRequest} from '../../../https/apiCall'
import {logisticsManager} from '../../../utils/roleNames'
import {useLayout} from '../../core'
import InfoIcon from '../header/infoIcon'
import {DefaultTitle} from '../header/page-title/DefaultTitle'

const Toolbar1: FC = () => {
  const {classes} = useLayout()
  const location = useLocation()
  const userType = localStorage.getItem('user_type') || ''
  const currentWarehouseId = localStorage.getItem('w_id')
  const warehouseName = localStorage.getItem('warehouse_name') || ''
  const initialDateFilter = {
    start_date: dateFormat(moment()).apiDate,
    end_date: dateFormat(moment()).apiDate,
  }

  const [downloadSalesButtonDisabled, setDownloadSalesButtonDisabled] = useState<boolean>(false)
  const [downloadNoOrderingCustomerButtonDisabled, setDownloadNoOrderingCustomerButtonDisabled] =
    useState<boolean>(false)
  const [downloadCustomerButtonDisabled, setDownloadCustomerButtonDisabled] =
    useState<boolean>(false)
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState<boolean>(false)
  const [downloadOdooButtonDisabled, setDownloadOdooButtonDisabled] = useState<boolean>(false)
  const [downloadPreOrderButtonDisabled, setDownloadPreOrderButtonDisabled] =
    useState<boolean>(false)
  const [downloadSrButtonDisabled, setDownloadSrButtonDisabled] = useState<boolean>(false)
  const [downloadCreditIssueButtonDisabled, setDownloadCreditIssueButtonDisabled] =
    useState<boolean>(false)
  const [downloadComplaintButtonDisabled, setDownloadComplaintButtonDisabled] =
    useState<boolean>(false)

  const downloadReport = async (name: any) => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId: any = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    let apiURL: string = ''
    let fileName: string = ''

    const dateFilter = initialDateFilter

    if (['/sales-invoices', '/reports/sales-invoice-report'].includes(location.pathname)) {
      const startDate = localStorage.getItem('start-date') || ''
      const endDate = localStorage.getItem('end-date') || ''

      if (!isEmpty(startDate) && !isEmpty(endDate)) {
        dateFilter.start_date = startDate
        dateFilter.end_date = endDate
      }
    }

    if (name === 'sales') {
      const selectedWarehouseId = localStorage.getItem('selected-wid')

      if (!isEmpty(selectedWarehouseId)) {
        warehouseId = selectedWarehouseId
      }

      if (typeof warehouseId === 'object') {
        warehouseId = !isEmpty(warehouseId) && !isEmpty(warehouseId.value) ? warehouseId.value : ''
      }

      setDownloadSalesButtonDisabled(true)
      apiURL = `${apiBaseURL()}preorder/excel/daily-sales-report/?warehouse=${
        isEmpty(warehouseId) ? '' : warehouseId
      }&start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`
      fileName = `Daily Sales Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
        dateFormat(dateFilter.end_date).displayDate
      } #${dateTimeFormat(moment())}`
      if (dateFilter.start_date === dateFilter.end_date) {
        fileName = `Daily Sales Report - ${
          dateFormat(dateFilter.start_date).displayDate
        } #${dateTimeFormat(moment())}`
      }
      await blobXlsRequest(apiURL, fileName, true, setDownloadSalesButtonDisabled)
    } else if (name === 'no-ordering-customer') {
      const warehouseId = localStorage.getItem('warehouseId') || ''
      const isHoreca = localStorage.getItem('isHoreca') || ''
      const search = localStorage.getItem('search') || ''
      const startDate = localStorage.getItem('start_date') || ''
      const endDate = localStorage.getItem('end_date') || ''

      setDownloadNoOrderingCustomerButtonDisabled(true)

      apiURL = `${apiBaseURL()}reports/no-ordering-customers/excel?search=${search}&warehouse_id=${warehouseId}&start_date=${startDate}&end_date=${endDate}&is_horeca_customer=${isHoreca}`

      fileName = `No Ordering Customer Report - ${dateFormat(startDate).displayDate} to ${
        dateFormat(endDate).displayDate
      } #${dateTimeFormat(moment())}`

      if (startDate === endDate) {
        fileName = `No Ordering Customer Report - ${
          dateFormat(startDate).displayDate
        } #${dateTimeFormat(moment())}`
      }

      await blobXlsRequest(apiURL, fileName, true, setDownloadNoOrderingCustomerButtonDisabled)
    } else {
      setDownloadCustomerButtonDisabled(true)
      apiURL = `${apiBaseURL()}customers/daily-report/${warehouseId}?start_date=${
        initialDateFilter.start_date
      }&end_date=${initialDateFilter.start_date}`
      fileName = `DMS Customers Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
        dateFormat(dateFilter.end_date).displayDate
      } #${dateTimeFormat(moment())}`
      if (dateFilter.start_date === dateFilter.end_date) {
        fileName = `DM Customers Report - ${
          dateFormat(dateFilter.start_date).displayDate
        } #${dateTimeFormat(moment())}`
      }
      await blobXlsRequest(apiURL, fileName, true, setDownloadCustomerButtonDisabled)
    }
  }

  const downloadOdooReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    let apiURL: string = ''
    let fileName: string = ''

    const dateFilter = initialDateFilter

    const startDate = localStorage.getItem('start-date') || ''
    const endDate = localStorage.getItem('end-date') || ''

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    const selectedWarehouseId = localStorage.getItem('selected-wid')

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    setDownloadOdooButtonDisabled(true)
    apiURL = `${apiBaseURL()}odoo-mapping/sales-excel?warehouse=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`
    fileName = `Sales Report for Odoo - ${dateFormat(dateFilter.start_date).displayDate} to ${
      dateFormat(dateFilter.end_date).displayDate
    } #${dateTimeFormat(moment())}`
    if (dateFilter.start_date === dateFilter.end_date) {
      fileName = `Sales Report for Odoo - ${
        dateFormat(dateFilter.start_date).displayDate
      } #${dateTimeFormat(moment())}`
    }
    await blobXlsRequest(apiURL, fileName, true, setDownloadOdooButtonDisabled)
  }

  const downloadDailySrReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    let apiURL: string = ''
    let fileName: string = ''

    const dateFilter = initialDateFilter

    const startDate = localStorage.getItem('start-date') || ''
    const endDate = localStorage.getItem('end-date') || ''

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    const selectedWarehouseId = localStorage.getItem('selected-wid')

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    setDownloadSrButtonDisabled(true)
    apiURL = `${apiBaseURL()}reports/daily-sr-report-excel?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`
    fileName = `Daily SR Sales Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
      dateFormat(dateFilter.end_date).displayDate
    } #${dateTimeFormat(moment())}`
    if (dateFilter.start_date === dateFilter.end_date) {
      fileName = `Daily SR Sales Report - ${
        dateFormat(dateFilter.start_date).displayDate
      } #${dateTimeFormat(moment())}`
    }
    await blobXlsRequest(apiURL, fileName, true, setDownloadSrButtonDisabled)
  }

  const downloadCreditIssuesReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')
    const customerId = localStorage.getItem('selected-customer') || ''
    const srId = localStorage.getItem('selected-sr') || ''
    const selectedSearch = localStorage.getItem('selected-search') || ''
    const customerName = localStorage.getItem('selected-customer-name') || ''
    const selectedStatus = localStorage.getItem('selected-status') || ''
    let isHoreca: any = localStorage.getItem('is-horeca')
    if (!isEmpty(isHoreca)) {
      isHoreca = isHoreca === 'true'
    } else {
      isHoreca = ''
    }

    let apiURL: string = ''
    let fileName: string = ''

    const selectedWarehouseId = localStorage.getItem('selected-wid') || ''

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    setDownloadCreditIssueButtonDisabled(true)
    apiURL = `${apiBaseURL()}reports/credit-issue/excel/report?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&customer_id=${customerId}&sr_id=${srId}&ordering=-id&search=${selectedSearch}&issue_status=${selectedStatus}&is_horeca_customer=${isHoreca}`

    if (isEmpty(customerId)) {
      fileName += `All Customers`
    } else {
      fileName += `${customerName}`
    }

    fileName += ` - Credit Issues Report`

    await blobXlsRequest(apiURL, fileName, true, setDownloadCreditIssueButtonDisabled)
  }

  const downloadCreditCollectionReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    let apiURL: string = ''
    let fileName: string = ''

    const dateFilter = initialDateFilter

    const startDate = localStorage.getItem('credit-start-date') || ''
    const endDate = localStorage.getItem('credit-end-date') || ''

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    const selectedWarehouseId = localStorage.getItem('credit-selected-wid')

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    setDownloadOdooButtonDisabled(true)
    apiURL = `${apiBaseURL()}reports/excel/credit-collection-report/?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`
    fileName = `Credit Collection Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
      dateFormat(dateFilter.end_date).displayDate
    } #${dateTimeFormat(moment())}`
    if (dateFilter.start_date === dateFilter.end_date) {
      fileName = `Credit Collection Report - ${
        dateFormat(dateFilter.start_date).displayDate
      } #${dateTimeFormat(moment())}`
    }
    await blobXlsRequest(apiURL, fileName, true, setDownloadOdooButtonDisabled)
  }

  const downloadPaymentIssueReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    const dateFilter = {start_date: '', end_date: ''}
    const startDate = localStorage.getItem('payment-issue-start-date') || ''
    const endDate = localStorage.getItem('payment-issue-end-date') || ''
    const selectedWarehouseId = localStorage.getItem('payment-issue-warehouse')
    const selectedSr = localStorage.getItem('payment-issue-sr-value') || ''
    const selectedSrName = localStorage.getItem('payment-issue-sr-name') || ''
    const selectedStatus = localStorage.getItem('payment-issue-status') || ''

    let apiURL: string = ''
    let fileName: string = ''
    let srName: string = ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    if (!isEmpty(selectedSr)) {
      if (!isEmpty(dateFilter.start_date) && !isEmpty(dateFilter.end_date)) {
        srName = `${selectedSrName} - `
      } else {
        srName = `- ${selectedSrName} `
      }
    }

    setDownloadOdooButtonDisabled(true)

    apiURL = `${apiBaseURL()}payment/issues/excel?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&start_date=${dateFilter.start_date}&end_date=${
      dateFilter.end_date
    }&status=${selectedStatus}&sr_user_id=${selectedSr}`

    if (!isEmpty(dateFilter.start_date) && !isEmpty(dateFilter.end_date)) {
      fileName = `Payment Issue Report - ${srName}${
        dateFormat(dateFilter.start_date).displayDate
      } to ${dateFormat(dateFilter.end_date).displayDate} #${dateTimeFormat(moment())}`

      if (dateFilter.start_date === dateFilter.end_date) {
        fileName = `Payment Issue Report - ${srName}${
          dateFormat(dateFilter.start_date).displayDate
        } #${dateTimeFormat(moment())}`
      }
    } else {
      fileName = `Payment Issue Report ${srName}`
    }

    await blobXlsRequest(apiURL, fileName, true, setDownloadOdooButtonDisabled)
  }

  const downloadGatePassReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    const dateFilter = {start_date: '', end_date: ''}
    const startDate = localStorage.getItem('Gatepass-start-date') || ''
    const endDate = localStorage.getItem('Gatepass-end-date') || ''
    const selectedWarehouseId = localStorage.getItem('Gatepass-warehouse')
    const selectedEntry = localStorage.getItem('Gatepass-entry-value')
    const selectedSr = localStorage.getItem('Gatepass-sr-value') || ''
    const selectedSrName = localStorage.getItem('Gatepass-sr-name') || ''

    let apiURL: string = ''
    let fileName: string = ''
    let srName: string = ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    if (!isEmpty(selectedSr)) {
      if (!isEmpty(dateFilter.start_date) && !isEmpty(dateFilter.end_date)) {
        srName = `${selectedSrName} - `
      } else {
        srName = `- ${selectedSrName} `
      }
    }

    setDownloadOdooButtonDisabled(true)

    apiURL = `${apiBaseURL()}reports/excel/gate-pass-report?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&date_duration_after=${dateFilter.start_date}&date_duration_before=${
      dateFilter.end_date
    }&sr_id=${selectedSr}&is_auto_handled=${isEmpty(selectedEntry) ? '' : selectedEntry}`

    if (!isEmpty(dateFilter.start_date) && !isEmpty(dateFilter.end_date)) {
      fileName = `Gatepass Report - ${srName}${dateFormat(dateFilter.start_date).displayDate} to ${
        dateFormat(dateFilter.end_date).displayDate
      } #${dateTimeFormat(moment())}`

      if (dateFilter.start_date === dateFilter.end_date) {
        fileName = `Gatepass Report - ${srName}${
          dateFormat(dateFilter.start_date).displayDate
        } #${dateTimeFormat(moment())}`
      }
    } else {
      fileName = `Gatepass Report ${srName}`
    }

    await blobXlsRequest(apiURL, fileName, true, setDownloadOdooButtonDisabled)
  }

  const downloadPreOrderReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    const dateFilter = {start_date: '', end_date: ''}
    const startDate = localStorage.getItem('pre-order-start-date') || ''
    const endDate = localStorage.getItem('pre-order-end-date') || ''
    const status = localStorage.getItem('pre-order-status') || ''
    const search = localStorage.getItem('pre-order-search') || ''
    const selectedWarehouseId = localStorage.getItem('pre-order-warehouse')

    let fileName: string = ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    setDownloadPreOrderButtonDisabled(true)

    let apiURL = `${apiBaseURL()}reports/pre-order/excel?search=${search}&warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}&status=${status}`

    if (!isEmpty(dateFilter.start_date) && !isEmpty(dateFilter.end_date)) {
      fileName = `Pre Order Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
        dateFormat(dateFilter.end_date).displayDate
      } #${dateTimeFormat(moment())}`

      if (dateFilter.start_date === dateFilter.end_date) {
        fileName = `Pre Order Report - ${
          dateFormat(dateFilter.start_date).displayDate
        } #${dateTimeFormat(moment())}`
      }
    } else {
      fileName = `Pre Order Report`
    }

    await blobXlsRequest(apiURL, fileName, true, setDownloadPreOrderButtonDisabled)
  }

  const downloadDailyComplaintReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    let apiURL: string = ''
    let fileName: string = ''

    const dateFilter = initialDateFilter

    const startDate = localStorage.getItem('support_request_start_date') || ''
    const endDate = localStorage.getItem('support_request_end_date') || ''

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      dateFilter.start_date = startDate
      dateFilter.end_date = endDate
    }

    // const selectedWarehouseId = localStorage.getItem('support_request_warehouse')

    const selectedWarehouse = isEmpty(localStorage.getItem('support_request_warehouse'))
      ? ''
      : JSON.parse(localStorage.getItem('support_request_warehouse') || '')

    if (!isEmpty(selectedWarehouse)) {
      warehouseId = selectedWarehouse?.value ?? ''
    }

    if (isEmpty(warehouseId)) {
      warehouseId = ''
    }
    const customerId = localStorage.getItem('customer') || ''
    const status = localStorage.getItem('status') || ''
    const search = localStorage.getItem('search') || ''

    let moduleView: boolean = false

    if (['/all-complaints'].includes(location.pathname)) {
      moduleView = true
    }

    setDownloadComplaintButtonDisabled(true)
    apiURL = `${apiBaseURL()}complaint_request/export-complaint-request?warehouse_id=${warehouseId}&date_duration_after=${
      dateFilter.start_date
    }&date_duration_before=${
      dateFilter.end_date
    }&customer=${customerId}&status=${status}&search=${search}&module_view=${moduleView}`

    fileName = `Daily Complaint Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
      dateFormat(dateFilter.end_date).displayDate
    } #${dateTimeFormat(moment())}`
    if (dateFilter.start_date === dateFilter.end_date) {
      fileName = `Daily Complaint Report - ${
        dateFormat(dateFilter.start_date).displayDate
      } #${dateTimeFormat(moment())}`
    }
    await blobXlsRequest(apiURL, fileName, true, setDownloadComplaintButtonDisabled)
  }

  const downloadDormantCustomerReport = async () => {
    const userType = localStorage.getItem('user_type') || ''
    let warehouseId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id')

    let apiURL: string = ''
    let fileName: string = ''

    const dateFilter = initialDateFilter

    const selectedWarehouseId = localStorage.getItem('dormant-selected-wid')
    const selectedType = localStorage.getItem('dormant-selected-type')

    if (!isEmpty(selectedWarehouseId)) {
      warehouseId = selectedWarehouseId
    }

    setDownloadOdooButtonDisabled(true)
    apiURL = `${apiBaseURL()}reports/dormant-customers-report-excel?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&created_by_type=${isEmpty(selectedType) ? '' : selectedType}`
    fileName = `Dormant Customer Report - ${dateFormat(dateFilter.start_date).displayDate} to ${
      dateFormat(dateFilter.end_date).displayDate
    } #${dateTimeFormat(moment())}`
    if (dateFilter.start_date === dateFilter.end_date) {
      fileName = `Dormant Customer Report - ${
        dateFormat(dateFilter.start_date).displayDate
      } #${dateTimeFormat(moment())}`
    }
    await blobXlsRequest(apiURL, fileName, true, setDownloadOdooButtonDisabled)
  }

  const downloadCustomerReport = async () => {
    setDownloadButtonDisabled(true)
    await blobXlsRequest(
      `${apiBaseURL()}customers/all-customer-report/${currentWarehouseId}`,
      `All Customers Report - ${checkEmpty(warehouseName, '-')} - Lifetime #${dateTimeFormat(
        moment()
      )}`,
      true,
      setDownloadButtonDisabled
    )
  }

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />
        {['sa', 'am', 'wm', 'oo'].includes(userType.toLowerCase()) &&
          ['/credit-issues'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center ms-auto'
              onClick={() => downloadCreditIssuesReport()}
              disabled={downloadCreditIssueButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadCreditIssueButtonDisabled ? 'Please Wait...' : 'Credit Issues Report'}
            </button>
          )}
        {['sa', 'am', 'wm', 'oo'].includes(userType.toLowerCase()) &&
          ['/reports/sales-invoice-report', '/sales-invoices'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center ms-auto'
              onClick={() => downloadDailySrReport()}
              disabled={downloadSrButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadSrButtonDisabled ? 'Please Wait...' : 'Daily SR Sales Report'}
            </button>
          )}

        {['am', 'sa', 'oo', 'wm'].includes(userType.toLowerCase()) &&
          ['/sales-invoices', '/reports/sales-invoice-report'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
              onClick={() => downloadReport('sales')}
              disabled={downloadSalesButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadSalesButtonDisabled ? 'Please Wait...' : 'Daily Sales Report'}
            </button>
          )}

        {['ts', 'sa', 'wm'].includes(userType.toLowerCase()) &&
          ['/reports/no-ordering-customer-report'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
              onClick={() => downloadReport('no-ordering-customer')}
              disabled={downloadNoOrderingCustomerButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadNoOrderingCustomerButtonDisabled
                ? 'Please Wait...'
                : 'Customer No Ordering Report'}
            </button>
          )}
        {['am'].includes(userType.toLowerCase()) && ['/dashboard'].includes(location.pathname) && (
          <button
            type='button'
            className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center ml-4'
            onClick={() => downloadReport('customer')}
            disabled={downloadCustomerButtonDisabled}
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
            {downloadCustomerButtonDisabled ? 'Please Wait...' : 'DMS Daily Customers Report'}
          </button>
        )}
        {['wm'].includes(userType.toLowerCase()) && ['/customers'].includes(location.pathname) && (
          <button
            type='button'
            className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center ml-4'
            disabled={downloadButtonDisabled}
            onClick={downloadCustomerReport}
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
            {downloadButtonDisabled ? 'Pleas Wait...' : 'All Customers - Lifetime'}
          </button>
        )}

        {['am', 'wm'].includes(userType.toLowerCase()) &&
          ['/sales-invoices', '/reports/sales-invoice-report'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
              onClick={() => downloadOdooReport()}
              disabled={downloadOdooButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadOdooButtonDisabled ? 'Please Wait...' : 'Sales Report for Odoo'}
            </button>
          )}

        {checkUserType(['sa', 'wm', 'am']) &&
          ['/reports/credit-collection-report'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
              onClick={() => downloadCreditCollectionReport()}
              disabled={downloadOdooButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadOdooButtonDisabled ? 'Please Wait...' : 'Credit Collection Report'}
            </button>
          )}

        {['/payment-issues', '/reports/payment-issues'].includes(location.pathname) && (
          <button
            type='button'
            className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
            onClick={() => downloadPaymentIssueReport()}
            disabled={downloadOdooButtonDisabled}
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
            {downloadOdooButtonDisabled ? 'Please Wait...' : 'Payment Issue Report'}
          </button>
        )}

        {['/reports/dormant-customer-report'].includes(location.pathname) && (
          <div className='d-flex justify-content-center align-items-center'>
            <InfoIcon message='In this report, we are considering customers who have not placed an order in the past two months.' />

            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
              onClick={() => downloadDormantCustomerReport()}
              disabled={downloadOdooButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadOdooButtonDisabled ? 'Please Wait...' : 'Dormant Customer Report'}
            </button>
          </div>
        )}

        {['/reports/gatepass-report'].includes(location.pathname) && (
          <button
            type='button'
            className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
            onClick={() => downloadGatePassReport()}
            disabled={downloadOdooButtonDisabled}
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
            {downloadOdooButtonDisabled ? 'Please Wait...' : 'Gatepass Report'}
          </button>
        )}

        {['/reports/order-report'].includes(location.pathname) && (
          <button
            type='button'
            className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
            onClick={() => downloadPreOrderReport()}
            disabled={downloadPreOrderButtonDisabled}
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
            {downloadPreOrderButtonDisabled ? 'Please Wait...' : 'Pre Order Report'}
          </button>
        )}

        {['sa', 'am', 'wm', 'ts'].includes(userType.toLowerCase()) &&
          ['/reports/complaint-report', '/all-complaints'].includes(location.pathname) && (
            <button
              type='button'
              className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center ms-0'
              onClick={() => downloadDailyComplaintReport()}
              disabled={downloadComplaintButtonDisabled}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
              {downloadComplaintButtonDisabled ? 'Please Wait...' : 'Daily Complaint Report'}
            </button>
          )}

        {checkUserType(['wm', 'sa']) &&
          ['/reports/attendance-report'].includes(location.pathname) && (
            <div id='sa-wm-attendance-report-filter'></div>
          )}

        {checkUserType(['wm', 'am']) && ['/targets'].includes(location.pathname) && (
          <div id='am-wm-target-export-button'></div>
        )}

        {checkRoleName([logisticsManager]) && ['/dashboard'].includes(location.pathname) && (
          <div id='lo-dashboard-filter'></div>
        )}

        {checkUserType(['ts']) && ['/dashboard'].includes(location.pathname) && (
          <div id='ts-dashboard-filters'></div>
        )}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar1}
