import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { KTSVG, apiBaseURL, fetchAsyncDropdown, isEmpty } from "../../../../_eaFruitsDms/helpers"
import { EaAlertSuccess, } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton"
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title"
import { PageTitle } from "../../../../_eaFruitsDms/layout/core"
import { getRequest, postRequest, putRequest } from "../../../../_eaFruitsDms/https/apiCall"
import tenantConfiguration from "../../../../TenantVariables"
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce"
import FetchingRecords from "../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords"
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import CustomPaginate from "../../../../_eaFruitsDms/layout/components/pagination/customPaginate"
import { AsyncPaginate } from "react-select-async-paginate"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler"

const SalesTeam = () => {
    const intl = useIntl()
    const initialValid = { product_groups: '', name: '' }

    const [selected, setSelected] = useState<number>(0)
    const [currentOffset, setCurrentOffset] = useState<number>(0)
    const [search, setSearch] = useState('')
    const [salesTeam, setSalesTeam] = useState([])
    const [count, setCount] = useState<string>('1')
    const [fetching, setFetching] = useState(true)
    const [id, setId] = useState('')
    const [groups, setGroups] = useState<any>([])
    const [teamName, setTeamName] = useState('')
    const [isValid, setIsValid] = useState(initialValid)
    const [buttonHandler, setButtonHandler] = useState('Submit')
    const [error, setError] = useState('')
    const [offset, setOffset] = useState(0)
    const [list, setList] = useState([])
    const [alert, setIsAlert] = useState(false)
    const [dropDownSearch, setDropDownSearch] = useState('')
    const [message, setMessage] = useState('')
    const [isForHoreca, setIsForHoreca] = useState(false)
    const [productId, setProductId] = useState([])
    const [loading, setLoading] = useState(false)

    const debounceSearch = useDebounce(search, 500);

    useEffect(() => {
        getAllProductIds()
    }, [])
    
    const getAllProductIds = async() => {
        const url = `${apiBaseURL()}master/product/group?limit=1000`
        const response = await getRequest(url, true)

        if(response && response.results){
            const productData = response.results

            setProductId(productData.map((i:any) => i.id))            
        }
    }

    const fetchingRecords = async () => {
        setFetching(true)
        const param = `limit=${tenantConfiguration.limit}&offset=${currentOffset}&search=${debounceSearch}`
        const url = apiBaseURL() + 'master/sales-team?' + param
        const response = await getRequest(url, true)
        if (response && response.results) {
            setSalesTeam(response.results)
            setCount(response.count)
        }
        setFetching(false)
    }

    const fetchProductGroups = async (search: any) => {
        return fetchAsyncDropdown(offset, setOffset, list, setList, 'master/product/group?ordering=name', search, setDropDownSearch, "name", false)
    }

    const validation = (e: any, name: any) => {
        let valid: any = { ...isValid }
        const value = e.target ? e.target.value : e
        if (isEmpty(value) || value.length === 0) {
            valid[name] = 'is-invalid'
        } else {
            valid[name] = ''
        }
        setIsValid(valid)
    }

    const isClear = () => {
        setLoading(false)
        setIsValid(initialValid)
        setIsForHoreca(false)
        setOffset(0)
        setList([])
        setDropDownSearch('')
        setTeamName('')
        setGroups([])
        setButtonHandler('Submit')
        setTimeout(() => {
            setIsAlert(false)
        }, 2000);
        setError('')
    }

    const submit = async () => {
        // UI validation
        setIsValid({
            name: teamName === '' ? 'is-invalid' : '',
            product_groups: !isForHoreca && groups.length === 0 ? 'is-invalid' : ''
        })

        if (teamName !== "" && groups.length > 0) {
            setButtonHandler('Please Wait')
            let productGroups: string[] = []
            groups.length > 0 && groups.forEach((i: any) => {
                productGroups.push(i.value)
            })

            let body = {
                name: teamName,
                product_groups: isForHoreca ? productId : productGroups,
                is_for_horeca:isForHoreca
            }

            let response = null
            let param = id === '' ? 'create' : `${id}/edit`
            let url = apiBaseURL() + 'master/sales-team/' + param

            if (id === '') {
                response = await postRequest(url, body, true)
            } else {
                response = await putRequest(url, body, true)
            }

            if (response.status === 201 || response.status === 200) {
                setIsAlert(true)
                if (id === '') {
                    setMessage("Sales team created successfully.")
                } else {
                    setMessage("Sales team updated successfully.")
                }
                $('#closeAddEaModalTeam').trigger('click') // modal close
                isClear()
                fetchingRecords()
            }

            if (response.status === 400) {
                setButtonHandler('Submit')
                setError('Your added sales team name already exists in this system! Please try again with a unique sales team name.')
            }
        }
    }

    const getSingleRecords = async (id: string) => {
        setLoading(true)

        const url = apiBaseURL() + 'master/sales-team/' + id
        const response = await getRequest(url, true)
        if (response && response.result) {
            const { name, product_groups, is_for_horeca } = response.result
            setIsForHoreca(is_for_horeca ?? false)
            let productGroups: object[] = []

            product_groups && product_groups.length > 0 && product_groups.forEach((item: any) => {
                productGroups.push({
                    label: item.name, value: item.id
                })
            })

            setTeamName(name)
            setGroups(productGroups)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchingRecords()
    }, [currentOffset, debounceSearch])

    const ShowGroupsToolTip = (product: any) => {
        if (product && product.length > 0) {
            if (product.length > 1) {
                let productArray = product.filter((i: any, index: number) => (index !== 0))
                return <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                        <Tooltip {...props} className="tooltip-dark">
                            {productArray.map((item: any, index: any) => {
                                return (
                                    <>
                                        <span>
                                            {index ? "," : ""} {item.name}{" "}
                                        </span>
                                    </>
                                );
                            })}
                        </Tooltip>
                    )}
                    placement="top"
                >
                    <span className="ms-2 cursor-pointer">
                        {product[0].name}
                        {` & ${productArray.length} more`}
                    </span>
                </OverlayTrigger>
            } else {
                return (
                    <span className="ms-2 cursor-pointer">
                        {product[0].name}
                    </span>
                )
            }
        }
    }

    return (
        <>
            <div>
                <EATitle title='Sales Team - ' />
                <PageTitle breadcrumbs={[{ title: 'Master' }]}>{intl.formatMessage({ id: 'Sales Team' })}</PageTitle>
                <EaAlertSuccess
                    onClick={() => { setIsAlert(false) }}
                    show={alert}
                    message={message}
                />
                <div className='card'>
                    {fetching && <IsLoader />}
                    <div className='card-header border-0 px-7'>
                        <div className='card-toolbar row g-0 w-100'>
                            <div className='col-12 col-md-auto mt-0'>
                                <CustomSearchBar
                                    onChange={(e: any) => {
                                        setSearch(e.target.value)
                                        setCurrentOffset(0)
                                        setSelected(0)
                                    }}
                                    value={search} isDisable={fetching}
                                />
                            </div>
                            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                                <EaButton
                                    isModal={true}
                                    dataTarget='#ea_modal_sales'
                                    btnName='Add Team'
                                    onClick={() => {
                                        setId('')
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-body py-4'>
                        <div className='table-responsive'>
                            {
                                fetching ? <FetchingRecords /> :
                                    salesTeam.length === 0 ? <NoRecords /> :
                                        <table className='table table-row-bordered align-middle gs-0 gy-2'>
                                            <thead>
                                                <tr className=' text-muted fw-500'>
                                                    <th className='min-w-100px py-2'>Name</th>
                                                    <th className='min-w-100px py-2'>Product Groups</th>
                                                    <th className='min-w-80px action-th py-2'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    salesTeam.length > 0 && salesTeam.map((item: any) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                    {
                                                                        item.is_for_horeca ?  <span className="ms-2 cursor-pointer">All Product Groups</span> :
                                                                    <>
                                                                    {
                                                                        item.product_groups && item.product_groups.length > 0 ? ShowGroupsToolTip(item.product_groups) : '-'
                                                                    }
                                                                    </>
                                                                    }
                                                                </td>
                                                                <td className='text-end'>
                                                                    <a
                                                                        href='javascript:void(0)'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-target='#ea_modal_sales'
                                                                        onClick={(event) => {
                                                                            setId(item.id)
                                                                            getSingleRecords(item.id)
                                                                        }}
                                                                        className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-2'
                                                                    >
                                                                        <KTSVG
                                                                            path='/media/icons/duotune/art/art005.svg'
                                                                            className='ic mr-0'
                                                                        />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                            }
                        </div>
                    </div>
                </div>
                <CustomPaginate
                    data={salesTeam}
                    selected={selected}
                    setSelected={setSelected}
                    setCurrentOffset={setCurrentOffset}
                    totalRecords={count}
                    limit={tenantConfiguration.limit}
                    isDisable={fetching}
                    saveFilterName={'feedbackReport'}
                    filterStates={''}
                />
            </div>

            <EaModal
                id='ea_modal_sales'
                modalTitle={id === '' ? 'Add Team' : 'Edit Team'}
                onSubmit={submit}
                closeAuto='closeAddEaModalTeam'
                cancel={isClear}
                actionBtnName={buttonHandler}
                btnDisabled={buttonHandler !== "Submit" ? true : false}
                loader={loading}
            >
                <div className='mb-3'>
                    <label className='form-label'>
                        Name<span className="text-danger">*</span>
                    </label>
                    <input
                        type='text'
                        className={clsx('form-control', isValid.name)}
                        placeholder='Type here...'
                        onChange={(e) => {
                            setTeamName(e.target.value)
                            validation(e, 'name')
                        }}
                        value={teamName}
                    />
                </div>

                <div className="mb-3">
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <label className="form-check-label m-0">
                    <input
                        className="form-check-input widget-13-check me-2"
                        name="checkValue"
                        checked={isForHoreca}
                        type="checkbox"
                        onChange={(e: any) => {
                          setIsForHoreca(e.target.checked)
                          e.target.checked ? setGroups(productId) : setGroups([])
                          setIsValid({...isValid ,product_groups:""})
                        }}
                        value=""
                    />
                    This is for HORECA Customers
                    </label>
                </div>
            </div>

                <div className="mb-4">
                    <label className='form-label'>
                        Product Groups<span className="text-danger">*</span>
                    </label>
                    <AsyncPaginate
                        loadOptions={fetchProductGroups}
                        isSearchable
                        placeholder="All Product Groups"
                        className={`react-select-container ${isValid.product_groups}`}
                        classNamePrefix="react-select"
                        name='product_groups'
                        isMulti={!isForHoreca}
                        isDisabled={isForHoreca}
                        onChange={(e) => {
                            setCurrentOffset(0)
                            setSelected(0)
                            setGroups(e)
                            validation(e, 'product_groups')
                        }}
                        value={isForHoreca ? {label:"All Product Groups", value:"-"} : groups}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#0c78a4',
                            },
                        })}
                    />
                </div>

                

                <div className="mb-3">
                    <ErrorHandler errorMessage={error} />
                </div>
            </EaModal>
        </>
    )
}

export default SalesTeam