import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  KTSVG,
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
} from '../../../../../../_eaFruitsDms/helpers'
import {EaAlertSuccess} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../../../_eaFruitsDms/layout/components/button/eaButton'
import IsLoader from '../../../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import {
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from '../../../../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../../../../TenantVariables'
import useDebounce from '../../../../../../_eaFruitsDms/helpers/components/useDebounce'
import FetchingRecords from '../../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import CustomSearchBar from '../../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import CustomPaginate from '../../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {AsyncPaginate} from 'react-select-async-paginate'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ErrorHandler from '../../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import ShowingCount from '../../../../../../_eaFruitsDms/layout/components/pagination/showingCount'
import {Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'

const IncentiveComponents = (props: any) => {
  const intl = useIntl()
  const initialValid = {product_groups: '', name: ''}
  const warehouseId = localStorage.getItem('wId')

  const [search, setSearch] = useState('')
  const [incentive, setIncentive] = useState<any>({})
  const [incentiveSingle, setIncentiveSingle] = useState<any>({})
  const [count, setCount] = useState<string>('1')
  const [fetching, setFetching] = useState(true)
  const [id] = useState(warehouseId)
  const [buttonHandler, setButtonHandler] = useState('Submit')
  const [error, setError] = useState('')
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [isNextMonthChecked, setIsNextMonthChecked] = useState(true)
  const [modalFetching, setModalFetching] = useState(true)
  const [isNextMonthCheckedFilter, setIsNextMonthCheckedFilter] = useState(false)
  const [isCurrentMonthApplyPopup, setCurrentMonthApplyPopup] = useState(false)

  const fetchingRecords = async () => {
    setFetching(true)
    const url =
      apiBaseURL() +
      'master/kpi/settings/' +
      id +
      '/incentive-components?show_future=' +
      isNextMonthCheckedFilter

    const response = await getRequest(url, true)

    if (response && response.result) {
      setIncentive(response.result)
      setIncentiveSingle(response.result)
    }

    setFetching(false)
  }

  const location: any = useLocation()

  useEffect(() => {
    if (location.state && location.state.timeStrap && !isEmpty(location.state.timeStrap)) {
      fetchingRecords()
    }
  }, [location])

  const isClear = () => {
    setIncentiveSingle({})
    setIsNextMonthChecked(true)
    setButtonHandler('Submit')
    setTimeout(() => {
      setIsAlert(false)
    }, 2000)
    setError('')
    setCurrentMonthApplyPopup(false)
  }

  const submit = async (type: string) => {
    if (checkValid()) {
      setButtonHandler('Please Wait')
      const url = apiBaseURL() + 'master/kpi/settings/' + id + '/incentive-components/update'

      const body = {
        fixed_incentive_defined: incentiveSingle.fixed_incentive_defined,
        revenue_incentive_defined: incentiveSingle.revenue_incentive_defined,
        bonus_incentive_perc_defined: incentiveSingle.bonus_incentive_perc_defined,
        revenue_incentive_atleast_defined: incentiveSingle.revenue_incentive_atleast_defined,
        discount_prec_atmost_defined: incentiveSingle.discount_prec_atmost_defined,
        achieved_perc_atleast_defined: incentiveSingle.achieved_perc_atleast_defined,
        is_applicable_from_next_month: isNextMonthChecked,
      }

      const response = await patchRequest(url, body, true)

      if (response.status === 200) {
        setIsAlert(true)
        setMessage(
          type === 'submit'
            ? 'Incentive Component updated successfully.'
            : `Your change(s) saved successfully! It may take some time while reflecting across all Sales Reps' Target KPIs & Incentives!`
        )
        $('#closeAddEaModalTeam').trigger('click') // modal close
        isClear()
        fetchingRecords()
        props.setIncentiveAdded(true)
      }

      if (response.status === 400) {
        setButtonHandler('Submit')
        setError('')
      }
    } else {
      setCurrentMonthApplyPopup(false)
    }
  }

  const checkValid = () => {
    let isValid = true
    let incentive = {...incentiveSingle}

    if (isEmpty(incentive.fixed_incentive_defined) || incentive.fixed_incentive_defined === 0) {
      incentive.fixed_incentive_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.fixed_incentive_defined_valid = ''
    }

    if (isEmpty(incentive.revenue_incentive_defined) || incentive.revenue_incentive_defined === 0) {
      incentive.revenue_incentive_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.revenue_incentive_defined_valid = ''
    }

    if (
      isEmpty(incentive.revenue_incentive_atleast_defined) ||
      incentive.revenue_incentive_atleast_defined === 0
    ) {
      incentive.revenue_incentive_atleast_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.revenue_incentive_atleast_defined_valid = ''
    }

    if (
      !checkPercentage(incentive.bonus_incentive_perc_defined) ||
      incentive.bonus_incentive_perc_defined === 0
    ) {
      incentive.bonus_incentive_perc_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.bonus_incentive_perc_defined_valid = ''
    }

    if (
      !checkPercentage(incentive.discount_prec_atmost_defined) ||
      incentive.discount_prec_atmost_defined === 0
    ) {
      incentive.discount_prec_atmost_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.discount_prec_atmost_defined_valid = ''
    }

    if (
      !checkPercentage(incentive.achieved_perc_atleast_defined) ||
      incentive.achieved_perc_atleast_defined === 0
    ) {
      incentive.achieved_perc_atleast_defined_valid = 'is-invalid'
      isValid = false
    } else {
      incentive.achieved_perc_atleast_defined_valid = ''
    }

    setIncentiveSingle(incentive)

    return isValid
  }

  const checkPercentage = (num: any) => {
    const value = parseFloat(num)
    let valid = true
    if (isNaN(value) || value < 0 || value > 100) {
      valid = false
    }

    return valid
  }

  const onChangeInput = (e: any, isPercentage?: boolean) => {
    let incentive: any = {...incentiveSingle}
    const value = parseFloat(e.target.value)

    if (isPercentage) {
      if (isNaN(value) || value < 0 || value > 100) {
        incentive[`${e.target.name}_valid`] = 'is-invalid'
      } else {
        incentive[`${e.target.name}_valid`] = ''
      }
      incentive[e.target.name] = e.target.value
    } else {
      const re = /^[0-9\b]+$/
      if (e.target.value === '' || re.test(e.target.value)) {
        incentive[e.target.name] = e.target.value
        if (e.target.value === '') {
          incentive[`${e.target.name}_valid`] = 'is-invalid'
        } else {
          incentive[`${e.target.name}_valid`] = ''
        }
      }
    }

    setIncentiveSingle(incentive)
  }

  const getSingleRecords = async () => {
    setModalFetching(true)
    const url =
      apiBaseURL() +
      'master/kpi/settings/' +
      id +
      '/incentive-components?show_future=' +
      isNextMonthCheckedFilter
    const response = await getRequest(url, true)
    if (response && response.result) {
      setIncentiveSingle(response.result)
    }
    setModalFetching(false)
  }

  useEffect(() => {
    fetchingRecords()
  }, [isNextMonthCheckedFilter])

  const CustomToolTip = (props: any) => {
    const {text, jsx} = props
    return (
      <OverlayTrigger
        delay={{hide: 450, show: 300}}
        overlay={(props) => (
          <Tooltip {...props} className='tooltip-dark'>
            <span>{text}</span>
            {jsx}
          </Tooltip>
        )}
        placement='top'
      >
        <span className='ms-2 cursor-pointer'>
          <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
        </span>
      </OverlayTrigger>
    )
  }

  return (
    <>
      <div>
        <EATitle title='Incentive Components - ' />
        <PageTitle breadcrumbs={[{title: 'Master'}]}>
          {intl.formatMessage({id: 'Incentive Components'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => {
            setIsAlert(false)
          }}
          show={alert}
          message={message}
        />
        <div className='card'>
          <div className='card-body py-4'>
            <div className=''>
              {fetching ? (
                <FetchingRecords />
              ) : incentive.length === 0 ? (
                <NoRecords />
              ) : (
                <>
                  <div className='row'>
                    <div className='col-md-3 mb-3'>
                      <div className='font-bold'>
                        Fixed Incentive{' '}
                        <CustomToolTip
                          text={`To get this incentive, the Sales Reps needs to meet the set target on KPIsin a month!`}
                        />
                      </div>
                      <div>
                        {numberFormatWithCode(incentive.fixed_incentive_defined).displayFormat}
                      </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <div className='font-bold'>
                        Revenue Incentive{' '}
                        <CustomToolTip
                          text={`This incentive is prorated based on the Revenue Target achievement in a month!`}
                        />
                      </div>
                      <div>
                        {numberFormatWithCode(incentive.revenue_incentive_defined).displayFormat}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3 mb-5'>
                      <div className='font-bold'>Bonus Incentive</div>
                      <div className='font-14 text-dark'>
                        {incentive.bonus_incentive_perc_defined} % of revenue achieved
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4 border-bottom mb-4'>
                      <div className='font-bold'>
                        Conditions for bonus incentive eligibility{' '}
                        <CustomToolTip
                          text={`The Sales Reps will be eligible for Bonus Incentive in a month if they
                          achieve the below.`}
                          jsx={
                            <>
                              <br />
                              <br />
                              <div>
                                - Revenue at least{' '}
                                {
                                  numberFormatWithCode(incentive.revenue_incentive_atleast_defined)
                                    .displayFormat
                                }
                              </div>
                              <br />
                              <div>
                                - Discounts KPI is less than{' '}
                                {
                                  numberFormat(incentive.discount_prec_atmost_defined, true)
                                    .displayFormat
                                }
                                %
                              </div>
                              <br />
                              <div>
                                - Achieve at least{' '}
                                {
                                  numberFormat(incentive.achieved_perc_atleast_defined, true)
                                    .displayFormat
                                }
                                % of the Fixed Incentive
                              </div>
                              <br />
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-3 mb-4'>
                      <div className='text-muted'>Min. Revenue Achievement</div>
                      <div>
                        {
                          numberFormatWithCode(incentive.revenue_incentive_atleast_defined)
                            .displayFormat
                        }
                      </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                      <div className='text-muted'>Total Discount given not than</div>
                      <div>
                        {numberFormat(incentive.discount_prec_atmost_defined, true).displayFormat}%
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-3 mb-4'>
                      <div className='text-muted'>Min. Fixed Incentive Achievement</div>
                      <div>{incentive.achieved_perc_atleast_defined}%</div>
                    </div>
                  </div>

                  <div className='d-flex align-center'>
                    <EaButton
                      isModal={true}
                      onClick={() => {
                        getSingleRecords()
                      }}
                      dataTarget='#ea_modal_incentive'
                      btnName='Update Incentive Components'
                    />

                    <div className='ms-4'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <label className='form-check-label m-0'>
                          <input
                            className='form-check-input me-2'
                            name='checkValue'
                            checked={isNextMonthCheckedFilter}
                            type='checkbox'
                            onChange={(e: any) => {
                              setIsNextMonthCheckedFilter(e.target.checked)
                            }}
                            value=''
                          />
                          Show Applicable From Next Month
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <EaModal
        id='ea_modal_incentive'
        modalTitle={
          incentiveSingle &&
          !isEmpty(incentiveSingle.warehouse_name) &&
          incentiveSingle.warehouse_name
        }
        subTitle={'Update Incentive Components'}
        widthcls='mw-570px'
        onSubmit={() => {
          if (isNextMonthChecked) {
            submit('submit')
          } else {
            setCurrentMonthApplyPopup(true)
          }
        }}
        closeAuto='closeAddEaModalTeam'
        cancel={isClear}
        actionBtnName={buttonHandler}
        btnDisabled={buttonHandler !== 'Submit' ? true : false}
        loader={modalFetching}
      >
        <div className='mb-3'>
          <div className='font-20 font-bold'>Fixed Incentive</div>
          <p>
            Add the incentive amount receivable to each Sales Representative if they quality for the
            KPIs set by Warehouse or Sales Manager.
          </p>
          <div className='col-sm-12 mt-4'>
            <div className={clsx('input-group')}>
              <span className='input-group-text bg-white border-end-0'>
                {tenantConfiguration.currencyCode}
              </span>
              <input
                onChange={(e) => {
                  onChangeInput(e)
                }}
                id='fixed_incentive_defined'
                value={incentiveSingle.fixed_incentive_defined}
                name='fixed_incentive_defined'
                type='text'
                className={clsx(
                  'form-control border-start-0 ps-0 border-focus-light',
                  incentiveSingle.fixed_incentive_defined_valid
                )}
                placeholder='Type Here...'
              />
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <div className='font-20 font-bold'>Revenue Incentive</div>
          <p>
            Add the incentive amount receivable to each Sales Representative if they meet the
            targeted revenue assigned by Sales Supervisor.
          </p>
          <div className='col-sm-12 mt-4'>
            <div className={clsx('input-group')}>
              <span className='input-group-text bg-white border-end-0'>
                {tenantConfiguration.currencyCode}
              </span>
              <input
                onChange={(e) => {
                  onChangeInput(e)
                }}
                id='revenue_incentive_defined'
                value={incentiveSingle.revenue_incentive_defined}
                name='revenue_incentive_defined'
                type='text'
                className={clsx(
                  'form-control border-start-0 ps-0 border-focus-light',
                  incentiveSingle.revenue_incentive_defined_valid
                )}
                placeholder='Type Here...'
              />
            </div>
          </div>
        </div>

        <div className='mb-4'>
          <div className='font-20 font-bold'>Bonus Incentive</div>
          <p>
            Add the percentage of achieved revenue as bonus incentive receivable if Sales
            Representative meet defined conditions below.
          </p>
          <div className='col-sm-8 mt-4'>
            <div className='p-1'>
              <div
                className={clsx(
                  'input-group  p-0',
                  incentiveSingle.bonus_incentive_perc_defined_valid === 'is-invalid'
                    ? 'is-invalid-grp'
                    : ''
                )}
              >
                <input
                  type='number'
                  id={'bonus_incentive_perc_defined'}
                  onChange={(e) => {
                    onChangeInput(e, true)
                  }}
                  placeholder='0'
                  value={incentiveSingle.bonus_incentive_perc_defined}
                  name='bonus_incentive_perc_defined'
                  className={clsx('form-control border-start-0')}
                />
                <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                  % Of Revenue Achieved
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <div className='font-bold'>Condition for bonus incentive eligibility</div>
          <hr />
          <div className='row mb-3'>
            <div className='col-md-6'>
              <div>Minimum Revenue Achievement</div>
            </div>
            <div className='col-md-6'>
              <div className={clsx('input-group')}>
                <span className='input-group-text bg-white border-end-0'>
                  {tenantConfiguration.currencyCode}
                </span>
                <input
                  onChange={(e) => {
                    onChangeInput(e)
                  }}
                  id='revenue_incentive_atleast_defined'
                  value={incentiveSingle.revenue_incentive_atleast_defined}
                  name='revenue_incentive_atleast_defined'
                  type='text'
                  className={clsx(
                    'form-control border-start-0 ps-0 border-focus-light',
                    incentiveSingle.revenue_incentive_atleast_defined_valid
                  )}
                  placeholder='Type Here...'
                />
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-md-6'>
              <div>Total Discount given not more than</div>
            </div>
            <div className='col-md-6'>
              <div className='p-1'>
                <div
                  className={clsx(
                    'input-group  p-0',
                    incentiveSingle.discount_prec_atmost_defined_valid === 'is-invalid'
                      ? 'is-invalid-grp'
                      : ''
                  )}
                >
                  <input
                    type='number'
                    id={'discount_prec_atmost_defined'}
                    onChange={(e) => {
                      onChangeInput(e, true)
                    }}
                    placeholder='0'
                    value={incentiveSingle.discount_prec_atmost_defined}
                    name='discount_prec_atmost_defined'
                    className={clsx('form-control border-start-0')}
                  />
                  <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-md-6'>
              <div>Minimum Achievement of Fixed incentive</div>
            </div>
            <div className='col-md-6'>
              <div className='p-1'>
                <div
                  className={clsx(
                    'input-group  p-0',
                    incentiveSingle.achieved_perc_atleast_defined_valid === 'is-invalid'
                      ? 'is-invalid-grp'
                      : ''
                  )}
                >
                  <input
                    type='number'
                    id={'achieved_perc_atleast_defined'}
                    onChange={(e) => {
                      onChangeInput(e, true)
                    }}
                    placeholder='0'
                    value={incentiveSingle.achieved_perc_atleast_defined}
                    name='achieved_perc_atleast_defined'
                    className={clsx('form-control border-start-0')}
                  />
                  <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        {!isNextMonthCheckedFilter && (
          <div className='mb-3'>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <label className='form-check-label m-0'>
                <input
                  className='form-check-input me-2'
                  name='checkValue'
                  checked={isNextMonthChecked}
                  type='checkbox'
                  onChange={(e: any) => {
                    setIsNextMonthChecked(e.target.checked)
                  }}
                  value=''
                />
                Apply the changes(s) from the next month!
              </label>
            </div>
          </div>
        )}
        <div className='mb-3'>
          <ErrorHandler errorMessage={error} />
        </div>
      </EaModal>

      <>
        <Modal show={isCurrentMonthApplyPopup} centered>
          <Modal.Header
            closeButton
            onClick={() => {
              setCurrentMonthApplyPopup(false)
            }}
          >
            <Modal.Title>{''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>
              The change(s) will impact the ongoing month's Target of all Sales Reps'
              KPIs/Incentives! Are you sure to apply them in the Current month?
            </b>
          </Modal.Body>
          <Modal.Footer
            style={{
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                setCurrentMonthApplyPopup(false)
              }}
              className='btn btn-light'
              disabled={buttonHandler !== 'Submit' ? true : false}
            >
              Cancel
            </button>
            <button
              disabled={buttonHandler !== 'Submit' ? true : false}
              onClick={() => submit('continue')}
              className='btn btn-primary'
            >
              {buttonHandler !== 'Submit' ? 'Please Wait' : 'Yes, Continue'}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default IncentiveComponents
