import clsx from "clsx";
import $ from "jquery";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
    apiBaseURL,
    checkEmpty,
    checkPermissions,
    dateFormat,
    defaultPermission,
    fetchAsyncDropdown,
    focusVatidateField,
    GLOBAL_DATE_FORMAT,
    googleApi,
    isModalValidation,
    staticSearch,
    toAbsoluteUrl
} from "../../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import { useExternalScript } from "../../../../_eaFruitsDms/helpers/components/scriptHandler";
import DatePickerCustom from "../../../../_eaFruitsDms/helpers/datePicker";
import { getRequest, patchRequest, postRequest, putRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import MobileNumberInput from "../../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput";
import EaModal from "../../../../_eaFruitsDms/layout/components/modal/eaModal";
import PlaceSearch from "../../../../_eaFruitsDms/layout/components/placeSearch/PlaceSearch";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { getUserId, getUserType } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import { validateEmail } from "../../../../_eaFruitsDms/validations/eaValidation";
import tenantConfiguration from "../../../../TenantVariables";
import AddBusinessDocuments from "../components/AddBusinessDocuments";
import Documents from "../components/Documents";
import {
    adminHeaderTitle,
    business,
    businessSize,
    customerTypes,
    familySize,
    getCustomerDetails,
    initialLatLong,
    premises,
    rejection,
    rejectionValidation
} from "./CustomerConst";

const ApproveOnBoardingRequest = (props: any) => {
    const navigate = useNavigate()
    const intl = useIntl()
    const customerId = localStorage.getItem('cId')
    const contactVerify = localStorage.getItem('contact_is_verified')
    const formComponentDisabled: boolean =
        props.overview === undefined ? false : contactVerify === 'false'

    const state = useExternalScript(googleApi)

    const initialCustomer = {
        id: customerId,
        business_name: '',
        type: '',
        nature_of_business: '',
        category_id: '',
        operations_since_view: "",
        operations_since: "",
        size: '',
        business_license: '',
        business_license_verify: '',
        is_address_verify: false,
        business_license_file: '',
        business_license_file_MIME: '',
        business_license_verify_on: '',
        business_license_verify_by: '',
        business_license_reject_on: '',
        business_license_reject_by: '',
        business_license_rejection_note: '',
        reg_certificate: '',
        registration_certificate_verify: '',
        reg_certificate_file: '',
        reg_certificate_file_MIME: '',
        registration_certificate_verify_on: '',
        registration_certificate_verify_by: '',
        registration_certificate_reject_on: '',
        registration_certificate_reject_by: '',
        registration_certificate_rejection_note: '',
        national_id: '',
        national_id_verify: '',
        national_id_file: '',
        national_id_file_MIME: '',
        national_id_verify_on: '',
        national_id_verify_by: '',
        national_id_reject_on: '',
        national_id_reject_by: '',
        national_id_rejection_note: '',
        name: '',
        contact_no: '',
        contact_is_verified: false,
        email: '',
        district_id: '',
        ward_id: '',
        street_id: '',
        pin_code: '',
        dob_of_promoter: '',
        premises_type: '',
        credit_range_id: '',
        credit_amount_cap: '',
        credit_cycle: '',
        prior_relationship: false,
        personal_family_size: '',
        note: '',
        document: '',
        address: '',
        latitude: '',
        longitude: '',
        place_id: '',
        customer_logo: '',
        tin_number: 0,
        vrn_number: 0,
        billing_address: '',
        billing_pin_code: '',
        billing_district_id: "",
        billing_ward_id: "",
        billing_street_id: "",
        same_business_address: false
    }

    const initialCustomerValidation = {
        business_name: '',
        type: '',
        nature_of_business: '',
        category_id: '',
        operations_since: '',
        size: '',
        business_license: '',
        reg_certificate: '',
        national_id: '',
        business_license_file: '',
        reg_certificate_file: '',
        national_id_file: '',
        name: '',
        contact_no: '',
        email: '',
        dob_of_promoter: '',
        premises_type: '',
        credit_range_id: '',
        credit_amount_cap: '',
        credit_cycle: '',
        personal_family_size: '',
        document: '',
        district_id: '',
        ward_id: '',
        address: '',
        street_id: '',
        customer_logo: '',
        billing_address: "",
        billing_district_id: "",
        billing_ward_id: "",
        billing_street_id: "",
    }

    const initialDateSetting: any = {
        startDate: moment(),
        maxDate: moment(),
        alwaysShowCalendars: true,
        autoUpdateInput: false,
        cancelClass: 'btn-secondary',
        singleDatePicker: true,
        locale: {
            format: GLOBAL_DATE_FORMAT,
        },
        drops: 'auto',
        showDropdowns: true,
    }

    const documentType: any = {
        business_license: false,
        reg_certificate: false,
        national_id: false,
    }

    const [date, setDate] = useState<any>('')
    const [isCalenderOpen, setIsCalenderOpen] = useState<boolean>(false)
    const [customer, setCustomer] = useState<any>(initialCustomer)
    const [documentsRequired, setDocumentsRequired] = useState<any>(documentType)
    const [documentNumberRequired, setDocumentNumberRequired] = useState<any>(documentType)
    const [customerDetail, setCustomerDetail] = useState<any>(initialCustomer)
    const [customerValidation, setCustomerValidation] = useState<any>(initialCustomerValidation)
    const [value, onChange] = useState(new Date())
    const [selectedAddress, setSelectedAddress] = useState<string>('')
    const [placeId, setPlaceId] = useState<string>('')
    const [latitudeLongitude, setLatitudeLongitude] = useState<any>(initialLatLong)
    const [postalCode, setPostalCode] = useState<string>('')
    const [searchSelect, setSearchSelect] = useState<string>('')
    const [limit, setLimit] = useState<number>(10)
    const [customerCategoryOffSet, setCustomerCategoryOffSet] = useState<any>('')
    const [customerCategories, setCustomerCategories] = useState<any>([])
    const [creditRangeOffSet, setCreditRangeOffSet] = useState<any>('')
    const [creditRanges, setCreditRanges] = useState<any>([])
    const [customerTagOffSet, setCustomerTagOffSet] = useState<any>('')
    const [customerTags, setCustomerTags] = useState<any>([])
    const [districtOffSet, setDistrictOffSet] = useState<any>('')
    const [districts, setDistricts] = useState<any>([])
    const [wardOffSet, setWardOffSet] = useState<any>('')
    const [wards, setWards] = useState<any>([])
    const [streetOffSet, setStreetOffSet] = useState<any>('')
    const [streets, setStreets] = useState<any>([])
    const [isButtonLoader, setIsButtonLoader] = useState<any>('Submit')
    const [approveButton, setApproveButton] = useState<any>('Save & Approve')
    const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
    const [isAlertFailed, setIsAlertFailed] = useState(false)
    const [isLatLongValidation, setIsLatLongValidation] = useState(false)
    const [failedMessage, setFailedMessage] = useState<string>('Failed')
    const [initialSettings, setInitialSettings] = useState<any>(initialDateSetting)
    const [dateShow, setDateShow] = useState('Select')
    const [documents, setDocuments] = useState<any>([])
    const [imgSizeInvalid, setimgSizeInvalid] = useState('')
    const [img, setImg] = useState<any>('')
    const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
    const [cropData, setCropData] = useState(defaultSrc)
    const [customerRejection, setCustomerRejection] = useState(rejection)
    const [customerRejectionValidation, setCustomerRejectionValidation] =
        useState<any>(rejectionValidation)
    const [rejectReasonOffSet, setRejectReasonOffSet] = useState<any>('')
    const [rejectReasonOption, setRejectReasonOption] = useState<any>([])
    const [taxLabels, setTaxLabels] = useState<any>([])
    const [isMobileNumberUnique, setIsMobileNumberUnique] = useState(false)
    const [permission, setPermission] = useState(defaultPermission)
    const [taxInfo, setTaxInfo] = useState<any>([])
    const [streetBillingOffSet, setStreetBillingOffSet] = useState<any>('')
    const [streetsBilling, setStreetsBilling] = useState<any>([])
    const [districtBillingOffSet, setDistrictBillingOffSet] = useState<any>('')
    const [districtsBilling, setDistrictsBilling] = useState<any>([])
    const [wardBillingOffSet, setWardBillingOffSet] = useState<any>('')
    const [wardsBilling, setWardsBilling] = useState<any>([])
    const [tags, setTags] = useState<any>([])
    const [categoryReset, setCategoryReset] = useState<any>(moment());
    const [wardReset, setWardReset] = useState<any>(moment());
    const [wardBillingReset, setWardBillingReset] = useState<any>(moment());
    const [streetReset, setStreetReset] = useState<any>(moment());
    const [streetBillingReset, setStreetBillingReset] = useState<any>(moment());

    useEffect(() => {
        checkPermissions("customer_verification_request").then((res: any) => {
            setPermission(res)
        })
        fetchTaxLabels().then()
        fetchCustomerDetails().then()
    }, [])

    useEffect(() => {
        if (date != '') {
            setIsCalenderOpen(false)
        }
    }, [date])

    useEffect(() => {
        let updatedCustomerValidation: any = { ...customerValidation }
        updatedCustomerValidation["address"] = ""
        updatedCustomerValidation["billing_address"] = ""

        setCustomerValidation(updatedCustomerValidation)

        let updateCustomer: any = customer

        updateCustomer['address'] = selectedAddress
        updateCustomer['billing_address'] = selectedAddress
        setIsLatLongValidation(false)
        setCustomer(updateCustomer)
    }, [selectedAddress])

    useEffect(() => {
        let updateCustomer: any = { ...customer }

        updateCustomer.latitude = latitudeLongitude.latitude
        updateCustomer.longitude = latitudeLongitude.longitude

        setCustomer(updateCustomer)
    }, [latitudeLongitude])

    useEffect(() => {
        let updateCustomer: any = { ...customer }

        updateCustomer.place_id = placeId

        setCustomer(updateCustomer)
    }, [placeId])

    useEffect(() => {
        let updateCustomer: any = { ...customer }

        updateCustomer.pin_code = postalCode

        setCustomer(updateCustomer)
    }, [postalCode])

    useEffect(() => {
        setCustomerCategoryOffSet('')
            setCategoryReset(moment())
    }, [customer.nature_of_business])

    useEffect(() => {
        setCreditRangeOffSet('')
        setCustomerTagOffSet('')
        setDistrictOffSet('')
        setDistrictBillingOffSet('')
        setCustomerCategoryOffSet('')
        setCategoryReset(moment())
        setWardOffSet('')
        setWardReset(moment())
        setWardBillingReset(moment())
        setWardBillingOffSet('')
        setStreetOffSet('')
        setStreetReset(moment())
        setStreetBillingReset(moment())
        setStreetBillingOffSet('')
    }, [searchSelect])

    useEffect(() => {
        let updateCustomer: any = { ...customer }

        updateCustomer.operations_since_view = moment(value).toDate()
        updateCustomer.operations_since = moment(value).format('YYYY-MM-DD')

        setCustomer(updateCustomer)
    }, [value])

    useEffect(() => {
        setCustomer(customerDetail)
    }, [customerDetail])

    const fetchTaxLabels = async () => {
        const response = await getRequest(`${apiBaseURL()}master/tax-labels`, true)

        if (response) {
            setTaxLabels(response.results)
        }
    }

    const fetchCustomerDetails = async () => {
        setCustomer(initialCustomer)
        if (customerId != null) {
            let customerResponse = await getCustomerDetails(customerId)

            if (customerResponse) {
                let updatedCustomerDetails: any = { ...customerDetail }
                Object.entries(customerResponse).map((object: any) => {
                    if (object[1] === null) {
                        object[1] = ""
                    }
                    if (object[0] in updatedCustomerDetails) {
                        return (updatedCustomerDetails[object[0]] = object[1])
                    }
                })

                setDate(moment(customerResponse.operations_since).toDate())

                if (
                    customerResponse.store_front_verify === 'Approved' &&
                    customerResponse.store_in_photo_verify === 'Approved'
                ) {
                    updatedCustomerDetails.is_address_verify = true
                }

                updatedCustomerDetails.operations_since_view = moment(
                    customerResponse.operations_since
                ).toDate()

                if (customerResponse.billing_taxes_information.length > 0) {
                    const updatedTaxInfo = [...taxInfo]

                    customerResponse.billing_taxes_information.map((tax: any) => {
                        updatedTaxInfo.push({
                            tax_label_id: tax.tax_label_id,
                            tax_number: tax.tax_number
                        })
                    })

                    setTaxInfo(updatedTaxInfo)
                }
                updatedCustomerDetails.business_name = customerResponse.business_name
                updatedCustomerDetails.billing_business_name = customerResponse.billing_business_name
                updatedCustomerDetails.billing_address = customerResponse.billing_address && customerResponse.billing_address
                updatedCustomerDetails.billing_district_id = customerResponse.billing_district && customerResponse.billing_district.id
                updatedCustomerDetails.billing_ward_id = customerResponse.billing_ward && customerResponse.billing_ward.id
                updatedCustomerDetails.billing_street_id = customerResponse.billing_street && customerResponse.billing_street.id
                updatedCustomerDetails.same_business_address = customerResponse.same_business_address && customerResponse.same_business_address

                if (customerResponse.dob_of_promoter) {
                    const dob = dateFormat(customerResponse.dob_of_promoter)
                    setDateShow(dob.displayDate)
                    setInitialSettings({ ...initialDateSetting, startDate: dob.displayDate })
                }

                setCropData(customerResponse.logo_thumbnail)

                setCustomerCategories([
                    {
                        label: customerResponse.category.category,
                        value: customerResponse.category.id,
                    },
                ])

                setDistricts([
                    {
                        label: customerResponse.district.name,
                        value: customerResponse.district.id,
                    },
                ])

                setWards([
                    {
                        label: customerResponse.ward.name,
                        value: customerResponse.ward.id,
                    },
                ])

                setStreets([
                    {
                        label: customerResponse.street.name,
                        value: customerResponse.street.id,
                    },
                ])

                if (customerResponse.credit_range) {
                    setCreditRanges([
                        {
                            label: customerResponse.credit_range.credit_range_name,
                            value: customerResponse.credit_range.id,
                        },
                    ])
                }

                const updatedTags: any = []

                if (customerResponse.tags.length > 0) {
                    customerResponse.tags.map((tag: any) => {
                        updatedTags.push({
                            label: tag.tag_name,
                            value: tag.tag_id
                        })
                    })
                }

                setTags(updatedTags)

                setSelectedAddress(customerResponse.address)
                updatedCustomerDetails.id = customerId
                updatedCustomerDetails.district_id = customerResponse.district.id
                updatedCustomerDetails.ward_id = customerResponse.ward.id
                updatedCustomerDetails.street_id = customerResponse.street.id
                updatedCustomerDetails.credit_range_id = customerResponse.credit_range && customerResponse.credit_range.id
                updatedCustomerDetails.type = customerResponse.type
                updatedCustomerDetails.category_id = customerResponse.category.id
                updatedCustomerDetails.document = customerResponse.other_documents
                updatedCustomerDetails.contact_is_verified = customerResponse.contact_is_verified
                updatedCustomerDetails.reg_certificate_file = customerResponse.reg_certificate_file
                updatedCustomerDetails.national_id_file = customerResponse.national_id_file
                updatedCustomerDetails.business_license_file = customerResponse.business_license_file

                if (updatedCustomerDetails.document.length > 0) {
                    let uploadedDocuments: any = []

                    updatedCustomerDetails.document.map((document: any) => {
                        uploadedDocuments.push(document)
                    })

                    setDocuments(uploadedDocuments)
                }

                setCustomerDetail(updatedCustomerDetails)
            }
        }
    }

    const fetchCustomerTypes = async (search?: any) => {
        let options = customerTypes

        if (search !== '') {
            options = staticSearch(options, search)
        }

        return {
            options,
            hasMore: false,
        }
    }

    const fetchBusinesses = (search?: any) => {
        let options = business

        if (search !== '') {
            options = staticSearch(options, search)
        }

        return {
            options,
            hasMore: false,
        }
    }

    const fetchBusinessSize = (search?: any) => {
        let options = businessSize

        if (search !== '') {
            options = staticSearch(options, search)
        }

        return {
            options,
            hasMore: false,
        }
    }

    const fetchPremises = (search?: any) => {
        let options = premises

        if (search !== '') {
            options = staticSearch(options, search)
        }

        return {
            options,
            hasMore: false,
        }
    }

    const fetchFamilySizes = (search?: any) => {
        let options = familySize

        if (search !== '') {
            options = staticSearch(options, search)
        }

        return {
            options,
            hasMore: false,
        }
    }

    const fetchCreditRange = async (search?: any) => {
        if (search != '') {
            setSearchSelect(search)
        }

        let options: any = []
        const creditRangeResponse = await getRequest(
            `${apiBaseURL()}master/credit-score/list/?ordering=credit_range_name&search=${search}&limit=${limit}&offset=${creditRangeOffSet}`,
            true
        )

        let hasMore: boolean = false

        if (creditRangeResponse.next !== null) {
            const queryParams = new URLSearchParams(creditRangeResponse.next)
            let newOffset: any = queryParams.get('offset')
            setCreditRangeOffSet(newOffset)
            hasMore = true
        }

        if (creditRangeResponse.results.length > 0) {
            creditRangeResponse.results.map((option: any) => {
                options.push({
                    label: option.credit_range_name,
                    // label: option.credit_range_name + ` - (${option.credit_from} to ${option.credit_to})`,
                    value: option.id,
                })
            })
        }

        if (creditRanges.length > 0) {
            setCreditRanges(creditRanges.concat(options))
        } else {
            setCreditRanges(options)
        }

        return {
            options: options,
            hasMore: hasMore,
        }
    }

    const fetchCustomerTags = async (search?: any) => {
        if (search != '') {
            setSearchSelect(search)
        }

        let options: any = []
        const customerTagResponse = await getRequest(
            `${apiBaseURL()}master/customer-tags/list/?ordering=tag_name&search=${search}&limit=${limit}&offset=${customerTagOffSet}`,
            true
        )

        let hasMore: boolean = false

        if (customerTagResponse.next !== null) {
            const queryParams = new URLSearchParams(customerTagResponse.next)
            let newOffset: any = queryParams.get('offset')
            setCustomerTagOffSet(newOffset)
            hasMore = true
        }

        if (customerTagResponse.results.length > 0) {
            customerTagResponse.results.map((option: any) => {
                options.push({
                    label: option.tag_name,
                    value: option.id,
                })
            })
        }

        if (customerTags.length > 0) {
            setCustomerTags(customerTags.concat(options))
        } else {
            setCustomerTags(options)
        }

        return {
            options: options,
            hasMore: hasMore,
        }
    }

    const fetchCategories = async (search?: any) => {
        return await fetchAsyncDropdown(
          customerCategoryOffSet,
          setCustomerCategoryOffSet,
          customerCategories,
          setCustomerCategories,
          `master/customer-category/list/?ordering=category&master_nature_of_business=${customer.nature_of_business}`,
          search,
          setSearchSelect,
          'category'
        )
    }

    const fetchDistricts = async (search?: any) => {
        return await fetchAsyncDropdown(
            districtOffSet,
            setDistrictOffSet,
            districts,
            setDistricts,
            `master/district/list/?ordering=name&user_id=${getUserType != 'TS' ? getUserId : ''}`,
            search,
            setSearchSelect
        )
    }

    const fetchWards = async (search?: any) => {
        if (customer.district_id == null || customer.district_id == '') {
            return {
                options: [],
                hasMore: false,
            }
        } else {
            return await fetchAsyncDropdown(
              wardOffSet,
              setWardOffSet,
              wards,
              setWards,
              `master/wards/list/?ordering=name&district__id=${customer.district_id}&user_id=${getUserType != 'TS' ? getUserId : ''
              }`,
              search,
              setSearchSelect
            )
        }
    }

    const fetchStreets = async (search?: any) => {
        if (customer.ward_id == null || customer.ward_id == '') {
            return {
                options: [],
                hasMore: false,
            }
        } else {
            return await fetchAsyncDropdown(
              streetOffSet,
              setStreetOffSet,
              streets,
              setStreets,
              `master/street/list/?ordering=name&ward__id=${customer.ward_id}`,
              search,
              setSearchSelect
            )
        }
    }

    const fetchDistrictsBilling = async (search?: any) => {
        return await fetchAsyncDropdown(
            districtBillingOffSet,
            setDistrictBillingOffSet,
            districtsBilling,
            setDistrictsBilling,
            `master/district/list/?ordering=name&user_id=${getUserType != 'TS' ? getUserId : ''}`,
            search,
            setSearchSelect
        )
    }

    const fetchWardsBilling = useCallback(
        async (search?: any) => {
            if (customer.district_id == null || customer.district_id == '') {
                return {
                    options: [],
                    hasMore: false,
                }
            } else {
                return await fetchAsyncDropdown(
                    wardBillingOffSet,
                    setWardBillingOffSet,
                    wardsBilling,
                    setWardsBilling,
                    `master/wards/list/?ordering=name&district__id=${customer.district_id}&user_id=${getUserType != 'TS' ? getUserId : ''
                    }`,
                    search,
                    setSearchSelect
                )
            }
        },
        [customer.billing_district_id, wardBillingOffSet]
    )

    const fetchStreetsBilling = useCallback(
        async (search?: any) => {
            if (customer.ward_id == null || customer.ward_id == '') {
                return {
                    options: [],
                    hasMore: false,
                }
            } else {
                return await fetchAsyncDropdown(
                    streetBillingOffSet,
                    setStreetBillingOffSet,
                    streetsBilling,
                    setStreetsBilling,
                    `master/street/list/?ordering=name&ward__id=${customer.ward_id}`,
                    search,
                    setSearchSelect
                )
            }
        },
        [customer.billing_district_id, customer.billing_ward_id, streetBillingOffSet]
    )

    const handleChange = (e: any, name?: string) => {
        const inputName = e.target ? e.target.name : name
        const inputValue = e.target ? e.target.value : e.value
        setIsMobileNumberUnique(false)
        setCustomerValidation({ ...customerValidation, [inputName]: '' })
        let updatedCustomer: any = { ...customer }

        let updatedCustomerValidation: any = { ...customerValidation }

        switch (inputName) {
            case 'same_business_address':
                updatedCustomer['same_business_address'] = e.target.checked
                updatedCustomer['billing_address'] = selectedAddress
                updatedCustomer['billing_business_name'] = updatedCustomer['business_name']
                // setSelectedAddress(updatedCustomer['address'])
                updatedCustomer['billing_street_id'] = updatedCustomer['street_id']
                updatedCustomer['billing_ward_id'] = updatedCustomer['ward_id']
                updatedCustomer['billing_district_id'] = updatedCustomer['district_id']
                updatedCustomer['billing_pin_code'] = updatedCustomer['pin_code']
                break
            case 'prior_relationship':
                updatedCustomer['prior_relationship'] = e.target.checked
                break
            case 'business_name':
                updatedCustomer[inputName] = inputValue
                updatedCustomer['billing_business_name'] = inputValue
                updatedCustomerValidation["business_name"] = ""
                break
            case 'nature_of_business':
                updatedCustomer.category_id = ''
                updatedCustomer['nature_of_business'] = inputValue
                updatedCustomerValidation["nature_of_business"] = ""
                setCategoryReset(moment())
                setCustomerCategoryOffSet('')
                setCategoryReset(moment())
                setCustomerCategories([])
                break
            case 'customer_tag_id':
                setTags(e)
                break
            case 'business_license_file':
            case 'reg_certificate_file':
            case 'national_id_file':
                e.preventDefault()
                const file = handleFile(e, inputName)
                updatedCustomer[inputName] = file
                break
            case 'business_license':
            case 'reg_certificate':
            case 'national_id':
                setDocumentsRequired({ ...documentsRequired, [inputName]: true })
                updatedCustomer[inputName] = inputValue
                break
            case 'document':
                e.preventDefault()
                const documentFile = handleFile(e, inputName)

                if (documentFile != '') {
                    let uploadedDocuments: any = [...documents]

                    uploadedDocuments.push(documentFile)

                    setDocuments(uploadedDocuments)
                }
                break
            case 'district_id':
                updatedCustomer['ward_id'] = ''
                updatedCustomer['street_id'] = ''
                setWards([])
                setStreets([])
                setWardReset(moment())
                setWardBillingReset(moment())
                setStreetReset(moment())
                setStreetBillingReset(moment())
                if (updatedCustomer['same_business_address']) {
                    setWardsBilling([])
                    setStreetsBilling([])
                    setWardBillingOffSet('')
                    setStreetBillingOffSet('')
                }
                setWardOffSet('')
                setStreetOffSet('')
                updatedCustomer[inputName] = inputValue
                updatedCustomerValidation["billing_district_id"] = ""
                updatedCustomerValidation["district_id"] = ""

                // if (updatedCustomer['same_business_address']) {
                updatedCustomer['billing_district_id'] = inputValue
                // }
                break
            case 'billing_district_id':
                updatedCustomer['billing_ward_id'] = ''
                updatedCustomer['billing_street_id'] = ''
                setWardsBilling([])
                setWardBillingReset(moment())
                setStreetsBilling([])
                setWardBillingOffSet('')
                setStreetBillingOffSet('')
                updatedCustomer[inputName] = inputValue
                break
            case 'ward_id':
                updatedCustomer['street_id'] = ''
                setStreets([])
                setStreetOffSet('')
                setStreetReset(moment())
                setStreetBillingReset(moment())
                if (updatedCustomer['same_business_address']) {
                    setStreetsBilling([])
                    setStreetBillingOffSet('')
                }
                updatedCustomerValidation["billing_ward_id"] = ""
                updatedCustomerValidation["ward_id"] = ""
                updatedCustomer[inputName] = inputValue
                // if (updatedCustomer['same_business_address']) {
                updatedCustomer['billing_ward_id'] = inputValue
                // }
                break
            case 'street_id':
                updatedCustomerValidation["billing_street_id"] = ""
                updatedCustomerValidation["street_id"] = ""
                updatedCustomer[inputName] = inputValue
                setStreetBillingReset(moment())
                // if (updatedCustomer['same_business_address']) {
                updatedCustomer['billing_street_id'] = inputValue
                // }
                break
            case 'pin_code':
                updatedCustomer[inputName] = inputValue
                // if (updatedCustomer['same_business_address']) {
                updatedCustomer['billing_pin_code'] = inputValue
                // }
                break
            case 'billing_ward_id':
                updatedCustomer['billing_street_id'] = ''
                updatedCustomerValidation['billing_ward_id'] = ''
                setStreetsBilling([])
                setStreetBillingOffSet('')
                updatedCustomer[inputName] = inputValue
                break
            case 'contact_no':
            case 'credit_amount_cap':
            case 'credit_cycle':
                updatedCustomerValidation["contact_no"] = ""
                const re = /^[0-9\b]+$/
                if (inputValue === '' || re.test(inputValue)) {
                    updatedCustomer[inputName] = inputValue
                }
                break
            case 'premises_type':
                updatedCustomer['address'] = ""
                updatedCustomer['billing_address'] = ""
                updatedCustomerValidation['premises_type'] = ""
                setSelectedAddress("")
                updatedCustomer[inputName] = inputValue
                break
            default:
                updatedCustomer[inputName] = inputValue
                updatedCustomerValidation["billing_address"] = ""
                updatedCustomerValidation[inputName] = ""

        }

        setCustomer(updatedCustomer)
        setCustomerValidation(updatedCustomerValidation)
    }

    const handleTaxChange = (e: any, id: number, index: number) => {
        let updatedTaxInfo: any = [...taxInfo]
        const value = e.target.value

        updatedTaxInfo[index] = {
            tax_label_id: id,
            tax_number: value
        }

        setTaxInfo(updatedTaxInfo)
    }

    const handleFile = (e: any, inputName: string) => {
        if (e.target.files.length === 0) {
            return ''
        }
        const allowedExtensions =
            inputName !== 'document'
                ? /(\.pdf|\.jpg|\.jpeg|\.png|\.gif|\.jfif)$/i
                : /(\.pdf|\.jpg|\.jpeg|\.png|\.gif|\.doc|\.xlsx|\.jfif)$/i

        setCustomerValidation({ ...customerValidation, [inputName]: '' })

        if (allowedExtensions.exec(e.target.value)) {
            let file: any = null
            if (e.dataTransfer) {
                file = e.dataTransfer.files[0]
            } else if (e.target) {
                file = e.target.files[0]
            }
            setDocumentNumberRequired({ ...documentNumberRequired, [inputName.replace('_file', '')]: true })
            return file
        } else {
            setCustomerValidation({ ...customerValidation, [inputName]: 'is-invalid' })
        }

        return ''
    }

    const validateForm = () => {
        let validations = { ...customerValidation }
        let isValid = true

        let navigateField = {
            business_name: customer.business_name,
            type: customer.type,
            nature_of_business: customer.nature_of_business,
            category_id: customer.category_id,
            size: customer.size,
            name: customer.name,
            contact_no: customer.contact_no,
            premises_type: customer.premises_type,
            address: customer.premises_type !== 'movable' ? selectedAddress : '0',
            district_id: customer.district_id,
            ward_id: customer.ward_id,
            street_id: customer.street_id,
            billing_address: customer.premises_type !== 'movable' ? customer.billing_address : '0',
            billing_district_id: customer.billing_district_id,
            billing_ward_id: customer.billing_ward_id,
            billing_street_id: customer.billing_street_id,
            credit_range_id: customer.credit_range_id,
        }

        isModalValidation(navigateField).length !== 0 &&
            focusVatidateField(isModalValidation(navigateField)[0])

        if (customer.business_name.trim() === '') {
            validations.business_name = 'is-invalid'
            isValid = false
        }

        if (customer.type === '') {
            validations.type = 'is-invalid'
            isValid = false
        }

        if (customer.nature_of_business === '') {
            validations.nature_of_business = 'is-invalid'

            isValid = false
        }

        if (customer.category_id === '') {
            validations.category_id = 'is-invalid'

            isValid = false
        }

        if (customer.operations_since === '') {
            validations.operations_since = 'is-invalid'
            isValid = false
        }

        if (customer.size === '') {
            validations.size = 'is-invalid'
            isValid = false
        }

        if (customer.name === '') {
            validations.name = 'is-invalid'
            isValid = false
        }

        if (customer.contact_no === '') {
            validations.contact_no = 'is-invalid'
            isValid = false
        }

        if (customer.premises_type !== 'movable' && selectedAddress === '') {
            validations.address = 'is-invalid'
            isValid = false
        }

        if (customer.email !== '') {
            if (!validateEmail(customer.email)) {
                setCustomerValidation({ ...customerValidation, ['email']: 'is-invalid' })
            }
        }

        if (customer.premises_type === '') {
            validations.premises_type = 'is-invalid'
            isValid = false
        }

        if (customer.district_id === '') {
            validations.district_id = 'is-invalid'
            isValid = false
        }

        if (customer.ward_id === '') {
            validations.ward_id = 'is-invalid'
            isValid = false
        }
        if (customer.street_id === '') {
            validations.street_id = 'is-invalid'
            isValid = false
        }
        if (customer.credit_range_id === '' || customer.credit_range_id == null) {
            validations.credit_range_id = 'is-invalid'
            isValid = false
        }

        if (
            documentsRequired.business_license &&
            (customer.business_license_file == null || customer.business_license_file == '')
        ) {
            validations.business_license_file = 'is-invalid'
            isValid = false
        }

        if (
            documentsRequired.reg_certificate &&
            (customer.reg_certificate_file == null ||
                customer.reg_certificate_file == '')
        ) {
            validations.reg_certificate_file = 'is-invalid'
            isValid = false
        }

        if (
            documentsRequired.national_id &&
            (customer.national_id_file == null || customer.national_id_file == '')
        ) {
            validations.national_id_file = 'is-invalid'
            isValid = false
        }

        if (
            documentNumberRequired.business_license &&
            (customer.business_license == null || customer.business_license == '')
        ) {
            validations.business_license = 'is-invalid'
            isValid = false
        }

        if (
            documentNumberRequired.reg_certificate &&
            (customer.reg_certificate == null || customer.reg_certificate == '')
        ) {
            validations.reg_certificate = 'is-invalid'
            isValid = false
        }

        if (
            documentNumberRequired.national_id &&
            (customer.national_id == null || customer.national_id == '')
        ) {
            validations.national_id = 'is-invalid'
            isValid = false
        }

        if (customer.premises_type !== 'movable') {
            if (customer.billing_address == "") {
                validations.billing_address = 'is-invalid'
                isValid = false
            }
        }

        if (customer.billing_district_id == "") {
            validations.billing_district_id = 'is-invalid'
            isValid = false
        }

        if (customer.billing_ward_id == "") {
            validations.billing_ward_id = 'is-invalid'
            isValid = false
        }

        if (customer.billing_street_id == "") {
            validations.billing_street_id = 'is-invalid'
            isValid = false
        }

        setCustomerValidation(validations)

        return isValid
    }

    const removeDocument = (index: number) => {
        let uploadedDocument: any = [...documents]

        uploadedDocument.splice(index, 1)

        setDocuments(uploadedDocument)
    }

    const saveCustomer = async (e: any) => {
        e.preventDefault()
        if (contactVerify === 'false' && props.overview !== undefined) {
            navigate('/customers/verification-requests/mobile-number')
        } else {
            if (props.overview !== undefined) {
                const approvedStatus = {
                    status: "approved"
                }
                setApproveButton('Please Wait...')
                const res: any = await patchRequest(`${apiBaseURL()}customers/${customerId}/approval`, approvedStatus, true)
                setApproveButton('Save & Approve')

                if (res && res.data && res.data.error && res.data.error.field === 'lat_long_not_found') {
                    setFailedMessage(res.data.error.error_message)
                    setIsLatLongValidation(true)
                    return false
                } else {
                    navigate('/customers/verification-requests')
                }
            }
            const isValid = validateForm()

            if (isValid) {
                setIsButtonLoader('Please Wait...')
                setApproveButton('Please Wait...')

                let customerData = new FormData()
                let customerInfo: any = { ...customer }

                customerInfo['place_id'] = checkEmpty(placeId, '')

                taxInfo.map((tax: any, index: number) => {
                    customerInfo[`billing_tax_numbers[${index}]tax_label_id`] = tax.tax_label_id
                    customerInfo[`billing_tax_numbers[${index}]tax_number`] = tax.tax_number
                })

                if (tags.length > 0) {
                    tags.map((tag: any, index: number) => {
                        customerInfo[`tag_ids[${index}]`] = tag.value
                    })
                }

                customerInfo.billing_tax_numbers = JSON.stringify(taxInfo)
                customerInfo.address = selectedAddress

                Object.entries(customerInfo).map((object: any) => {
                    if (
                        ['business_license_file', 'reg_certificate_file', 'national_id_file'].includes(
                            object[0]
                        )
                    ) {
                        if (object[1] != null && typeof object[1] != 'string') {
                            return customerData.append(object[0], object[1])
                        } else {
                            return customerData.append(object[0], "")
                        }
                    } else {
                        if (object[0] == 'customer_logo') {
                            if (object[1] != null && typeof object[1] != 'string') {
                                return customerData.append("logo", object[1])
                            }
                        } else {
                            return customerData.append(object[0], object[1])
                        }
                    }
                })

                if (documents.length > 0) {
                    documents.map((document: any, index: number) => {
                        if (!document.id) {
                            customerData.append(`other_documents[${index}]`, document)
                        }
                    })
                }

                if (props.overview != undefined) {
                    customerData.append('on_boarding_status', 'Approved')
                }

                if (customerId != null) {
                    putRequest(`${apiBaseURL()}customers/update/${customerId}`, customerData, true).then((res) => {
                        handleResponse(res, 'Edit')
                    })
                } else {
                    postRequest(`${apiBaseURL()}customers/create`, customerData, true, true).then((res) => {
                        handleResponse(res, 'Add')
                    })
                }
            }
        }
    }

    const handleResponse = (res: any, type: string) => {
        setIsButtonLoader('Submit')
        setApproveButton('Save & Approve')
        if (res.status === 201 || res.status === 200) {
            setIsAlertSuccess(true)
            const customerDetails: any = { ...customer }
            setCustomer(initialCustomer)
            localStorage.setItem('type', type)
            localStorage.removeItem('cId')
            localStorage.setItem('name', customerDetails.business_name)

            if (props.overview != undefined) {
                navigate('/customers/verification-requests')
            }

            navigate('/customers')
        } else {
            if (res.status === 400) {
                if (res.data && res.data.error && res.data.error.field) {
                    if (res.data.error.field === 'contact_no') {
                        setIsMobileNumberUnique(true);
                        focusVatidateField("contact_no");
                    }

                    if (res.data.error.field === 'lat_long_not_found') {
                        setFailedMessage(res.data.error.error_message)
                        setIsLatLongValidation(true)

                        setTimeout(() => {
                            setIsLatLongValidation(false)
                        }, 2500)
                    }
                }
            }
            setIsAlertFailed(true)
        }

        setTimeout(() => {
            setIsAlertFailed(false)
            setIsAlertSuccess(false)
        }, 1500)
    }

    const isAlertSHow = (val: any) => {
        if (val == 'success') {
            setIsAlertSuccess(false)
        }
        if (val == 'failed') {
            setIsAlertFailed(false)
        }
    }

    const CustomDatePicker = () => {
        return (
            <DatePickerCustom
                onCallback={(start: any, end: any, label: any) => {
                    let date = start._d
                    let newDate = dateFormat(start._d)
                    setCustomer({ ...customer, dob_of_promoter: newDate.apiDate })
                    setDateShow(newDate.displayDate)
                    setInitialSettings((pre: any) => ({ ...pre, startDate: start._d }))
                }}
                initialSettings={initialSettings}
                dateValidation={'w-100'}
                dateValue={dateShow}
            />
        )
    }
    const onImageChange = (e: any) => {
        if (e.target.files[0]) {
            const [file] = e.target.files
            let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
            if (allowedExtensions.exec(e.target.value)) {
                setImg(URL.createObjectURL(file))
                setCustomer({ ...customer, ['customer_logo']: e.target.files[0] })
            }
            let img: any = ''
            if (e.target.files[0]) {
                img = new Image()
                let objectUrl = URL.createObjectURL(file)
                img.onload = function () {
                    URL.revokeObjectURL(objectUrl)
                }
                img.src = objectUrl
            }
            e.target.value = ''
        }
    }

    const getImage = (val: any) => {
        setCustomer({ ...customer, ['customer_logo']: val })
    }

    const fetchRejectionReason = async (search?: any) => {
        if (search != '') {
            setSearchSelect(search)
        }
        const response = await fetchAsyncDropdown(
            rejectReasonOffSet,
            setRejectReasonOffSet,
            rejectReasonOption,
            setRejectReasonOption,
            `master/options-master/?type=CustomerRejection`,
            search,
            setSearchSelect,
            'option'
        )
        response.options.push({
            label: 'Other',
            value: '',
        })
        return response
    }

    const handleChangeReason = (e: any, name?: string) => {
        const inputName = e.target ? e.target.name : name
        const inputValue = e.target ? e.target.value : e.value
        setCustomerRejectionValidation({ ...customerRejectionValidation, [inputName]: '' })

        switch (inputName) {
            case 'option':
                setCustomerRejection({ ...customerRejection, ['option']: inputValue })
                break
            case 'comments':
                setCustomerRejection({ ...customerRejection, ['comments']: inputValue })
                break
        }
    }

    const clearFunction = () => {
        setCustomerRejection(rejection)
        setCustomerRejectionValidation(rejectionValidation)
        setRejectReasonOffSet('')
        setRejectReasonOption([])
        setimgSizeInvalid('')
        setIsMobileNumberUnique(false)
    }

    const rejectCustomer = async () => {
        const rejectCustomerData: any = {
            rejection_reason: customerRejection.option,
            rejection_comment: customerRejection.comments,
            on_boarding_status: 'Reject',
        }

        await patchRequest(
            `${apiBaseURL()}customer/aprrove_reject_revoke/${customer.id}/`,
            rejectCustomerData,
            true
        )
        clearFunction()
        $('#closeRejectionReasonModal').trigger('click')
        setTimeout(() => {
            clearFunction()
        }, 200)
        localStorage.removeItem('cId')
        navigate('/customers/verification-requests')
    }

    return (
        <>
            <div>
                <PageTitle breadcrumbs={adminHeaderTitle}>
                    {intl.formatMessage({
                        id: props.overview
                            ? 'Overview'
                            : customer.id != null
                                ? 'Update Customer'
                                : 'Add Customer',
                    })}
                </PageTitle>
                <EaAlertSuccess
                    onClick={() => isAlertSHow('success')}
                    show={IsAlertSuccess}
                    message={FormatMessage('success')}
                />
                <EaAlertDanger
                    onClick={() => isAlertSHow('failed')}
                    show={isAlertFailed}
                    message='failed'
                />

                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                        {/* begin::Title */}
                        <h3 className='card-title align-items-start flex-column my-0'>
                            <span className='card-label fw-bold font-17'>
                                {props.overview
                                    ? 'Overview'
                                    : customer.id != null
                                        ? 'Update Customer'
                                        : 'Add Customer'}
                            </span>
                        </h3>
                        {/* end::Title */}
                    </div>
                    {/* <div className='card-body pt-4 pt-md-5'> */}
                    <form
                        className='card-body pt-4 pt-md-5'
                        onSubmit={(e) => e.preventDefault()}
                        autoComplete='off'
                    >
                        <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'> Business Information</h3>
                        <div className='row'>
                            <div className='col-lg-9 col-xl-9 col-xxl-9'>
                                <div className='row'>
                                    <div className='col-md-8 mb-4'>
                                        <label className='form-label'>
                                            Business Name<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            onChange={handleChange}
                                            id='business_name'
                                            name='business_name'
                                            disabled={formComponentDisabled}
                                            className={clsx('form-control', customerValidation.business_name)}
                                            value={customer.business_name}
                                            placeholder='Type here...'
                                        />
                                    </div>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>
                                            Customer Type<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchCustomerTypes}
                                            id='type'
                                            placeholder='Select'
                                            isSearchable
                                            isDisabled={formComponentDisabled}
                                            className={clsx(
                                                'react-select-container w-100',
                                                customerValidation.type
                                            )}
                                            classNamePrefix='react-select'
                                            value={customerTypes.find(
                                                (option: any) => option.value == customer.type
                                            )}
                                            onChange={(e: any) => handleChange(e, 'type')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <label className='form-label'>
                                            Nature of Business<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchBusinesses}
                                            placeholder='Select'
                                            id='nature_of_business'
                                            isSearchable
                                            isDisabled={formComponentDisabled}
                                            className={clsx(
                                                'react-select-container me-3 w-100%',
                                                customerValidation.nature_of_business
                                            )}
                                            classNamePrefix='react-select'
                                            value={business.find(
                                                (option: any) => option.value == customer.nature_of_business
                                            )}
                                            onChange={(e: any) => handleChange(e, 'nature_of_business')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <label className='form-label'>
                                            Customer Category<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            key={categoryReset}
                                            loadOptions={fetchCategories}
                                            placeholder='Select'
                                            id='category_id'
                                            isSearchable
                                            isDisabled={formComponentDisabled || customer.nature_of_business === ''}
                                            className={clsx(
                                                'react-select-container w-100%',
                                                customerValidation.category_id
                                            )}
                                            classNamePrefix='react-select'
                                            value={
                                                (customerCategories.length > 0 &&
                                                    customerCategories.find(
                                                        (option: any) => option.value == customer.category_id
                                                    )) ||
                                                ''
                                            }
                                            onChange={(e: any) => handleChange(e, 'category_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <label className='form-label'>Operational Since<span className="text-danger">*</span></label>
                                        <DatePicker
                                            value={date}
                                            className={clsx(
                                                'calendar react-datepicker form-control me-3',
                                                customerValidation.operations_since
                                            )}
                                            clearIcon={null}
                                            calendarIcon={null}
                                            closeCalendar
                                            disableCalendar={formComponentDisabled}
                                            defaultView={'month'}
                                            format={'MM yyyy'}
                                            isOpen={isCalenderOpen}
                                            maxDate={moment().toDate()}
                                            onClickMonth={(e: any) => {
                                                setIsCalenderOpen(true)
                                                setDate(e)
                                                setCustomer({
                                                    ...customer,
                                                    ['operations_since']: dateFormat(e).apiDate,
                                                })
                                                setCustomerValidation((preValue: any) => ({
                                                    ...preValue, operations_since: ""
                                                }))
                                            }}
                                            monthAriaLabel={'Month'}
                                            monthPlaceholder='MM'
                                            yearAriaLabel={'Year'}
                                            yearPlaceholder={'YYYY'}
                                            view={'year'}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <label className='form-label'>
                                            Size of Business<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchBusinessSize}
                                            placeholder='Select'
                                            id='size'
                                            isSearchable
                                            isDisabled={formComponentDisabled}
                                            className={clsx(
                                                'react-select-container w-100',
                                                customerValidation.size
                                            )}
                                            classNamePrefix='react-select'
                                            value={businessSize.find(
                                                (option: any) => option.value == customer.size
                                            )}
                                            onChange={(e: any) => handleChange(e, 'size')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                {props.overview == undefined && (
                                    customerId == null && (
                                        <AddBusinessDocuments
                                            customer={customer}
                                            handleChange={handleChange}
                                            customerValidation={customerValidation}
                                        />
                                    )
                                )}
                            </div>
                            {/* {!formComponentDisabled && (
                               
                            )} */}
                        </div>

                        <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Contact Information</h3>
                        <div className='row'>
                            <div className='col-lg-9 col-xl-9 col-xxl-9'>
                                <div className='row'>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>
                                            Promoter's Name<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            id='name'
                                            onChange={handleChange}
                                            name='name'
                                            disabled={formComponentDisabled}
                                            className={clsx('form-control', customerValidation.name)}
                                            placeholder='Type here...'
                                            value={customer.name}
                                        />
                                    </div>
                                    <div className='col-md-4 mb-4' id='contact_no'>
                                        <MobileNumberInput
                                            validation={customerValidation.contact_no}
                                            onChange={handleChange}
                                            numberValue={customer.contact_no}
                                            name='contact_no'
                                            contactNoVerify={([true, 'True'].includes(customer.contact_is_verified)) || formComponentDisabled}
                                        />
                                        <div className='mt-1'>
                                            <ErrorHandler errorMessage={isMobileNumberUnique && FormatMessage('CUSTOMER_MOBILE_ALREADY_EXIST')} />
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>
                                            Email
                                        </label>
                                        <input
                                            type='text'
                                            id='email'
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                if (e.target.value !== "" && !validateEmail(e.target.value)) {
                                                    setCustomerValidation({
                                                        ...customerValidation,
                                                        ['email']: 'is-invalid',
                                                    })
                                                }
                                            }}
                                            disabled={formComponentDisabled}
                                            name='email'
                                            className={clsx('form-control', customerValidation.email)}
                                            placeholder='Type here...'
                                            value={customer.email}
                                        />
                                    </div>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label' id='dob_of_promoter'>
                                            DOB of Promoter
                                        </label>
                                        <CustomDatePicker />
                                    </div>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>
                                            Premises (Address)<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            id='premises_type'
                                            placeholder='Select'
                                            loadOptions={fetchPremises}
                                            isSearchable
                                            isDisabled={customer.is_address_verify || formComponentDisabled}
                                            className={clsx(
                                                'react-select-container my-1 me-3 w-100%',
                                                customerValidation.premises_type
                                            )}
                                            classNamePrefix='react-select'
                                            name="premises_type"
                                            value={premises.find((option: any) => option.value === customer.premises_type)}
                                            onChange={(e: any) => handleChange(e, 'premises_type')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-9 col-xl-9 col-xxl-9'>
                                {customer.premises_type !== 'movable' && (
                                    <div className='mb-4'>
                                        <label className='form-label' id='address'>
                                            Address<span className="text-danger">*</span>
                                        </label>
                                        {state === 'ready' && (
                                            <PlaceSearch
                                                validationClass={customerValidation.address}
                                                setSelectedAddress={setSelectedAddress}
                                                selectedAddress={selectedAddress}
                                                setLatitudeLongitude={setLatitudeLongitude}
                                                setPostalCode={setPostalCode}
                                                setPlaceId={setPlaceId}
                                                addressDisable={customer.is_address_verify || formComponentDisabled}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="col-9">
                                <div className='row'>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label'>
                                            District<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchDistricts}
                                            placeholder='Select'
                                            isSearchable
                                            id='district'
                                            isDisabled={customer.is_address_verify || formComponentDisabled}
                                            className={clsx('react-select-container w-100%', customerValidation.district_id)}
                                            classNamePrefix='react-select'
                                            value={districts.find((option: any) => option.value === customer.district_id)}
                                            onChange={(e: any) => handleChange(e, 'district_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label'>
                                            {' '}
                                            Ward<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            key={wardReset}
                                            loadOptions={fetchWards}
                                            placeholder='Select'
                                            id='ward'
                                            isSearchable
                                            isDisabled={customer.is_address_verify || formComponentDisabled}
                                            className={clsx('react-select-container w-100%', customerValidation.ward_id)}
                                            classNamePrefix='react-select'
                                            value={
                                                (wards.length > 0 &&
                                                    wards.find((option: any) => option.value === customer.ward_id)) ||
                                                ''
                                            }
                                            onChange={(e: any) => handleChange(e, 'ward_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label'>
                                            Street<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            key={streetReset}
                                            loadOptions={fetchStreets}
                                            placeholder='Select'
                                            id='street'
                                            isSearchable
                                            isDisabled={customer.is_address_verify || formComponentDisabled}
                                            className={clsx('react-select-container w-100%', customerValidation.street_id)}
                                            classNamePrefix='react-select'
                                            value={
                                                (streets.length > 0 &&
                                                    streets.find((option: any) => option.value === customer.street_id)) ||
                                                ''
                                            }
                                            onChange={(e: any) => handleChange(e, 'street_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label'>Pin code</label>
                                        <input
                                            type='text'
                                            id='pin_code'
                                            disabled={customer.is_address_verify || formComponentDisabled}
                                            onChange={handleChange}
                                            name='pin_code'
                                            value={customer.pin_code}
                                            className='form-control'
                                            placeholder='Type here...'
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='col-md-3 mb-4 align-self-end'>
                                <button className="btn btn-primary">Capture Location</button>
                            </div>
                        </div>

                        {
                            taxLabels.length > 0 && <>
                                <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Billing Information</h3>
                                <div className='row'>
                                    <div className='col-lg-9 col-xl-9 col-xxl-9'>
                                        <div className='row'>
                                            {
                                                taxLabels.map((taxLabel: any, index: number) => {
                                                    return <div className='col-md-4 mb-4'>
                                                        <label className='form-label'>
                                                            {taxLabel.name}
                                                        </label>
                                                        <input
                                                            type='text'
                                                            id='name'
                                                            onChange={(e: any) => handleTaxChange(e, taxLabel.id, index)}
                                                            name='name'
                                                            disabled={formComponentDisabled}

                                                            className={clsx('form-control', "")}
                                                            placeholder='Type here...'
                                                            value={taxInfo[index] && taxInfo[index][`tax_number`]}
                                                        />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Billing Address</h3>
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input widget-13-check'
                                        name='same_business_address'
                                        checked={customer.same_business_address}
                                        onChange={(e: any) => handleChange(e, 'same_business_address')}
                                        type='checkbox'
                                        disabled={formComponentDisabled}
                                        value=''
                                        id='same_business_address'
                                    />
                                    <label className='form-check-label' htmlFor='same_business_address'>
                                        Use the same as Business Address
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-9 col-xl-9 col-xxl-9'>
                                <div className='mb-4'>
                                    <label className='form-label' id='billing_business_name'>
                                        Business Name
                                    </label>
                                    <input
                                        type='text'
                                        onChange={handleChange}
                                        id='billing_business_name'
                                        name='billing_business_name'
                                        disabled={formComponentDisabled || customer.same_business_address}
                                        className={clsx('form-control', customerValidation.billing_business_name)}
                                        value={customer.billing_business_name}
                                        placeholder='Type here...'
                                    />
                                </div>
                                {customer.premises_type !== 'movable' && (
                                    <div className='mb-4'>
                                        <label className='form-label' id='billing_address'>
                                            Address<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            id='name'
                                            onChange={handleChange}
                                            name='billing_address'
                                            disabled={formComponentDisabled || customer.same_business_address}
                                            className={clsx('form-control', customerValidation.billing_address)}
                                            placeholder='Type here...'
                                            value={customer.billing_address}
                                        />
                                    </div>
                                )}
                                <div className='row'>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label' id="billing_district_id">
                                            District<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchDistrictsBilling}
                                            placeholder='Select'
                                            isSearchable
                                            id='district'
                                            isDisabled={formComponentDisabled || customer.same_business_address}
                                            className={clsx('react-select-container w-100%', customerValidation.billing_district_id)}
                                            classNamePrefix='react-select'
                                            value={districts.find((option: any) => option.value === customer.billing_district_id)}
                                            onChange={(e: any) => handleChange(e, 'billing_district_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label' id="billing_ward_id">
                                            {' '}
                                            Ward<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchWardsBilling}
                                            key={wardBillingReset}
                                            placeholder='Select'
                                            id='ward'
                                            isSearchable
                                            isDisabled={formComponentDisabled || customer.same_business_address}
                                            name="billing_ward_id"
                                            className={clsx('react-select-container w-100%', customerValidation.billing_ward_id)}
                                            classNamePrefix='react-select'
                                            value={
                                                (wards.length > 0 &&
                                                    wards.find((option: any) => option.value === customer.billing_ward_id)) ||
                                                ''
                                            }
                                            onChange={(e: any) => handleChange(e, 'billing_ward_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label' id="billing_street_id">
                                            Street<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchStreetsBilling}
                                            key={streetBillingReset}
                                            placeholder='Select'
                                            id='street'
                                            isSearchable
                                            name="billing_street_id"
                                            isDisabled={formComponentDisabled || customer.same_business_address}
                                            className={clsx('react-select-container w-100%', customerValidation.billing_street_id)}
                                            classNamePrefix='react-select'
                                            value={
                                                (streets.length > 0 &&
                                                    streets.find((option: any) => option.value === customer.billing_street_id)) ||
                                                ''
                                            }
                                            onChange={(e: any) => handleChange(e, 'billing_street_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-4'>
                                        <label className='form-label'>Pin code</label>
                                        <input
                                            type='text'
                                            id='billing_pin_code'
                                            disabled={formComponentDisabled || customer.same_business_address}
                                            onChange={handleChange}
                                            name='billing_pin_code'
                                            value={customer.billing_pin_code}
                                            className='form-control'
                                            placeholder='Type here...'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Credit Information</h3>
                        <div className='row'>
                            <div className='col-lg-9 col-xl-9 col-xxl-9'>
                                <p>
                                    Specify this Customer's Credit Amount and Credit Cycle Approval Capping here; if
                                    these crosses, credit approval request goes to the Manager!
                                </p>
                                <div className='row g-4'>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>
                                            Credit Range<span className="text-danger">*</span>
                                        </label>
                                        <AsyncPaginate
                                            loadOptions={fetchCreditRange}
                                            placeholder='Select'
                                            id='credit_range_id'
                                            isSearchable
                                            isDisabled={formComponentDisabled}
                                            className={clsx(
                                                'react-select-container my-1 me-3 w-100%',
                                                customerValidation.credit_range_id
                                            )}
                                            classNamePrefix='react-select'
                                            value={creditRanges.find(
                                                (option: any) => (option.value === customer.credit_range_id)
                                            )}
                                            onChange={(e: any) => handleChange(e, 'credit_range_id')}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#0c78a4',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>Credit Amount Cap</label>
                                        <div className='input-group min-w-130px disabled'>
                                            <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-50px disabled'>
                                                {tenantConfiguration.currencyCode}
                                            </span>
                                            <input
                                                type='number'
                                                onChange={handleChange}
                                                name='credit_amount_cap'
                                                disabled={formComponentDisabled}
                                                className={clsx('form-control', customerValidation.credit_amount_cap)}
                                                placeholder='0'
                                                value={customer.credit_amount_cap}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-4'>
                                        <label className='form-label'>Credit Cycle Cap</label>
                                        <div className='input-group min-w-130px disabled'>
                                            <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-50px disabled'>
                                                Days
                                            </span>
                                            <input
                                                type='number'
                                                onChange={handleChange}
                                                name='credit_cycle'
                                                disabled={formComponentDisabled}
                                                className={clsx('form-control', customerValidation.credit_cycle)}
                                                placeholder='0'
                                                value={customer.credit_cycle}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Other Information</h3>
                        <div className='col-lg-9 col-xl-9 col-xxl-9'>
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <label className='form-label'>
                                        Customer Tag
                                    </label>
                                    <AsyncPaginate
                                        loadOptions={fetchCustomerTags}
                                        id='customer_tag'
                                        placeholder='Select'
                                        isMulti={true}
                                        isSearchable
                                        isDisabled={formComponentDisabled}
                                        className={clsx(
                                            'react-select-container my-1 me-3 w-100%',
                                        )}
                                        classNamePrefix='react-select'
                                        value={tags}
                                        onChange={(e: any) => handleChange(e, 'customer_tag_id')}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label className='form-label'>
                                        Personal Family Size
                                    </label>
                                    <AsyncPaginate
                                        loadOptions={fetchFamilySizes}
                                        placeholder='Select'
                                        id='personal_family_size'
                                        isSearchable
                                        isDisabled={formComponentDisabled}
                                        className={clsx(
                                            'react-select-container my-1 me-3 w-100%',
                                        )}
                                        classNamePrefix='react-select'
                                        value={familySize.find(
                                            (option: any) => option.value === customer.personal_family_size
                                        )}
                                        onChange={(e: any) => handleChange(e, 'personal_family_size')}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='row g-4'>
                                <div className='col-md-4 mb-4'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input widget-13-check'
                                            name='prior_relationship'
                                            checked={customer.prior_relationship}
                                            onChange={(e: any) => handleChange(e, 'prior_relationship')}
                                            type='checkbox'
                                            disabled={formComponentDisabled}
                                            value=''
                                            id='prior_relation'
                                        />
                                        <label className='form-check-label' htmlFor='prior_relation'>
                                            Prior Relation with EAF?
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-12 mt-0 mb-4'>
                                    <label className='form-label'>Note</label>
                                    <textarea
                                        className='form-control'
                                        rows={4}
                                        placeholder='Type here…'
                                        disabled={formComponentDisabled}
                                        name='note'
                                        onChange={handleChange}
                                        value={customer.note}
                                    ></textarea>
                                </div>
                            </div>
                            <Documents
                                showWrite={permission.write}
                                documents={documents}
                                handleChange={handleChange}
                                removeDocument={removeDocument}
                                isUpdate={customerId != null}
                            />
                        </div>
                        {
                            isLatLongValidation && <div className="mt-3 text-danger">{failedMessage}</div>
                        }
                    </form>
                    {/* </div> */}
                    <div className='card-footer py-4'>
                        {props.overview == undefined ? (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-secondary me-3'
                                    onClick={() => navigate('/customers')}
                                >
                                    Cancel
                                </button>
                                <button
                                    disabled={isButtonLoader !== 'Submit' || formComponentDisabled}
                                    className='btn btn-primary'
                                    onClick={saveCustomer}
                                >
                                    {isButtonLoader}
                                </button>
                            </>
                        ) : (
                            <>
                                {permission.write &&
                                    <>
                                        <button
                                            disabled={approveButton !== 'Save & Approve'}
                                            className='btn btn-primary me-3'
                                            onClick={saveCustomer}
                                        >
                                            {approveButton}
                                        </button>
                                        <EaButton
                                            isModal={true}
                                            // onClick={() =>
                                            //     rejectionDocumentType('business_license_rejection_option')
                                            // }
                                            className='btn btn-secondary'
                                            btnName='Reject'
                                            dataTarget='#ea_modal_reject_reason'
                                        ></EaButton>
                                    </>
                                }
                                <EaModal
                                    modalTitle='Reject Customer Verification Request'
                                    onSubmit={rejectCustomer}
                                    cancel={clearFunction}
                                    closeAuto='closeRejectionReasonModal'
                                    children={
                                        <>
                                            <div className='mb-4'>
                                                <label className='form-label'>Verification Request Rejection Reason</label>
                                                <AsyncPaginate
                                                    loadOptions={fetchRejectionReason}
                                                    placeholder='Select'
                                                    isSearchable
                                                    className={clsx(
                                                        'react-select-container w-100',
                                                        customerRejectionValidation.option
                                                    )}
                                                    classNamePrefix='react-select'
                                                    name='option'
                                                    onChange={(e: any) => handleChangeReason(e, 'option')}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: '#0c78a4',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            {customerRejection.option == '' && (
                                                <div>
                                                    <label className='form-label'>Comments</label>
                                                    <textarea
                                                        className={clsx('form-control', customerRejectionValidation.comments)}
                                                        rows={4}
                                                        name='comments'
                                                        value={customerRejection.comments}
                                                        onChange={(e: any) => handleChangeReason(e, 'comments')}
                                                    ></textarea>
                                                </div>
                                            )}
                                        </>
                                    }
                                    id={'ea_modal_reject_reason'}
                                    actionBtnName='Yes, Proceed'
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApproveOnBoardingRequest
