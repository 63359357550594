import {Suspense, useEffect, useState} from 'react'
import {Provider} from 'react-redux'
import {Outlet, useNavigate} from 'react-router-dom'
import {I18nProvider} from '../_eaFruitsDms/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_eaFruitsDms/layout/core'
import {MasterInit} from '../_eaFruitsDms/layout/MasterInit'
import Notifications from '../_eaFruitsDms/Notifications/Notifications'
import {EafruitsStore} from '../_eaFruitsDms/redux/store/store'
import tenantConfiguration from '../TenantVariables'
import {onMessageListener} from '../_eaFruitsDms/firebase/firebaseInit'
import {Toaster} from 'react-hot-toast'
import {toast} from 'react-hot-toast'

const App = () => {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({title: '', body: ''})
  const isUserType = localStorage.getItem('user_type')
  const navigate = useNavigate()

  const [splashScreenStyle, setSplashScreenStyle] = useState<any>({
    display: 'flex',
    position: 'fixed',
  })

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = tenantConfiguration.imageBaseUrl + 'favicon.ico'

    const splashScreen = document.getElementById('splash-screen')

    if (splashScreen) {
      const splashScreenImage: any = document.getElementById('splashimage')
      splashScreenImage.src = tenantConfiguration.imageBaseUrl + 'tenant.png'
    }

    let time = 0

    if (isUserType == 'SA') {
      time = 4000
    } else {
      time = 2000
    }

    setTimeout(() => {
      setSplashScreenStyle({
        ...splashScreenStyle,
        display: 'none',
      })
    }, time)
  }, [])

  onMessageListener()
    .then((payload: any) => {
      console.log('app notification === ', payload)
      setShow(true)
      setNotification({
        title: payload.data.title,
        body: payload.data.body,
      })

      toast.success(
        <div>
          <h3>{payload.data.title}</h3>
          <b>{payload.data.body}</b>

          {payload.data && payload.data.notification_type_id === '1' && (
            <div className='text-decoration-underline mt-1 mb-1 text-end'>
              <b
                onClick={() => {
                  if (payload.data && payload.data.notification_type_id === '1') {
                    navigate('/price/discount-request?isActionRequired=true')
                  }
                }}
                className='cursor-pointer p-1'
              >
                View Details
              </b>
            </div>
          )}

          {payload.data && payload.data.notification_type_id === '22' && (
            <div className='text-decoration-underline mt-1 mb-1 text-end'>
              <b
                onClick={() => {
                  if (payload.data && payload.data.notification_type_id === '22') {
                    navigate('/reports/low-stock-report')
                  }
                }}
                className='cursor-pointer p-1'
              >
                View Details
              </b>
            </div>
          )}
        </div>
      )
    })
    .catch((err) => console.log('app.tsx notification failed: ', err))

  return (
    <Provider store={EafruitsStore}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Toaster
              toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                position: 'bottom-right',
              }}
            />
            <div style={splashScreenStyle} className='splash-screen' id='splashScreenStyle'>
              <img
                src={tenantConfiguration.imageBaseUrl + 'tenant.png'}
                id='splashimage'
                alt='EAfruits logo'
                className='h-60px'
              />
              <span>Loading ...</span>
            </div>
            {/*  notification component  */}
            <Notifications />
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </Provider>
  )
}

export {App}
