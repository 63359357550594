import clsx from 'clsx'
import $ from 'jquery'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  addCustomerCategories,
  customerCategoriesDelete,
  customerCategoriesList,
  customerCategoriesUpdate,
} from '../../../../_eaFruitsDms/apiFunctions/master/master'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  focusVatidateField,
  isModalValidation,
  KTSVG,
  staticSearch,
} from '../../../../_eaFruitsDms/helpers'
import capitalizeFirstLetter from '../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import ValidationHandler from '../../../../_eaFruitsDms/helpers/components/validationHandler'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {getToken} from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {business} from '../../customers/WMcustomers/CustomerConst'
import {parseInt} from 'lodash'

const CustomerCategories = (props: any) => {
  const intl = useIntl()
  const masterHeaderTitle = {
    title: 'Masters',
    subTitle: 'Customer Categories',
  }
  const [getList, setGetList] = useState<any>([])
  const [count, setCount] = useState('')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>('')
  const [requestHandler, setRequest] = useState('')
  const [categories, setCategories] = useState<any>('')
  const [parentCategories, setParentCategories] = useState<any>('')
  const [categoriesEdit, setCategoriesEdit] = useState<any>('')
  const [parentCategoriesEdit, setParentCategoriesEdit] = useState<any>('')
  const [EditId, setEditId] = useState<any>('')
  const [ValidationEdit, setValidationEdit] = useState<any>('')
  const [Validation, setValidation] = useState<any>('')
  const [parentValidation, setParentValidation] = useState<any>('')
  const [isLoadingAdd, setIsloadingAdd] = useState('Submit')
  const [getParentCategoryList, setParentCategory] = useState<any>([])
  const [getParentCategoryListModal, setParentCategoryModal] = useState<any>([])
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [creditDays, setCreditDays] = useState<any>('')
  const [creditDaysEdit, setCreditDaysEdit] = useState<any>('')
  const [creditDaysValidation, setCreditDaysValidation] = useState<any>('')
  const [creditDaysValidationEdit, setCreditDaysValidationEdit] = useState<any>('')
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [isCreditDays, setIsCreditDays] = useState(false)
  const [items, setItems] = useState<any>('')

  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  useEffect(() => {
    fetchPermission()
    CategoriesList()
  }, [])

  const fetchPermission = async () => {
    let res = await checkPermissions('masters_customers')
    setPermission(res)
  }

  const CategoriesList = () => {
    setListLoader(true)
    customerCategoriesList('10', '0', '', '').then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }

  //request method for api implementation :- last change by kriti
  const categoryApi = () => {
    getRequest(`${apiBaseURL()}master/customer-category/list/?ordering=name`, header).then(
      (res) => {
        setParentCategory(res)
        setParentCategoryModal(res)
      }
    )
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
      setListLoader(true)
      customerCategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
      setListLoader(true)
      customerCategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
      setListLoader(true)
      customerCategoriesList('10', newOffset, search).then((res) => {
        setGetList(res.data)
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setListLoader(false)
      })
    }
  }
  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    const newTimer = setTimeout(() => {
      setListLoader(true)
      customerCategoriesList('10', '0', e.target.value).then((res) => {
        setGetList(res.data)
        if (res.data.length == 0) {
          setLength('2')
        } else {
          setLength('1')
        }
        setCount(res.count)
        setNext(res.next)
        setPrev(res.prev)
        setSelected(0)
        setListLoader(false)
      })
    }, 500)

    setTimer(newTimer)
  }

  const fetchCategory = async (search: any) => {
    let options: any = []
    if (search) {
      options = staticSearch(business, search)
    } else {
      options = business
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  //  Develop BY: Kriti
  //  Description: Add Customer tag function

  const AddFunction = () => {
    let navigateField = {
      categories: categories,
      parentCategories: parentCategories,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])
    let isValid: boolean = true
    if (categories === '') {
      isValid = false
      setValidation('is-invalid')
    } else {
      setValidation('')
    }
    if (parentCategories === '') {
      isValid = false
      setParentValidation('is-invalid')
    } else {
      setParentValidation('')
    }
    if (creditDays === '' || creditDays === '0') {
      isValid = false
      setCreditDaysValidation('is-invalid')
    } else {
      setCreditDaysValidation('')
    }

    if (isValid) {
      AddFunctionApi()
    }
  }
  const clearFunctionAdd = () => {
    setValidation('')
    setParentCategories('')
    setCategories('')
    setErrorMessage('')
    setParentValidation('')
    setCreditDays('')
    setCreditDaysValidation('')
    setIsCreditDays(false)
  }

  const AddFunctionApi = () => {
    setIsloadingAdd('Please Wait..')
    const credit = creditDays === '' || !isCreditDays ? 0 : creditDays

    addCustomerCategories(categories, parentCategories, credit).then((notify) => {
      setIsloadingAdd('Submit')
      if (notify.success) {
        clearFunctionAdd()
        setListLoader(true)
        customerCategoriesList('10', '0', '').then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeAddEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'category') {
        setErrorMessage(
          'Your added Customer Category  name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  //  Develop BY: Kriti
  //  Description: Edit Customer tag function

  const onChangeAdd = (item: any) => {
    setModalLoader(true)
    setCategoriesEdit(item.category)
    setParentCategoriesEdit(item.master_nature_of_business)
    setCreditDaysEdit(item.max_credit_days)
    setEditId(item.id)
    setModalLoader(false)
    categoryApi()

    if (item.is_max_credit_days_active === true) {
      setIsCreditDays(true)
    } else {
      setIsCreditDays(false)
    }
  }

  const EditFunction = () => {
    let navigateField = {
      categoriesEdit: categoriesEdit,
    }
    isModalValidation(navigateField).length !== 0 &&
      focusVatidateField(isModalValidation(navigateField)[0])
    let isValid: boolean = true

    if (categoriesEdit === '') {
      isValid = false
      setValidationEdit('is-invalid')
    } else {
      setValidationEdit('')
    }

    if (creditDaysEdit === '' || parseInt(creditDaysEdit) === 0) {
      isValid = false
      setCreditDaysValidationEdit('is-invalid')
    } else {
      setCreditDaysValidationEdit('')
    }

    if (isValid) {
      EditFunctionApi()
    }
  }
  const clearFunctionEdit = () => {
    setValidationEdit('')
    setCategoriesEdit('')
    setErrorMessage('')
    setParentCategoriesEdit('')
    setCreditDaysEdit('')
    setCreditDaysValidationEdit('')
    setIsCreditDays(false)
  }

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    const credit = creditDaysEdit === '' || !isCreditDays ? 0 : creditDaysEdit

    customerCategoriesUpdate(
      categoriesEdit,
      parentCategoriesEdit,
      credit,
      EditId,
      items,
      isCreditDays
    ).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        customerCategoriesList('10', '0', search).then((res) => {
          setGetList(res.data)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)
          setListLoader(false)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      if (notify.errorField == 'category') {
        setErrorMessage(
          'Your added Customer Category  name already exists in this system! Please try again with a unique name.'
        )
      }
      setIsloading('Submit')
    })
  }

  //  Develop BY: Kriti
  //  Description: Delete Customer tag function

  const onDelete = (item: any) => {
    setDeleteId(item.id)

    // api call
    customerCategoriesDelete(DeleteId)
    customerCategoriesList('', '', '')
  }
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }
  return (
    <>
      <EATitle title='Customer Categories' />
      <PageTitle breadcrumbs={[masterHeaderTitle]}>
        {intl.formatMessage({id: 'Customer Categories'})}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? 'Your customer category has been added successfully.'
            : 'Your customer category has been edited  successfully.'
        }
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />

      <div className={`card`}>
        {listLoadder && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            {/* <CustomSearchBar /> */}
            <div className='col-12 col-md-auto mt-0'>
              <input
                type='text'
                placeholder='Search'
                className='form-control form-search'
                onChange={searchList}
              />
            </div>
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              {permission.write && (
                <EaButton
                  isModal={true}
                  dataTarget='#ea_modal_create_customer_category'
                  btnName='Add Category'
                  onClick={() => {
                    setMessageHandler('successAdd')
                    categoryApi()
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            {/* <TableCategories /> */}
            {getList.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                  <tr className=' text-muted fw-500'>
                    <th className='min-w-100px py-2' style={{width: '25%'}}>
                      Category
                    </th>
                    <th className='min-w-100px py-2'>Nature of Business</th>
                    <th className='min-w-100px py-2'>Max Credit Days Allowed</th>
                    <th className='min-w-80px action-th py-2'>{permission.write && 'Actions'}</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {getList.map((item: any) => {
                    return (
                      <tr>
                        <td>{item.category}</td>
                        <td>
                          {item.master_nature_of_business !== null
                            ? item.master_nature_of_business.toUpperCase()
                            : '-'}
                        </td>
                        <td>
                          {item.is_max_credit_days_active &&
                          item.is_max_credit_days_active === true ? (
                            `${item.max_credit_days} Days`
                          ) : (
                            <div className='text-muted font-13 mb-1 font-medium'>Disabled</div>
                          )}
                        </td>
                        <td className='text-end'>
                          {permission.write && (
                            <a
                              href='javascript:void(0)'
                              data-bs-toggle='modal'
                              data-bs-target='#ea_modal_edit_customer_category'
                              className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                              onClick={(event) => {
                                onChangeAdd(item)
                                setMessageHandler('successEdit')
                                setItems(item)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='ic mr-0'
                              />
                            </a>
                          )}
                          {/* {permission.delete && (
                                <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'
                                onClick={(event) => {
                                  onDelete(item)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='ic mr-0'
                                />
                              </a>
                              )}*/}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getList.length == 0 ? 0 : selected * 10 + 1} to ${
              getList.length == 0 ? 0 : getList.length < 10 ? count : (selected + 1) * 10
            } of ${getList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div>
      {/* <AddCustomercategory /> */}
      <EaModal
        id='ea_modal_create_customer_category'
        modalTitle='Add Customer Category'
        onSubmit={AddFunction}
        closeAuto='closeAddEaModalBrand'
        cancel={clearFunctionAdd}
        actionBtnName={isLoadingAdd}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Category Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='categories'
            className={clsx('form-control', Validation)}
            placeholder='Type here...'
            onChange={(e) => {
              setCategories(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidation(validation)
            }}
            value={categories}
          />
        </div>
        <div className=''>
          <label className='form-label' id='parentValidation'>
            Nature of Business (If Available)<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchCategory}
            isSearchable
            placeholder='Select'
            className={clsx('react-select-container mb-4', parentValidation)}
            classNamePrefix='react-select'
            onChange={(parentCategories: any) => {
              setParentCategories(parentCategories.value)
            }}
            value={business.filter((option: any) => option.value == parentCategories)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <label htmlFor='creditDaysCheckBox' className='form-check-label m-0'>
              <input
                className='form-check-input widget-13-check me-2'
                id='creditDaysCheckBox'
                name='checkValue'
                checked={isCreditDays}
                type='checkbox'
                onChange={(e: any) => {
                  setIsCreditDays(!isCreditDays)
                }}
              />
              Credit Days Allowed
            </label>
          </div>
        </div>
        {isCreditDays && (
          <div className='mb-3'>
            <label className='form-label'>Max Credit Days Allowed</label>
            <input
              type='text'
              id='creditDays'
              className={clsx('form-control', creditDaysValidation)}
              placeholder='Type here...'
              onChange={(e) => {
                const regex = /^[0-9\b]+$/
                const inputValue = e.target.value
                if (inputValue === '' || regex.test(inputValue)) {
                  setCreditDays(capitalizeFirstLetter(e.target.value))
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  setCreditDaysValidation(validation)
                }
              }}
              value={creditDays}
            />
          </div>
        )}
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>

      {/* edit customer category */}
      <EaModal
        id='ea_modal_edit_customer_category'
        modalTitle='Edit Customer Category'
        onSubmit={EditFunction}
        closeAuto='closeEditEaModalBrand'
        cancel={clearFunctionEdit}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      >
        <div className='mb-3'>
          <label className='form-label'>
            Category Name<span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            id='categoriesEdit'
            className={clsx('form-control', ValidationEdit)}
            placeholder='Type here...'
            onChange={(e) => {
              setCategoriesEdit(capitalizeFirstLetter(e.target.value))
              const validation = ValidationHandler(e.target.value, 'is-invalid')
              setValidationEdit(validation)
            }}
            value={categoriesEdit}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Nature of Business (If Available)<span className='text-danger'>*</span>
          </label>
          <AsyncPaginate
            loadOptions={fetchCategory}
            isSearchable
            placeholder='Select'
            className='react-select-container mb-4'
            classNamePrefix='react-select'
            onChange={(parentCategoriesEdit: any) => {
              setParentCategoriesEdit(parentCategoriesEdit.value)
            }}
            value={business.filter((option: any) => option.value == parentCategoriesEdit)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>
        <div className='mb-4'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <label htmlFor='creditDaysCheckBox' className='form-check-label m-0'>
              <input
                className='form-check-input widget-13-check me-2'
                id='creditDaysCheckBox'
                name='checkValue'
                checked={isCreditDays}
                type='checkbox'
                onChange={(e: any) => {
                  setIsCreditDays(!isCreditDays)
                }}
              />
              Credit Days Allowed
            </label>
          </div>
        </div>
        {isCreditDays && (
          <div className='mb-3'>
            <label className='form-label'>Max Credit Days Allowed</label>
            <input
              type='text'
              id='creditDaysEdit'
              className={clsx('form-control', creditDaysValidationEdit)}
              placeholder='Type here...'
              onChange={(e) => {
                const regex = /^[0-9\b]+$/
                const inputValue = e.target.value
                if (inputValue === '' || regex.test(inputValue)) {
                  setCreditDaysEdit(capitalizeFirstLetter(e.target.value))
                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                  setCreditDaysValidationEdit(validation)
                }
              }}
              value={creditDaysEdit}
            />
          </div>
        )}
        <ErrorHandler errorMessage={errorMessage} />
      </EaModal>
    </>
  )
}

export default CustomerCategories
