import { apiBaseURL, generateUUID, roleAndPermission } from "../../../helpers";
import { postRequest } from "../../../https/apiCall";
import { getUserDetails } from "../../../localStorage/setItem/setLocalStorage";
import { LOGIN_EAFRUITS_FAILS, LOGIN_EAFRUITS_IS_LOADING, LOGIN_EAFRUITS_SUCCESS } from "../../types/types";

export const loginEaFruits = (email: string, password: string) => async (dispatch: any) => {
    dispatch({
        type: LOGIN_EAFRUITS_IS_LOADING,
        payload: ''
    })
    const deviceId = localStorage.getItem('device_id')
    let uniqueDeviceId: any = deviceId

    if (uniqueDeviceId == "" || uniqueDeviceId == undefined || uniqueDeviceId == null) {
        uniqueDeviceId = generateUUID(40)
        localStorage.setItem('device_id', uniqueDeviceId)
    }

    const fcmToken = localStorage.getItem('device_token')
    let deviceToken = fcmToken

    if (deviceToken == null) {
        deviceToken = "web_token"
    }

    let body = {
        email: email,
        password: password,
        device_id: uniqueDeviceId,
        device_token: deviceToken,
        device_type: "web"
    }

    const res = await postRequest(`${apiBaseURL()}auth/login`, body)

    if (res.status === 200) {
        if (res.data.error) {
            if (res.data.error.code == 417) {
                dispatch({
                    type: LOGIN_EAFRUITS_FAILS,
                    payload: `You don't have access to the DMS Web Application, please get the EA Fruits Staff Mobile Application from your administrator and login there!`
                })
            } else {
                dispatch({
                    type: LOGIN_EAFRUITS_FAILS,
                    payload: "Please enter correct Email or Password."
                })
            }

            localStorage.clear()
            console.log("login Error ==>>", res.data.error.error_message)
        } else {
            // Login Success


            localStorage.setItem("token", res.data.token);
            localStorage.setItem("warehouse_name", res.data.warehouse_name);

            // NOTE : impersonation-token , impersonation-id
            // we will use this two keys for the imperson login in the warehouse manager panel.
            localStorage.setItem("impersonation-token", res.data.token);
            localStorage.setItem("impersonation-id", res.data.id);

            getUserDetails(res.data)

            const updateFcmTokeData = {
                device_token: deviceToken,
                device_id: uniqueDeviceId
            }
            await roleAndPermission()

            await postRequest(`${apiBaseURL()}auth/update_fcm_token`, updateFcmTokeData, true)
            dispatch({
                type: LOGIN_EAFRUITS_SUCCESS,
                payload: res.data
            })
            window.location.reload();
        }
    }

    if (res.data.error) {
        // Login Failed
        if (res.data.error.code === 401) {
            dispatch({
                type: LOGIN_EAFRUITS_FAILS,
                payload: "Please enter correct Email or Password."
            })
            localStorage.clear()
            console.log("login Error ==>>", res.data.error.error_message)
        }
    }
}
