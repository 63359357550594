/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'

import {getUserType} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import SurveyForms from './surveyForms'
import TSSurveyForms from './TSsurveyForms/TSSurveyForms'
import {checkUserType} from '../../../_eaFruitsDms/helpers'
// import { OO, superAdmin, w_manager } from '../../../_eaFruitsDms/localstorage/setitemStore';

const superAdmin = localStorage.getItem('superAdmin')
const w_manager = localStorage.getItem('w_manager')
const OO = localStorage.getItem('OO')

const SurveyFormsWrapper: FC = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Survey Forms',
  }
  return (
    <>
      {/* <PageTitle breadcrumbs={[headerTitle]} >{intl.formatMessage({ id: 'ProductSku' })}</PageTitle> */}

      {getUserType === 'SA' && <SurveyForms className='super-admin-productsku' />}
      {checkUserType(['ts', 'wm']) && <TSSurveyForms />}
    </>
  )
}

export {SurveyFormsWrapper}
