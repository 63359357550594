import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  bulkCreateUpdate,
  lowStockEdit,
  lowStockList
} from "../../../_eaFruitsDms/apiFunctions/lowStockSetting/lowStockSetting";
import { apiBaseURL, checkPermissions, defaultPermission, measurementLabel, retrieveFilter, saveFilter } from "../../../_eaFruitsDms/helpers";
import FormatMessage from "../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../_eaFruitsDms/helpers/components/validationHandler";
import { getRequest } from "../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../_eaFruitsDms/layout/components/button/eaButton";
import EaModal from "../../../_eaFruitsDms/layout/components/modal/eaModal";
import CustomPagination from "../../../_eaFruitsDms/layout/components/pagination/pagination";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import { getW_Id } from "../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import TableLowStack from "./tableLowStockSetting";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce";
import tenantConfiguration from "../../../TenantVariables";
import ResetButton from "../../../_eaFruitsDms/layout/components/button/resetButton";
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import { filters } from "../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}
const LowStock: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Low Stock Settings',
  }

  let lowStockFiltered : any = retrieveFilter(filters.wm_low_stock);
  let warehouse_name : any = localStorage.getItem('warehouse_name')

    const initialState = {
      warehouseId : lowStockFiltered?.warehouseId || {label : warehouse_name , value : getW_Id},
      selected: lowStockFiltered?.selected || '',
      search: lowStockFiltered?.search || '',
      currentOffSet: lowStockFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [isModalLoading, setIsLoading] = useState('Submit')
  const [getlowstockList, setLowStockList] = useState<any>([])
  const [getlowstockListBulkEdit, setLowStockListBulkEdit] = useState<any>([])
  const [count, setCount] = useState<any>('')
  const [next, setNext] = useState<any>('')
  const [prev, setPrev] = useState<any>('')
  const [getLength, setLength] = useState<any>('1')
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(lowStockFiltered?.search || '')
  const [currentOffset, setCurrentOffset] = useState<any>(lowStockFiltered?.currentOffSet || '0');
  const [selected, setSelected] = useState<number>(lowStockFiltered?.selected || 0)
  const [warehouseDropDownValue, setWh_filter] = useState<any>(getW_Id)
  const [Getdata, setGetdata] = useState<any>('')
  const [ProductSku, setProductSku] = useState<any>('')
  const [size, setSize] = useState<any>('')
  const [sizeUnit, setSizeWithUnit] = useState<any>('')
  const [productMeasurementLabel, setProductMeasurementLabel] = useState<any>('')
  const [getLowstock, setLowStock] = useState<any>('')
  const [getWarehouseId, setWarehouseId] = useState<any>('')
  const [getId, setId] = useState<any>('')
  const [getProductId, setProductId] = useState<any>('')
  const [isLoadingEdit, setIsloadingEdit] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [isLoading, setIsloading] = useState('Submit')
  const [lowStockValidationEdit, setLowStockValidationEdit] = useState<any>('')
  const [selectedWarehouseName, setSelectedWarehouseName] = useState<string>()
  const [masterArray, setMasterArray] = useState<any>([])
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [dropDownOffset, setDropDownOffset] = useState<any>('')
  const [wareHouseOption, setWareHouseOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [listLoader, setListLoader] = useState<any>(false)
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [bulkmodalLoader, setBulkModalLoader] = useState<any>(false)
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(lowStockFiltered?.buttonDisable || false) 
  const [warehouseArray, setWarehouseArray] = useState<any>(lowStockFiltered?.warehouseId || {label : warehouse_name , value : getW_Id} )

  const debounceSearch = useDebounce(search, 500); 
  
  useEffect(() => {
    getWareHouseDropdown('')
  },[])

  useEffect(() => {
    fetchPermission()    
    setListLoader(true)   
                        
    lowStockList(limit, currentOffset, debounceSearch, warehouseArray.value).then((res) => {
      setLowStockList(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      setListLoader(false)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_low_stock , filterStates)

    if(warehouseArray.value == getW_Id && search == ''){
        setButtonDisable(false)
    }

  }, [currentOffset, debounceSearch, warehouseArray])

  useEffect(() => {
    setDropDownOffset('')
  }, [searchSelect])

  //pagignation
  
  const fetchPermission = async () =>{
    let res = await checkPermissions("stock_qty_settings")
    setPermission(res)
   } 

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  useEffect(() => {
    wareHouseOption.filter((option: any) => {
      if (option.value == warehouseDropDownValue) {
        setSelectedWarehouseName(option.label)
      }
    })
  }, [wareHouseOption])

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const getWareHouseDropdown = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const ROLE_API = `${apiBaseURL()}warehouse/list?limit=${limit}&parent_warehouse__id=${getW_Id}&ordering=name&offset=${dropDownOffset}&search=${search}&is_parent=True`

    const response = await getRequest(ROLE_API, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setDropDownOffset(newOffset)
      hasMore = true
    }
    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (wareHouseOption.length > 0) {
      setWareHouseOption(wareHouseOption.concat(options))
    } else {
      setWareHouseOption(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const searchList = (e: any) => {
    clearTimeout(timer)
    setSearch(e.target.value)
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    setFilterStates({ ...filterStates, ['search']: e.target.value })

    const newTimer = setTimeout(() => { }, 500)

    setTimer(newTimer)
  }

  const filterDistribution = (e: any) => {
    const warehouseId = e.value
    setWarehouseArray(e)
    setWh_filter(warehouseId)
    setSelectedWarehouseName(e.label)
    setListLoader(true)
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    setFilterStates({ ...filterStates, ['warehouseId']: e })
  }

  // Develop BY: Kriti
  // Description: get value from table

  const getValue = async (productId: any) => {
    const wId = localStorage.getItem('w_id')
    setModalLoader(true)
    const productSku: any = await getRequest(`${apiBaseURL()}inventory/product-SKUs/${productId}/?warehouse_id=${warehouseDropDownValue}`, true)
    setGetdata(productSku)
    setProductSku(productSku.name)
    setSize(productSku.size)
    setModalLoader(false)
    const size = productSku.fix_size ? ((productSku.measurement_unit.toLowerCase() === 'gram') ? productSku.size / 1000 : productSku.size) : (productSku.variants.approx_weight > 0) ? productSku.variants.approx_weight / 1000 : 1

    const result = size + " Kgs"
    setProductMeasurementLabel(measurementLabel(productSku.material.name))
    setSizeWithUnit(result)

    setLowStock(productSku.low_stock_alert.length !== 0 ? productSku.low_stock_alert[0].low_stock : '')
    setWarehouseId(warehouseDropDownValue)
    setProductId(productSku.id)
    setId(productSku.low_stock_alert.length !== 0 ? productSku.low_stock_alert[0].id : 0)
  }

  //  Develop BY: Kriti
  //  Description: validate data and single edit low-stock function

  const EditFunction = () => {
    if (getLowstock === '') {
      setLowStockValidationEdit('is-invalid')
    } else {
      setLowStockValidationEdit('')
    }
    if (getLowstock !== '') {
      EditFunctionApi()
    }
  }
  const clearFunctionEdit = () => {
    setLowStockValidationEdit('')
  }

  //  Develop BY: Kriti
  //  Description: Edit low-stock API called function

  const EditFunctionApi = () => {
    setIsloadingEdit('Please Wait..')
    lowStockEdit(getId, getProductId, getWarehouseId, getLowstock).then((notify) => {
      setIsloadingEdit('Submit')
      if (notify.success) {
        clearFunctionEdit()
        setListLoader(true)
        lowStockList(limit, currentOffset, search, warehouseDropDownValue).then((res) => {
          setLowStockList(res.data)
          setListLoader(false)
          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          // setSelected(0)
        })
        $('#closeEditEaModalBrand').trigger('click')
        setTimeout(() => {
          setIsAlertSuccess(false)
          setIsAlertFailed(false)
        }, 2000)
        setIsAlertSuccess(true)
      }
      setIsloading('Submit')
    })
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  //  Develop BY: Kriti
  //  Description: onChange handler

  const getBulkValue = (e: any, item: any, key: any) => {
    let bulkId = e.target.id
    let productId = item.id
    let checkedValue = item.low_stock_alert.length

    const { name, value } = e.target
    let customMasterArr = [...masterArray]

    try {
      const index = masterArray.findIndex((item: any) => {
        return item && item.id == bulkId
      })

      if (index !== -1) {
        customMasterArr[bulkId][name] = value
      } else {
        if (customMasterArr[bulkId] == undefined) {
          customMasterArr[bulkId] = {
            id: bulkId,
            checkedValue: checkedValue,
            productId: productId,
            [name]: value,
          }
        } else {
          customMasterArr[bulkId][name] = value
        }
      }
      setMasterArray(customMasterArr)
    } catch (error) { }
  }

  const clearFunction = () => {
    setMasterArray([])

    $('#tableId')
      .find('input[type=number]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  //  Develop BY: Kriti
  //  Description: bulk edit low-stock which is called on Eamodel's onsubmit click

  const updateBulkPrice = () => {
    setIsLoading('Please Wait...')
    let masterData: any = []

    masterArray.forEach((element: any) => {
      if (element !== undefined) {
        if (element.lowStock !== '') {
          if (element.checkedValue === 0) {
            masterData.push({
              product_id: element.id,
              warehouse_id: warehouseDropDownValue,
              low_stock: element.lowStock ? element.lowStock : '',
            })
          } else {
            getlowstockListBulkEdit.find((item: any) => {
              if (item.low_stock_alert.length !== 0) {
                if (item.low_stock_alert[0].id == element.id) {
                  masterData.push({
                    id: element.id,
                    product_id: element.productId,
                    warehouse_id: warehouseDropDownValue,
                    // effective_date: date,
                    low_stock: element.lowStock
                      ? element.lowStock
                      : item.low_stock_alert[0].low_stock,
                  })
                }
              }
            })
          }
        }
      }
    })

    bulkCreateUpdate(masterData).then((res) => {
      setMasterArray([])
      // setDisableBtn(false)
      setIsLoading('Submit')

      $('#tableId')
        .find('input[type=number]')
        .each(function () {
          var defaultVal = $(this).data('default')
          $(this).val(defaultVal)
        })

      $('#closeBulkEditEaModalBrand').trigger('click')
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 1500)

      if (res.success) {
        setIsAlertSuccess(true)
        setListLoader(true)
        lowStockList(limit, '0', search, warehouseDropDownValue).then((res) => {
        setListLoader(false)
          setLowStockList(res.data)
          setCount(res.count)
          setNext(res.next)
          setPrev(res.prev)
          setSelected(0)

          if (res.data.length == 0) {
            setLength('2')
          } else {
            setLength('1')
          }
        })
      }
      if (res.failed) {
        setIsAlertFailed(true)
      }
    })
  }

  const bulkEditHandler = async () => {
    setBulkModalLoader(true)
    const res = await lowStockList(10000, 0, '', warehouseDropDownValue)
    setLowStockListBulkEdit(res.data)
    setBulkModalLoader(false)
  }

  const handleChangeReset = () =>{
    setSearch('')
    setWarehouseArray({label : warehouse_name , value : getW_Id})
    setCurrentOffset(0)
    setSelected(0)
    let updatedState: any = { ...filterStates }
    updatedState['warehouseId'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Low Stock Settings' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: 'Low Stock Settings' })}
      </PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={IsAlertSuccess}
        // message='Your low stock has been updated successfully.'
        message={FormatMessage('LOWSTOCK.UPDATE')}
      />
      <EaAlertDanger onClick={() => isAlertSHow('failed')} show={isAlertFailed} message='failed' />
      <div className={`card ${className}`}>
      {listLoader && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            {/* <CustomSearchBar /> */}

            <div className='col-12 col-md-auto mt-0'>
              <input
                type='text'
                placeholder='Search'
                className='form-control form-search'
                onChange={(e) => searchList(e)}
                value={search}
                disabled={listLoader}
              />
            </div>

            <div className='col-auto ms-md-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form className='card-toolbar d-flex my-0' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                <AsyncPaginate
                    
                  loadOptions={getWareHouseDropdown}
                  isSearchable
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='Warehouse'
                  name='role'
                  // value={wareHouseOption.filter(
                  //   (option: any) => option.value == warehouseDropDownValue
                  // )}
                  value={warehouseArray}
                  onChange={filterDistribution}
                  isDisabled={listLoader}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {permission.write && 
                <div className="me-3">  
                <EaButton
                  isModal={true}
                  dataTarget='#ea_modal_bulk_edit_lowstock'
                  btnName='Edit'
                  onClick={bulkEditHandler}
                  className='btn btn-primary'
              /></div>
              }
                <ResetButton onClick = {handleChangeReset} isDisable = {buttonDisable}/>
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {/* lowStockList */}

        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {getlowstockList.length === 0 ? <NoRecords/> : 
              <>
                 {/* begin::Table */}
                <TableLowStack
                  getValue={getValue}
                  tableLowStockList={getlowstockList}
                  getLength={getLength}
                  showDelete={permission.delete}
                  showWrite={permission.write}
                // getPropsEdit={getProps}
                ></TableLowStack>
                  </>
            }
            {/* <TableTeamMember getLength={getLength} tableList={getList} getPropsEdit={getProps} /> */}

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>

        {/* begin::Body */}
      </div>
      {/* <div className='table-info py-5'>
        <div className='row g-0'>
          <div className='col'>
            {`Showing ${getlowstockList.length == 0 ? 0 : selected * 10 + 1} to ${getlowstockList.length == 0
              ? 0
              : getlowstockList.length < 10
                ? count
                : (selected + 1) * 10
              } of ${getlowstockList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              numberClick={numberClick}
              prev={prevPage}
              next={nextPage}
              count={getlowstockList.length == 0 ? 0 : count}
              selected={selected}
            />
          </div>
        </div>
      </div> */}
    <CustomPaginate
          data={getlowstockList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_low_stock}
          filterStates={filterStates}
          isDisabled={listLoader}
        />
      <EaModal
        id='ea_modal_edit_lowstock'
        modalTitle='Update Low Qty. Stock Alert'
        // className='modal fade'
        cancel={() => setLowStockValidationEdit('')}
        onSubmit={EditFunction}
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        closeAuto='closeEditEaModalBrand'
        widthcls='modal-lg modal-dialog'
        subTitle={selectedWarehouseName}
        actionBtnName={isLoadingEdit}
        loader={modalLoader}
      // cancel={clearFunctionEdit}
      //  onSubmit={EditFunction}
      >
        <div className='modal-body py-3'>
          <table className='table table-borderless table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted fw-500'>
                <th className='min-w-250px' style={{ width: '65%' }}>
                  Product SKUs
                </th>
                <th className='min-w-150px' style={{ width: '65%' }}>
                  Size
                </th>
                <th className='min-w-200px w-auto'>Low Stock Alert At</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <input type='text' className='form-control ' value={ProductSku} disabled />
                </td>
                <td>
                  <input type='text' className='form-control' value={sizeUnit} disabled />
                </td>
                <td>
                  <div className='input-group'>
                    <input
                      type='text'
                      className={clsx('form-control', lowStockValidationEdit)}
                      // className='form-control border-focus-light'
                      value={getLowstock}
                      onChange={(e) => {
                        const valid = /^[0-9\b]+$/
                        if (e.target.value === '' || valid.test(e.target.value)) {
                          setLowStock(e.target.value)
                          const validation = ValidationHandler(e.target.value, 'is-invalid')
                          setLowStockValidationEdit(validation)
                        }
                      }}
                      placeholder={'Type here...'}
                    />
                    <span
                      className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                      {productMeasurementLabel}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </EaModal>

      {/* bulk edit  */}
      <EaModal
        id='ea_modal_bulk_edit_lowstock'
        modalTitle='Update Low Qty. Stock Alert'
        onSubmit={updateBulkPrice}
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        closeAuto='closeBulkEditEaModalBrand'
        widthcls='modal-lg modal-dialog'
        subTitle={selectedWarehouseName}
        actionBtnName={isModalLoading}
        cancel={clearFunction}
        loader={bulkmodalLoader}
      >
        <div className='modal-body py-3'>
          <table
            className='table table-borderless table-row-gray-100 align-middle gs-0 gy-3'
            id='tableId'
          >
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted fw-500'>
                <th className='min-w-250px' style={{ width: '65%' }}>
                  Product SKUs
                </th>
                <th className='min-w-150px' style={{ width: '65%' }}>
                  Size
                </th>
                <th className='min-w-200px w-auto'>Low Stock Alert At</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {getlowstockListBulkEdit.map((item: any) => {
                let size = item.fix_size ? ((item.measurement_unit.toLowerCase() === 'gram') ? item.size / 1000 : item.size) : (item.variants.approx_weight > 0) ? item.variants.approx_weight / 1000 : 1

                return (
                  <tr>
                    <td>
                      <input type='text' className='form-control ' value={item.name} disabled />
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={size + ' Kgs'}
                        disabled
                      />
                    </td>
                    <td>
                      <div className='input-group'>
                        <input
                          type='text'
                          className={clsx('form-control', lowStockValidationEdit)}
                          name='lowStock'
                          onChange={(e) => {
                            const valid = /^[0-9\b]+$/
                            if (e.target.value === '' || valid.test(e.target.value)) {
                              getBulkValue(e, item, 'lowStock')
                            }
                          }}
                          id={
                            item.low_stock_alert.length === 0 ? item.id : item.low_stock_alert[0].id
                          }
                          value={
                            masterArray.length !== 0 &&
                              masterArray[
                              item.low_stock_alert.length === 0
                                ? item.id
                                : item.low_stock_alert[0].id
                              ] !== undefined
                              ? masterArray[
                                item.low_stock_alert.length === 0
                                  ? item.id
                                  : item.low_stock_alert[0].id
                              ].lowStock
                              : ''
                          }
                          // onChange= {(e) =>   setLowStock(e.target.value)}
                          placeholder={
                            item.low_stock_alert[0] ? item.low_stock_alert[0].low_stock : '-'
                          }
                        />
                        <span
                          className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                          {measurementLabel(item.material.name)}
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </EaModal>
    </>
  )
}

export default LowStock
