import clsx from 'clsx'
import $ from 'jquery'
import moment from 'moment/moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router'
import {Link, useLocation} from 'react-router-dom'
import Select from 'react-select'
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  IMPERSONATE_FAILED,
  IMPERSONATE_FOR_INACTIVE,
  removeFilterImpersonate,
} from '../../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {deleteRequest, postRequest, putRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {
  EaAlertDanger,
  EaAlertSuccess,
} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../../_eaFruitsDms/layout/components/button/eaButton'
import EaModal from '../../../../../_eaFruitsDms/layout/components/modal/eaModal'
import {getImpersonationUserDetails} from '../../../../../_eaFruitsDms/localStorage/setItem/setLocalStorage'
import {addUser} from '../../../../../_eaFruitsDms/redux/action/teleSalesUser/teleSalesUser'
import tenantConfiguration from '../../../../../TenantVariables'

const optionGender = [
  {value: 'Male', label: 'Male'},
  {value: 'Female', label: 'Female'},
]

const TeleSalesNameHeader: React.FC = (props: any) => {
  const location = useLocation()
  const navigator = useNavigate()

  const user: any = props.user
  // if (user == undefined) {
  //     navigator("/tele-sales")
  // }

  const currentDate = moment().format('YYYY-MM-DD')
  const currentDateObj = moment().toDate()

  const initialValidations = {
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
    gender: '',
    dob: '',
  }

  const API_BASE_URL = tenantConfiguration.apiBaseUrl

  const [teleSaleUser, setTeleSaleUser] = useState<any>(user)
  const [submitButtonLabel, setSubmitButtonLabel] = useState('Submit')
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(false)
  const [isCancelBtnDisabled, setIsCancelBtnDisabled] = useState<boolean>(false)
  const [isNotUniqueEmail, setIsNotUniqueEmail] = useState<boolean>(false)
  const [isNotUniquePhone, setIsNotUniquePhone] = useState<boolean>(false)
  const [messageHandler, setMessageHandler] = useState<string>('')
  const [messageTitle, setMessageTitle] = useState<string>('')
  const [validationFor, setValidationFor] = useState<any>(initialValidations)
  const [isAlertSuccess, setIsAlertSuccess] = useState<boolean>(false)
  const [isAlertFailed, setIsAlertFailed] = useState<boolean>(false)
  const [impersonate, setImpersonate] = useState<any>(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [permission, setPermission] = useState(defaultPermission)
  const [isButtonLoader, setIsButtonLoader] = useState<boolean>(false)

  useEffect(() => {
    fetchPermission()
  }, [])

  const fetchPermission = async () => {
    let res: any
    res = await checkPermissions('telesales')
    setPermission(res)

    res = await checkPermissions('telesales_impersonation')
    if (res && res.read) {
      setImpersonate(true)
    } else {
      setImpersonate(false)
    }
  }

  const isFormValid = () => {
    let isValid = true
    const validationOccurFor = {...validationFor}

    if (teleSaleUser.first_name.trim() === '') {
      validationOccurFor.first_name = 'is-invalid'
      isValid = false
    }

    if (teleSaleUser.last_name.trim() === '') {
      validationOccurFor.last_name = 'is-invalid'
      isValid = false
    }

    if (
      teleSaleUser.email.trim() === '' ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(teleSaleUser.email)
    ) {
      validationOccurFor.email = 'is-invalid'
      isValid = false
    }

    if (teleSaleUser.phone_no.trim() === '') {
      validationOccurFor.phone_no = 'is-invalid'
      isValid = false
    }

    if (teleSaleUser.date_of_birth === 'Select') {
      validationOccurFor.dob = 'is-invalid'
      isValid = false
    }

    if (teleSaleUser.gender === '') {
      validationOccurFor.gender = 'is-invalid'
      isValid = false
    }

    if (!isValid) {
      setIsSubmitBtnDisabled(false)
    }

    setValidationFor(validationOccurFor)
    return isValid
  }

  const submitTeleSaleUserData = async () => {
    if (isFormValid()) {
      setSubmitButtonLabel('Please Wait..')
      setIsCancelBtnDisabled(true)
      let res: any = null

      const UPDATE_TELE_SALE_USER_API = `${API_BASE_URL}auth/users/Telesales/update/${user.id}/`

      res = await putRequest(UPDATE_TELE_SALE_USER_API, teleSaleUser, true)

      setSubmitButtonLabel('Submit')
      setIsCancelBtnDisabled(false)

      if (res.data && res.data.error) {
        res.data.error.map((err: any) => {
          if (err.field === 'email') {
            setIsNotUniqueEmail(true)
          }

          if (err.field === 'phone_no') {
            setIsNotUniquePhone(true)
          }
        })
      } else {
        setMessageHandler('successEdit')
        setIsAlertSuccess(true)
        $('#tele_sales_user').trigger('click')
        props.addUser(teleSaleUser)
        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
      }
    }
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    let inputValue =
      inputName === 'is_active' ? e.target.checked : e.target ? e.target.value : e.value

    if (inputName === 'phone_no') {
      if (inputValue.length > 13) {
        setValidationFor({...validationFor, phone_no: 'is-invalid'})
        return false
      }

      inputValue = inputValue.replace(/\D/g, '')
    }
    if (inputName === 'email') {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValue)) {
        setValidationFor({...validationFor, email: 'is-invalid'})
      }
    }

    setValidationFor({...validationFor, [inputName]: ''})

    if (inputValue === '') {
      setValidationFor({...validationFor, [inputName]: 'is-invalid'})
      setIsSubmitBtnDisabled(true)
    } else {
      setIsSubmitBtnDisabled(false)
    }

    setTeleSaleUser({...teleSaleUser, [inputName]: inputValue})
  }

  const deleteUser = async () => {
    const DELETE_USER_API = `${API_BASE_URL}auth/users/Telesales/delete/${user.id}/`

    await deleteRequest(DELETE_USER_API, true)

    $('#delete_user').trigger('click')
    localStorage.setItem('is_deleted', '1')
    navigator('/tele-sales', {})
  }

  const [name, setName] = useState('')

  useEffect(() => {
    if (user == undefined) {
      navigator('/tele-sales')
    }

    const feedBackName = localStorage.getItem('feedBackName')

    if (location.pathname !== '/tele-sales/tele-sales-name/survey-feedback/survey-feedback-name') {
      if (user !== undefined) {
        setName(user.first_name + ' ' + user.last_name)
      }
    } else {
      if (feedBackName) {
        setName(feedBackName)
      }
    }
  }, [location.pathname])

  const navigate = useNavigate()
  const splashScreen = document.getElementById('splashScreenStyle')

  const handleImpersonation = async () => {
    if (!user.is_impersonate_allow) {
      setErrorMessage(IMPERSONATE_FAILED)
      setIsAlertFailed(true)

      setTimeout(() => {
        setIsAlertFailed(false)
      }, 3000)
    } else {
      if (!user.is_active) {
        setErrorMessage(IMPERSONATE_FOR_INACTIVE)
        setIsAlertFailed(true)

        setTimeout(() => {
          setIsAlertFailed(false)
        }, 3000)
      } else {
        // remove filter's data from localstorage
        removeFilterImpersonate()

        let body = {
          user_id_to_impersonate: user.id,
        }
        setIsButtonLoader(true)
        const response = await postRequest(`${apiBaseURL()}auth/impersonate`, body, true)
        setIsButtonLoader(false)
        if (response.status == 200) {
          if (response.data.error) {
            setIsAlertFailed(true)

            setTimeout(() => {
              setIsAlertFailed(false)
            }, 3000)
          } else {
            if (splashScreen) {
              splashScreen.style.setProperty('display', 'flex')
            }

            let impersonationUser = {
              role: response.data.role,
              warehouse: response.data.warehouse,
              warehouse_name: response.data.warehouse_name,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              profile_thumbnail: response.data.profile_thumbnail,
              base_url: response.data.base_url,
              user_type: response.data.type,
              id: response.data.request_to,
              token: response.data.token,
              email: response.data.email,
              role_name: response.data.role_name,
            }

            // NOTE : here set the details of login user
            localStorage.setItem('isImpersonationLogin', 'true')
            localStorage.removeItem('permissions')
            localStorage.removeItem('api_process')
            localStorage.removeItem('support_request_warehouse')
            localStorage.removeItem('support_request_start_date')
            localStorage.removeItem('support_request_end_date')
            getImpersonationUserDetails(impersonationUser)

            // redirect to user panel
            navigate('/dashboard', {
              state: {
                isDisableApiCall: true,
              },
            })
            window.location.reload()
          }
        } else {
          setErrorMessage('Something went wrong.! Please try again after sometime.!')
          setIsAlertFailed(true)
          setTimeout(() => {
            setIsAlertFailed(false)
          }, 3000)
        }
      }
    }
  }

  return (
    <>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successEdit'
            ? FormatMessage('TELE_SALE.UPDATE', messageTitle)
            : messageHandler === 'successDelete' && FormatMessage('TELE_SALE.DELETE', messageTitle)
        }
      />
      {/*Alert Error*/}
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <EaAlertDanger
            onClick={() => isAlertSHow('failed')}
            show={isAlertFailed}
            message={
              errorMessage === '' && user
                ? FormatMessage('IMPERSONATE_FAILED', user.first_name + ' ' + user.last_name)
                : errorMessage
            }
          />
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {user ? name : 'Tele-Sales Name'}
                    </span>
                  </div>
                  {/* <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                                        <span className='d-flex align-items-center me-6 mb-2'>
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen018.svg'
                                                className='me-1'
                                            />
                                            Kinondoni
                                        </span>
                                        <span className='d-flex align-items-center me-5 mb-2 '>
                                            <KTSVG
                                                path="/media/icons/duotune/general/gen003.svg"
                                                className="text-warning me-1"
                                            />
                                            4.2 (342 Reviews)
                                        </span>
                                    </div> */}
                </div>
              </div>
            </div>
            <div>
              {permission.write && (
                <a
                  href='#'
                  className='btn btn-secondary me-2'
                  data-bs-toggle='modal'
                  data-bs-target='#ea_modal_create_telesales_user'
                >
                  Edit
                </a>
              )}
              {/*<a*/}
              {/*    href='#'*/}
              {/*    data-bs-toggle='modal'*/}
              {/*    data-bs-target='#ea_modal_create_telesales_user'*/}
              {/*    className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'*/}
              {/*>*/}
              {/*    <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />*/}
              {/*</a>*/}
              {/* {permission.delete && <a href="#"
                                className="btn btn-secondary mx-3"
                                data-bs-toggle='modal'
                                data-bs-target='#deleteUser'
                            >Delete</a>} */}
              {impersonate && (
                <EaButton
                  isModal={false}
                  isDisable={isButtonLoader}
                  btnName={isButtonLoader ? 'Please Wait...' : 'Login to Tele-sale'}
                  onClick={handleImpersonation}
                  className='btn btn-primary btn-sm font-13 my-1'
                />
              )}
            </div>
          </div>

          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/tele-sales/tele-sales-name/overview' && 'active')
                  }
                  to='/tele-sales/tele-sales-name/overview'
                >
                  Overview
                </Link>
              </li>
              {/*<li className='nav-item'>*/}
              {/*  <Link*/}
              {/*    className={*/}
              {/*      `nav-link text-active-primary me-0 ` +*/}
              {/*      (location.pathname === '/tele-sales/tele-sales-name/complaints' && 'active')*/}
              {/*    }*/}
              {/*    to='/tele-sales/tele-sales-name/complaints'*/}
              {/*  >*/}
              {/*    Complaints 111*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    // (location.pathname === '/tele-sales/tele-sales-name/survey-feedback' && 'active') +
                    (location.pathname.includes('/tele-sales/tele-sales-name/survey-feedback') &&
                      'active')
                  }
                  to='/tele-sales/tele-sales-name/survey-feedback'
                >
                  Survey Feedback
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/tele-sales/tele-sales-name/sales-order-feedback' &&
                      'active')
                  }
                  to='/tele-sales/tele-sales-name/sales-order-feedback'
                >
                  Sales Order Feedback
                </Link>
              </li>
              {/* <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-0 ` +
                                        (location.pathname === '/tele-sales/tele-sales-name/call-notes' && 'active')
                                    }
                                    to='/tele-sales/tele-sales-name/call-notes'
                                >
                                    Call Notes
                                </Link>
                            </li> */}
            </ul>
          </div>
        </div>
        <EaModal
          closeAuto={'tele_sales_user'}
          id='ea_modal_create_telesales_user'
          onSubmit={submitTeleSaleUserData}
          modalTitle='Edit Tele-Sales User'
          actionBtnName={submitButtonLabel}
          btnDisabled={isSubmitBtnDisabled}
          cancel={() => {
            setTeleSaleUser(user)
            setValidationFor(initialValidations)
          }}
          cancelBtnDisabled={isCancelBtnDisabled}
        >
          {/* <Form> */}
          <div className='row'>
            <div className='mb-4 col-sm-6'>
              <label className='form-label'>First Name</label>
              <input
                type='text'
                className={clsx(
                  'form-control',
                  validationFor.first_name && validationFor.first_name
                )}
                placeholder='Type here...'
                onChange={handleChange}
                name='first_name'
                value={teleSaleUser && teleSaleUser.first_name}
              />
            </div>
            <div className='mb-4 col-sm-6'>
              <label className='form-label'>Last Name</label>
              <input
                type='text'
                className={clsx('form-control', validationFor.last_name && validationFor.last_name)}
                placeholder='Type here...'
                name='last_name'
                onChange={handleChange}
                value={teleSaleUser && teleSaleUser.last_name}
              />
            </div>
          </div>
          <div className='mb-4'>
            <label className='form-label'>Email</label>
            <input
              type='text'
              className={clsx('form-control', validationFor.email && validationFor.email)}
              placeholder='Type here...'
              name='email'
              disabled
              onChange={handleChange}
              value={teleSaleUser && teleSaleUser.email}
            />
            {isNotUniqueEmail ? (
              <p className='text-danger'>
                Your added email already exist in this system.! Please try again with unique name
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='mb-4'>
            <label className='form-label'>Phone Number</label>
            <input
              type='text'
              className={clsx('form-control', validationFor.phone_no && validationFor.phone_no)}
              placeholder='Type here...'
              name='phone_no'
              onChange={handleChange}
              value={teleSaleUser && teleSaleUser.phone_no}
            />
            {isNotUniquePhone ? (
              <p className='text-danger'>
                Your added phone number already exist in this system.! Please try again with unique
                name
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='row'>
            <div className='col-lg-6 fv-row mb-4'>
              <label className='form-label'>Gender</label>
              <Select
                options={optionGender}
                isSearchable
                className={clsx(
                  'react-select-container mb-4',
                  validationFor.gender && validationFor.gender
                )}
                classNamePrefix='react-select'
                name='gender'
                onChange={(e: any) => handleChange(e, 'gender')}
                value={
                  teleSaleUser &&
                  optionGender.find((gender: any) => gender.value === teleSaleUser.gender)
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div className='col-lg-6 fv-row mb-4'>
              <label className='form-label'>Date of Birth</label>
              <DateRangePicker
                onCallback={(start: any) => {
                  setTeleSaleUser({
                    ...teleSaleUser,
                    date_of_birth: moment(start._d).format('YYYY-MM-DD'),
                  })
                }}
                onCancel={() => {
                  setTeleSaleUser({...teleSaleUser, date_of_birth: 'Select'})
                }}
                initialSettings={{
                  startDate: currentDateObj,
                  maxDate: currentDate,
                  alwaysShowCalendars: true,
                  cancelClass: 'btn-secondary',
                  singleDatePicker: true,
                  drops: 'auto',
                  locale: {
                    format: 'YYYY-MM-DD',
                  },
                }}
              >
                <input
                  type='text'
                  name='date_of_birth'
                  value={teleSaleUser && teleSaleUser.date_of_birth}
                  className={clsx('form-control calendar', validationFor.dob)}
                  autoFocus={true}
                />
              </DateRangePicker>
            </div>
          </div>
          <div className='mb-3'>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <label className='form-check-label me-3'>Access Status</label>
              <input
                className='form-check-input'
                name='is_active'
                onChange={handleChange}
                checked={teleSaleUser && teleSaleUser.is_active}
                type='checkbox'
                value=''
              />
            </div>
          </div>
        </EaModal>

        {/* delete User */}
        <EaModal
          modalTitle='Delete Tele-Sale User'
          children={
            <p className='mb-0'>
              Are you sure you want to delete{' '}
              <span className='fw-bold'>{user && user.first_name + ' ' + user.last_name}?</span>
            </p>
          }
          closeAuto='delete_user'
          id={'deleteUser'}
          actionBtnName='Yes, Proceed'
          onSubmit={deleteUser}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.teleSalesUserReducer.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUser: (val: any) => {
      dispatch(addUser(val))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeleSalesNameHeader)
