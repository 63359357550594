import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {AsyncPaginate} from 'react-select-async-paginate'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import {EaChartBar} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import clsx from 'clsx'
import DatePicker from 'react-date-picker'

const VehicleBreakdownReport = (props: any) => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Vehicle Breakdown Report',
    subTitle: '',
  }

  const baseUrl = apiBaseURL()
  const currentWarehouseId: any = localStorage.getItem('w_id')

  // for bar chart
  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  // for bar chart
  const labelOption = {
    show: true,
    position: app.position,
    distance: app.distance,
    align: app.align,
    verticalAlign: app.verticalAlign,
    rotate: app.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 10,
    rich: {
      name: {},
    },
  }

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [vehicleReport, setVehicleReport] = useState([])
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [isFromCalenderOpen, setIsFromCalenderOpen] = useState<boolean>(false)
  const [isToCalenderOpen, setIsToCalenderOpen] = useState<boolean>(false)
  const [fromMonth, setFromMonth] = useState<any>(moment().toDate())
  const [toMonth, setToMonth] = useState<any>(moment().toDate())
  const [isListLoading, setIsListLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [offsetVehicleMake, setOffsetVehicleMake] = useState(0)
  const [listVehicleMake, setListVehicleMake] = useState([])
  const [dropDownSearchVehicleMake, setDropDownSearchVehicleMake] = useState('')
  const [vehicle, setVehicle] = useState<any>('')
  const [make, setMake] = useState<any>('')
  const [barChart, setBarChart] = useState({})
  const [isChartLoading, setChartLoading] = useState(true)
  const [vehicleType, setVehicleType] = useState('make')
  const [warehouse, setWarehouse] = useState(
    checkUserType(['lo'])
      ? {label: 'All Warehouses', value: currentWarehouseId}
      : {label: 'All Warehouses', value: ''}
  )
  const [warehouseReport, setWarehouseReport] = useState(
    checkUserType(['lo'])
      ? {label: 'All Warehouses', value: currentWarehouseId}
      : {label: 'All Warehouses', value: ''}
  )

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    if (fromMonth != '') {
      setIsFromCalenderOpen(false)
    }
  }, [fromMonth])

  useEffect(() => {
    if (toMonth != '') {
      setIsToCalenderOpen(false)
    }
  }, [toMonth])

  const fetchVehicleReportList = async () => {
    setIsListLoading(true)
    const filter = `&vehicle_type=${vehicle !== '' ? vehicle.value : ''}&vehicle_make=${
      make !== '' ? make.value : ''
    }`

    let warehouseFilter = `&warehouse_id=` + warehouse.value

    const param =
      `limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&start_date=${apiDate.startDate}&end_date=${apiDate.endDate}` +
      warehouseFilter +
      filter
    const url = baseUrl + 'reports/vehicle-breakdown?' + param

    const response = await getRequest(url, true)
    if (response && response.results) {
      setVehicleReport(response.results)
      setCount(response.count)
    }
    setIsListLoading(false)
  }

  const vehicleChartReport = async () => {
    setChartLoading(true)

    const url = `${baseUrl}reports/vehicle-breakdown/${vehicleType}?&warehouse_id=${
      warehouseReport.value
    }&start_month=${dateFormat(fromMonth, 'MM-yyyy', true).displayDate}&end_month=${
      dateFormat(toMonth, 'MM-yyyy', true).displayDate
    }`

    const response = await getRequest(url, true)
    if (response) handleChartResponse(response)

    setChartLoading(false)
  }

  const handleChartResponse = (response: any) => {
    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []
    let type: any = []
    let key = vehicleType === 'make' ? 'vehicle_make' : 'vehicle_type'

    if (response.length > 0) {
      response[0].data.forEach((item: any) => {
        legend.push(item[key])
        type.push({name: item[key]})
      })

      response.forEach((item: any) => xAxisData.push(item.month))

      type.forEach((i: any) => {
        let data: any = []
        response.forEach((item: any) => {
          item.data.length > 0 &&
            item.data.forEach((item: any, index: any) => {
              if (i.name === item[key]) {
                data.push(item.count)
              }
            })
        })

        seriesData.push({
          name: i.name,
          type: 'bar',
          width: '100%',
          label: labelOption,
          emphasis: {
            focus: 'series',
          },
          data: data,
        })
      })
    }

    initialChart.xAxisData = xAxisData
    initialChart.series = seriesData
    initialChart.legend = legend

    setBarChart(initialChart)
  }

  const fetchVehicleTypes = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `master/vechile-type/?ordering=vechile_type`,
      search,
      setDropDownSearch,
      'vechile_type',
      true,
      'All Vehicle'
    )
  }

  const fetchVehicleMake = async (search: any) => {
    return fetchAsyncDropdown(
      offsetVehicleMake,
      setOffsetVehicleMake,
      listVehicleMake,
      setListVehicleMake,
      `master/vechile-make/?ordering=vechile_make`,
      search,
      setDropDownSearchVehicleMake,
      'vechile_make',
      true,
      'All Make'
    )
  }

  useEffect(() => {
    fetchVehicleReportList()
  }, [debounceSearch, currentOffset, apiDate, vehicle, make, warehouse])

  useEffect(() => {
    vehicleChartReport()
  }, [vehicleType, warehouseReport, fromMonth, toMonth])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={isListLoading}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Vehicle Breakdown Report - ' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Vehicle Breakdown Report'})}
      </PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Breakdown Overview</span>
          </h3>
          <div className='card-toolbar d-flex g-3 my-0'>
            <ul className='nav'>
              <li className='nav-item me-3 mt-1'>
                <ul className='nav'>
                  <li className='nav-item me-3'>
                    <label className='font-bold mb-1 form-label'>From Month:</label>
                    <DatePicker
                      value={fromMonth}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isFromCalenderOpen}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setIsFromCalenderOpen(true)
                        setFromMonth(e)
                        setToMonth(e)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                  <li>
                    <label className='font-bold mb-1 form-label'>To Month:</label>
                    <DatePicker
                      value={toMonth}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isToCalenderOpen}
                      minDate={fromMonth}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setToMonth(e)
                        setIsToCalenderOpen(true)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                </ul>
              </li>
              <li className='nav-item mt-6'>
                {checkUserType(['sa', 'wm', 'ts', 'lo']) && (
                  <WarehouseFilter
                    warehouse={warehouseReport}
                    handleFilterChange={setWarehouseReport}
                    isDisabled={isChartLoading}
                  />
                )}
              </li>
              <li className='nav-item mt-6'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_8_tab_1'
                  onClick={() => setVehicleType('make')}
                >
                  By Vehicle Make
                </a>
              </li>
              <li className='nav-item mt-6'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_8_tab_2'
                  onClick={() => setVehicleType('type')}
                >
                  By Vehicle Type
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='card-body'>
          {isChartLoading ? <IsLoader /> : <EaChartBar {...barChart} />}
        </div>
      </div>
      <div>
        <div className='card'>
          {isListLoading && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              value={search}
              isDisable={isListLoading}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {checkUserType(['sa', 'wm', 'ts']) && (
                  <WarehouseFilter
                    warehouse={warehouse}
                    handleFilterChange={setWarehouse}
                    isDisabled={isListLoading}
                  />
                )}
                <AsyncPaginate
                  loadOptions={fetchVehicleTypes}
                  isSearchable
                  placeholder='All Vehicle'
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setVehicle(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={vehicle}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchVehicleMake}
                  isSearchable
                  placeholder='All Make'
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setMake(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={make}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                />
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {vehicleReport.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-180px'>Breakdown Reported On</th>
                      <th className='min-w-170px'>Breakdown Reason</th>
                      <th className='min-w-120px'>Breakdown At</th>
                      <th className='min-w-120px'>Reported By</th>
                      <th className='min-w-120px'>Vehicle Type</th>
                      <th className='min-w-120px'>Make/Model/Year</th>
                      <th className='min-w-120px py-2'>Vehicle Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleReport.length > 0 &&
                      vehicleReport.map((item: any) => {
                        return (
                          <tr>
                            <td>
                              {item.breakdown_date && item.breakdown_date !== '' ? (
                                <>
                                  {dateFormat(item.breakdown_date).displayDate}
                                  <p className='font-13 text-muted mb-0'>
                                    {dateFormat(item.breakdown_date, 'LT').displayDate}
                                  </p>
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isEmpty(item.reason)
                                ? isEmpty(item.reason_note)
                                  ? '-'
                                  : item.reason_note
                                : item.reason}
                            </td>
                            <td>{isEmpty(item.breakdown_at) ? '-' : item.breakdown_at}</td>
                            <td>{isEmpty(item.action_by) ? '-' : item.action_by}</td>
                            <td>{isEmpty(item.vehicle_type) ? '-' : item.vehicle_type}</td>
                            <td>
                              {isEmpty(item.vehicle_make)
                                ? '-'
                                : `${item.vehicle_make}/${item.vehicle_model}/${item.vehicle_year}`}
                            </td>
                            <td>{isEmpty(item.vehicle_number) ? '-' : item.vehicle_number}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <CustomPaginate
          data={vehicleReport}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isListLoading}
          saveFilterName={'feedbackReport'}
          filterStates={''}
        />
      </div>
    </>
  )
}

export default VehicleBreakdownReport
