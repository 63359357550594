export const getLoginKey = localStorage.getItem('eafruitsLogin')
export const getEmail = localStorage.getItem('email')
export const getUserId = localStorage.getItem('user_id')
export const getW_Id: any = localStorage.getItem('w_id')
export const getRoleId = localStorage.getItem('roleId')
export const getToken = localStorage.getItem('token')
export const getUserType = localStorage.getItem('user_type')
export const getroleId = localStorage.getItem('roleId')
export const getRoleName = localStorage.getItem('roleName')
export const getTimeZone = localStorage.getItem('timeZone')
export const userLoginDetails = localStorage.getItem('userDetails')
export const cId = localStorage.getItem('cId')
