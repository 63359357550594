/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core';
import { getUserType } from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage';
import ComparisonReport from './comparisonReport';
import ComparisonReportWM from './comparisonReportWM';

type Props = {
    className: string
}
const ComparisonWrapper: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: 'Reports',
        subTitle: 'Credit Issue Report',
    }

    return (
        <>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Credit Issue Report' })}</PageTitle>
            {getUserType === "SA" && <ComparisonReport className='credit-issue-sa' />}
            {getUserType === "WM" && <ComparisonReportWM className='credit-issue' />}
        </>
    )
}

export { ComparisonWrapper }