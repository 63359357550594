/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react"
import { useIntl } from "react-intl"
import { PageTitle } from "../../../_eaFruitsDms/layout/core"
import { getUserType } from "../../../_eaFruitsDms/localStorage/getItem/getLocalStorage"
import VehicleManagement from "./vehicleManagement"
import WMVehicleManagement from "./WMvehicleManagement"

const superAdmin = localStorage.getItem('superAdmin');
const w_manager = localStorage.getItem('w_manager');
const OO = localStorage.getItem('OO');

const VehicleManagementWrapper: FC = () => {
    const intl = useIntl()
    const headerTitle = {
        title: "ProductSku"
    }
    return (
        <>
            <PageTitle breadcrumbs={[headerTitle]} >{intl.formatMessage({ id: 'ProductSku' })}</PageTitle>
            {getUserType === "WM" && <WMVehicleManagement className='wm-admin-VehicleManagement' />}
            {getUserType === "LO" && <VehicleManagement className='lo-admin-VehicleManagement' />}
        </>
    )
}

export { VehicleManagementWrapper }

