import { Chart, registerables } from "chart.js"
import ReactDOM from "react-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { EafruitsI18nProvider } from "./_eaFruitsDms/i18n/Eafruits18n"
import { AppRoutes } from "./app/routing/appRoutes"
import tenantConfiguration from "./TenantVariables"

require(tenantConfiguration.cssBaseUrl + 'style.scss')
require(tenantConfiguration.cssBaseUrl + 'style.react.scss')

Chart.register(...registerables)
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <EafruitsI18nProvider>
      <AppRoutes />
    </EafruitsI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
