import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { DrawerComponent, ScrollComponent, ToggleComponent } from "../../../assets/ts/components";
import { getUserType } from "../../../localStorage/getItem/getLocalStorage";
import { AsideMenuMain } from "./AsideMenuMain";
import { LOAsideMenuMain } from "./LOAsideMenuMain";
import { OOAsideMenuMain } from "./OOAsideMenuMain";
import { TSAsideMenuMain } from "./TSAsideMenuMain";
import { WMAsideMenuMain } from "./WMAsideMenuMain";
import { AMAsideMenuMain } from "./AMAsideMenuMain";
// import { OO, superAdmin, w_manager } from '../../../localstorage/setitemStore'
const superAdmin = localStorage.getItem('superAdmin');
const w_manager = localStorage.getItem('w_manager');
const OO = localStorage.getItem('OO');

type Props = {
    asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
    const scrollRef = useRef<HTMLDivElement | null>(null)
    const { pathname } = useLocation()
    const navigate: any = useNavigate()

    useEffect(() => {
        window.addEventListener('storage', () => {
            window.addEventListener("click", function () {
                if (localStorage.getItem("eafruitsLogin") === null) {
                    window.location.reload();
                }
            });
        })
        setTimeout(() => {
            DrawerComponent.reinitialization()
            ToggleComponent.reinitialization()
            ScrollComponent.reinitialization()
            // if (scrollRef.current) {
            //   scrollRef.current.scrollTop = 0
            // }
        }, 50)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])


    const handleOnchange = (e: any) => {
        let usertype = e.target.value
        localStorage.setItem("selectedName", e.target.value)
        if (usertype === "superAdmin") {
            navigate('/')
            localStorage.setItem("superAdmin", "superAdmin")
            localStorage.setItem("w_manager", "")
            localStorage.setItem("OO", "")
            window.location.reload()
        }
        if (usertype === "WM") {
            navigate('/')
            localStorage.setItem("w_manager", "w_manager")
            localStorage.setItem("superAdmin", "")
            localStorage.setItem("OO", "")
            window.location.reload()
        }
        if (usertype === "OO") {
            navigate('/')
            localStorage.setItem("OO", "OO")
            localStorage.setItem("superAdmin", "")
            localStorage.setItem("w_manager", "")
            window.location.reload()
        }
    }

    const s_name = localStorage.getItem('selectedName')

    return (
        <>
            <div
                id='kt_aside_menu_wrapper'
                ref={scrollRef}
                className='hover-scroll-overlay-y my-5 my-lg-5'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-height='auto'
                data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
                data-kt-scroll-wrappers='#kt_aside_menu'
                data-kt-scroll-offset='0'
            >
                {/* <div className="px-4 pt-3">
          <select onChange={handleOnchange} className='form-select'>
            <option hidden >{s_name === "" ? "Select User Type" : s_name}</option>
            <option value="superAdmin">Super Admin</option>
            <option value="wm">Warehouse Manager</option>
            <option value="OO">OO</option>
          </select>
        </div> */}
                <div
                    id='#kt_aside_menu'
                    data-kt-menu='true'
                    className={clsx(
                        'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
                        asideMenuCSSClasses.join(' ')
                    )}
                >
                    {/* {superAdmin === "superAdmin" && <AsideMenuMain />}
          {w_manager === "w_manager" && <WMAsideMenuMain />}
          {OO === "OO" && <OOAsideMenuMain />} */}
                    {/* {getUserType === "SA" && <AsideMenuMain />} */}
                    {getUserType === "SA" && <AsideMenuMain />}
                    {getUserType === "WM" && <WMAsideMenuMain />}
                    {getUserType === "OO" && <OOAsideMenuMain />}
                    {getUserType === "LO" && <LOAsideMenuMain />}
                    {getUserType === "TS" && <TSAsideMenuMain />}
                    {getUserType === "AM" && <AMAsideMenuMain />}

                </div>
            </div>
            {/* <AsideMenuMain /> */}

        </>
    )
}

export { AsideMenu }