import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  calculateDays,
  checkUserType,
  contactNumber,
  dateFormat,
  dateTimeFormat,
  fetchAsyncDropdown,
  formatTags,
  formDataConverter,
  isAdmin,
  isEmpty,
  isModalValidation,
  isWarehouseManager,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import tenantConfiguration from '../../../../TenantVariables'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import IssueStatus from './issueStatus'
import EaButton from '../../../../_eaFruitsDms/layout/components/button/eaButton'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import {getRequest, postRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {AsyncPaginate} from 'react-select-async-paginate'
import Imagecropper from '../../brands/components/imageCroper'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import EaModal from '../../../../_eaFruitsDms/layout/components/modal/eaModal'
import $ from 'jquery'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FormatMessage from '../../../../_eaFruitsDms/helpers/components/FormateMessage'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

type Props = {
  className?: string
}
const CreditIssuesDetail: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const location = useLocation()
  const {state}: any = location
  const WMHeaderTitle = {
    title: 'Credit Issues',
    subTitle:
      isEmpty(state) || isEmpty(state.credit_issue.order_no)
        ? '-'
        : `#${state.credit_issue.order_no}`,
  }
  const navigate = useNavigate()
  const warehouseId = localStorage.getItem('w_id')
  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')

  const [creditIssue, setCreditIssue] = useState<any>(null)
  const [isModal, setIsModal] = useState(false)
  const [apiDate, setApiDate] = useState<any>({
    startDate: dateFormat(new Date(), 'YYYY-MM-DD hh:mm'),
  })
  const [displayDate, setDisplayDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD hh:mm'),
  })
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [paymentRefImg, setPaymentRefImg] = useState('')
  const [cropData, setCropData] = useState(defaultSrc)
  const [offsetBank, setOffsetBank] = useState(0)
  const [listBank, setListBank] = useState([])
  const [dropDownSearchBank, setDropDownSearchBank] = useState('')
  const [srUser, setSrUser] = useState<any>('')
  const [payment, setPayment] = useState<any>('')
  const [banks, setBanks] = useState<any>('')
  const [creditIssueId, setCreditIssueId] = useState<any>('')
  const [dueAmount, setDueAmount] = useState('')
  const [amountError, setAmountError] = useState('')
  const [isModalLoading, setIsModalLoading] = useState('Submit')
  const [isFetching, setIsFetching] = useState(true)

  const initialState = {
    amount: dueAmount,
    payment_date_time: dateFormat(new Date(), 'YYYY-MM-DD hh:mm').displayDate,
    received_by: '',
    payment_method_type: '',
    ref_no: '',
    cheque_no: '',
    bank: '',
    bank_name: '',
    payment_ref_img: '',
    description: '',
  }

  const [received, setReceived] = useState(initialState)
  const [validation, setValidation] = useState(initialState)
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)

  useEffect(() => {
    if (isEmpty(state) || isEmpty(state.credit_issue)) {
      navigate('/credit-issues')
    }

    if (!isEmpty(state) && !isEmpty(state.creditIssueId)) {
      state.creditIssueId && setCreditIssueId(state.creditIssueId)
    } else {
      navigate('/credit-issues')
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(creditIssueId)) {
      getCreditIssueDetails()
    }
  }, [creditIssueId])

  const getCreditIssueDetails = async () => {
    setIsFetching(true)
    const response = await getRequest(`${apiBaseURL()}credit/issues/${creditIssueId}`, true)
    setCreditIssue(response.result)
    setDueAmount(response.result.due_amount)

    setTimeout(() => {
      let creditReceived = {...initialState}
      creditReceived['amount'] = response.result.due_amount
      setReceived(creditReceived)
    }, 500)
    setIsFetching(false)
  }

  const goToOrderDetailPage = (orderId: number) => {
    localStorage.setItem('oId', orderId.toString())
    localStorage.setItem('redirection_back', location.pathname)
    navigate('/credit-issue/order-detail')
  }

  const backURL = () => {
    let URL = '/credit-issues'

    if (isAdmin() && !isEmpty(state)) {
      if (!state.reports && location.pathname === '/credit-issue/credit-issues-detail') {
        URL = '/customers/singlecustomer/credit-issue'
      } else if (
        state.reports &&
        location.pathname === '/reports/credit-issues/credit-issues-detail'
      ) {
        URL = '/reports/credit-issues'
      } else {
        URL = '/warehouse/DC/singlewarehousename/credit-issues'
      }
    } else {
      if (isWarehouseManager()) {
        if (location.pathname === '/credit-issues/credit-issues-detail') {
          URL = '/credit-issues'
        } else if (location.pathname === '/credit-issues/detail') {
          URL = '/payment-issues/payment-issue-detail/transactions'
        } else {
          URL = '/customers/singlecustomer/credit-issue'
        }
      } else {
        if (location.pathname === '/credit-issues/detail') {
          URL = '/payment-issues/payment-issue-detail/transactions'
        }
      }
    }

    if (!isEmpty(state) && !isEmpty(state.creditCollection)) {
      // navigate(-1)
      // return
      URL = '/reports/credit-collection-report'
    }

    return URL
  }

  const handleReceived = () => {
    handleModal()
  }

  const handleModal = () => {
    setIsModal(!isModal)
  }

  const clear = () => {
    setValidation(initialState)
    setReceived(initialState)
    setAmountError('')
    setDisplayDate({startDate: moment().format('YYYY-MM-DD hh:mm')})
    setCropData(defaultSrc)
    setBanks('')
    setPayment('')
    setSrUser('')
  }

  const onMarkSubmit = async () => {
    let validationHandler: any = {...received}

    delete validationHandler['description']

    if (received.payment_method_type === 'cheque') {
      delete validationHandler['bank']
      delete validationHandler['ref_no']
    }

    if (received.payment_method_type === 'bank_transfer') {
      delete validationHandler['cheque_no']
      delete validationHandler['bank_name']
    }

    if (received.payment_method_type === 'cash') {
      delete validationHandler['payment_ref_img']
      delete validationHandler['cheque_no']
      delete validationHandler['bank']
      delete validationHandler['bank_name']
      delete validationHandler['ref_no']
    }

    const validation = isModalValidation(validationHandler)

    let modalValidationHandler: any = {...validation}

    for (const key in validationHandler) {
      if (validationHandler[key] === '') {
        modalValidationHandler[key] = 'is-invalid'
      }
    }

    setValidation(modalValidationHandler)

    if (validation.length === 0 && amountError === '') {
      setIsModalLoading('Please Wait')
      const url = apiBaseURL() + `credit/issues/${creditIssueId}/mark-as-received`
      const response = await postRequest(url, formDataConverter(received, []), true)

      if (response) {
        setValidation(initialState)
        clear()
        handleModal()
        getCreditIssueDetails()
        setIsModalLoading('Submit')
        $('#ea_credit_Issue_close').trigger('click')
        setIsAlertSuccess(true)

        setTimeout(() => {
          setIsAlertSuccess(false)
        }, 2000)
      }
    }
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d, 'YYYY-MM-DD hh:mm').displayDate,
            }
            console.log('callBakeDate == ', callBakeDate)

            onCallback(callBakeDate)
            setDisplayDate({startDate: start._d})
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY hh:mm'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            minDate: moment(creditIssue.action_at).format('DD MMM YYYY hh:mm'),
            maxDate: moment().format('DD MMM YYYY hh:mm'),
            timePicker: true,
            showDropdowns: true,
            singleDatePicker: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            parenEl: '.modal',
            locale: {
              format: 'DD MMM YYYY hh:mm',
            },
          }}
        >
          <input
            type='text'
            className={`form-control calendar ${validation.payment_date_time}`}
            defaultValue={value}
            name='payment_date_time'
          />
        </DateRangePicker>
      </>
    )
  }

  const fetchSalesReps = async (search: any) => {
    if (search != '') {
      setDropDownSearch(search)
      setOffset(0)
    }

    let limit = tenantConfiguration.limit // default limit

    let options: any = []

    let warehouseFilter = '&warehouse_id=' + warehouseId

    let concatUrl =
      `${apiBaseURL()}auth/users/team/list?type=sr&ordering=first_name&search=${search}&limit=${limit}&offset=${offset}` +
      warehouseFilter

    const response = await getRequest(concatUrl, true)

    let hasMore: boolean = false

    if (response.results) {
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setOffset(newOffset)
        hasMore = true
      }

      if (list.length === 0) {
        options.push({
          label: 'All Sales Reps', // these
          value: '',
        })
      }

      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.first_name + ' ' + option.last_name,
            value: option.id,
          })
        })
      }

      if (list.length > 0) {
        setList(list.concat(options))
      } else {
        setList(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const paymentMode = async (search: any) => {
    let option = [
      {label: 'All Payment Mode', value: ''},
      {label: 'Cash', value: 'cash'},
      {label: 'Cheque', value: 'cheque'},
      {label: 'Bank Transfer', value: 'bank_transfer'},
    ]

    return {
      options: option,
      hasMore: false,
    }
  }

  const fetchBanks = async (search: any) => {
    return fetchAsyncDropdown(
      offsetBank,
      setOffsetBank,
      listBank,
      setListBank,
      `master/payment-bank?&ordering=bank_name`,
      search,
      setDropDownSearchBank,
      'bank_name',
      true,
      'All Banks'
    )
  }

  const getImage = (val: any) => {
    setPaymentRefImg(val)
  }

  const onImageChange = (e: any) => {
    if (e.target.files[0]) {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        onChangeHandler(e, 'payment_ref_img')
        onValidationHandler(e, 'payment_ref_img')
      }
      e.target.value = ''
    }
  }

  const onChangeHandler = (e: any, name?: any) => {
    const inputName: string = e.target ? e.target.name : name

    let masterData: any = {...received}

    if (e.target && name !== 'payment_ref_img') {
      masterData[inputName] = e.target.value
    }

    if (e.value) {
      masterData[inputName] = e.value
    }

    if (name === 'payment_date_time') {
      masterData[inputName] = e
    }

    if (name === 'payment_ref_img') {
      masterData['payment_ref_img'] = e.target.files[0]
    }

    if (name === 'payment_method_type') {
      if (e.value === 'cheque') {
        masterData['bank'] = ''
        masterData['ref_no'] = ''
        setBanks('')
      }

      if (e.value === 'bank_transfer') {
        masterData['cheque_no'] = ''
        masterData['bank_name'] = ''
      }

      if (e.value === 'cash') {
        masterData['payment_ref_img'] = ''
        masterData['cheque_no'] = ''
        masterData['bank'] = ''
        masterData['bank_name'] = ''
        masterData['ref_no'] = ''
        setBanks('')
      }
    }

    setReceived(masterData)
  }

  const onValidationHandler = (e: any, name?: any, isDropDown?: any) => {
    let validationHandler: any = {...validation}
    validationHandler[name] = ''

    if (e.target && e.target.value === '') {
      validationHandler[name] = 'is-invalid'
    }

    if (isDropDown) {
      if (e.value == '') {
        validationHandler[name] = 'is-invalid'
      }
    }

    if (name === 'payment_ref_img' && e === '') {
      validationHandler[name] = 'is-invalid'
    }

    if (name === 'payment_date_time' && e === '') {
      validationHandler[name] = 'is-invalid'
    }

    setValidation(validationHandler)
  }

  return (
    <>
      <PageTitle backNavigate={backURL()} breadcrumbs={[WMHeaderTitle]}>
        {intl.formatMessage({id: creditIssue ? `#${creditIssue.order_no}` : '-'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => setIsAlertSuccess(false)}
        show={IsAlertSuccess}
        message={FormatMessage('Mark as received done successfully.')}
      />

      {isFetching ? (
        <FetchingRecords />
      ) : (
        <>
          <div className='card mb-4 mb-xl-5'>
            <div className='card-body pt-5 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap'>
                    <div className='d-flex flex-column mb-2 mb-sm-0'>
                      <div className='mb-1'>
                        <span className='text-dark font-20 fw-bold me-1'>
                          Credit Issue{' '}
                          {creditIssue && !isEmpty(creditIssue.order_no)
                            ? `#${creditIssue.order_no}`
                            : '-'}
                        </span>
                      </div>
                      <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                        <span className='d-flex align-items-center me-6 mb-2'>
                          <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                          {creditIssue && !isEmpty(creditIssue.created_at_datetime)
                            ? dateTimeFormat(creditIssue.created_at_datetime)
                            : '-'}
                        </span>
                        <span className='d-flex align-items-center me-6 mb-2'>
                          <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='me-1' />
                          {creditIssue && creditIssue.issue_status !== 'completed' && (
                            <>
                              Due Amount{' '}
                              {creditIssue && !isEmpty(creditIssue.due_amount)
                                ? numberFormatWithCode(creditIssue.due_amount).displayFormat
                                : 0}{' '}
                              | Due Date{' '}
                              {creditIssue && !isEmpty(creditIssue.approved_due_date)
                                ? dateFormat(creditIssue.approved_due_date).displayDate
                                : '-'}
                            </>
                          )}
                          {creditIssue && creditIssue.issue_status !== 'completed' ? ' |' : ''}
                          {' Order Amount '}
                          {creditIssue && !isEmpty(creditIssue.total_amount)
                            ? numberFormatWithCode(creditIssue.total_amount).displayFormat
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {checkUserType('am') &&
                    creditIssue &&
                    creditIssue.issue_status &&
                    creditIssue.issue_status !== 'completed' && (
                      <EaButton
                        isModal={true}
                        dataTarget='#ea_credit_issue_modal'
                        btnName={'Mark as Received'}
                        onClick={() => {
                          handleReceived()
                        }}
                        className='btn btn-primary btn-sm font-13 me-3'
                      />
                    )}

                  {creditIssue && (
                    <>
                      <IssueStatus
                        status={creditIssue.issue_status}
                        dueDate={creditIssue.approved_due_date}
                      />
                      <button
                        className='btn btn-primary ms-3'
                        onClick={() => goToOrderDetailPage(creditIssue.order_id)}
                      >
                        <i className='fa fa-info-circle' aria-hidden='true'></i>Order Detail
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-5'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <div className='card-title my-0'>
                <span className='fw-bold font-17 mb-1'>Credit Issue Details</span>
              </div>
            </div>
            <div className='card-body pt-2 mt-1 pb-5'>
              <div className='row'>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Requested Amount</label>
                  <div className='font-17 fw-bold text-dark'>
                    {creditIssue && !isEmpty(creditIssue.requested_amount)
                      ? numberFormatWithCode(creditIssue.requested_amount).displayFormat
                      : 0}
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Requested Due Date</label>
                  <div className='font-17 fw-bold text-dark'>
                    {creditIssue && !isEmpty(creditIssue.requested_due_date)
                      ? dateFormat(creditIssue.requested_due_date).displayDate
                      : '-'}
                    &nbsp;
                    <span className='font-12 fw-500'>
                      (
                      {creditIssue &&
                        calculateDays(
                          dateFormat(creditIssue.order_date).apiDate,
                          creditIssue.requested_due_date
                        )}
                      )
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved Amount</label>
                  <div className='font-17 fw-bold text-dark'>
                    {creditIssue && !isEmpty(creditIssue.approved_amount)
                      ? numberFormatWithCode(creditIssue.approved_amount).displayFormat
                      : 0}
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved Due Date</label>
                  <div className='font-17 fw-bold text-dark'>
                    {creditIssue && !isEmpty(creditIssue.approved_due_date)
                      ? dateFormat(creditIssue.approved_due_date).displayDate
                      : '-'}
                    &nbsp;
                    <span className='font-12 fw-500'>
                      (
                      {creditIssue &&
                        calculateDays(
                          dateFormat(creditIssue.order_date).apiDate,
                          creditIssue.approved_due_date
                        )}
                      )
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Requested By</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {creditIssue && !isEmpty(creditIssue.sr_name)
                        ? `${creditIssue.sr_name} (SR)`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>SR Contact No.</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {creditIssue && !isEmpty(creditIssue.sr_phone_no)
                        ? contactNumber(creditIssue.sr_phone_no)
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved By</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {creditIssue && !isEmpty(creditIssue.action_by)
                        ? `${
                            creditIssue.action_by
                          } (${creditIssue.action_by_user_type.toUpperCase()})`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Contact No.</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {creditIssue && !isEmpty(creditIssue.action_by_user_phone_no)
                        ? contactNumber(creditIssue.action_by_user_phone_no)
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Reason</label>
                  <div className='font-14 text-dark'>
                    {creditIssue && !isEmpty(creditIssue.description)
                      ? creditIssue.description
                      : '-'}
                  </div>
                </div>
                <div className='col-sm-6 col-xl-6 mb-6'>
                  <label className='font-14 text-muted mb-2'>Reason</label>
                  <div className='font-14 text-dark'>
                    {creditIssue &&
                      (!isEmpty(creditIssue.partial_approval_reason)
                        ? creditIssue.partial_approval_reason
                        : !isEmpty(creditIssue.partial_approval_reason_note)
                        ? creditIssue.partial_approval_reason_note
                        : '-')}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Request Date & Time</label>
                  <div className='font-14 text-dark'>
                    {creditIssue && !isEmpty(creditIssue.created_at_datetime)
                      ? dateTimeFormat(creditIssue.created_at_datetime)
                      : '-'}
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Approved Date & Time</label>
                  <div className='font-14 text-dark'>
                    {creditIssue && !isEmpty(creditIssue.action_at)
                      ? dateTimeFormat(creditIssue.action_at)
                      : '-'}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Order No</label>
                  <div>
                    <span className='font-14'>
                      {creditIssue && !isEmpty(creditIssue.order_no)
                        ? `#${creditIssue.order_no}`
                        : '-'}
                    </span>
                  </div>
                </div>
                {/* <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Order Amount</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {creditIssue && !isEmpty(creditIssue.total_amount)
                        ? numberFormatWithCode(creditIssue.total_amount).displayFormat
                        : 0}
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {creditIssue && creditIssue.received_payments.length > 0 && (
            <div className='card mb-5 mb-xl-5'>
              <div className='card-header border-0 py-2 px-7 align-items-center'>
                <div className='card-title my-0'>
                  <span className='fw-bold font-17 mb-1'>
                    Payment Received against this Credit Issue
                  </span>
                </div>
              </div>
              <div className='card-body pt-2 mt-1 pb-5'>
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-120px'>Date</th>
                        <th className='min-w-160px'>Amount Paid</th>
                        <th className='min-w-150px'>Outstanding Amt.</th>
                        <th className='min-w-150px'>Payment Details</th>
                        <th className='min-w-120px'>Received By</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {creditIssue.received_payments.map((payment: any) => {
                        let paymentThrough = 'Cash'
                        let bankName = ''
                        let refNo = ''

                        switch (payment.payment_method_type) {
                          case 'bank_transfer':
                            paymentThrough = 'Bank Transfer'
                            bankName = payment.bank
                            refNo = payment.ref_no
                            break

                          case 'cheque':
                            paymentThrough = 'Cheque'
                            bankName = payment.bank_name
                            refNo = payment.cheque_no
                            break

                          case 'mobile_wallet':
                            paymentThrough = 'Mobile Wallet'
                            break
                        }

                        return (
                          <tr>
                            <td>{dateFormat(payment.payment_date).displayDate}</td>
                            <td>
                              {payment.amount > 0
                                ? numberFormatWithCode(payment.amount).displayFormat
                                : 0}
                            </td>
                            <td>
                              {payment.amount > 0
                                ? numberFormatWithCode(payment.outstanding_amount).displayFormat
                                : 0}
                            </td>
                            <td>
                              {paymentThrough} {paymentThrough !== 'Cash' && <br />}
                              {!isEmpty(bankName) && bankName}
                              <br />
                              {!isEmpty(refNo) && refNo}
                            </td>
                            <td>
                              {
                                isEmpty(payment.received_by) ? '-' : `${payment.received_by} (SR)` //TODO: As per Sanjay told me received by ONLY SR that's why static SR put on 09-June-2023
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
          )}

          <div className='card mb-5 mb-xl-5'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <div className='card-title my-0'>
                <span className='fw-bold font-17 mb-1'>Customer Details</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card-body pt-2 mt-1 pb-5'>
                  <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 mt-4'>Customer Details</h3>
                  <div className='row'>
                    <div className='col-sm-6 col-md-6 mb-6'>
                      <label className='font-14 text-muted mb-2'>Customer ID</label>
                      <div className='font-14 text-dark'>
                        {creditIssue.customer ? `#${creditIssue.customer.id}` : '-'}
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-6 mb-6'>
                      <label className='font-14 text-muted mb-2'>Mobile Number</label>
                      <div className='font-14 text-dark'>
                        {isEmpty(creditIssue)
                          ? '-'
                          : isEmpty(creditIssue.customer)
                          ? '-'
                          : isEmpty(creditIssue.customer.contact_no)
                          ? '-'
                          : `${tenantConfiguration.baseCountryCode} ${creditIssue.customer.contact_no}`}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 mb-6'>
                      <label className='font-14 text-muted mb-2'>Customer Name</label>
                      <div className='font-14 text-dark'>
                        {isEmpty(creditIssue)
                          ? '-'
                          : isEmpty(creditIssue.customer)
                          ? '-'
                          : isEmpty(creditIssue.customer.business_name)
                          ? '-'
                          : `${creditIssue.customer.business_name}`}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 mb-6'>
                      <label className='font-14 text-muted mb-2'>Address</label>
                      <div className='font-14 text-dark'>
                        {isEmpty(creditIssue)
                          ? '-'
                          : isEmpty(creditIssue.customer)
                          ? '-'
                          : isEmpty(creditIssue.customer.address)
                          ? '-'
                          : `${creditIssue.customer.address}`}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 mb-6'>
                      <label className='font-14 text-muted mb-2'>
                        Customer Category/Sub-category
                      </label>
                      <div className='font-14 text-dark text-capitalize'>
                        {isEmpty(creditIssue)
                          ? '-'
                          : isEmpty(creditIssue.customer)
                          ? '-'
                          : isEmpty(creditIssue.customer.nature_of_business)
                          ? '-'
                          : `${creditIssue.customer.nature_of_business}`}
                        /
                        {isEmpty(creditIssue)
                          ? '-'
                          : isEmpty(creditIssue.customer)
                          ? '-'
                          : isEmpty(creditIssue.customer.category)
                          ? '-'
                          : `${creditIssue.customer.category}`}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 mb-0'>
                      <label className='font-14 text-muted mb-2'>Customer Tags</label>
                      <div className='font-14 text-dark'>
                        {creditIssue &&
                        creditIssue.customer &&
                        creditIssue.customer.tags.length > 0 ? (
                          <div className='d-flex align-items-center'>
                            {creditIssue.customer.tags.length === 1 ? (
                              <span className='ms-2 btn btn-sm bg-light-primary py-1 cursor-default'>
                                {creditIssue.customer.tags[0].tag_name}
                              </span>
                            ) : (
                              <OverlayTrigger
                                delay={{hide: 450, show: 300}}
                                overlay={(props) => (
                                  <Tooltip {...props} className='tooltip-dark'>
                                    <span>{formatTags(creditIssue.customer.tags)}</span>
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <span className='ms-2 btn btn-sm bg-light-primary py-1 cursor-default'>
                                  {creditIssue.customer.tags[0].tag_name}{' '}
                                  {creditIssue.customer.tags.length > 1 &&
                                    `& ${creditIssue.customer.tags.length - 1} More`}
                                </span>
                              </OverlayTrigger>
                            )}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <EaModal
        cancel={() => {
          handleModal()
          clear()
        }}
        actionBtnName={isModalLoading}
        btnDisabled={isModalLoading === 'Submit' ? false : true}
        closeAuto={'ea_credit_Issue_close'}
        onSubmit={onMarkSubmit}
        modalTitle='Mark As Received'
        id='ea_credit_issue_modal'
      >
        <div className='row'>
          <div className='col-sm-12'>
            <div className='mb-4'>
              <label className='form-label'>Due Amount</label>
              <div className='input-group'>
                <input
                  type='text'
                  className={'form-control'}
                  placeholder='Type here...'
                  disabled={true}
                  value={
                    creditIssue && !isEmpty(creditIssue.due_amount) ? creditIssue.due_amount : 0
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='mb-4'>
              <label className='form-label'>Receiving Amount</label>
              <div className={`input-group ${validation.amount}`}>
                <span className='input-group-text bg-white border-end-0'>
                  {tenantConfiguration.currencyCode}
                </span>
                <input
                  type='text'
                  className={`form-control ${validation.amount}`}
                  placeholder='Type here...'
                  name='amount'
                  value={received.amount}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/
                    const inputValue = e.target.value

                    if (inputValue === '' || regex.test(inputValue)) {
                      onChangeHandler(e)
                      onValidationHandler(e, 'amount')
                      setAmountError('')
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value > dueAmount) {
                      setAmountError(
                        'Your added amount must be greater than Due amount! Please try again.'
                      )
                    }
                  }}
                />
              </div>
              <ErrorHandler errorMessage={amountError} />
            </div>
          </div>
        </div>

        <div className='col-sm-12'>
          <div className='mb-4'>
            <label className='form-label'>Receiving Date & Time</label>
            {creditIssue && (
              <CustomDatePickerComponent
                onCallback={(callBakeDate: any) => {
                  console.log('callBakeDate === ', callBakeDate)
                  setApiDate({startDate: callBakeDate.startDate})
                  onChangeHandler(callBakeDate.startDate, 'payment_date_time')
                  //onChangeHandler('2023-07-17T12:21:15.679Z', 'payment_date_time')
                  onValidationHandler('1', 'payment_date_time')
                }}
                value={displayDate}
                startDate={displayDate.startDate}
              />
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='mb-4'>
              <label className='form-label'>Receiving Sales Reps</label>
              <AsyncPaginate
                loadOptions={fetchSalesReps}
                isSearchable
                placeholder='All Sales Reps'
                className={`react-select-container ${validation.received_by} `}
                classNamePrefix='react-select'
                name='received_by'
                onChange={(e) => {
                  setSrUser(e)
                  onChangeHandler(e, 'received_by')
                  onValidationHandler(e, 'received_by', 'dropdown')
                }}
                value={srUser}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='mb-4'>
              <label className='form-label'>Payment Mode</label>
              <AsyncPaginate
                loadOptions={paymentMode}
                isSearchable
                placeholder='All Payment Mode'
                className={`react-select-container ${validation.payment_method_type}`}
                classNamePrefix='react-select'
                name='payment_method_type'
                onChange={(e) => {
                  setPayment(e)
                  onChangeHandler(e, 'payment_method_type')
                  onValidationHandler(e, 'payment_method_type', 'dropdown')
                }}
                value={payment}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>

        {payment && payment.value === 'cheque' && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='mb-4'>
                <label className='form-label'>Bank Name</label>
                <input
                  type='text'
                  className={`form-control ${validation.bank_name} `}
                  placeholder='Type here...'
                  name='bank_name'
                  value={received.bank_name}
                  onChange={(e) => {
                    onChangeHandler(e)
                    onValidationHandler(e, 'bank_name')
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {payment && payment.value === 'cheque' && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='mb-4'>
                <label className='form-label'>Cheque No</label>
                <input
                  type='text'
                  className={`form-control ${validation.cheque_no}`}
                  placeholder='Type here...'
                  value={received.cheque_no}
                  name='cheque_no'
                  onChange={(e) => {
                    onChangeHandler(e)
                    onValidationHandler(e, 'cheque_no')
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {payment && payment.value === 'bank_transfer' && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='mb-4'>
                <label className='form-label'>Banks</label>
                <AsyncPaginate
                  loadOptions={fetchBanks}
                  isSearchable
                  placeholder='All Banks'
                  className={`react-select-container ${validation.bank} `}
                  classNamePrefix='react-select'
                  name='bank'
                  onChange={(e) => {
                    setBanks(e)
                    onChangeHandler(e, 'bank')
                    onValidationHandler(e, 'bank', 'dropdown')
                  }}
                  value={banks}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        )}

        {payment && payment.value === 'bank_transfer' && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='mb-4'>
                <label className='form-label'>Ref No</label>
                <input
                  type='text'
                  className={`form-control ${validation.ref_no}`}
                  placeholder='Type here...'
                  name='ref_no'
                  value={received.ref_no}
                  onChange={(e) => {
                    onChangeHandler(e)
                    onValidationHandler(e, 'ref_no')
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {payment && (payment.value === 'cheque' || payment.value === 'bank_transfer') && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='mb-4'>
                <label className='form-label'>Payment Reference</label>
                <Imagecropper
                  title='Profile Image'
                  getImage={getImage}
                  onImageChange={onImageChange}
                  imageRequire={
                    validation.payment_ref_img === 'is-invalid' ? 'file-icon-inValid' : ''
                  }
                  imageSize={''}
                  text={' '}
                  cropData={cropData}
                  setCrop={setCropData}
                />
              </div>
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col-sm-12'>
            <div className='mb-4'>
              <label className='form-label'>Note</label>
              <textarea
                id='reason_note'
                className={`form-control`}
                value={received.description}
                rows={4}
                placeholder='Type here…'
                name='description'
                onChange={(e) => {
                  onChangeHandler(e)
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </EaModal>
    </>
  )
}

export default CreditIssuesDetail
