import clsx from "clsx";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiBaseURL, toAbsoluteUrl } from "../../../../_eaFruitsDms/helpers";
import { dateFormat, GLOBAL_DATE_FORMAT, staticSearch } from "../../../../_eaFruitsDms/helpers/CommonHelpers";
import FormatMessage from "../../../../_eaFruitsDms/helpers/components/FormateMessage";
import DatePickerCustom from "../../../../_eaFruitsDms/helpers/datePicker";
import { getRequest, patchRequest, postRequest } from "../../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../../_eaFruitsDms/layout/components/alert/eaAlert";
import PlaceSearch from "../../../../_eaFruitsDms/layout/components/placeSearch/PlaceSearch";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import { getUserId } from "../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import { validateEmail } from "../../../../_eaFruitsDms/validations/eaValidation";
import AddBusinessDocuments from "../components/AddBusinessDocuments";
import BusinessDocuments from "../components/BusinessDocuments";
import Documents from "../components/Documents";
import CustomerLogo from "../WMcustomers/components/uploadCustomerLogo";

const TSAddCustomer = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const customerId = localStorage.getItem('cId')

  const adminHeaderTitle = [
    {
      title: 'Customers',
      path: '/customers',
    },
  ]
  const business: any = [
    {
      label: 'Formal',
      value: 'Formal',
    },
    {
      label: 'Informal',
      value: 'Informal',
    },
    {
      label: 'Corporate',
      value: 'Corporate',
    },
  ]

  const businessSize: any = [
    {
      label: 'Small',
      value: 'Small',
    },
    {
      label: 'Medium',
      value: 'Medium',
    },
    {
      label: 'Large',
      value: 'Large',
    },
    {
      label: 'SmallInformal',
      value: 'SmallInformal',
    },
    {
      label: 'Medium-LargeInformal',
      value: 'Medium-LargeInformal',
    },
  ]

  const premises: any = [
    {
      label: 'Stationary',
      value: 'Stationary',
    },
    {
      label: 'Movable',
      value: 'Movable',
    },
  ]
  const familySize: any = [
    {
      label: 'Single On Children',
      value: 'SingleOnChildren',
    },
    {
      label: 'Single With Children',
      value: 'SingleWithChildren',
    },
    {
      label: 'Stable Relation or Marriage With Children',
      value: 'Stable_Relation_or_Marriage_With_Children',
    },
    {
      label: 'Stable Relation or Marriage With no Children',
      value: 'Stable_Relation_or_Marriage_With_no_Children',
    },
  ]

  const customerTypes: any = [
    {
      label: 'Wholesaler',
      value: 'Wholesaler',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
  ]
  const initialLatLong = {
    latitude: '',
    longitude: '',
  }

  const initialCustomer = {
    id: customerId,
    business_name: '',
    customer_type: '',
    nature_of_business: '',
    customer_category: '',
    year_of_operations: moment().format('YYYY-MM-DD'),
    year_of_operations_view: new Date(),
    dob_of_promoter_view: new Date(),
    size_of_business: '',
    business_license: '',
    business_license_file: '',
    registration_certificate: '',
    registration_certificate_file: '',
    national_id: '',
    national_id_file: '',
    promoter_name: '',
    contact_no: '',
    email: '',
    district: '',
    ward: '',
    street: '',
    pincode: 0,
    dob_of_promoter: '',
    premises: '',
    credit_range: '',
    credit_amount_cap: 0,
    credit_cycle: 0,
    prior_relationship: false,
    personal_family_size: '',
    customer_tag: '',
    note: '',
    document: '',
    address: '',
    latitude: '',
    longitude: '',
    place_id: '',
  }
  const initialCustomerValidation = {
    business_name: '',
    customer_type: '',
    nature_of_business: '',
    customer_category: '',
    year_of_operations: '',
    size_of_business: '',
    business_license: '',
    registration_certificate: '',
    national_id: '',
    business_license_file: '',
    registration_certificate_file: '',
    national_id_file: '',
    promoter_name: '',
    contact_no: '',
    email: '',
    dob_of_promoter: '',
    premises: '',
    credit_range: '',
    credit_amount_cap: '',
    credit_cycle: '',
    personal_family_size: '',
    customer_tag: '',
    document: '',
    district: '',
    ward: '',
    address: '',
    street: '',
    place_id: '',
  }

  const initialDateSetting: any = {
    startDate: moment(),
    alwaysShowCalendars: true,
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    drops: 'auto',
    showDropdowns: true,
    maxDate: moment(),
  }

  const [customer, setCustomer] = useState<any>(initialCustomer)
  const [customerValidation, setCustomerValidation] = useState<any>(initialCustomerValidation)
  const [value, onChange] = useState(new Date())
  const [searchSelect, setSearchSelect] = useState('')
  const [customerTypeOffset, setCustomerTypeOffset] = useState<any>('')
  const [creditRangeOffset, setCreditRangeOffset] = useState<any>('')
  const [tagOffset, setTagOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(10)
  const [customerType, setCustomerType] = useState<any>([])
  const [creditRanger, setCreditRange] = useState<any>([])
  const [customerTag, setCustomerTag] = useState<any>([])
  const [selectedAddress, setSelectedAddress] = useState<string>('')
  const [latitudeLongitude, setLatitudeLongitude] = useState<any>(initialLatLong)
  const [postalCode, setPostalCode] = useState<string>('')
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const [placeId, setPlaceId] = useState<string>('')
  const [customerCategoryOffset, setCustomerCategoryOffset] = useState<any>('')
  const [customerCategory, setCustomerCategory] = useState<any>([])
  const [districtOffset, setDistrictOffset] = useState<any>('')
  const [districts, setDistricts] = useState<any>([])
  const [wardOffset, setWardOffset] = useState<any>('')
  const [wards, setWards] = useState<any>([])
  const [streetOffset, setStreetOffset] = useState<any>('')
  const [street, setStreet] = useState<any>([])
  const [isButtonLoadder, setIsButtonLoader] = useState<any>('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [customerDetail, setCustomerDetail] = useState<any>(initialCustomer)
  const [initialSettings, setInitialSettings] = useState<any>(initialDateSetting)
  const [dateShow, setDateShow] = useState('Select...')
  const [documents, setDocuments] = useState<any>([])
  const [imgSizeInvalid, setimgSizeInvalid] = useState('')
  const [imageValidation, setImageValidation] = useState<boolean>(false)
  const [BrandImage, setBrandImage] = useState('')
  const [img, setImg] = useState<any>('')
  const [imageSize, setImageSize] = useState('')
  const [imageRequire, setImageRequire] = useState('')

  const defaultSrc = toAbsoluteUrl('/media/icons/placeholder_img_crop.jpg')
  const [cropData, setCropData] = useState(defaultSrc)

  useEffect(() => {
    fetchCustomerDetails().then()
  }, [])

  useEffect(() => {
    setCustomerTypeOffset('')
    setTagOffset('')
    setCreditRangeOffset('')
    setDistrictOffset('')
    setCustomerCategoryOffset('')
    setStreetOffset('')
    setWardOffset('')
  }, [searchSelect])

  useEffect(() => {
    setCustomerValidation({ ...customerValidation, ['address']: '' })
    setCustomer({ ...customer, address: selectedAddress })
  }, [selectedAddress])

  useEffect(() => {
    let updateCustomer: any = { ...customer }
    updateCustomer.latitude = latitudeLongitude.latitude
    updateCustomer.longitude = latitudeLongitude.longitude
    setCustomer(updateCustomer)
  }, [latitudeLongitude])

  useEffect(() => {
    let updateCustomer: any = { ...customer }
    updateCustomer.place_id = placeId
    setCustomer(updateCustomer)
  }, [placeId])

  useEffect(() => {
    let updateCustomer: any = { ...customer }
    updateCustomer.pincode = postalCode
    setCustomer(updateCustomer)
  }, [postalCode])

  useEffect(() => {
    let updateCustomer: any = { ...customer }
    updateCustomer.year_of_operations_view = moment(value).toDate()
    updateCustomer.year_of_operations = moment(value).format('YYYY-MM-DD')
    setCustomer(updateCustomer)
  }, [value])

  useEffect(() => {
    setCustomer(customerDetail)
  }, [customerDetail])

  useEffect(() => {
    setCustomerCategoryOffset('')
  }, [customer.nature_of_business])

  useEffect(() => {
    setCustomerTypeOffset('')
  }, [customer.customer_type])

  const fetchCustomerDetails = async () => {
    setCustomer(initialCustomer)
    if (customerId != null) {
      let customerResponse = await getRequest(`${apiBaseURL()}customers/${customerId}`, true)

      if (customerResponse) {
        let customerDetail1: any = { ...customerDetail }

        customerDetail1 = customerResponse
        customerDetail1.year_of_operations_view = moment(
          customerResponse.year_of_operations
        ).toDate()
        customerDetail1.business_name = customerResponse.business_name

        if (customerResponse.dob_of_promoter) {
          const dob = dateFormat(customerResponse.dob_of_promoter)
          setDateShow(dob.displayDate)
          setInitialSettings({ ...initialDateSetting, startDate: dob.displayDate })
        }

        setCustomerCategory([
          {
            label: customerResponse.customer_category.category,
            value: customerResponse.customer_category.id,
          },
        ])

        setDistricts([
          {
            label: customerResponse.district.name,
            value: customerResponse.district.id,
          },
        ])

        setCreditRange([
          {
            label: customerResponse.credit_range.credit_range_name,
            value: customerResponse.credit_range.id,
          },
        ])

        setWards([
          {
            label: customerResponse.ward.name,
            value: customerResponse.ward.id,
          },
        ])

        setStreet([
          {
            label: customerResponse.street.name,
            value: customerResponse.street.id,
          },
        ])
        setCustomerTag([
          {
            label: customerResponse.customer_tag.tag_name,
            value: customerResponse.customer_tag.id,
          },
        ])
        setSelectedAddress(customerResponse.address)

        customerDetail1.id = customerId
        // customerDetail1.customer_type = customerResponse.customer_type.id
        customerDetail1.district = customerResponse.district.id
        customerDetail1.ward = customerResponse.ward.id
        customerDetail1.street = customerResponse.street.id
        customerDetail1.credit_range = customerResponse.credit_range.id
        customerDetail1.customer_tag = customerResponse.customer_tag.id
        customerDetail1.customer_category = customerResponse.customer_category.id
        customerDetail1.document = customerResponse.document
        customerDetail1.registration_certificate_file =
          customerResponse.registration_certificate_file
        customerDetail1.national_id_file = customerResponse.national_id_file
        customerDetail1.business_license_file = customerResponse.business_license_file

        if (customerDetail1.document.length > 0) {
          let uploadedDocuments: any = []

          customerDetail1.document.map((document: any) => {
            uploadedDocuments.push(document)
          })

          setDocuments(uploadedDocuments)
        }

        setCustomerDetail(customerDetail1)
      }
    }
  }

  const fetchCustomerType = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchNatureofBusiness = async (search: any) => {
    let options = business
    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchBusinessSize = async (search: any) => {
    let options = businessSize
    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchPremises = async (search: any) => {
    let options = premises

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }
  const fetchFamilySize = async (search: any) => {
    let options = familySize

    if (search !== '') {
      options = staticSearch(options, search)
    }
    return {
      options: options,
      hasMore: false,
    }
  }
  const fetchCreditRange = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const CREDIT_RANGE_API = `${apiBaseURL()}master/credit-score/list/?ordering=credit_range_name&search=${search}&limit=${limit}&offset=${creditRangeOffset}`

    const Response = await getRequest(CREDIT_RANGE_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setCreditRangeOffset(newOffset)
      hasMore = true
    }
    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.credit_range_name,
          value: option.id,
        })
      })
    }
    if (creditRanger.length > 0) {
      setCreditRange(creditRanger.concat(options))
    } else {
      setCreditRange(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchCustomerTag = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const CUSTOMER_TAG_API = `${apiBaseURL()}master/customer-tags/list/?&limit=${limit}&search=${search}&offset=${tagOffset}`
    const Response = await getRequest(CUSTOMER_TAG_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setTagOffset(newOffset)
      hasMore = true
    }
    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.tag_name,
          value: option.id,
        })
      })
    }
    if (customerTag.length > 0) {
      setCustomerTag(customerTag.concat(options))
    } else {
      setCustomerTag(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchCustomerCategory = async (search: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const business = customer.nature_of_business

    let options: any = []
    const CUSTOMER_TAG_API = `${apiBaseURL()}master/customer-category/list/?master_nature_of_business=${business}&limit=${limit}&search=${search}&offset=${customerCategoryOffset}`
    const Response = await getRequest(CUSTOMER_TAG_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerCategoryOffset(newOffset)
      hasMore = true
    }
    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.category,
          value: option.id,
        })
      })
    }
    if (customerCategory > 0) {
      setCustomerCategory(customerCategory.concat(options))
    } else {
      setCustomerCategory(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchDistrict = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []
    const CUSTOMER_TYPE_API = `${apiBaseURL()}master/district/list/?user_id=${getUserId}&limit=${limit}&offset=${districtOffset}&search=${search}`
    const Response = await getRequest(CUSTOMER_TYPE_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setDistrictOffset(newOffset)
      hasMore = true
    }
    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (districts.length > 0) {
      setDistricts(districts.concat(options))
    } else {
      setDistricts(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchWard = async (search: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const district = customer.district

    let options: any = []
    const CUSTOMER_TAG_API = `${apiBaseURL()}master/wards/list/?district__id=${district}&user_id=${getUserId}&limit=${limit}&search=${search}&offset=${wardOffset}`
    const Response = await getRequest(CUSTOMER_TAG_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setWardOffset(newOffset)
      hasMore = true
    }
    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (wards.length > 0) {
      setWards(wards.concat(options))
    } else {
      setWards(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchStreet = async (search: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    const ward = customer.ward

    let options: any = []
    const CUSTOMER_TAG_API = `${apiBaseURL()}master/street/list/?ward__id=${ward}&limit=${limit}&search=${search}&offset=${streetOffset}`
    const Response = await getRequest(CUSTOMER_TAG_API, true)
    let hasMore: boolean = false

    if (Response.next !== null) {
      const queryParams = new URLSearchParams(Response.next)
      let newOffset: any = queryParams.get('offset')
      setStreet(newOffset)
      hasMore = true
    }
    if (Response.results.length > 0) {
      Response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }
    if (street.length > 0) {
      setStreet(street.concat(options))
    } else {
      setStreet(options)
    }
    return {
      options: options,
      hasMore: hasMore,
    }
  }

  //call backfunction
  const extendedLoadOptionsCategory = useCallback(
    async (search) => {
      return fetchCustomerCategory(search)
    },

    [customer.nature_of_business, customerCategoryOffset]
  )
  const extendedLoadOptionsWards = useCallback(
    async (search) => {
      return fetchWard(search)
    },
    [customer.district, wardOffset]
  )

  const extendedLoadOptionsStreet = useCallback(
    async (search) => {
      return fetchStreet(search)
    },
    [customer.ward, streetOffset]
  )

  const handleChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setCustomerValidation({ ...customerValidation, [inputName]: '' })
    let updatedCustomer: any = { ...customer }
    switch (inputName) {
      case 'prior_relationship':
        // setCustomer({...customer, ['prior_relationship']: e.target.checked})
        updatedCustomer['prior_relationship'] = e.target.checked
        break
      case 'contact_no':
        const re = /^[0-9\b]+$/
        if (inputValue === '' || re.test(inputValue)) {
          updatedCustomer['contact_no'] = inputValue
        }
        break
      case 'nature_of_business':
        updatedCustomer.customer_category = ''
        updatedCustomer['nature_of_business'] = inputValue
        setCustomerCategoryOffset('')
        setCustomerCategory([])
        break
      case 'customer_type':
        updatedCustomer.customer_type = ''
        updatedCustomer['customer_type'] = inputValue
        // setCustomerCategoryOffset('')
        setCustomerTypeOffset('')
        // setCustomerCategory([])
        setCustomerType([])
        break
      case 'business_license_file':
      case 'registration_certificate_file':
      case 'national_id_file':
        e.preventDefault()
        const file = handleFile(e, inputName)
        updatedCustomer[inputName] = file
        break
      case 'document':
        e.preventDefault()
        const documentFile = handleFile(e, inputName)
        if (documentFile != '') {
          let uploadedDocuments: any = [...documents]

          uploadedDocuments.push(documentFile)

          setDocuments(uploadedDocuments)
        }
        // updatedCustomer[inputName] = handleFile(e, inputName)
        // setCustomer({...customer, [inputName]: handleFile(e, inputName)})
        break
      case 'district':
        updatedCustomer['ward'] = ''
        updatedCustomer['street'] = ''
        setWards([])
        setStreet([])
        setWardOffset('')
        setStreetOffset('')
        updatedCustomer[inputName] = inputValue
        break
      case 'ward':
        updatedCustomer['street'] = ''
        setStreet([])
        setStreetOffset('')
        updatedCustomer[inputName] = inputValue
        break
      default:
        updatedCustomer[inputName] = inputValue

      // default:
      //   setCustomer({...customer, [inputName]: inputValue})
    }
    setCustomer(updatedCustomer)
  }

  const handleFile = (e: any, inputName: string) => {
    const allowedExtensions =
      inputName !== 'document'
        ? /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i
        : /(\.pdf|\.jpg|\.jpeg|\.png|\.gif|\.doc|\.xlsx)$/i

    setCustomerValidation({ ...customerValidation, [inputName]: '' })

    if (allowedExtensions.exec(e.target.value)) {
      if (e.dataTransfer) {
        return e.dataTransfer.files[0]
      } else if (e.target) {
        return e.target.files[0]
      }
    } else {
      setCustomerValidation({ ...customerValidation, [inputName]: 'is-invalid' })
    }

    return ''
  }

  const onImageChange = (e: any) => {
    setImageSize('')
    if (e.target.files[0]) {
      let size = e.target.files[0].size / 1024 / 1024
      const [file] = e.target.files
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
      if (allowedExtensions.exec(e.target.value)) {
        setImg(URL.createObjectURL(file))
        setBrandImage(e.target.files[0])
      }
      let img: any = ''
      if (e.target.files[0]) {
        img = new Image()
        var objectUrl = URL.createObjectURL(file)
        img.onload = function () {
          URL.revokeObjectURL(objectUrl)
          let width, height
          width = this.width
          height = this.height
          if (size > 5.0) {
            setimgSizeInvalid('text-danger')
            setImageValidation(false)
          } else {
            setimgSizeInvalid('')
            setImageValidation(true)
          }
        }
        img.src = objectUrl
      }
      e.target.value = ''
    }
  }

  const getImage = (val: any) => {
    setBrandImage(val)
  }

  const validateForm = () => {
    let validations = { ...customerValidation }
    let isValid = true

    if (customer.business_name.trim() === '') {
      validations.business_name = 'is-invalid'
      isValid = false
    }

    if (customer.customer_type === '') {
      validations.customer_type = 'is-invalid'
      isValid = false
    }

    if (customer.nature_of_business === '') {
      validations.nature_of_business = 'is-invalid'
      isValid = false
    }
    if (customer.customer_category === '') {
      validations.customer_category = 'is-invalid'
      isValid = false
    }

    if (customer.size_of_business === '') {
      validations.size_of_business = 'is-invalid'
      isValid = false
    }

    if (customer.promoter_name === '') {
      validations.promoter_name = 'is-invalid'

      isValid = false
    }

    if (customer.contact_no === '') {
      validations.contact_no = 'is-invalid'
      isValid = false
    }
    // const re = /^[0-9\b]+$/
    // if (customer.contact_no === '' || re.test(customer.contact_no)) {
    //   setCustomerValidation({...customerValidation, ['contact_no']: 'is-invalid'})
    //   validations.contact_no = 'is-invalid'
    //   isValid = false
    // }

    if (customer.email === '') {
      validations.email = 'is-invalid'
      isValid = false
    } else {
      if (!validateEmail(customer.email)) {
        setCustomerValidation({ ...customerValidation, ['email']: 'is-invalid' })
      }
    }

    if (customer.premises === '') {
      validations.premises = 'is-invalid'
      isValid = false
    }

    if (customer.personal_family_size === '') {
      validations.personal_family_size = 'is-invalid'
      isValid = false
    }

    if (customer.district === '') {
      validations.district = 'is-invalid'
      isValid = false
    }

    if (customer.ward === '') {
      validations.ward = 'is-invalid'
      isValid = false
    }

    if (customer.street === '') {
      validations.street = 'is-invalid'
      isValid = false
    }

    setCustomerValidation(validations)

    return isValid
  }

  //post api
  const addCustomer = async () => {
    let customerData = new FormData()
    const customerInfo = { ...customer }

    delete customerInfo.year_of_operations_view
    delete customerInfo.rejection_reason
    delete customerInfo.revoke_access_option
    delete customerInfo.rejection_comment
    delete customerInfo.revoke_comment
    delete customerInfo.grant_access_comment
    delete customerInfo.grant_access_option
    delete customerInfo.id
    delete customerInfo.business_license_verify
    delete customerInfo.business_license_verify_on
    delete customerInfo.business_license_verify_by
    delete customerInfo.registration_certificate_verify_on
    delete customerInfo.registration_certificate_verify
    delete customerInfo.registration_certificate_verify_by
    delete customerInfo.national_id_verify_on
    delete customerInfo.national_id_verify
    delete customerInfo.national_id_verify_by
    delete customerInfo.business_license_file
    delete customerInfo.registration_certificate_file
    delete customerInfo.national_id_file

    Object.entries(customerInfo).map((object: any) => {
      customerData.append(object[0], object[1])
    })
    if (customerId != null) {
      patchRequest(`${apiBaseURL()}customer/${customerId}/`, customerData, true).then((res) => {
        handleResponse(res, 'Edit')
      })
    } else {
      postRequest(`${apiBaseURL()}customer/create/`, customerData, true).then((res) => {
        handleResponse(res, 'Add')
      })
    }
  }

  const handleResponse = (res: any, type: string) => {
    setIsButtonLoader('Submit')
    if (res.status === 201 || res.status === 200) {
      setIsAlertSuccess(true)
      const customerDetails: any = { ...customer }
      setCustomer(initialCustomer)
      localStorage.setItem('type', type)
      localStorage.removeItem('cId')
      localStorage.setItem('name', customerDetails.business_name)
      navigate('/customers')
    } else {
      setIsAlertFailed(true)
    }

    setTimeout(() => {
      setIsAlertFailed(false)
      setIsAlertSuccess(false)
    }, 1500)
  }

  const removeDocument = (index: number) => {
    let uploadedDocument: any = [...documents]

    uploadedDocument.splice(index, 1)

    setDocuments(uploadedDocument)
  }

  const saveCustomer = () => {
    const isValid = validateForm()
    if (isValid) {
      setIsButtonLoader('Please Wait...')
      addCustomer()
    }
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let newDate = dateFormat(start._d)
          setCustomer({ ...customer, dob_of_promoter: newDate.apiDate })
          setDateShow(newDate.displayDate)
          setInitialSettings((pre: any) => ({ ...pre, startDate: start._d }))
        }}
        initialSettings={initialSettings}
        dateValidation={'w-100'}
        dateValue={dateShow}
      />
    )
  }

  return (
    <>
      <div>
        <PageTitle breadcrumbs={adminHeaderTitle}>
          {intl.formatMessage({ id: customer.id != null ? 'Update Customer' : 'Add Customer' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={FormatMessage('success')}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column my-0'>
              <span className='card-label fw-bold font-17'>
                {customer.id != null ? 'Update Customer' : 'Add Customer'}
              </span>
            </h3>
            {/* end::Title */}
          </div>
          <div className='card-body pt-4 pt-md-5'>
            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4'> Business Information</h3>
            <div className='row'>
              <div className='col-lg-9 col-xl-9 col-xxl-8'>
                <div className='row'>
                  <div className='col-md-8 mb-4'>
                    <label className='form-label'>Business Name</label>
                    <input
                      type='text'
                      placeholder='Type here...'
                      onChange={handleChange}
                      name='business_name'
                      className={clsx('form-control', customerValidation.business_name)}
                      value={customer.business_name}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Customer Type</label>
                    <AsyncPaginate
                    
                      loadOptions={fetchCustomerType}
                      isSearchable
                      className={clsx(
                        'react-select-container w-100%',
                        customerValidation.customer_type
                      )}
                      classNamePrefix='react-select'
                      value={customerTypes.find(
                        (option: any) => option.value == customer.customer_type
                      )}
                      onChange={(e: any) => handleChange(e, 'customer_type')}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mb-4'>
                    <label className='form-label'>Nature of Business</label>
                    <AsyncPaginate
                    
                      loadOptions={fetchNatureofBusiness}
                      isSearchable
                      className={clsx(
                        'react-select-container me-3 w-100%',
                        customerValidation.nature_of_business
                      )}
                      classNamePrefix='react-select'
                      value={business.find(
                        (option: any) => option.value == customer.nature_of_business
                      )}
                      onChange={(e: any) => {
                        handleChange(e, 'nature_of_business')
                        setCustomerCategoryOffset('')
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='col-md-6 mb-4'>
                    <label className='form-label'>Customer Category</label>

                    <AsyncPaginate
                    
                      loadOptions={extendedLoadOptionsCategory}
                      cacheUniqs={[customer.nature_of_business, customerCategoryOffset]}
                      isDisabled={customer.nature_of_business == ''}
                      isSearchable
                      className={clsx(
                        'react-select-container my-1 me-3 w-100%',
                        customerValidation.customer_category
                      )}
                      classNamePrefix='react-select'
                      value={
                        customerCategory.length > 0 &&
                        customerCategory.find(
                          (option: any) => option.value == customer.customer_category
                        )
                        || ''
                      }
                      onChange={(e: any) => handleChange(e, 'customer_category')}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mb-4'>
                    <label className='form-label'>Operational Since</label>
                    <DatePicker
                      className='calendar react-datepicker form-control'
                      clearIcon={null}
                      calendarIcon={null}
                      onChange={onChange}
                      name='year_of_operations'
                      value={customer.year_of_operations_view}
                      defaultView={'year'}
                      format={'MM-yyyy'}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                    // maxDetail={"month"}
                    // view={"year"}
                    />
                  </div>
                  <div className='col-md-6 mb-4'>
                    <label className='form-label'>Size of Business</label>
                    <AsyncPaginate
                    
                      loadOptions={fetchBusinessSize}
                      isSearchable
                      //   className='react-select-container w-100%'
                      className={clsx(
                        'react-select-container w-100',
                        customerValidation.size_of_business
                      )}
                      classNamePrefix='react-select'
                      value={businessSize.find(
                        (option: any) => option.value == customer.size_of_business
                      )}
                      onChange={(e: any) => handleChange(e, 'size_of_business')}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>

                {customerId == null ? (
                  <AddBusinessDocuments customer={customer} handleChange={handleChange} />
                ) : (
                  <BusinessDocuments
                    customer={customer}
                    fetchCustomerDetails={fetchCustomerDetails}
                  />
                )}
              </div>
              <div className='col-xxl-3 col-lg-3'>
                <div className='shadow-sm p-lg-5 p-4'>
                  <CustomerLogo
                    // title='Brand Image'
                    getImage={getImage}
                    onImageChange={onImageChange}
                    imageRequire={imageRequire}
                    imageSize={imgSizeInvalid}
                    cropData={cropData}
                    setCrop={setCropData}
                  />
                </div>
              </div>
            </div>

            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Contact Information</h3>
            <div className='row'>
              <div className='col-lg-9 col-xl-9 col-xxl-8'>
                <div className='row'>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Promoter's Name </label>
                    <input
                      type='text'
                      onChange={handleChange}
                      name='promoter_name'
                      className={clsx('form-control', customerValidation.promoter_name)}
                      placeholder='Type here...'
                      value={customer.promoter_name}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Contact Number</label>
                    <input
                      type='text'
                      className={clsx('form-control', customerValidation.contact_no)}
                      name='contact_no'
                      placeholder='Type here...'
                      onChange={handleChange}
                      value={customer.contact_no}
                      maxLength={10}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Email</label>
                    <input
                      type='text'
                      className={clsx('form-control', customerValidation.email)}
                      placeholder='Type here...'
                      name='email'
                      onChange={handleChange}
                      value={customer.email}
                      onBlur={(e) => {
                        if (!validateEmail(e.target.value)) {
                          setCustomerValidation({
                            ...customerValidation,
                            ['email']: 'is-invalid',
                          })
                        }
                      }}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>DOB of Promoter</label>

                    <CustomDatePicker />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Premises (Address)</label>
                    <AsyncPaginate
                    
                      loadOptions={fetchPremises}
                      isSearchable
                      className={clsx(
                        'react-select-container my-1 me-3 w-100%',
                        customerValidation.premises
                      )}
                      name='premises'
                      classNamePrefix='react-select'
                      value={premises.find((option: any) => option.value === customer.premises)}
                      onChange={(e: any) => handleChange(e, 'premises')}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-9 col-xl-9 col-xxl-8'>
                {customer.premises !== 'Movable' && (
                  <div className='mb-4'>
                    <label className='form-label'>Address</label>
                    <PlaceSearch
                      validationClass={customerValidation.address}
                      setSelectedAddress={setSelectedAddress}
                      selectedAddress={selectedAddress}
                      setLatitudeLongitude={setLatitudeLongitude}
                      setPostalCode={setPostalCode}
                      setPlaceId={setPlaceId}
                    />
                  </div>
                )}
                <div className='row'>
                  <div className='col-md-3 mb-4'>
                    <label className='form-label'>District</label>
                    <AsyncPaginate
                    
                      loadOptions={fetchDistrict}
                      isSearchable
                      className={clsx('react-select-container w-100%', customerValidation.district)}
                      classNamePrefix='react-select'
                      value={districts.find((option: any) => option.value === customer.district)}
                      onChange={(e: any) => handleChange(e, 'district')}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='col-md-3 mb-4'>
                    <label className='form-label'>Ward</label>
                    <AsyncPaginate
                    
                      loadOptions={extendedLoadOptionsWards}
                      isSearchable
                      className={clsx('react-select-container w-100%', customerValidation.ward)}
                      classNamePrefix='react-select'
                      value={
                        wards.length > 0 &&
                        wards.find((option: any) => option.value === customer.ward)
                        || ''
                      }
                      onChange={(e: any) => handleChange(e, 'ward')}
                      cacheUniqs={[customer.district, wardOffset]}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='col-md-3 mb-4'>
                    <label className='form-label'>Street</label>
                    <AsyncPaginate
                    
                      loadOptions={extendedLoadOptionsStreet}
                      isSearchable
                      className={clsx('react-select-container w-100%', customerValidation.street)}
                      classNamePrefix='react-select'
                      value={
                        street.length > 0 &&
                        street.find((option: any) => option.value === customer.street)
                      }
                      onChange={(e: any) => handleChange(e, 'street')}
                      cacheUniqs={[customer.ward, streetOffset]}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='col-md-3 mb-4'>
                    <label className='form-label'>Pincode</label>
                    <input
                      type='text'
                      onChange={handleChange}
                      name='pincode'
                      value={customer.pincode}
                      className='form-control'
                      placeholder='Type here...'
                    />
                  </div>
                </div>
              </div>
            </div>

            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Credit Information</h3>
            <div className='row'>
              <div className='col-lg-9 col-xl-9 col-xxl-8'>
                <p>
                  Specify this Customer's Credit Amount and Credit Cycle Approval Capping here; if
                  these crosses, credit approval request goes to the Manager!
                </p>
                <div className='row g-4'>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Credit Range</label>
                    <AsyncPaginate
                    
                      loadOptions={fetchCreditRange}
                      isSearchable
                      className={clsx(
                        'react-select-container my-1 me-3 w-100%',
                        customerValidation.credit_range
                      )}
                      value={creditRanger.find(
                        (option: any) => option.value === customer.credit_range
                      )}
                      onChange={(e: any) => handleChange(e, 'credit_range')}
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Credit Amount Cap</label>
                    <input
                      type='number'
                      className={clsx('form-control', customerValidation.credit_amount_cap)}
                      onChange={handleChange}
                      name='credit_amount_cap'
                      placeholder='Type here...'
                      value={customer.credit_amount_cap}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <label className='form-label'>Credit Cycle Cap</label>
                    <input
                      type='number'
                      onChange={handleChange}
                      name='credit_cycle'
                      className={clsx('form-control', customerValidation.credit_cycle)}
                      placeholder='Type here...'
                      value={customer.credit_cycle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <h3 className='font-14 fw-500 border-bottom pb-4 mb-4 pt-3'>Other Information</h3>
            <div className='col-lg-9 col-xl-9 col-xxl-8'>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label className='form-label'>Prior Relation with EAF</label>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className={clsx(
                        'form-check-input widget-13-check mx-2',
                        customerValidation.credit_cycle
                      )}
                      name='prior_relationship'
                      checked={customer.prior_relationship}
                      onChange={(e: any) => handleChange(e, 'prior_relationship')}
                      type='checkbox'
                      value=''
                    />
                  </div>
                </div>
                <div className='col-md-6 mb-4'>
                  <label className='form-label'>Personal Family Size</label>
                  <AsyncPaginate
                    
                    loadOptions={fetchFamilySize}
                    isSearchable
                    className={clsx(
                      'react-select-container my-1 me-3 w-100%',
                      customerValidation.personal_family_size
                    )}
                    classNamePrefix='react-select'
                    value={familySize.find(
                      (option: any) => option.value === customer.personal_family_size
                    )}
                    onChange={(e: any) => handleChange(e, 'personal_family_size')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
              </div>
              <div className='row g-4'>
                <div className='col-md-4 mb-4'>
                  <label className='form-label'>Customer Tag</label>
                  <AsyncPaginate
                    
                    loadOptions={fetchCustomerTag}
                    isSearchable
                    className={clsx(
                      'react-select-container my-1 me-3 w-100%',
                      customerValidation.customer_tag
                    )}
                    classNamePrefix='react-select'
                    onChange={(e: any) => handleChange(e, 'customer_tag')}
                    value={customerTag.find(
                      (option: any) => option.value === customer.customer_tag
                    )}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
                <div className='col-md-12 mt-0'>
                  <label className='form-label'>Note</label>
                  <textarea
                    className={clsx('form-control', customerValidation.customer_tag)}
                    rows={4}
                    placeholder='Type here…'
                    name='note'
                    onChange={handleChange}
                    value={customer.note}
                  ></textarea>
                </div>
                {/* <div className='col-md-6'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <label htmlFor=''>Attach Document</label>
                    <label
                      htmlFor='attachFile'
                      className='btn btn-with-icon btn-icon-dark btn-light px-3 fw-600 py-0 lnh-36px'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com008.svg'
                        className='svg-icon-muted ic me-2'
                      />
                      <span className='text-muted'>
                        {customer.document ? customer.document.name : 'Attach'}
                      </span>
                    </label>
                    <input
                      className={clsx(
                        'form-control invisible position-absolute',
                        customerValidation.customer_tag
                      )}
                      type='file'
                      id='attachFile'
                      name='document'
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                <Documents
                  documents={documents}
                  handleChange={handleChange}
                  removeDocument={removeDocument}
                  isUpdate={customerId != null}
                />
              </div>
            </div>
          </div>
          <div className='card-footer py-4'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              onClick={() => navigate('/customers')}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isButtonLoadder === 'Submit' ? false : true}
              onClick={saveCustomer}
            >
              {isButtonLoadder}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TSAddCustomer
