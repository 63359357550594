import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {connect} from 'react-redux'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  measurementLabel,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../TenantVariables'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {useNavigate} from 'react-router-dom'

const SrDailyRoutesReport = (props: any) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'SRs Daily Routes',
    subTitle: 'SRs Daily Routes',
  }
  const navigate = useNavigate()

  const [records, setRecords] = useState([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState(tenantConfiguration.limit)
  const [warehouse, setWarehouse] = useState({label: 'All Warehouse', value: ''})
  const [count, setCount] = useState<string>('1')
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
  const [search, setSearch] = useState('')

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    fetchRecords()
  }, [currentOffset, warehouse, apiDate, debounceSearch])

  const fetchRecords = async () => {
    setDisplayLoader(true)

    const response = await getRequest(
      `${apiBaseURL()}reports/sr-routes-report/list?start_date=${apiDate.startDate}&end_date=${
        apiDate.endDate
      }&warehouse_id=${warehouse.value}&search=${debounceSearch}`,
      true
    )

    if (response && response.results) {
      setRecords(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const onNavigate = (item: any) => {
    localStorage.setItem('sr-routes-id', item.sr_id)
    localStorage.setItem('sr-routes-date', apiDate.startDate)

    navigate('/reports/sr-daily-routes')
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            singleDatePicker: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-220px'
            defaultValue={value}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='SRs Daily Routes - ' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'SRs Daily Routes'})}
      </PageTitle>

      <div className='card mb-5'>
        <form
          className='card-header border-0 py-2 px-7 align-items-center'
          onSubmit={(e) => e.preventDefault()}
          autoComplete='off'
        >
          <CustomSearchBar
            onChange={(e: any) => {
              setSearch(e.target.value)
              setCurrentOffset(0)
              setSelected(0)
            }}
            isDisable={displayLoader}
          />
          <div className='d-flex'>
            <WarehouseFilter
              warehouse={warehouse}
              handleFilterChange={(e: any) => {
                setWarehouse(e)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisabled={displayLoader}
            />

            <CustomDatePickerComponent
              onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                setApiDate({
                  startDate: callBakeDate.startDate,
                  endDate: callBakeDate.endDate,
                })
                setCurrentOffset(0)
                setSelected(0)
              }}
              value={apiDate}
              startDate={apiDate.startDate}
              endDate={apiDate.endDate}
            />
          </div>
        </form>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {displayLoader ? (
              <FetchingRecords />
            ) : records.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-120px w-120px'>SR Name</th>
                    <th className='w-160px min-w-160px'>Gatepasses(s)</th>
                    <th className='w-160px min-w-160px'>Dist. Traveled</th>
                    <th className='w-160px min-w-160px'>Successful Calls</th>
                    <th className='w-160px min-w-160px'>Qty. Sold</th>
                    <th className='w-160px min-w-160px'>Qty. Unsold</th>
                    <th className='w-160px min-w-160px'>Total Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((item: any) => {
                    return (
                      <>
                        <tr className='cursor-pointer' onClick={() => onNavigate(item)}>
                          <td>{checkEmpty(item.sr_name)}</td>
                          <td>
                            {isEmpty(item.total_gatepass)
                              ? '-'
                              : numberFormat(item.total_gatepass).displayFormat}
                          </td>
                          <td>
                            {isEmpty(item.km_driven)
                              ? '-'
                              : numberFormat(item.km_driven, true).displayFormat}
                          </td>
                          <td>
                            {isEmpty(item.total_orders)
                              ? '-'
                              : numberFormat(item.total_orders).displayFormat}
                          </td>
                          <td>
                            {isEmpty(item.total_deliverd_stock)
                              ? '-'
                              : numberFormat(item.total_deliverd_stock).displayFormat}
                          </td>
                          <td>
                            {isEmpty(item.total_return_stock)
                              ? '-'
                              : numberFormat(item.total_return_stock).displayFormat}
                          </td>
                          <td>
                            {isEmpty(item.total_sales) ? (
                              '-'
                            ) : (
                              <>{numberFormat(item.total_sales).displayFormat}</>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={records}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={displayLoader}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default SrDailyRoutesReport
