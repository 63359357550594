import React, {useEffect, useState} from 'react'
import {
  apiBaseURL,
  dateFormat,
  getQuarter,
  isEmpty,
  measurementLabel,
  numberFormat,
  numberFormatWithCode,
  staticSearch,
} from '../../../_eaFruitsDms/helpers'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../TenantVariables'
import {EaChartBar, EaChartPie} from '../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {connect} from 'react-redux'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {AsyncPaginate} from 'react-select-async-paginate'
import moment from 'moment'
import {useLocation, useNavigate} from 'react-router-dom'

const SADashboard = (props: any) => {
  const {warehouseId, month} = props
  const initialType = {label: 'Sales', value: 'sales'}
  const navigate = useNavigate()

  let checkApiCall = true

  const location: any = useLocation()

  if (location && location.state && location.state.isDisableApiCall) {
    checkApiCall = false
  }

  const [reset, setReset] = useState<any>(moment())
  const [widget, setWidget] = useState({
    total_team_size: 0,
    total_warehouses: 0,
    total_skus: 0,
    total_product_groups: 0,
    total_customers: 0,
    total_categories: 0,
    total_sales_amount: 0,
    total_open_payment_issues_amount: 0,
    total_open_payment_issues: 0,
    total_expired_products: 0,
    total_open_credit_issues_amount: 0,
    total_open_credit_issues_due_percentage: 0,
    total_approved_discount_amount: 0,
    total_approved_discount_percentage: 0,
  })

  const [brandPieChart, setBrandPieChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
    selectedMode: true,
  })
  const [productGroupsChart, setProductGroupsChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
    selectedMode: true,
  })
  const [productSkuChart, setProductSkuChart] = useState({
    data: [],
    title: '',
    bottom: '5%',
  })

  const [brandId, setBrandId] = useState('')
  const [productGroupId, setProductGroupId] = useState('')
  const [productGroupLoading, setProductGroupLoading] = useState(false)
  const [productSkuLoading, setProductSkuLoading] = useState(false)
  const [brandsChartLoading, setBrandsChartLoading] = useState(false)
  const [barChart, setBarChart] = useState({})
  const [isChartLoading, setChartLoading] = useState(true)
  const [srType, setSrType] = useState<any>(initialType)
  const [topSr, setTopSr] = useState<any>([])
  const [srLoading, setSrLoading] = useState(true)
  const [topCustomer, setTopCustomer] = useState<any>([])
  const [customerLoading, setCustomerLoading] = useState(true)
  const [customerType, setCustomerType] = useState<any>(initialType)
  const [barCharEmpty, setBarChartEmpty] = useState<any>([])
  const [isHoreca, setIsHoreca] = useState(false)
  const [fetchingLowStockAlert, setFetchingLowStockAlert] = useState(true)
  const [lowStockAlert, setLowStockAlert] = useState([])
  const [fetchingOutOfStockAlert, setFetchingOutOfStockAlert] = useState(true)
  const [outOfStockAlert, setOutOfStockAlert] = useState([])
  const [stockType, setStockType] = useState('')

  // for bar chart
  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  // for bar chart
  const labelOption = (name: any) => {
    return {
      show: true,
      position: app.position,
      distance: app.distance,
      align: app.align,
      verticalAlign: app.verticalAlign,
      rotate: app.rotate,
      // formatter: '{c}  {name|{a}}',
      // formatter: function (params: any) {
      formatter: function (params: any) {
        // return numberFormatWithCode(params.data).displayFormat
        return name === 'New Customers'
          ? params.data
          : numberFormatWithCode(params.data).displayFormat
      },
      //   return numberFormatWithCode(params.data).displayFormat
      // },
      fontSize: 10,
      rich: {
        name: {},
      },
    }
  }

  const todayDate = dateFormat(moment()).apiDate
  const monthUrl = `&timeline=${month ? month : 'today'}${
    isEmpty(month) || month === 'today'
      ? `&date_duration_before=${todayDate}&date_duration_after=${todayDate}`
      : ''
  }`
  const filter = `warehouse_id=${warehouseId ? warehouseId : ''}${monthUrl}`

  const fetchWidgetsCounts = async () => {
    const url = apiBaseURL() + 'reports/sa-dashboard/card-counts?' + filter
    const response = await getRequest(url, true)
    if (response && response.result) {
      setWidget(response.result)
    }
  }

  const fetchBrandPieChart = async () => {
    setBrandsChartLoading(true)
    setProductGroupLoading(true)
    setProductSkuLoading(true)

    const url = apiBaseURL() + 'reports/sa-dashboard/brands?' + filter
    const response = await getRequest(url, true)
    let brandChart: any = {...brandPieChart}
    let mergeBrandChart: Object[] = []

    if (response && response.result) {
      if (response.result.length === 0) {
        setBrandId('')
        setProductGroupId('')
      }

      if (response.result.length >= 1) {
        setBrandId(response.result[0].brand_id)
      }

      response.result.forEach((item: any, index: number) => {
        mergeBrandChart.push({
          name: item.brand_name,
          value: parseFloat(item.sales_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          brand_id: item.brand_id,
          selected: index === 0 ? true : false,
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    brandChart.data = mergeBrandChart
    setBrandPieChart(brandChart)
    setBrandsChartLoading(false)
  }

  const fetchProductGroupsPieChart = async () => {
    setProductGroupLoading(true)
    setProductSkuLoading(true)

    const url = apiBaseURL() + `reports/sa-dashboard/brands/${brandId}/product-groups?` + filter
    const response = await getRequest(url, true)
    let chart: any = {...productGroupsChart}
    let mergeChart: Object[] = []

    if (response && response.result) {
      if (response.result.length >= 1) {
        setProductGroupId(response.result[0].product_group_id)
      }

      response.result.forEach((item: any) => {
        mergeChart.push({
          name: item.product_group_name,
          value: parseFloat(item.sales_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          product_group_id: item.product_group_id,
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setProductGroupsChart(chart)
    setProductGroupLoading(false)
  }

  const fetchProductSkuPieChart = async () => {
    setProductSkuLoading(true)
    const url =
      apiBaseURL() +
      `reports/sa-dashboard/brands/product-groups/${productGroupId}/product-skus?` +
      filter
    const response = await getRequest(url, true)
    let chart: any = {...productSkuChart}
    let mergeChart: Object[] = []

    if (response && response.result) {
      response.result.forEach((item: any, index: number) => {
        mergeChart.push({
          name: item.product_sku_name,
          value: parseFloat(item.sales_percentage)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
          total_payable_amount: parseFloat(item.total_payable_amount)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
        })
      })
    }

    chart.data = mergeChart
    setProductSkuChart(chart)
    setProductSkuLoading(false)
  }

  const fetchBusinessOverviewChart = async () => {
    setChartLoading(true)

    const url = apiBaseURL() + `reports/sa-dashboard/business-overview?` + filter
    const response = await getRequest(url, true)

    if (response && response.result) handleChartResponse(response.result)

    setChartLoading(false)
  }

  const handleChartResponse = (response: any) => {
    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []
    let type: any = []
    let key = 'label'
    let lastQuarter = getQuarter().currentQuarter - 1
    let year: any = []

    if (response.length > 0) {
      setBarChartEmpty([1])
    } else {
      setBarChartEmpty([])
    }

    if (response.length > 0) {
      response[0].chart_data.forEach((item: any) => {
        legend.push(item[key])
        type.push({name: item[key]})
      })

      response.forEach((item: any) => {
        year.push(getQuarter(item.range, true).concatMonths)

        xAxisData.push(
          isEmpty(warehouseId)
            ? !isEmpty(item.warehouse_name)
              ? item.warehouse_name
              : '-'
            : item.timeline_type + ' ' + item.range
        )
      })

      type.forEach((i: any) => {
        let data: any = []
        response.forEach((item: any) => {
          item.chart_data.length > 0 &&
            item.chart_data.forEach((item: any, index: any) => {
              if (i.name === item[key]) {
                data.push(
                  parseFloat(item.value)
                    .toFixed(2)
                    .replace(/\.?0+$/, '')
                )
              }
            })
        })

        seriesData.push({
          name: i.name,
          type: 'bar',
          width: '100%',
          label: labelOption(i.name),
          tooltip: {
            valueFormatter: function (params: any) {
              return i.name === 'New Customers'
                ? params
                : numberFormatWithCode(params).displayFormat
            },
          },
          emphasis: {
            focus: 'series',
          },
          data: data,
        })
      })
    }

    switch (month) {
      case 'this_quarter':
        initialChart.xAxisData = getQuarter().quarterMonths
        initialChart.rotate = 1
        break

      case 'last_quarter':
        initialChart.xAxisData = getQuarter(lastQuarter).quarterMonths
        initialChart.rotate = 1
        break

      case 'this_year':
        initialChart.xAxisData = year
        initialChart.rotate = 1
        break

      case 'last_year':
        initialChart.xAxisData = year
        initialChart.rotate = 1
        break

      default:
        initialChart.xAxisData = xAxisData
        initialChart.rotate = 30
        break
    }

    if (isEmpty(warehouseId)) {
      initialChart.xAxisData = xAxisData
      initialChart.rotate = 30
    }

    initialChart.series = seriesData
    initialChart.legend = legend
    initialChart.startZoom = 0
    initialChart.endZoom = 100

    setBarChart(initialChart)
  }

  const fetchLowStockAlert = async () => {
    setFetchingLowStockAlert(true)
    const url = `${apiBaseURL()}inventory/oo-dashboard/lowest-stock-product-sku?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&records=`
    const response = await getRequest(url, true)

    if (response && response.results) {
      setLowStockAlert(response.results)
    }

    setFetchingLowStockAlert(false)
  }

  const fetchOutOfStockAlert = async () => {
    setFetchingOutOfStockAlert(true)
    const url = `${apiBaseURL()}inventory/oo-dashboard/lowest-stock-product-sku?warehouse_id=${
      isEmpty(warehouseId) ? '' : warehouseId
    }&records=out_of_stock`
    const response = await getRequest(url, true)

    if (response && response.results) {
      setOutOfStockAlert(response.results)
    }

    setFetchingOutOfStockAlert(false)
  }

  const fetchSrType = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'sales',
        label: 'Sales',
      },
      {
        value: 'payment_issues',
        label: 'Payment Issues',
      },
      {
        value: 'discount_requests',
        label: 'Discount Requests',
      },
      {
        value: 'unsold_returns',
        label: 'Unsold Returns',
      },
      {
        value: 'gatepass_issues',
        label: 'Gatepass Issues',
      },
      {
        value: 'pay_later_usage',
        label: 'Pay Later Usage',
      },
      {
        value: 'pay_later_to_credit_issues',
        label: 'Pay Later To Credit Issues',
      },
    ]

    if (isEmpty(month) || month === 'today') {
      defaultOptions.push({
        value: 'pay_later_balances',
        label: 'Pay Later Balances',
      })
    }

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchCustomerType = async (search: any) => {
    let options: any
    const defaultOptions: any = [
      {
        value: 'sales',
        label: 'Sales',
      },
      {
        value: 'credit_requests',
        label: 'Credit Requests',
      },
      {
        value: 'overdue_credit_issues',
        label: 'Overdue Credit Issues',
      },
      {
        value: 'discount_requests',
        label: 'Discount Requests',
      },
      {
        value: 'pay_later_usage',
        label: 'Pay Later Usage',
      },
      {
        value: 'pay_later_to_credit_issues',
        label: 'Pay Later To Credit Issues',
      },
    ]

    if (search) {
      options = staticSearch(defaultOptions, search)
    } else {
      options = defaultOptions
    }
    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchTopSr = async () => {
    setSrLoading(true)
    const url =
      apiBaseURL() + 'reports/sa-dashboard/sr-performance?' + filter + `&records=${srType.value}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setTopSr(response.result)
    }
    setSrLoading(false)
  }

  const fetchTopCustomer = async () => {
    setCustomerLoading(true)
    const url =
      apiBaseURL() +
      'reports/sa-dashboard/customers-performance?' +
      filter +
      `&records=${customerType.value}&is_horeca_customer=${isHoreca === true ? true : ''}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      setTopCustomer(response.result)
    }
    setCustomerLoading(false)
  }

  useEffect(() => {
    if (checkApiCall) {
      fetchWidgetsCounts()
      fetchBrandPieChart()
      fetchBusinessOverviewChart()
    }
  }, [filter])

  useEffect(() => {
    if (checkApiCall) {
      fetchLowStockAlert()
      fetchOutOfStockAlert()
    }
  }, [warehouseId])

  useEffect(() => {
    if (brandId !== '' && !brandsChartLoading) {
      fetchProductGroupsPieChart()
    }
  }, [brandId, brandsChartLoading])

  useEffect(() => {
    if (productGroupId !== '' && !productGroupLoading) {
      fetchProductSkuPieChart()
    }
  }, [productGroupId, productGroupLoading])

  useEffect(() => {
    if (checkApiCall) {
      fetchTopSr()
    }
  }, [filter, srType])

  useEffect(() => {
    if (checkApiCall) {
      fetchTopCustomer()
    }
  }, [filter, customerType, isHoreca])

  useEffect(() => {
    const srToday = ['pay_later_balances']

    setReset(moment())

    if (month !== 'today') {
      if (srToday.includes(srType.value)) {
        setSrType(initialType)
      }
    }
  }, [filter])

  const updateSelectedBrands = (brands: any) => {
    const id = brands.data.brand_id
    let chart: any = {...brandPieChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        brand_id: item.brand_id,
        selected: item.brand_id === id ? true : false,
        total_payable_amount: item.total_payable_amount,
      })
    })

    chart.data = mergeChart
    setBrandPieChart(chart)
  }

  const updateSelectedProductGroups = (brands: any) => {
    const id = brands.data.product_group_id
    let chart: any = {...productGroupsChart}
    let mergeChart: Object[] = []

    chart.data.forEach((item: any, index: number) => {
      mergeChart.push({
        name: item.name,
        value: item.value,
        product_group_id: item.product_group_id,
        selected: item.product_group_id === id ? true : false,
        total_payable_amount: item.total_payable_amount,
      })
    })

    chart.data = mergeChart
    setProductGroupsChart(chart)
  }

  const toolTip = (item: any) => {
    return `<div>
        <p>
          <b>${item.data.name}</b>
        </p>
        <div>
          <b>${numberFormatWithCode(item.data.total_payable_amount).displayFormat}</b>
        </div>
      </div>`
  }

  return (
    <>
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <a href='/reports/team-report' target='_blank'>
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='success'
              title={widget.total_team_size.toString()}
              description={`Total Team Size in ${
                numberFormat(widget.total_warehouses).displayFormat
              } Warehouse`}
              label='Lifetime'
            />
          </a>
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <a href='/reports/product-skus-report' target='_blank'>
            <EaStatisticsWidget
              className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
              color='info'
              title={widget.total_skus.toString()}
              description={`Total SKUs in ${
                numberFormat(widget.total_product_groups).displayFormat
              } Product Groups`}
              label='Lifetime'
            />
          </a>
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={widget.total_customers.toString()}
            description={`Total Customers in ${
              numberFormat(widget.total_categories).displayFormat
            } Categories`}
            label='Lifetime'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={numberFormatWithCode(widget.total_open_credit_issues_amount).displayFormat}
            description={`Open Credit Issues ${
              numberFormat(widget.total_open_credit_issues_due_percentage, true).displayFormat
            }% Overdue`}
            label='Current'
          />
        </div>
      </div>
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={numberFormatWithCode(widget.total_sales_amount).displayFormat}
            description='Total Sales'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={numberFormatWithCode(widget.total_approved_discount_amount).displayFormat}
            description={`Total Approved Discounts ${
              numberFormat(widget.total_approved_discount_percentage, true).displayFormat
            }% of Total Sales`}
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='dark'
            title={numberFormatWithCode(widget.total_open_payment_issues_amount).displayFormat}
            description={`Total Payment Issues ${
              numberFormat(widget.total_open_payment_issues).displayFormat
            }`}
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={numberFormat(widget.total_expired_products).displayFormat}
            description={`Total Unsold Expired Products`}
          />
        </div>
      </div>

      {/* business overview */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Business Overview</span>
              </h3>
            </div>
            <div className='border-0 py-3 py-md-2 px-5 px-md-7'>
              {isChartLoading ? (
                <FetchingRecords />
              ) : barCharEmpty.length === 0 ? (
                <NoRecords />
              ) : (
                <EaChartBar {...barChart} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* brands and product overview */}
      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Brands & Products Overview</span>
                <span className='text-muted font-14'>
                  Based on Sales amount in {tenantConfiguration.currencyCode}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              {brandsChartLoading ? (
                <FetchingRecords />
              ) : (
                <div className='row'>
                  {brandPieChart.data.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <>
                      <div className='col-md-4'>
                        <EaChartPie
                          item={brandPieChart}
                          tooltip={toolTip}
                          onClick={(item: any) => {
                            setBrandId(item.data.brand_id)
                            updateSelectedBrands(item)
                          }}
                          isAnimationDisable={true}
                        />
                      </div>
                      <div className='col-md-4'>
                        {productGroupLoading ? (
                          <div
                            style={{
                              marginTop: '50%',
                            }}
                          >
                            <FetchingRecords />
                          </div>
                        ) : (
                          <EaChartPie
                            item={productGroupsChart}
                            onClick={(item: any) => {
                              setProductGroupId(item.data.product_group_id)
                              updateSelectedProductGroups(item)
                            }}
                            tooltip={toolTip}
                            isAnimationDisable={true}
                          />
                        )}
                      </div>
                      <div className='col-md-4'>
                        {productSkuLoading ? (
                          <div
                            style={{
                              marginTop: '50%',
                            }}
                          >
                            <FetchingRecords />
                          </div>
                        ) : (
                          <EaChartPie
                            tooltip={toolTip}
                            item={productSkuChart}
                            isAnimationDisable={true}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        {/* Top 5 Sales Representatives */}
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-5`}>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0 mt-3'>
                  <span className='card-label fw-bold font-17'>Top 5 Sales Representatives</span>
                </h3>
              </div>
              <div>
                <AsyncPaginate
                  loadOptions={fetchSrType}
                  isSearchable
                  key={reset}
                  className='react-select-container my-1 me-3 w-180px'
                  classNamePrefix='react-select'
                  placeholder='Sales'
                  onChange={setSrType}
                  value={srType}
                  isDisabled={srLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            <div className='card-body py-0'>
              <div className='table-responsive'>
                {srLoading ? (
                  <FetchingRecords />
                ) : topSr.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='py-2'>SR Name</th>
                        <th className='min-w-70px py-2'>
                          {srType && srType.value === 'gatepass_issues' ? 'Count' : 'Amount'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSr.length > 0 &&
                        topSr.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <p className='font-14 mb-1'>{item.sr}</p>
                                <p className='font-13 mb-0  text-muted'>
                                  {!isEmpty(item.warehouse_name) ? item.warehouse_name : '-'}
                                </p>
                              </td>
                              <td>
                                {srType && srType.value === 'gatepass_issues'
                                  ? numberFormat(item.value).displayFormat
                                  : numberFormatWithCode(item.value).displayFormat}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Top 5 Customer */}
        <div className='col-xl-6'>
          <div className={`card card-xl-stretch mb-5`}>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <div>
                <h3 className='card-title align-items-start flex-column my-0 mt-3'>
                  <span className='card-label fw-bold font-17'>Top 5 Customers</span>
                </h3>
              </div>
              <div className='d-flex'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                  <input
                    className='form-check-input widget-13-check'
                    name='isHoreca'
                    checked={isHoreca}
                    onChange={(e: any) => {
                      setIsHoreca(!isHoreca)
                    }}
                    type='checkbox'
                    disabled={false}
                    value=''
                    id='isHoreca'
                  />
                  <label className='form-check-label' htmlFor='isHoreca'>
                    HORECA Only
                  </label>
                </div>
                <AsyncPaginate
                  loadOptions={fetchCustomerType}
                  isSearchable
                  key={reset}
                  className='react-select-container my-1 me-3 w-180px'
                  classNamePrefix='react-select'
                  placeholder='Sales'
                  onChange={setCustomerType}
                  value={customerType}
                  isDisabled={customerLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
            <div className='card-body py-0'>
              <div className='table-responsive'>
                {customerLoading ? (
                  <FetchingRecords />
                ) : topCustomer.length === 0 ? (
                  <NoRecords />
                ) : (
                  <table className='table table-row-bordered'>
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='py-2'>Customer Name</th>
                        <th className='min-w-70px py-2'>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topCustomer.length > 0 &&
                        topCustomer.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                <p className='font-14 mb-1'>{item.customer}</p>
                                <p className='font-13 mb-0  text-muted'>
                                  {!isEmpty(item.warehouse_name) ? item.warehouse_name : '-'}
                                </p>
                              </td>
                              <td>{numberFormatWithCode(item.value).displayFormat}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          {/* Top 5 Low Stock */}
          <div className='col-xl-6'>
            <div className={`card card-xl-stretch mb-5`}>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                <div>
                  <h3 className='card-title align-items-start flex-column my-0'>
                    <span className='card-label fw-bold font-17'>Low Stock Alert</span>
                  </h3>
                </div>
                <div className='m-2'>
                  <button
                    onClick={() =>
                      navigate('/reports/low-stock-report', {
                        state: {
                          lowStock: true,
                        },
                      })
                    }
                    className='btn btn-primary'
                  >
                    View All
                  </button>
                </div>
              </div>
              <div className='card-body py-0'>
                <div className='table-responsive'>
                  {fetchingLowStockAlert ? (
                    <FetchingRecords />
                  ) : lowStockAlert.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='py-2'>Product Name</th>
                          <th className='py-2'>Warehouse Name</th>
                          <th className='min-w-70px py-2'>Low Stock at</th>
                          <th className='min-w-70px py-2'>Current Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lowStockAlert.length > 0 &&
                          lowStockAlert.map((item: any) => {
                            return (
                              <tr>
                                <td>
                                  <p className='font-14 mb-1'>
                                    {isEmpty(item.product_name) ? '-' : item.product_name}
                                  </p>
                                  <p className='font-13 mb-0  text-muted'>
                                    {isEmpty(item.brand_name) ? '-' : item.brand_name}
                                  </p>
                                </td>
                                <td>{isEmpty(item.warehouse_name) ? '-' : item.warehouse_name}</td>
                                <td>
                                  {isEmpty(item.alert_count) ? (
                                    '-'
                                  ) : (
                                    <>
                                      {numberFormat(item.alert_count).displayFormat}{' '}
                                      {measurementLabel(
                                        item.fix_size
                                          ? item.material_name
                                          : item.approx_weight > 0
                                          ? item.material_name
                                          : item.measurement_unit
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {isEmpty(item.in_stock) ? (
                                    '-'
                                  ) : (
                                    <>
                                      {numberFormat(item.in_stock).displayFormat}{' '}
                                      {measurementLabel(
                                        item.fix_size
                                          ? item.material_name
                                          : item.approx_weight > 0
                                          ? item.material_name
                                          : item.measurement_unit
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-6'>
            <div className={`card card-xl-stretch mb-5`}>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                <div>
                  <h3 className='card-title align-items-start flex-column my-0'>
                    <span className='card-label fw-bold font-17'>Out Of Stock Alert</span>
                  </h3>
                </div>
                <div className='m-2'>
                  <button
                    onClick={() =>
                      navigate('/reports/low-stock-report', {
                        state: {
                          lowStock: true,
                        },
                      })
                    }
                    className='btn btn-primary'
                  >
                    View All
                  </button>
                </div>
              </div>
              <div className='card-body py-0'>
                <div className='table-responsive'>
                  {fetchingOutOfStockAlert ? (
                    <FetchingRecords />
                  ) : outOfStockAlert.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='py-2'>Product Name</th>
                          <th className='py-2'>Warehouse Name</th>
                          <th className='min-w-70px py-2'>Low Stock at</th>
                        </tr>
                      </thead>
                      <tbody>
                        {outOfStockAlert.length > 0 &&
                          outOfStockAlert.map((item: any) => {
                            return (
                              <tr>
                                <td>
                                  <p className='font-14 mb-1'>
                                    {isEmpty(item.product_name) ? '-' : item.product_name}
                                  </p>
                                  <p className='font-13 mb-0  text-muted'>
                                    {isEmpty(item.brand_name) ? '-' : item.brand_name}
                                  </p>
                                </td>
                                <td>{isEmpty(item.warehouse_name) ? '-' : item.warehouse_name}</td>
                                <td>
                                  {isEmpty(item.alert_count) ? (
                                    '-'
                                  ) : (
                                    <>
                                      {numberFormat(item.alert_count).displayFormat}{' '}
                                      {measurementLabel(
                                        item.fix_size
                                          ? item.material_name
                                          : item.approx_weight > 0
                                          ? item.material_name
                                          : item.measurement_unit
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouseId: state.saDashboard.warehouseId,
    month: state.saDashboard.month,
  }
}

export default connect(mapStateToProps, null)(SADashboard)
