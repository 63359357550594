import { apiBaseURL } from "../../helpers";
import { patchRequest } from "../../https/apiCall";

export const generalKPIsBulkUpdate = async (warehouseId: number, generalKpi: any) => {
    const notify = {
        success: false,
        failed: false,
        errorField: '',
    }

    await patchRequest(
        `${apiBaseURL()}master/kpi/settings/${warehouseId}/generals/update`,
      generalKpi,
        true
    ).then((res) => {
        if (res.status === 200) {
            notify.success = true
        }
        if (res.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = res.data.error.field
        }
    })
    return notify
}

export const commissionDivisionKPIsBulkUpdate = async (warehouseId: number, commissionDivision: any) => {
    const notify = {
        success: false,
        failed: false,
        errorField: '',
    }

    await patchRequest(
        `${apiBaseURL()}master/kpi/settings/${warehouseId}/commission-divisions/update`,
      commissionDivision,
        true
    ).then((res) => {
        if (res.status === 200) {
            notify.success = true
        }
        if (res.statusText === 'Bad Request') {
            notify.failed = true
            notify.errorField = res.data.error.field
        }
    })
    return notify
}
