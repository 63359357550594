import $ from "jquery"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import {
  apiBaseURL,
  checkPermissions,
  defaultPermission,
  KTSVG,
  toAbsoluteUrl
} from "../../../../../_eaFruitsDms/helpers"
import FormatMessage from "../../../../../_eaFruitsDms/helpers/components/FormateMessage"
import { deleteRequest, getRequest } from "../../../../../_eaFruitsDms/https/apiCall"
import { EaAlertSuccess } from "../../../../../_eaFruitsDms/layout/components/alert/eaAlert"
import IsLoader from "../../../../../_eaFruitsDms/layout/components/loader/loader"
import EaModal from "../../../../../_eaFruitsDms/layout/components/modal/eaModal"
import NoRecords from "../../../../../_eaFruitsDms/layout/components/noRecords/noRecords"
import CustomPagination from "../../../../../_eaFruitsDms/layout/components/pagination/pagination"
import CustomSearchBar from "../../../../../_eaFruitsDms/layout/components/searchbar/searchbar"
import EATitle from "../../../../../_eaFruitsDms/layout/components/title/title"
import { PageLink, PageTitle } from "../../../../../_eaFruitsDms/layout/core"
import useDebounce from "../../../../../_eaFruitsDms/helpers/components/useDebounce"

type Props = {
  className: string
}

const BlogPosts: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  // all states
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [count, setCount] = useState('1')
  const [timer, setTimer] = useState<number>(0)
  const [search, setSearch] = useState<any>('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [limit, setLimit] = useState<number>(10)
  const [postsList, setPostsList] = useState([])
  const [messageHandler, setMessageHandler] = useState('')
  const [DeleteId, setDeleteId] = useState<any>('')
  const [permission, setPermission] = useState(defaultPermission)
  const debounceSearch = useDebounce(search, 500);

  const cmsPost: Array<PageLink> = [
    {
      title: 'CMS',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Blog',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    fetchPermission()
  }, [])

  useEffect(() => {
    localStorage.removeItem('postId')
    if (localStorage.getItem('type')) {
      setMessageHandler(`success${localStorage.getItem('type')}`)

      setIsAlertSuccess(true)
      setTimeout(() => {
        setIsAlertSuccess(false)
      }, 3000)

      localStorage.removeItem('type')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => blogPostsList().then(), timer)
  }, [currentOffset, debounceSearch, count, postsList.length])

  const fetchPermission = async () =>{
    let res = await checkPermissions("cms")
    setPermission(res)
   }

  const blogPostsList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}blog/post/list?&limit=${limit}&search=${debounceSearch}&offset=${currentOffset}`
    const response = await getRequest(API, true)

    setPostsList(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset: any = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const numberClick = (val: number, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }
  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSearch(inputValue)
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
  }

  const deleteItem = async (item: any) => {
    setDeleteId(item.id)
  }

  const deletAPI = async () => {
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    await deleteRequest(`${apiBaseURL()}blog/post/delete/${DeleteId}/`, true).then((res) => {
      if (res.status === 204) {
        notify.success = true
      }
      if (res.statusText === 'Bad Request') {
        notify.failed = true
        notify.errorField = res.data.error.field
      }
    })
    if (notify.success) {
      const API = `${apiBaseURL()}blog/post/list?limit=${limit}&ordering=name&search=${search}&offset=${currentOffset}`
      const response = await getRequest(API, true)
      setPostsList(response.results)
      $('#closeDeleteEaModalPost').trigger('click')
      setTimeout(() => {
        setIsAlertFailed(false)
        setIsAlertSuccess(false)
      }, 2000)
      setIsAlertSuccess(true)
    }
    if (notify.failed) {
      // setIsAlertFailed(true)
    }
  }
  return (
    <>
      <EATitle title='Posts - ' />
      <PageTitle breadcrumbs={cmsPost}>{intl.formatMessage({ id: 'Posts' })}</PageTitle>
      <EaAlertSuccess
        onClick={() => isAlertSHow('success')}
        show={isAlertSuccess}
        message={
          messageHandler === 'successAdd'
            ? FormatMessage('CMS_BLOG_POSTS.ADD')
            : messageHandler === 'successEdit'
              ? FormatMessage('CMS_BLOG_POSTS.UPDATE')
              : messageHandler === 'successDelete' && FormatMessage('CMS_BLOG_POSTS.DELETE')
        }
      />

      <div className={`card ${className}`}>
      {listLoadder && <IsLoader />}
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar onChange={handleChangeSearch} />
            {/* {showWrite && */}
            <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
              {permission.write && (
                <button
                  className='btn btn-sm btn-primary my-1'
                  onClick={() => {
                    localStorage.removeItem('postId')
                    navigate('add-post')
                  }}
                >
                  Add Post
                </button>
              )}
            </div>
            {/* } */}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
          {postsList.length == 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                      <thead>
                        <tr className=' text-muted fw-500'>
                          <th className='min-w-100px w-100px py-2'>Image</th>
                          <th className='min-w-200px py-2'>Post Title</th>
                          <th className='min-w-120px py-2'>Author Name</th>
                          <th className='min-w-120px py-2'>Publish Date</th>
                          <th className='min-w-100px py-2'>Categories</th>
                          <th className='min-w-100px py-2'>Status</th>
                          <th className='min-w-80px action-th py-2'>
                            {permission.write || permission.delete ? 'Actions' : ''}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {postsList &&
                          postsList.length > 0 &&
                          postsList.map((item: any) => {
                            return (
                              <>
                                <tr
                                //key={item.id}
                                >
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className='symbol symbol-50px me-2'> */}
                                      <div className='symbol symbol-28px me-2'>
                                        <span className='symbol-label'>
                                          {item.cover_thumbnail == null ? (
                                            <img
                                              alt='group image'
                                              // className='h-50px rounded-1'
                                              className='h-35px rounded-1'
                                              src={toAbsoluteUrl('/media/smiles/img_brand.png')}
                                            />
                                          ) : (
                                            <img
                                              src={item.cover_thumbnail}
                                              className='h-40 rounded-1'
                                              alt=''
                                              style={{ width: '100%', height: '100%' }}
                                            />
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.title}</td>
                                  <td>{item.author ? item.author.name : '-'}</td>
                                  <td>
                                    {item.length !== 0 && item.is_published == true
                                      ? moment(item.published_date, 'YYYY-MM-DD-h:m:s').format(
                                        'DD MMM YYYY'
                                      )
                                      : '-'}
                                  </td>
                                  <td>
                                    {item.category.length !== 0 ? (
                                      <>
                                        {item.category.map((item: any, index: any) => {
                                          return (
                                            <>
                                              {index ? ',' : ''}
                                              {item.title}
                                            </>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {item.is_published == true ? (
                                      <>
                                        <span className='badge badge-light-success text-dark'>
                                          {/* Published */}
                                          Acive
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className='badge badge-light-danger text-dark'>
                                          {/* Draft */}
                                          Inactive
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td className='text-end'>
                                    {permission.write && (
                                      <a
                                        onClick={() => {
                                          localStorage.setItem('postId', item.id)
                                          navigate('add-post')
                                        }}
                                        className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/art/art005.svg'
                                          className='ic mr-0'
                                        />
                                      </a>
                                    )}

                                    {/* {permission.delete && <span
                                      data-bs-target='#ea_modal_delete_posts'
                                      data-bs-toggle='modal'
                                      onClick={(event) => {
                                        deleteItem(item)
                                        setMessageHandler('successDelete')
                                      }}
                                      className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr011.svg'
                                        className='ic mr-0'
                                      />
                                    </span>} */}
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
          </div>
        </div>
      </div>
      <div className='table-info py-5'>
        <div className='row g-0'>
          {/* <div className='col'>
            {`Showing ${postsList.length == 0 ? 0 : selected * 10 + 1} to ${
              postsList.length == 0 ? 0 : postsList.length < 10 ? count : (selected + 1) * 10
            } of ${postsList.length == 0 ? 0 : count} entries`}
          </div> */}
          <div className='col'>
            {`Showing ${postsList.length == 0 ? 0 : selected * limit + 1} to ${postsList.length == 0 ? 0 : postsList.length < 10 ? count : (selected + 1) * 10
              } of ${postsList.length == 0 ? 0 : count} entries`}
          </div>
          <div className='col'>
            <CustomPagination
              prev={prevPage}
              next={nextPage}
              count={count}
              selected={selected}
              numberClick={numberClick}
            />
          </div>
        </div>
      </div>
      <EaModal
        modalTitle='Delete Posts'
        closeAuto='closeDeleteEaModalPost'
        children={<p className='mb-0'>Are you sure you want to delete this post?</p>}
        id={'ea_modal_delete_posts'}
        actionBtnName='Yes, Proceed'
        onSubmit={deletAPI}
      />
    </>
  )
}

export default BlogPosts
