import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Props} from 'react-select'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import tenantConfiguration from '../../../TenantVariables'
import {apiBaseURL, dateFormat, isEmpty, toAbsoluteUrl} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

const NoOrderingCustomerReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Customer No-Ordering Report',
    subTitle: 'Customer No-Ordering Report',
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [reports, setReports] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouseId, setWarehouseId] = useState<any>({label: 'All Warehouses', value: ''})
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().weekday(0).format('YYYY-MM-DD'),
    endDate: moment().weekday(6).format('YYYY-MM-DD'),
  })
  const [isHoreca, setIsHoreca] = useState(false)

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    localStorage.removeItem('start_date')
    localStorage.removeItem('end_date')
    localStorage.removeItem('isHoreca')
    localStorage.removeItem('warehouseId' + '')
    localStorage.removeItem('search')
  }, [])

  useEffect(() => {
    fetchingReports()
  }, [currentOffset, debounceSearch, warehouseId, apiDate, isHoreca])

  const fetchingReports = async () => {
    setDisplayLoader(true)

    localStorage.setItem('start_date', apiDate.startDate)
    localStorage.setItem('end_date', apiDate.endDate)
    localStorage.setItem('isHoreca', isHoreca ? 'true' : '')
    localStorage.setItem('warehouseId', warehouseId.value)
    localStorage.setItem('search', searchByName)

    const url = `${apiBaseURL()}reports/no-ordering-customers?search=${searchByName}&warehouse_id=${
      warehouseId.value
    }&start_date=${apiDate.startDate}&end_date=${
      apiDate.endDate
    }&limit=${limit}&offset=${currentOffset}&ordering=-last_ordered_date&is_horeca_customer=${
      isHoreca ? true : ''
    }`

    const response = await getRequest(url, true)

    if (!isEmpty(response.results)) {
      setReports(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        localStorage.setItem('warehouseId', e.value)
        break
      case 'search':
        setSearchByName(inputValue)
        break
      default:
        setSearchByName(inputValue)
        break
    }
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Customer No-Ordering Report' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Customer No-Ordering Report',
        })}
      </PageTitle>
      <div className={`card ${className}`}>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                <input
                  className='form-check-input widget-13-check'
                  name='isHoreca'
                  checked={isHoreca}
                  onChange={(e: any) => {
                    setIsHoreca(!isHoreca)
                    handleFilterChange(e, 'isHoreca')
                  }}
                  type='checkbox'
                  disabled={false}
                  value=''
                  id='isHoreca'
                />
                <label className='form-check-label' htmlFor='isHoreca'>
                  HORECA Only
                </label>
              </div>

              <CustomDatePickerComponent
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate({
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  })
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                value={apiDate}
                startDate={apiDate.startDate}
                endDate={apiDate.endDate}
              />
              <WarehouseFilter
                warehouse={warehouseId}
                handleFilterChange={(e: any) => handleFilterChange(e, 'warehouse')}
                isDisabled={displayLoader}
              />
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {displayLoader ? (
              <FetchingRecords />
            ) : reports.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='w-30px'>ID</th>
                    <th className='w-100px'>Name</th>
                    <th className='w-100px'>Category</th>
                    <th className='w-100px'>Warehouse Name</th>
                    <th className='w-100px'>Type</th>
                    <th className='w-100px'>Credit Range</th>
                    <th className='w-100px'>Last Ordered Date</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {reports.map((reports: any) => {
                    return (
                      <tr className='cursor-pointer'>
                        <td>#{reports.id}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span
                              className='rounded-circle pro-icon me-2'
                              data-bs-toggle='tooltip'
                              title='Mark Larson'
                            >
                              {reports.logo_thumbnail === null ? (
                                <img
                                  src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                  alt=''
                                  className='rounded-circle pro-icon'
                                />
                              ) : (
                                <img
                                  src={reports.logo_thumbnail}
                                  alt=''
                                  className='rounded-circle pro-icon'
                                />
                              )}
                            </span>
                            <div className='media-body'>
                              <p className='m-0 xs-font'>{reports.business_name}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          {
                            <div>
                              <div className='text-muted font-13 mb-1 font-medium text-capitalize'>
                                {isEmpty(reports.nature_of_business)
                                  ? '-'
                                  : reports.nature_of_business}
                              </div>
                              {isEmpty(reports.category_name) ? '-' : reports.category_name}
                            </div>
                          }
                        </td>
                        <td>{isEmpty(reports.warehouse_name) ? '-' : reports.warehouse_name}</td>
                        <td className='text-capitalize'>
                          {isEmpty(reports.type) ? '-' : reports.type}
                        </td>
                        <td>{isEmpty(reports.credit_range) ? '-' : reports.credit_range}</td>
                        <td>
                          {isEmpty(reports.last_ordered_date)
                            ? '-'
                            : dateFormat(reports.last_ordered_date).displayDate}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={reports}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default NoOrderingCustomerReport
