import clsx from "clsx";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  commissionDivisionKPIsBulkUpdate
} from "../../../_eaFruitsDms/apiFunctions/targetKPIsSettings/targetKPIsSettings";
import { apiBaseURL, checkPermissions, defaultPermission, isEmpty } from "../../../_eaFruitsDms/helpers";
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../_eaFruitsDms/layout/components/button/eaButton";
import ErrorHandler from "../../../_eaFruitsDms/layout/components/errorHandler/errorHandler";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import EaModal from "../../../_eaFruitsDms/layout/components/modal/eaModal";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import { PageLink, PageTitle } from "../../../_eaFruitsDms/layout/core";
import { getW_Id } from "../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import { getRequest } from "../../../_eaFruitsDms/https/apiCall";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";

type Props = {
  className: string
}
const CommissionDivision: React.FC<Props> = ({ className }) => {
  const intl = useIntl();
  const kpiBreadCrumbs: Array<PageLink> = [
    {
      title: "Commission Divisions %",
      path: "target-kpi-settings/commission-divisions",
      isSeparator: false,
      isActive: false
    }
  ];
  const [validation, setValidation] = useState<any>([]);
  const [commissionList, setCommissionList] = useState<any>([]);
  const [percentValue, setPercentValue] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingEdit, setIsloadingEdit] = useState("Submit");
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false);
  const [isAlertFailed, setIsAlertFailed] = useState(false);
  const [listLoader, setListLoader] = useState<any>(true);
  const [messageHandler, setMessageHandler] = useState("successEdit");
  const [permission, setPermission] = useState(defaultPermission);

  useEffect(() => {
    fetchPermission().then();
    fetchCommissionKpi().then();
  }, []);

  const fetchCommissionKpi = async () => {
    setListLoader(true);
    const response = await getRequest(`${apiBaseURL()}master/kpi/settings/${getW_Id}/commission-divisions`, true);

    if (response && response.result) {
      const percentValueList: any = [];
      response.result.map((result: any) => {
        percentValueList.push({
          kpi_parameter_id: result.kpi_parameter_id,
          commission_value: result.commission_value
        });
      });
      setPercentValue(percentValueList);
      setCommissionList(response.result);
    }
    setListLoader(false);
  };

  const fetchPermission = async () => {
    let res = await checkPermissions("sales_reps_target");
    setPermission(res);
  };

  // Develop BY: Pinak
  // Description: OnChange Handler and Validate data

  const kpiValueChange = (e: any) => {
    const { value, id } = e.target

    const valid = /^(\d*\.)?\d+$/
    let isValid = true
    if (value !== '' && valid.test(value)) {
      const arr = value.split('.')
      if (arr.length == 2) {
        if (arr[1].length > 2) {
          isValid = false
        }
      }
    }

    if (isValid) {
      const updatedPercentageValue: any = [...percentValue]

      const findIndex = percentValue.findIndex((option: any) => option.kpi_parameter_id == parseInt(id))

      if (findIndex > -1) {
        updatedPercentageValue[findIndex].commission_value = parseFloat(value)
      }

      setPercentValue(updatedPercentageValue)

      if (value.trim() != '' && value <= 100) {
        setValidation((preValue: any) => ({ ...preValue, [id]: '' }))
      } else {
        setValidation((preValue: any) => ({ ...preValue, [id]: 'is-invalid' }))
      }
    }
  }

  const editHandler = async () => {
    setMessageHandler("successEdit");
    fetchCommissionKpi().then()
  };
  const clearFunction = () => {
    setPercentValue([]);
    setErrorMessage("");
    setValidation([]);
  };

  // Develop BY: Kriti
  // Description: bulk update put api called function
  const updateApiCall = () => {
    setIsloadingEdit("Please Wait..");
    commissionDivisionKPIsBulkUpdate(getW_Id, percentValue).then((notify) => {
      setIsloadingEdit("Submit");
      if (notify.success) {
        clearFunction();
        fetchCommissionKpi()
        $("#closeEditEaModalBrand").trigger("click");
        setTimeout(() => {
          setIsAlertSuccess(false);
          setIsAlertFailed(false);
        }, 2000);
        setIsAlertSuccess(true);
      }
      // if (notify.errorField == 'non_field_errors') {
      //   setErrorMessage('The Total percentage isnt 100%. Please match 100%')
      // }
    });
  };

  // Develop BY: Kriti
  // Description: bulk update function which is called on submit button of EAmodel

  const updateBulkCommissionDivsion = () => {
    // let navigateField = {
    //   new_customer: percentValue.new_customer,
    //   return_rate: percentValue.return_rate,
    //   successful_calls: percentValue.successful_calls,
    //   no_credit: percentValue.no_credit,
    //   pre_order: percentValue.pre_order,
    //   un_credit: percentValue.un_credit,
    //   un_disc: percentValue.un_disc,
    //   disc: percentValue.disc,
    //   attendance: percentValue.attendance
    // };
    //
    // isModalValidation(navigateField).length !== 0 &&
    // focusVatidateField(isModalValidation(navigateField)[0]);

    let sum: number = 0
    let isValid = true

    percentValue.map((value: any, index: number) => {
      if ((isEmpty(value.commission_value)) || parseFloat(value.commission_value) > 100) {
        isValid = false
        return false
      } else {
        sum += parseFloat(value.commission_value)
      }

      return value
    })

    if (sum != 100) {
      setErrorMessage("The sum of the percentages does not reach 100%. Please make sure the values add up to a total of 100%.");
      return false;
    } else {
      if (isValid) {
        updateApiCall()
      }
    }
  };
  const isAlertSHow = (val: any) => {
    if (val == "success") {
      setIsAlertSuccess(false);
    }
    if (val == "failed") {
      setIsAlertFailed(false);
    }
  };

  const kpiValue = (kpiId: number) => {
    const findIndex = percentValue && percentValue.findIndex((option: any) => option.kpi_parameter_id === kpiId);

    if (findIndex > -1) {
      if (percentValue[findIndex].commission_value > 0) {
        return percentValue[findIndex].commission_value;
      }
    }

    return "";
  };

  return (
    <>
      <EATitle title="Commission Divisions %" />
      <div>
        <PageTitle breadcrumbs={kpiBreadCrumbs}>
          {intl.formatMessage({ id: "Commission Divisions %" })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow("success")}
          show={IsAlertSuccess}
          message="Your Commission Divisions % has been updated successfully."
        />
        <EaAlertDanger
          onClick={() => isAlertSHow("failed")}
          show={isAlertFailed}
          message="failed"
        />
        <div className={`card ${className}`}>
          {
            listLoader && <IsLoader />
          }
          {/* begin::Header */}
          <div className="card-header border-0 px-7">
            <div className="card-toolbar row g-0 w-100">
              <div className="col-12 col-md-auto ms-auto mt-3 mt-md-0">
                {permission.write &&
                  <EaButton
                    isModal={true}
                    dataTarget="#ea_modal_edit_commision"
                    btnName="Edit"
                    onClick={editHandler}
                  />}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className="card-body py-4">
            <div className="table-responsive">
              {
                commissionList.length === 0 ? <NoRecords />
                  :
                  <table className="table table-row-bordered align-middle gs-0 gy-3 mb-0">
                    {/* begin::Table head */}
                    <thead>
                      <tr className=" text-muted fw-500">
                        <th className="w-150px py-2">KPI</th>
                        <th className="min-w-100px py-2">Commission Divisions %</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {
                        commissionList.map((list: any) => {
                          return <tr>
                            <td>{list.kpi_parameter_display_name}</td>
                            <td>{list.commission_value}%</td>
                          </tr>;
                        })
                      }
                    </tbody>
                  </table>
              }
            </div>
          </div>
          {/* begin::Body */}
        </div>
      </div>
      <EaModal
        id="ea_modal_edit_commision"
        modalTitle="Update Commission Division %"
        onSubmit={updateBulkCommissionDivsion}
        closeAuto="closeEditEaModalBrand"
        cancel={clearFunction}
        actionBtnName={isLoadingEdit}
      >
        <div>
          <p>Total of the commission divisions between these KPIs must be always 100%.</p>
          {
            commissionList.length === 0 ?
              <NoRecords />
              :
              // <table className='table table-borderless gy-1 mb-3'>
              //   <tr>
              //     <td className='w-50'>New Customers</td>
              //     <td>
              //       <div className='p-1'>
              //         <div
              //           className={clsx(
              //             'input-group  p-0',
              //             validation.new_customer === 'is-invalid' ? 'is-invalid-grp' : ''
              //           )}
              //         >
              //           <input
              //             type='number'
              //             id='new_customer'
              //             onChange={kpiValueChange}
              //             value={percentValue.kpiValue}
              //             placeholder={percentValue.new_customer}
              //             name='new_customer'
              //             className={clsx('form-control border-start-0', validation.new_customer)}
              //           />
              //           <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
              //             %
              //           </span>
              //         </div>
              //       </div>
              //     </td>
              //   </tr>
              //
              // </table>

              commissionList.map((list: any) => {
                return <tr>
                  <td className="w-50">{list.kpi_parameter_display_name}</td>
                  <td>
                    <div className="p-1">
                      <div
                        className={clsx(
                          "input-group  p-0",
                          !isEmpty(validation[list.kpi_parameter_id]) && validation[list.kpi_parameter_id] === "is-invalid" && "is-invalid-grp"
                        )}
                      >
                        <input
                          type="number"
                          id={list.kpi_parameter_id.toString()}
                          onChange={kpiValueChange}
                          placeholder="0"
                          value={kpiValue(list.kpi_parameter_id)}
                          name="no_credit"
                          className={clsx("form-control border-start-0", !isEmpty(validation[list.kpi_parameter_id]) && validation[list.kpi_parameter_id])}
                        />
                        <span className="input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0">
                          %
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>;
              })
          }

          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </EaModal>
    </>
  );
};

export default CommissionDivision;
