import React from "react";

const CustomPagination = (props: any) => {
    if (props.newProp) {
        const { pageState, setPageState } = props;

        let totalCount: any = Math.ceil(pageState.count / (props.limit ? props.limit : 10));
        let numberCount: any = [];

        for (let index = 1; index <= totalCount; index++) {
            numberCount.push(index)
        }

        const numberClick = (val: any, index: any) => {
            if (val) {
                let newOffset = index * 10;

                setPageState((preValue: any) => ({
                    ...preValue, selected: val - 1, currentOffset: newOffset
                }))
            }
        }

        const previous = () => {
            if (pageState.previous !== null) {
                const queryParams = new URLSearchParams(pageState.prev);
                const newOffset = queryParams.get('offset');

                setPageState((preValue: any) => ({
                    ...preValue, selected: pageState.selected - 1, currentOffset: newOffset
                }))
            }
        }

        const nextPage = () => {
            if (pageState.next !== null) {
                const queryParams = new URLSearchParams(pageState.next);
                const newOffset = queryParams.get('offset');

                setPageState((preValue: any) => ({
                    ...preValue, selected: pageState.selected + 1, currentOffset: newOffset
                }))
            }
        }

        return (
            <>
                <ul className="pagination justify-content-md-end">
                    <li className="page-item previous" onClick={previous}>
                        <a className="page-link cursor-pointer">
                            <i className="previous"></i>
                        </a>
                    </li>
                    {numberCount.map((item: any, index: any) => (
                        <li className={`page-item offset  ${index === pageState.selected ? 'active' : ''}`} key={item} onClick={() => numberClick(item, index)}>
                            <a className="page-link cursor-pointer" id='paginationValue'>
                                {item}
                            </a>
                        </li>
                    ))}
                    <li className="page-item next" onClick={nextPage}>
                        <a className="page-link cursor-pointer">
                            <i className="next"></i>
                        </a>
                    </li>
                </ul>
            </>
        )
    } else {
        let count: any = Math.ceil(props.count / (props.limit ? props.limit : 10));
        let numberCount: any = [];
        for (let index = 1; index <= count; index++) {
            numberCount.push(index)
        }

        const numberClick = (val: any, index: any) => {
            props.numberClick(val, index)
        }

        return (
            <>
                <ul className="pagination justify-content-md-end">
                    <li className="page-item previous" onClick={props.prev}>
                        <a href="#" className="page-link">
                            <i className="previous"></i>
                        </a>
                    </li>
                    {numberCount.map((item: any, index: any) => (
                        <li className={`page-item offset  ${index === props.selected ? 'active' : ''}`} key={item} onClick={() => numberClick(item, index)}>
                            <a href="#" className="page-link" id='paginationValue'>
                                {item}
                            </a>
                        </li>
                    ))}
                    <li className="page-item next" onClick={props.next}>
                        <a href="#" className="page-link">
                            <i className="next"></i>
                        </a>
                    </li>
                </ul>
            </>
        )
    }
}

export default CustomPagination
