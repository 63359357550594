import { LOGIN_EAFRUITS_FAILS, LOGIN_EAFRUITS_IS_LOADING, LOGIN_EAFRUITS_SUCCESS } from "../../types/types"

type state = {
    loginResponse?: string,
    isLoading?: boolean | string
    errorMessage?: string
    isLogin?: boolean
}

const initialState: state = {
    loginResponse: '',
    isLoading: '',
    errorMessage: '',
    isLogin: false,
}

export const LoginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_EAFRUITS_IS_LOADING:
            return {
                loginResponse: '',
                isLoading: true,
            }
        case LOGIN_EAFRUITS_SUCCESS:
            return {
                loginResponse: action.payload,
                isLoading: false,
                isLogin: true
            }
        case LOGIN_EAFRUITS_FAILS:
            return {
                loginResponse: '',
                isLoading: false,
                errorMessage: action.payload,
                isLogin: false
            }
        default:
            return {
                ...state
            }
    }
}
