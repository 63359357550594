import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {EaChartBar} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../../TenantVariables'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AsyncPaginate} from 'react-select-async-paginate'

type Props = {
  className: string
}

const WMVehicalReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = [
    {
      title: 'Reports',
    },
  ]
  const getUserType = localStorage.getItem('user_type') || ''
  const getW_Id: any = localStorage.getItem('w_id')
  const wId = getUserType.toLowerCase() !== 'sa' ? getW_Id : ''
  const baseUrl = apiBaseURL()

  const [usageBarChart, setUsageBarChart] = useState({
    xAxisData: [],
    seriesData: [],
    yAxisZoom: false,
    xAxisZoom: true,
  })
  const [brandUsageBarChart, setBrandUsageBarChart] = useState({
    xAxisData: [],
    seriesData: [],
    yAxisZoom: false,
    xAxisZoom: true,
  })
  const [isBrandChartLoader, setIsBrandChartLoading] = useState(true)
  const [isBrandLoader, setIsChartLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [vehicle, setVehicle] = useState([])
  const [isListLoading, setListLoading] = useState(false)
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [count, setCount] = useState<string>('1')
  const [vehicleTypeOffset, setVehicleTypeOffset] = useState('')
  const [vehicleList, setVehicleList] = useState([])
  const [vehicleSearch, setVehicleSearch] = useState('')
  const [vehicleMakeOffset, setVehicleMakeOffset] = useState('')
  const [vehicleMakeList, setVehicleMakeList] = useState([])
  const [vehicleMakeSearch, setVehicleMakeSearch] = useState('')
  const [brandsOffset, setBrandsOffset] = useState('')
  const [brandsList, setBrandsList] = useState([])
  const [brandsSearch, setBrandsSearch] = useState('')
  const [vehicleUsageDate, setVehicleUsageDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [brandUsageDate, setBrandUsageDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [listDate, setListDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [vehicleType, setVehicleType] = useState<any>('')
  const [vehicleMake, setVehicleMake] = useState<any>('')
  const [brands, setBrands] = useState<any>('')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [warehouseValueReport, setWarehouseValueReport] = useState<any>({
    label: 'All Warehouse',
    value: '',
  })

  const debounceSearch = useDebounce(search, 500)

  // UI 1 chart
  const fetchUsageOverBarChart = async () => {
    setIsChartLoading(true)
    const param = `start_date=${vehicleUsageDate.startDate}&end_date=${vehicleUsageDate.endDate}&warehouse_id=${wId}`
    const url = baseUrl + 'reports/lm-lo-dashboard/vehicle-trips/graph?' + param
    const response = await getRequest(url, true)

    if (response && response.result) {
      let xAxisData: any = []
      let seriesData: any = []

      response.result.map((item: any) => {
        if (item.total_trips !== 0) {
          xAxisData.push(item.vehicle_number)
          seriesData.push({value: item.total_trips})
        }
      })

      let barChartUsage = {...usageBarChart}
      barChartUsage.seriesData = seriesData
      barChartUsage.xAxisData = xAxisData

      setUsageBarChart(barChartUsage)
    }
    setIsChartLoading(false)
  }

  // UI 2 chart
  const fetchBrandUsageOverBarChart = async () => {
    setIsBrandChartLoading(true)
    const param = `start_date=${brandUsageDate.startDate}&end_date=${brandUsageDate.endDate}&warehouse_id=${wId}`
    const url = baseUrl + 'reports/lm-lo-dashboard/vehicle-brand-trips/graph?' + param
    const response = await getRequest(url, true)

    if (response && response.result) {
      let xAxisData: any = []
      let seriesData: any = []

      response.result.map((item: any, index: any) => {
        if (item.total_trips !== 0) {
          xAxisData.push({value: item.vehicle_brand_name, itemStyle: {color: '#a900c1'}})
          seriesData.push({value: item.total_trips})
        }
      })

      let brandData = {...brandUsageBarChart}
      brandData.seriesData = seriesData
      brandData.xAxisData = xAxisData

      setBrandUsageBarChart(brandData)
    }

    setTimeout(() => {
      setIsBrandChartLoading(false)
    }, 300)
  }

  const fetchVehicleList = async () => {
    setListLoading(true)
    const param = `limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&start_date=${
      listDate.startDate
    }&end_date=${listDate.endDate}&warehouse_id=${warehouseValueReport.value}&vehicle_type=${
      vehicleType !== '' ? vehicleType.value : ''
    }&make=${vehicleMake !== '' ? vehicleMake.value : ''}&brand=${
      brands !== '' ? brands.value : ''
    }`
    const url = baseUrl + 'reports/lm-lo-dashboard/vehicle-trips/list?' + param
    const response = await getRequest(url, true)

    if (response && response.results && response.results.length > 0) {
      setVehicle(response.results)
      setCount(response.count)
    } else {
      setVehicle([])
      setCount('1')
    }

    setListLoading(false)
  }

  useEffect(() => {
    fetchUsageOverBarChart()
  }, [vehicleUsageDate])

  useEffect(() => {
    fetchVehicleList()
  }, [
    debounceSearch,
    currentOffset,
    listDate,
    vehicleType,
    vehicleMake,
    brands,
    warehouseValueReport,
  ])

  useEffect(() => {
    fetchBrandUsageOverBarChart()
  }, [brandUsageDate])

  const findCoverVehicleImage = (item: any) => {
    if (item.vehicle_images && item.vehicle_images.length > 0) {
      let image = item.vehicle_images

      if (image && image.length > 0) {
        let findCoverImage = image.find((item: any) => item.is_cover === true)

        if (findCoverImage && findCoverImage.vehicle_thumbnail) {
          return findCoverImage.vehicle_thumbnail
        }
      }
    }

    return toAbsoluteUrl('/media/smiles/img_brand.png')
  }

  const showBrand = (item: any) => {
    if (item.products_brand && item.products_brand.length > 0) {
      const length = item.products_brand.length

      if (length > 1) {
        return (
          <OverlayTrigger
            delay={{hide: 450, show: 300}}
            overlay={(props) => (
              <Tooltip {...props} className='tooltip-dark'>
                {item.products_brand &&
                  item.products_brand.map((item: any, index: any) => {
                    return (
                      <>
                        <span>
                          {index ? ',' : ''} {item.name}{' '}
                        </span>
                      </>
                    )
                  })}
              </Tooltip>
            )}
            placement='top'
          >
            <span className='ms-2 cursor-pointer'>
              {item.products_brand[0].name}
              {` & ${length} more`}
            </span>
          </OverlayTrigger>
        )
      } else {
        return <span className='ms-2 cursor-pointer'>{item.products_brand[0].name}</span>
      }
    }
  }

  // fetch chart Warehouse Dropdown
  const fetchWarehouse = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `warehouse/list?parent_warehouse__id=${wId}&ordering=name`,
      search,
      setDropDownSearch,
      'name',
      true,
      'All Warehouse'
    )
  }

  const fetchVehicle = async (search: any) => {
    return fetchAsyncDropdown(
      vehicleTypeOffset,
      setVehicleTypeOffset,
      vehicleList,
      setVehicleList,
      `master/vechile-type/?ordering=vechile_type`,
      search,
      setVehicleSearch,
      'vechile_type',
      true,
      'All Vehicle'
    )
  }

  const fetchVehicleMake = async (search: any) => {
    return fetchAsyncDropdown(
      vehicleMakeOffset,
      setVehicleMakeOffset,
      vehicleMakeList,
      setVehicleMakeList,
      `master/vechile-make/?ordering=vechile_make`,
      search,
      setVehicleMakeSearch,
      'vechile_make',
      true,
      'All Make'
    )
  }

  const fetchBrands = async (search: any) => {
    return fetchAsyncDropdown(
      brandsOffset,
      setBrandsOffset,
      brandsList,
      setBrandsList,
      `master/product/brand?ordering=name`,
      search,
      setBrandsSearch,
      'name',
      true,
      'All Brands'
    )
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Vehicle Usage Report - ' />
      <PageTitle breadcrumbs={reportHeaderTitle}>
        {intl.formatMessage({id: 'Vehicle Usage Report'})}
      </PageTitle>
      <div className='card mb-5'>
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Vehicle Usage Overview</span>
          </h3>
          <div className='card-toolbar d-flex g-3 my-0'>
            <CustomDatePickerComponent
              onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                setVehicleUsageDate({
                  startDate: callBakeDate.startDate,
                  endDate: callBakeDate.endDate,
                })
              }}
              value={vehicleUsageDate}
              startDate={vehicleUsageDate.startDate}
              endDate={vehicleUsageDate.endDate}
            />
          </div>
        </div>
        <div className='card-body'>
          {isBrandLoader && <IsLoader />}
          {usageBarChart.seriesData.length > 0 ? <EaChartBar {...usageBarChart} /> : <NoRecords />}
        </div>
      </div>
      <div className='card mb-5'>
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Brand wise Vehicle Usage Overview</span>
          </h3>
          <div className='card-toolbar d-flex g-3 my-0'>
            <CustomDatePickerComponent
              onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                setBrandUsageDate({
                  startDate: callBakeDate.startDate,
                  endDate: callBakeDate.endDate,
                })
              }}
              value={brandUsageDate}
              startDate={brandUsageDate.startDate}
              endDate={brandUsageDate.endDate}
            />
          </div>
          {/* end::Toolbar */}
        </div>
        <div className='card-body'>
          {isBrandChartLoader && <IsLoader />}
          {brandUsageBarChart.seriesData.length > 0 ? (
            <EaChartBar {...brandUsageBarChart} />
          ) : (
            <NoRecords />
          )}
        </div>
      </div>
      <div>
        <div className='card'>
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              value={search}
              isDisable={isListLoading}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                {checkUserType(['wm', 'sa']) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouse}
                    isSearchable
                    placeholder='All Warehouses'
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    name='select_team'
                    onChange={(e) => {
                      setWarehouseValueReport(e)
                    }}
                    value={warehouseValueReport}
                    isDisabled={isListLoading}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <AsyncPaginate
                  loadOptions={fetchVehicle}
                  isSearchable
                  placeholder='All Vehicle'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setVehicleType(e)
                  }}
                  value={vehicleType}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                {/* DO NOR REMOVE - KASHYAP TRIVEDI */}
                {/* <AsyncPaginate
                                    loadOptions={fetchVehicleMake}
                                    isSearchable
                                    placeholder="All Make"
                                    className='react-select-container my-1 me-3 w-160px'
                                    classNamePrefix="react-select"
                                    name='select_team'
                                    onChange={(e) => {
                                        setVehicleMake(e)
                                    }}
                                    value={vehicleMake}
                                    isDisabled={isListLoading}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                /> */}
                <AsyncPaginate
                  loadOptions={fetchBrands}
                  isSearchable
                  placeholder='All Brands'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e) => {
                    setBrands(e)
                  }}
                  value={brands}
                  isDisabled={isListLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setListDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={listDate}
                  startDate={listDate.startDate}
                  endDate={listDate.endDate}
                />
                {/* <label className="form-label mb-0"><button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                    <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                    Export</button>
                                </label> */}
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {isListLoading && <IsLoader />}
              {vehicle.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-150px'>Gatepass ID</th>
                      <th className='min-w-150px'>Date</th>
                      <th className='min-w-150px'>Vehicle Number</th>
                      <th className='min-w-140px'>Vehicle Type</th>
                      <th className='min-w-120px'>Capacity</th>
                      {/* <th className='min-w-120px'>Load</th> */}
                      <th className='min-w-150px'>SR Name</th>
                      <th className='min-w-130px'>Brand</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicle.map((item: any) => {
                      return (
                        <tr>
                          <td>
                            {item.gatepass_id && item.gatepass_id !== ''
                              ? `#${item.gatepass_id}`
                              : '-'}
                          </td>
                          <td>
                            {item.trip_date && item.trip_date !== ''
                              ? dateFormat(item.trip_date).displayDate
                              : '-'}
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='me-3'>
                                <span className='symbol-label'>
                                  <img
                                    alt=''
                                    className='h-30px rounded-1'
                                    src={findCoverVehicleImage(item)}
                                  />
                                </span>
                              </div>
                              <span>{item.number && item.number !== '' ? item.number : '-'}</span>
                            </div>
                          </td>
                          <td>{item.type && item.type !== '' ? item.type : '-'}</td>
                          <td>
                            {item.capacity && item.capacity !== '' ? `${item.capacity} KG` : '-'}
                          </td>
                          {/* <td className='text-danger'>-</td> */}
                          <td>{item.sr_name && item.sr_name !== '' ? item.sr_name : '-'}</td>
                          <td>
                            {item.brand_name && item.brand_name !== '' ? item.brand_name : '-'}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={vehicle}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={isListLoading}
          saveFilterName={'feedbackReport'}
          filterStates={''}
        />
      </div>
      {/* <Addwarehouse /> */}
    </>
  )
}

export default WMVehicalReport
