import clsx from 'clsx'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {getMyProfile} from '../../../apiFunctions/master/master'
import {apiBaseURL, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getRequest} from '../../../https/apiCall'
import {getUserId, userLoginDetails} from '../../../localStorage/getItem/getLocalStorage'
import {getImpersonationUserDetails} from '../../../localStorage/setItem/setLocalStorage'
import {QuickLinks} from '../../../partials'
import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu'
import EaFruitsLogout from '../../../redux/action/logout/logoutAction'
import {manageProfile} from '../../../redux/action/profile/manageProfile'
import {useLayout} from '../../core'

const toolbarButtonMarginClass = 'ms-2 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'ic'

const Topbar = (props: any) => {
  const {config} = useLayout()
  const navigate = useNavigate()

  // note : isImpersonationLogin key is checking user login as a imperson or not. EX:isImpersonationLogin === 'true'
  // note :  impersonationId and impersonationToken this two key return imperson id and token
  const isImpersonationLogin = localStorage.getItem('isImpersonationLogin')
  const impersonationId = localStorage.getItem('impersonation-id')
  const impersonationToken = localStorage.getItem('impersonation-token')
  const splashScreen = document.getElementById('splashScreenStyle')

  let userDetails: any

  if (userLoginDetails) {
    userDetails = JSON.parse(userLoginDetails || '')
  } else {
    EaFruitsLogout()
  }

  const img = process.env.PUBLIC_URL + '/media/avatars/img_user.png'

  useEffect(() => {
    getMyProfile(getUserId)
  }, [])

  useEffect(() => {
    if (userLoginDetails) {
      props.manageProfile(userDetails)
    }
  }, [userLoginDetails])

  const {image} = props.profile

  const handleImpersonationLogout = async () => {
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }

    const response = await getRequest(`${apiBaseURL()}auth/logout?user_id=${impersonationId}`, true)

    if (response.first_name) {
      let impersonationUser = {
        role: response.role,
        warehouse: response.warehouse,
        warehouse_name: response.warehouse_name,
        first_name: response.first_name,
        last_name: response.last_name,
        profile_thumbnail: response.profile_thumbnail,
        base_url: response.base_url,
        user_type: response.type,
        id: response.id,
        token: impersonationToken,
        email: response.email,
        role_name: response.role_name,
      }

      // reset the value of imperson user
      localStorage.setItem('isImpersonationLogin', 'false')
      getImpersonationUserDetails(impersonationUser)

      // back to imperson panel
      navigate('/dashboard')
      window.location.reload()
    } else {
      if (splashScreen) {
        splashScreen.style.setProperty('display', 'none')
      }
    }
  }

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          {/* <Search /> */}
        </div>
        {/* Activities */}
        <div className={clsx('d-flex align-items-center d-none', toolbarButtonMarginClass)}>
          {/* begin::Drawer toggle */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          {/* end::Drawer toggle */}
        </div>
        {/* product & search */}
        {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
        {/*  <div className="input-group top-search">*/}
        {/*    <button className="btn btn-outline border-end dropdown-toggle btn-sm text-start w-150px" type="button" data-bs-toggle="dropdown" aria-expanded="false">Products</button>*/}
        {/*    <ul className="dropdown-menu">*/}
        {/*      <li><a className="dropdown-item py-2" href="#">Option 1</a></li>*/}
        {/*      <li><a className="dropdown-item py-2" href="#">Option 2</a></li>*/}
        {/*      <li><a className="dropdown-item py-2" href="#">Option 3</a></li>*/}
        {/*      <li><a className="dropdown-item py-2" href="#">Option 4</a></li>*/}
        {/*    </ul>*/}
        {/*    <input type="text" placeholder='Search' className='form-search form-control ms-0 w-250px' />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* NOTIFICATIONS */}
        <div
          className={clsx(
            'd-flex align-items-center menu-bordered menu-state-bg menu-active-bg',
            toolbarButtonMarginClass
          )}
        >
          {/* begin::Menu- wrapper */}
          {/*<div*/}
          {/*  className={clsx(*/}
          {/*    'btn btn-icon btn-active-light-primary btn-custom position-relative',*/}
          {/*    toolbarButtonHeightClass*/}
          {/*  )}*/}
          {/*  data-kt-menu-trigger='click'*/}
          {/*  data-kt-menu-attach='parent'*/}
          {/*  data-kt-menu-placement='bottom-end'*/}
          {/*  data-kt-menu-flip='bottom'*/}
          {/*>*/}
          {/*  <KTSVG*/}
          {/*    path='/media/icons/duotune/general/gen007.svg'*/}
          {/*    className={toolbarButtonIconSizeClass}*/}
          {/*  />*/}
          {/*  <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-25 start-75 "></span>*/}
          {/*</div>*/}
          {/* <HeaderNotificationsMenu /> */}

          {/* <div
            className='menu menu-sub menu-sub-dropdown menu-column w-275px w-lg-300px'
            data-kt-menu='true'
          >
            {getUserType == "WM" ?
              <>
                <MenuItem to='/price/discount-request' title='An order discount request of TZS 100.00' hasBullet={false} />
                <MenuItem to='/price/discount-request' title='An order discount request of TZS 100.00' hasBullet={false} />
                <MenuItem to='/price/discount-request' title='An order discount request of TZS 100.00' hasBullet={false} />
                <MenuItem to='/price/discount-request' title='An order discount request of TZS 100.00' hasBullet={false} />
              </>
              :
              getUserType == "LO" ?
                <>
                  <MenuItem to='/reports/vehicle-usage-report' title='2 vehicle breakdown reported today' hasBullet={false} />
                  <MenuItem to='/vehicle-management' title='3 vehicles are In-service today' hasBullet={false} />

                </>
                :
                <></>
            }
          </div> */}
          {/* end::Menu wrapper */}
        </div>

        {/* CHAT */}
        <div className={clsx('d-flex align-items-center  d-none', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div>
          {/* end::Menu wrapper */}
        </div>

        {/* Quick links */}
        <div className={clsx('d-flex align-items-center  d-none', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
          {/* end::Menu wrapper */}
        </div>

        {/* {isImpersonationLogin === 'true' && <div className={clsx('d-flex align-items-center')}>
          <EaButton
            isModal={false}
            btnName={`Logout`}
            onClick={handleImpersonationLogout}
            className='btn btn-primary btn-sm font-13 my-1'
          />
        </div>} */}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <>
              {image === null ? (
                <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Eafruits' />
              ) : (
                <img
                  src={image}
                  onError={({currentTarget}) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${img}`
                  }}
                />
              )}
            </>
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}

        {/* begin::Aside Toggler */}
        {config.header.left === 'menu' && (
          <div className='d-flex align-items-center d-lg-none ms-1 me-n1' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-40px h-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-2' />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.profile.profile,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    manageProfile: (value: any) => {
      dispatch(manageProfile(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)
