/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'

import {getUserType} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import CustomerPage from './customerPage'
import TSCustomers from './TScustomers/TSCustomers'
import WMCustomers from './WMcustomers/WMCustomers'
import WMHorecaContract from './WMcustomers/horecaContract/horecaContract'
import TSHorecaContract from './TScustomers/horecaContract/horecaContract'

const superAdmin = localStorage.getItem('superAdmin')
const w_manager = localStorage.getItem('w_manager')
const OO = localStorage.getItem('OO')

type Props = {
  className: string
}
const HorecaContractWrapper: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Customers',
  }
  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({id: 'Customers'})}</PageTitle>
      {/* {getUserType === "SA" && < className='super-admin-customers' />} */}
      {(getUserType === 'WM' || getUserType === 'SA') && <WMHorecaContract />}
      {getUserType === 'TS' && <TSHorecaContract />}
      {/*{getUserType === "OO" && <OOProductSku className='oo-admin-productsku' />} */}
    </>
  )
}

export {HorecaContractWrapper}
