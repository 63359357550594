import {dateFormat} from '../CommonHelpers'
import moment from 'moment/moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import React from 'react'

export const CustomDatePicker = ({date, setDate, displayLoader}: any) => {
  return (
    <>
      <DateRangePicker
        onCallback={(start, end, label) => {
          const callBakeDate = {
            startDate: dateFormat(start._d),
            endDate: dateFormat(end._d),
          }
          setDate(callBakeDate)
        }}
        initialSettings={{
          startDate: moment(date.startDate.apiDate).toDate(),
          endDate: moment(date.endDate.apiDate).toDate(),
          autoUpdateInput: true,
          alwaysShowCalendars: true,
          cancelClass: 'btn-secondary',
          drops: 'auto',
          locale: {
            format: 'DD MMM YYYY',
          },
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
          },
        }}
      >
        <input
          type='text'
          className='form-control calendar my-1 me-3 w-auto'
          defaultValue={date}
          disabled={displayLoader}
        />
      </DateRangePicker>
    </>
  )
}
