import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import EATitle from '../../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import {EaAlertSuccess} from '../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import {useIntl} from 'react-intl'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import tenantConfiguration from '../../../../../TenantVariables'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  fetchAsyncDropdown,
  isEmpty,
  numberFormat,
  toAbsoluteUrl,
} from '../../../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ErrorHandler from '../../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import {AsyncPaginate} from 'react-select-async-paginate'
import moment from 'moment'
import {getRequest, postRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getW_Id} from '../../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import capitalizeFirstLetter from '../../../../../_eaFruitsDms/helpers/components/CapitalLetter'
import usePermission from '../../../../../_eaFruitsDms/helpers/components/usePermission'

const OrderQuantity = () => {
  const intl = useIntl()
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'Special Order Limits',
  }

  const initialOrderLimit = {
    customer: '',
    limit: '2',
    customerDrop: {label: 'Select', value: ''},
    isCustomerValid: '',
    isLimitValid: '',
  }

  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})
  const [fetchingRecords, setFetchingRecords] = useState(false)
  const [orderQuantity, setOrderQuantity] = useState([])
  const [count, setCount] = useState<string>('1')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [resetCustomer, setResetCustomer] = useState<any>(new Date())
  const [showModal, setShowModal] = useState(false)
  const [fetchingModal, setFetchingModal] = useState(false)
  const [orderLimit, setOrderLimit] = useState([initialOrderLimit])
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState<any>([])
  const [warehouseOffset, setWarehouseOffset] = useState('')
  const [warehouseList, setWarehouseList] = useState([])
  const [warehouseSearch, setWarehouseSearch] = useState('')
  const [warehouseModal, setWarehouseModal] = useState({label: 'Select', value: ''})
  const [isWarehouseValid, setWarehouseValid] = useState('')
  const [submit, setSubmit] = useState('Submit')
  const [edit, setEdit] = useState(false)

  const debounceSearch = useDebounce(search, 500)
  const permission = usePermission('special_order_limit')

  const fetchCustomer = async (search: string) => {
    let response = await fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `customers/drop-down?ordering=business_name&order_limit_exists=False&warehouse_id=${warehouseModal.value}`,
      search,
      setDropDownSearch,
      'business_name',
      false
    )

    let filterCustomer = response.options.filter((i: any) => !selectedCustomer.includes(i.value))

    response.options = filterCustomer

    return response
  }

  const fetchOrderLimit = async () => {
    setFetchingRecords(true)

    const url = `${apiBaseURL()}customers/order-limit/settings?warehouse_id=${
      warehouse.value
    }&search=${debounceSearch}&limit=${limit}&offset=${currentOffset}`

    const response = await getRequest(url, true)

    if (response && response.results) {
      setOrderQuantity(response.results)
      setCount(response.count)
    }

    setFetchingRecords(false)
  }

  const addRow = () => {
    let updatedOrderLimit = [...orderLimit]
    let isValid = true

    if (!isEmpty(warehouseModal.value)) {
      updatedOrderLimit.forEach((item, index) => {
        if (isEmpty(item.customer)) {
          isValid = false
          updatedOrderLimit[index].isCustomerValid = 'is-invalid'
        }

        if (isEmpty(item.limit.trim())) {
          isValid = false
          updatedOrderLimit[index].isLimitValid = 'is-invalid'
        }
      })
    } else {
      isValid = false
      setWarehouseValid('is-invalid')
    }

    if (isValid) {
      updatedOrderLimit.push(initialOrderLimit)
    }

    setOrderLimit(updatedOrderLimit)
  }

  const removeRow = (item: any, index: number) => {
    let updatedOrderLimit = [...orderLimit]

    let filterOrderLimit = updatedOrderLimit.filter((i, orderIndex) => orderIndex !== index)

    setOrderLimit(filterOrderLimit)
  }

  const handleOrderLimit = (e: any, index: number, type: string) => {
    let updatedOrderLimit = [...orderLimit]

    switch (type) {
      case 'customer':
        updatedOrderLimit[index].customer = e.value
        updatedOrderLimit[index].customerDrop = e
        updatedOrderLimit[index].isCustomerValid = ''
        break

      case 'limit':
        const re = /^[0-9\b]+$/
        if (e.target.value === '' || re.test(e.target.value)) {
          if (e.target.value !== '0') {
            updatedOrderLimit[index].limit = e.target.value
            updatedOrderLimit[index].isLimitValid = ''
          }
        }
        break
    }

    setOrderLimit(updatedOrderLimit)
  }

  const fetchWarehouse = (search: any) => {
    return fetchAsyncDropdown(
      warehouseOffset,
      setWarehouseOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?parent_warehouse__id=${getW_Id}&is_parent=True&ordering=name`,
      search,
      setWarehouseSearch,
      false,
      false
    )
  }

  const handleFilterChange = (e: any) => {
    setCurrentOffset(0)
    setSelected(0)
    setWarehouse(e)
  }

  const fetchSelectedCustomer = () => {
    let updatedOrderLimit = [...orderLimit]
    let customer: any = []

    updatedOrderLimit.forEach((item) => {
      customer.push(item.customer)
    })

    setSelectedCustomer(customer)
  }

  const submitOrderLimit = async () => {
    let isValid = true

    let updatedOrderLimit = [...orderLimit]

    if (!edit && isEmpty(warehouseModal.value)) {
      isValid = false
      setWarehouseValid('is-invalid')
    }

    updatedOrderLimit.forEach((item, index) => {
      if (isEmpty(item.customer)) {
        updatedOrderLimit[index].isCustomerValid = 'is-invalid'
        isValid = false
      }

      if (isEmpty(item.limit)) {
        updatedOrderLimit[index].isLimitValid = 'is-invalid'
        isValid = false
      }
    })

    setOrderLimit(updatedOrderLimit)

    if (isValid) {
      setSubmit('Please Wait')
      let body: any = []

      updatedOrderLimit.forEach((item) => {
        body.push({
          customer: parseInt(item.customer),
          limit: parseInt(item.limit),
        })
      })

      const url = `${apiBaseURL()}customers/order-limit/settings/create`
      const response = await postRequest(url, {order_limit_settings: body}, true)

      if (response.status === 200) {
        isClear()
        setIsAlert(true)

        if (edit) {
          setMessage('Order limit updated successfully.')
        } else {
          setMessage('Selected customer order limit added successfully.')
        }

        fetchOrderLimit()

        setTimeout(() => {
          setIsAlert(false)
        }, 2000)
      }

      setSubmit('Submit')
    }
  }

  const isClear = () => {
    setEdit(false)
    setSubmit('Submit')
    setShowModal(false)
    setWarehouseSearch('')
    setWarehouseList([])
    setWarehouseOffset('')
    setOrderLimit([initialOrderLimit])
    setSelectedCustomer([])
    setDropDownSearch('')
    setList([])
    setOffset(0)
    setErrorMessage('')
    setWarehouseValid('')
    setWarehouseModal({label: 'Select', value: ''})
    setFetchingModal(false)
  }

  const isEdit = async (item: any) => {
    setEdit(true)
    setShowModal(true)
    setFetchingModal(true)

    const url = `${apiBaseURL()}customers/order-limit/settings/${item.id}`
    const response = await getRequest(url, true)

    if (!isEmpty(response.customer_name)) {
      setFetchingModal(false)
      let updatedOrderLimit = [...orderLimit]

      updatedOrderLimit[0].customer = response.customer_id
      updatedOrderLimit[0].customerDrop = {
        label: response.customer_name,
        value: response.customer_id,
      }
      updatedOrderLimit[0].limit = response.limit

      setOrderLimit(updatedOrderLimit)
    }
  }

  useEffect(() => {
    fetchOrderLimit()
  }, [currentOffset, debounceSearch, warehouse])

  return (
    <>
      <EATitle title='Special Order Limits' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'Special Order Limits'})}
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      <div className={`card`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)
              }}
              isDisable={fetchingRecords}
            />
            <div className='col-auto ms-auto'>
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                {/* filter dropdown */}
                <WarehouseFilter
                  warehouse={warehouse}
                  handleFilterChange={handleFilterChange}
                  isDisabled={fetchingRecords}
                />

                {checkUserType(['wm']) && permission.write && (
                  <button
                    className='btn btn-primary ms-3'
                    onClick={() => {
                      setShowModal(true)
                    }}
                  >
                    Add Special Order Limits
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingRecords ? (
              <FetchingRecords />
            ) : orderQuantity.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-80px'>ID</th>
                    <th className='min-w-100px'>Name</th>
                    <th className='min-w-100px'>Warehouse Name</th>
                    <th className='min-w-120px'>Category</th>
                    <th className='min-w-120px'>Type</th>
                    <th className='min-w-170px'>Special Order Limits</th>
                    <th className='min-w-120px'>Action By</th>
                    {checkUserType(['wm']) && permission.write && (
                      <th className='min-w-80px action-th py-2'>Action</th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {orderQuantity.length > 0 &&
                    orderQuantity.map((item: any) => {
                      return (
                        <tr>
                          <td className='py-3'>
                            {isEmpty(item.customer_id) ? '-' : `#${item.customer_id}`}
                          </td>
                          <td className='py-3'>
                            <div className='d-flex align-items-center'>
                              <span
                                className='rounded-circle pro-icon me-2'
                                data-bs-toggle='tooltip'
                                title={item.customer_name}
                              >
                                {item.customer_logo_thumbnail === null ? (
                                  <img
                                    src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                    alt=''
                                    className='rounded-circle pro-icon'
                                  />
                                ) : (
                                  <img
                                    src={item.customer_logo_thumbnail}
                                    alt=''
                                    className='rounded-circle pro-icon'
                                  />
                                )}
                              </span>
                              <div className='media-body'>
                                <p className='m-0 xs-font'>{item.customer_name}</p>
                              </div>
                            </div>
                            <div className='text-muted'>
                              {isEmpty(item.customer_street) ? '-' : item.customer_street}
                            </div>
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.warehouse_name) ? '-' : item.warehouse_name}
                          </td>
                          <td className='py-3'>
                            <div className='text-capitalize'>
                              <div className='text-muted font-13 font-medium'>
                                {item.customer_nature_of_business
                                  ? item.customer_nature_of_business
                                  : '-'}
                              </div>
                              {item.customer_category ? item.customer_category : '-'}
                            </div>
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.customer_type)
                              ? '-'
                              : capitalizeFirstLetter(item.customer_type)}
                          </td>
                          <td className='py-3'>
                            {isEmpty(item.limit) ? '-' : numberFormat(item.limit).displayFormat}
                          </td>
                          <td className='py-3'>{isEmpty(item.action_by) ? '-' : item.action_by}</td>
                          {checkUserType(['wm']) && permission.write && (
                            <td className='text-end py-3'>
                              <div className='cursor-pointer' onClick={() => isEdit(item)}>
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='ic mr-0'
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          isClear()
        }}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-570px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>{edit ? 'Edit' : 'Add'} Special Order Limits</h2>
          </Modal.Title>
          <div
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            onClick={() => {
              isClear()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          {fetchingModal ? (
            <FetchingRecords />
          ) : (
            <div className='row'>
              {!edit && (
                <div className='col-md-12 mb-3'>
                  <label className='mb-3' htmlFor=''>
                    Warehouse
                  </label>
                  <AsyncPaginate
                    loadOptions={fetchWarehouse}
                    onChange={(e: any) => {
                      setWarehouseModal(e)
                      setOrderLimit([initialOrderLimit])
                      setWarehouseValid('')
                    }}
                    isSearchable
                    placeholder='Select'
                    value={warehouseModal}
                    className={`react-select-container ${isWarehouseValid}`}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>
              )}

              <div className='col-12'>
                <table className='table gs-0 gy-3 mb-0'>
                  <thead>
                    <tr>
                      <th className='ps-0 text-muted' style={{width: '58%'}}>
                        Customer Name
                      </th>
                      <th className='ps-0 max-w-140px text-muted' style={{width: '35%'}}>
                        Special Order Limits
                      </th>
                      {!edit && (
                        <th style={{width: '7%'}}>
                          <span className='invisible'>Hidden</span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {orderLimit.map((item, index) => {
                      return (
                        <tr>
                          <td className='ps-0'>
                            <AsyncPaginate
                              loadOptions={fetchCustomer}
                              isSearchable
                              key={resetCustomer}
                              placeholder='Select'
                              value={item.customerDrop}
                              isDisabled={edit || isEmpty(warehouseModal.value)}
                              onBlur={() => {
                                setOffset(0)
                                setResetCustomer(moment())
                                fetchSelectedCustomer()
                              }}
                              className={`react-select-container ${item.isCustomerValid}`}
                              onChange={(e) => {
                                handleOrderLimit(e, index, 'customer')
                              }}
                              classNamePrefix='react-select'
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#0c78a4',
                                },
                              })}
                            />
                          </td>
                          <td className='ps-0'>
                            <input
                              type='text'
                              placeholder='0'
                              className={`form-control min-w-70px ${item.isLimitValid}`}
                              value={item.limit}
                              onChange={(e) => {
                                handleOrderLimit(e, index, 'limit')
                              }}
                            />
                          </td>
                          {!edit && (
                            <td className='align-middle'>
                              {orderLimit.length === 1 || orderLimit.length - 1 === index ? (
                                <div
                                  className='cursor-pointer'
                                  onClick={() => {
                                    addRow()
                                  }}
                                >
                                  <KTSVG
                                    path={'/media/icons/duotune/abstract/abs011.svg'}
                                    className='ic me-0'
                                  />
                                </div>
                              ) : (
                                <div
                                  className='cursor-pointer'
                                  onClick={() => {
                                    removeRow(item, index)
                                  }}
                                >
                                  <KTSVG
                                    path={'/media/icons/duotune/abstract/abs012.svg'}
                                    className='ic me-0'
                                  />
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <ErrorHandler errorMessage={errorMessage} />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={() => {
                isClear()
              }}
              disabled={submit !== 'Submit'}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                submitOrderLimit()
              }}
              disabled={submit !== 'Submit' || fetchingModal}
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <CustomPaginate
        data={orderQuantity}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={fetchingRecords}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default OrderQuantity
