import moment from 'moment'
import {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import tenantConfiguration from '../../../TenantVariables'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateFormat,
  dateTimeFormat,
  fetchAsyncDropdownNew,
  isEmpty,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {blobXlsRequest, getRequest} from '../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import ReactDOM from 'react-dom'

const AttendanceReport = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Attendance Report',
    subTitle: 'Attendance Report',
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [reports, setReports] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouseId, setWarehouseId] = useState<any>({label: 'All Warehouses', value: ''})
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
  const [srOffSet, setSrOffSet] = useState<number>(0)
  const [sr, setSr] = useState<any>({
    label: 'All Sales Reps',
    value: '',
  })
  const [srReset, setSrReset] = useState<any>(moment())
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchingReports()
  }, [currentOffset, debounceSearch, warehouseId, apiDate, sr])

  useEffect(() => {
    if (attendanceReportId) {
      if (moment(apiDate.endDate).diff(moment(apiDate.startDate), 'months', true) > 3) {
        attendanceReportId.style.display = 'none'
      } else {
        attendanceReportId.style.display = 'block'
      }
    }
  }, [apiDate])

  const fetchingReports = async () => {
    setDisplayLoader(true)

    const url = `${apiBaseURL()}reports/sr/attendance-report?search=${searchByName}&warehouse_id=${
      warehouseId.value
    }&date_duration_after=${apiDate.startDate}&date_duration_before=${
      apiDate.endDate
    }&limit=${limit}&offset=${currentOffset}&sr_id=${sr.value}`

    const response = await getRequest(url, true)

    if (!isEmpty(response.results)) {
      setReports(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        setSrReset(moment())
        setSalesRepsOption([])
        setSr({
          label: 'All Sales Reps',
          value: '',
        })
        setSrOffSet(0)
        break

      case 'sr':
        setSr(e)
        break

      default:
        setSearchByName(inputValue)
        break
    }
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  const fetchSrs = async (search?: string) => {
    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `reports/users/drop-down?ordering=first_name&warehouse_id=${selectedWarehouseId}&type=sr`,
      search,
      setSearchSelect,
      'full_name',
      '',
      true,
      'All Sales Reps'
    )
  }

  const attendanceReportId = document.getElementById('sa-wm-attendance-report-filter')

  const downloadReport = async () => {
    let apiURL: string = ''
    let fileName: string = ''
    let srName: string = ''

    if (!isEmpty(sr.value)) {
      if (!isEmpty(apiDate.startDate) && !isEmpty(apiDate.endDate)) {
        srName = `${sr.label} - `
      } else {
        srName = `- ${sr.label} `
      }
    }

    setIsButtonDisabled(true)

    apiURL = `${apiBaseURL()}reports/excel/attendance-report?warehouse_id=${
      isEmpty(warehouseId.value) ? '' : warehouseId.value
    }&start_date=${apiDate.startDate}&end_date=${apiDate.endDate}&sr_id=${sr.value}`

    if (!isEmpty(apiDate.startDate) && !isEmpty(apiDate.endDate)) {
      fileName = `Attendance Report - ${srName}${dateFormat(apiDate.startDate).displayDate} to ${
        dateFormat(apiDate.endDate).displayDate
      } #${dateTimeFormat(moment())}`

      if (apiDate.startDate === apiDate.endDate) {
        fileName = `Attendance Report - ${srName}${
          dateFormat(apiDate.startDate).displayDate
        } #${dateTimeFormat(moment())}`
      }
    } else {
      fileName = `Attendance Report ${srName}`
    }

    await blobXlsRequest(apiURL, fileName, true, setIsButtonDisabled)
  }

  const ExportButton = () => {
    return (
      <button
        type='button'
        className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
        onClick={() => downloadReport()}
        disabled={isButtonDisabled}
      >
        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
        {isButtonDisabled ? 'Please Wait...' : 'Attendance Report'}
      </button>
    )
  }

  return (
    <>
      {checkUserType(['wm', 'sa']) &&
        attendanceReportId &&
        ReactDOM.render(
          <ExportButton />,
          document.getElementById('sa-wm-attendance-report-filter')
        )}

      <EATitle title='Attendance Report' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Attendance Report',
        })}
      </PageTitle>
      <div className='card'>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <CustomDatePickerComponent
                onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                  setApiDate({
                    startDate: callBakeDate.startDate,
                    endDate: callBakeDate.endDate,
                  })
                  setCurrentOffset(0)
                  setSelected(0)
                }}
                value={apiDate}
                startDate={apiDate.startDate}
                endDate={apiDate.endDate}
              />
              <WarehouseFilter
                warehouse={warehouseId}
                handleFilterChange={(e: any) => handleFilterChange(e, 'warehouse')}
                isDisabled={displayLoader}
              />
              <AsyncPaginate
                key={srReset}
                loadOptions={fetchSrs}
                className='react-select-container my-1 me-3 w-140px'
                classNamePrefix='react-select'
                isDisabled={displayLoader}
                value={sr}
                placeholder='All Sales Reps'
                onChange={(e: any) => handleFilterChange(e, 'sr')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {displayLoader ? (
              <FetchingRecords />
            ) : reports.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='w-100px'>SR Name</th>
                    <th className='w-100px'>Attendance Date</th>
                    <th className='w-100px'>Clock In</th>
                    <th className='w-100px'>Clock Out</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {reports.map((reports: any) => {
                    return (
                      <tr className='cursor-pointer'>
                        <td>
                          {isEmpty(reports.represantative_id) ? (
                            '-'
                          ) : (
                            <div className='d-flex align-items-center'>
                              <span
                                className='rounded-circle pro-icon me-2'
                                data-bs-toggle='tooltip'
                                title='Mark Larson'
                              >
                                {isEmpty(reports.represantative_id.profile_thumbnail) ? (
                                  <img
                                    src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                                    alt=''
                                    className='rounded-circle pro-icon'
                                  />
                                ) : (
                                  <img
                                    src={reports.represantative_id.profile_thumbnail}
                                    alt=''
                                    className='rounded-circle pro-icon'
                                  />
                                )}
                              </span>
                              <div className='media-body'>
                                <p className='m-0 xs-font'>
                                  {isEmpty(reports.represantative_id.first_name)
                                    ? ''
                                    : reports.represantative_id.first_name}{' '}
                                  {isEmpty(reports.represantative_id.last_name)
                                    ? ''
                                    : reports.represantative_id.last_name}
                                </p>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          {isEmpty(reports.attendance_date)
                            ? '-'
                            : dateFormat(reports.attendance_date).displayDate}
                        </td>
                        <td
                          className={
                            !isEmpty(reports.clock_in) &&
                            !isEmpty(reports.on_time) &&
                            (reports.on_time === false || reports.on_time === 'false')
                              ? 'text-danger'
                              : ''
                          }
                        >
                          {isEmpty(reports.clock_in) ? (
                            '-'
                          ) : (
                            <>{dateFormat(reports.clock_in, 'LT').displayDate}</>
                          )}
                        </td>
                        <td>
                          {isEmpty(reports.clock_out)
                            ? '-'
                            : dateFormat(reports.clock_out, 'LT').displayDate}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={reports}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default AttendanceReport
