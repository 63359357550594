import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_eaFruitsDms/layout/core'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import { AsyncPaginate } from 'react-select-async-paginate'
import { categoryStyle } from '../../../../_eaFruitsDms/helpers/components/categoryStyle'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  getFirstWarehouse,
  getLoginWarehouse,
  GLOBAL_DATE_FORMAT,
  measurementLabel,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import { getRequest } from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../_eaFruitsDms/layout/components/pagination/pagination'
import clsx from 'clsx'
import SkuThumbnail from '../../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import { EaChartBar, EaChartPie } from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'

type Props = {
  className: string
}
type modalType = {
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}

const ExpiredProductsWM: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const currentWarehouseId: any = localStorage.getItem('w_id')

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Expired Products Report',
  }

  const [value, onChange] = useState(new Date())
  const [product, setProduct] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [limit, setLimit] = useState<number>(10)
  const [searchSelect, setSearchSelect] = useState('')
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [groupOption, setGroupOption] = useState<any>([])
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [selectedGroupId, setSelectedGroupId] = useState<any>('')
  const [selectedCategory, setSelectedCategory] = useState<any>('')
  const [selectedBrand, setSelectedBrand] = useState<any>('')
  const [searchByName, setSearchByName] = useState<string>('')
  const debounceSearch = useDebounce(searchByName, 500);
  const [isBrandLoader, setIsChartLoading] = useState(true)

  const [warehouseValueReport, setWarehouseValueReport] = useState<any>(getLoginWarehouse().value !== null ? getLoginWarehouse() : '')
  const [offset, setOffset] = useState(0)
  const [report, setReport] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')

  const [warehouseValueList, setWarehouseValueList] = useState<any>(getLoginWarehouse().value !== null ? getLoginWarehouse() : '')
  const [offsetList, setOffsetList] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearchList, setDropDownSearchList] = useState('')

  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  const labelOption = {
    show: true,
    position: app.position,
    distance: app.distance,
    align: app.align,
    verticalAlign: app.verticalAlign,
    rotate: app.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 10,
    rich: {
      name: {}
    }
  };

  const [usageBarChart, setUsageBarChart] = useState(
    {
      xAxisData: ['2012', '2013', '2014', '2017'],
      series: [
        {
          name: '',
          type: 'bar',
          width: "100%",
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [{ value: '200' }]
        },
        {
          name: '',
          type: 'bar',
          width: "100%",
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [{ value: 10 }]
        },
        {
          name: '',
          type: 'bar',
          width: "100%",
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [{ value: 10 }]
        },
        {
          name: '',
          type: 'bar',
          width: "100%",
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [{ value: 10 }]
        },
      ],
    }
  )

  const [dateRange, setDateRange] = useState<modalType>({
    startDateApi: '',
    startDateView: 'Select',
    endDateApi: '',
    endDateView: '',
  })
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    drops: 'down',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
    alwaysShowCalendars: true,
  })
  const [validation, setValidation] = useState<any>({
    dateRange: '',
    startDateView: '',
  })
  const [dateHandler, setDateHandler] = useState({
    firstDate: [moment().startOf('month').format('YYYY-MM-DD')],
    lastDate: [moment().endOf('month').format('YYYY-MM-DD')],
  })
  const [apiDate, setApiDate] = useState<any>({ startDate: moment().startOf('month').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD') })
  const [isReportLoading, setIsReportLoading] = useState(false)
  const [productPieChart, setProductPieChart] = useState({
    data: [],
    title: "",
    bottom: '40%'
  })
  const [brandPieChart, setBrandPieChart] = useState({
    data: [],
    title: "",
    bottom: '40%'
  })
  const userType = localStorage.getItem('user_type') || ""

  const checkUserType = () => {
    if (userType !== "") {
      if (userType === "SA" || userType === "TS") {
        return true
      } else {
        return false
      }
    }
  }

  const modalValueHandler = (e: any, checkBox: any) => {
    setDateRange((preValue: any) => ({
      ...preValue,
      startDateApi: e.startDateApi,
      endDateApi: e.endDateApi,
      startDateView: e.startDateView,
      endDateView: e.endDateView,
    }))

    setDateHandler({ firstDate: e.startDateApi, lastDate: e.endDateApi })
  }

  useEffect(() => {
    if (warehouseValueList && warehouseValueList.value && warehouseValueList.value !== "") {
      fetchExpiredProductList().then()
    }
  }, [
    currentOffset,
    dateHandler,
    selectedBrand,
    selectedCategory,
    selectedGroupId,
    debounceSearch,
    warehouseValueList
  ])

  const fetchExpiredProductList = async () => {
    setListLoader(true)

    let warehouseFilter = ''

    if (checkUserType() === true) {
      if (warehouseValueList !== "" && warehouseValueList.value) {
        warehouseFilter = `&warehouse_id=${warehouseValueList.value}`
      } else {
        warehouseFilter = ``
      }
    }

    if (checkUserType() === false) {
      warehouseFilter = `&warehouse_id=${warehouseValueList !== '' && warehouseValueList.value !== '' ? warehouseValueList.value : currentWarehouseId}`
    }

    const API = `${apiBaseURL()}reports/product_expiry/list/?ordering=name&search=${debounceSearch}&offset=${currentOffset}&brand_id=${selectedBrand}&category_id=${selectedCategory}&variants__group_name__id=${selectedGroupId}&start_date=${dateHandler.firstDate
      }&end_date=${dateHandler.lastDate}` + warehouseFilter
    const response = await getRequest(API, true)

    if (response) {
      setProduct(response.results)
      setCount(response.count)
    }

    setListLoader(false)
  }

  // fetch chart Warehouse Report Dropdown
  const fetchWarehouseReport = async (search: any) => {
    return fetchAsyncDropdown(offset, setOffset, list, setList, `warehouse/list?${checkUserType() === true ? '' : `parent_warehouse__id=${currentWarehouseId}`}&ordering=name`, search, setDropDownSearch, "name", false)
  }

  // fetch chart Warehouse List Dropdown
  const fetchWarehouseList = async (search: any) => {
    return fetchAsyncDropdown(offsetList, setOffsetList, report, setReport, `warehouse/list?${checkUserType() === true ? '' : `parent_warehouse__id=${currentWarehouseId}`}&ordering=name`, search, setDropDownSearchList, "name", false)
  }

  const CustomDateRangePicker = () => {
    return (
      <DateRangePicker
        initialSettings={initialSettings}
        onEvent={(e) => {
          e.preventDefault()
        }}
        onCallback={(start, end, label) => {
          setCurrentOffset(0)
          setInitialSettings({
            startDate: start._d,
            endDate: end._d,
            cancelClass: 'btn-secondary',
            drops: 'down',
            autoUpdateInput: false,
            locale: {
              format: GLOBAL_DATE_FORMAT,
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
            alwaysShowCalendars: true,
          })

          let newStartDate = dateFormat(start._d)
          let newEndDate = dateFormat(end._d)

          let startDate = start._d
          let endDate = end._d

          let dateObj = {
            startDateView: newStartDate.displayDate,
            endDateView: newEndDate.displayDate,

            startDateApi: newStartDate.apiDate,
            endDateApi: newEndDate.apiDate,
          }
          modalValueHandler(dateObj, '')
        }}
        onCancel={(event: any, picker: any) => {
          setDateRange((preValue: any) => ({
            ...preValue,
            startDateView: 'Select',
          }))
        }}
      >
        <input
          id='datePickerFocus'
          type='text'
          onFocus={() => {
            // $('.daterangepicker').css('display', 'block')
          }}
          className={clsx('form-control calendar', validation.startDateView)}
          readOnly
          value={
            dateRange.startDateView === 'Select'
              ? [moment().startOf('month').format('DD MMM YYYY')] +
              '-' +
              [[moment().endOf('month').format('DD MMM YYYY')]]
              : dateRange.startDateView + ' - ' + dateRange.endDateView
          }
        />
      </DateRangePicker>
    )
  }

  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
      setCategoryOffset('')
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffset(newOffset)
      hasMore = true
    }

    if (categoryOption.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categoryOption.length > 0) {
      setCategoryOption(categoryOption.concat(options))
    } else {
      setCategoryOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchBrands = async (search?: any) => {
    return await fetchAsyncDropdown(
      brandOffset,
      setBrandOffset,
      brandOption,
      setBrandOption,
      `master/product/brand?ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All Brands'
    )
  }

  const fetchGroups = async (search?: any) => {
    return await fetchAsyncDropdown(
      groupOffSet,
      setGroupOffSet,
      groupOption,
      setGroupOption,
      `master/product/group?ordering=name`,
      search,
      setSearchSelect,
      '',
      true,
      'All Product Groups'
    )
  }

  const selectStyles = {
    menuList: (base: any) => ({
      ...base,
      maxHeight: 200,
    }),
  }

  const handleFilterOptionChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'brand':
        setTimeoutSeconds(0)
        setSelectedBrand(inputValue)
        break
      case 'category':
        setTimeoutSeconds(0)
        setSelectedCategory(inputValue)
        break
      case 'group':
        setTimeoutSeconds(0)
        setSelectedGroupId(inputValue)
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearchByName(inputValue)
        break
    }

    setCurrentOffset(0)
    setSelected(0)
  }

  // charts
  const fetchPieCharts = async () => {
    setIsReportLoading(true)

    let warehouseFilter = ''
    if (checkUserType() === true) {
      if (warehouseValueReport !== "" && warehouseValueReport.value) {
        warehouseFilter = `&warehouse_id=${warehouseValueReport.value}`
      } else {
        warehouseFilter = `&warehouse_id=`
      }
    }

    if (checkUserType() === false) {
      warehouseFilter = `&warehouse_id=${warehouseValueReport !== '' && warehouseValueReport.value !== '' ? warehouseValueReport.value : currentWarehouseId}`
    }

    const param = `start_date=${apiDate.startDate}&end_date=${apiDate.endDate}` + warehouseFilter

    const url = apiBaseURL() + 'reports/expired_stocks/brand/count?' + param
    const brandsChartResponse = await getRequest(url, true)

    const productUrl = apiBaseURL() + 'reports/expired_stocks/count?' + param
    const productChartResponse = await getRequest(productUrl, true)

    let brandChart: any = { ...brandPieChart }
    let productChart: any = { ...productPieChart }
    let mergeBrandChart: Object[] = []
    let mergeProductChart: Object[] = []

    if (brandsChartResponse) {
      brandsChartResponse.forEach((item: any) => {
        if (item.expired_product_count !== 0)
          mergeBrandChart.push({ name: item.brand_name, value: item.expired_product_count })
      })
    }

    if (productChartResponse) {
      productChartResponse.forEach((item: any) => {
        if (item.expired_product_count !== 0)
          mergeProductChart.push({ name: item.product_name, value: item.expired_product_count })
      })
    }

    brandChart.data = mergeBrandChart
    productChart.data = mergeProductChart

    setBrandPieChart(brandChart)
    setProductPieChart(productChart)
    setIsReportLoading(false)
  }

  const CustomDatePickerComponent = (props: any) => {
    const { onCallback, startDate, endDate, value } = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
          />
        </DateRangePicker>
      </>
    )
  }

  useEffect(() => {
    if (warehouseValueReport && warehouseValueReport.value && warehouseValueReport.value !== "") {
      fetchPieCharts()
    }
  }, [apiDate, warehouseValueReport])

  useEffect(() => {
    getFirstWh()
  }, [])

  const getFirstWh = async () => {
    if (checkUserType() === true) {
      const url = apiBaseURL() + `warehouse/list?ordering=name`
      const response: any = await getFirstWarehouse(url)
      if (response && response.value) {
        setWarehouseValueReport(response)
        setWarehouseValueList(response)
      }
    }
  }

  return (
    <>
      <EATitle title='Unsold Expired Products -' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({ id: 'Unsold Expired Products' })}
      </PageTitle>
      <div>
        <div className='row mt-3 mt-md-0'>
          <div className='col-12 mt-md-0'>
            <div className='card card-xl-stretch mb-5'>
              <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                {/* begin::Title */}
                <div>
                  <h3 className="card-title align-items-start flex-column my-0">
                    <span className="card-label fw-bold font-17">Unsold Expired Products</span>
                  </h3>
                  <p className="font-13 text-muted mb-0">Based on Unsold Expired Products Count</p>
                </div>
                <div className='card-toolbar d-flex g-3 my-0'>
                  <AsyncPaginate
                    loadOptions={fetchWarehouseReport}
                    isSearchable
                    placeholder="All Warehouses"
                    className='react-select-container my-1 me-1 w-150px'
                    classNamePrefix="react-select"
                    name='select_team'
                    onChange={(e) => {
                      setWarehouseValueReport(e)
                    }}
                    value={warehouseValueReport}
                    isDisabled={isReportLoading}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />

                  <CustomDatePickerComponent
                    onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                      setApiDate({
                        startDate: callBakeDate.startDate,
                        endDate: callBakeDate.endDate
                      })
                    }}
                    value={apiDate}
                    startDate={apiDate.startDate}
                    endDate={apiDate.endDate}
                  />
                </div>
              </div>
              <div className='card-body'>
                <div className="row">
                  {isReportLoading && <IsLoader />}
                  {brandPieChart.data.length === 0 ? <NoRecords /> :
                    <>
                      <div className="col-md-6">
                        <EaChartPie item={brandPieChart} />
                      </div>
                      <div className="col-md-6">
                        <EaChartPie item={productPieChart} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`card ${className} mb-5`}>
          <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
            <div>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17'>Expired Products Overview</span>
              </h3>
              <p className="font-13 text-muted mb-0">Based on Expired Products Count</p>
            </div>
            <div className='card-toolbar d-flex g-3 my-0'>
              <ul className='nav'>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                    data-bs-toggle='tab'
                    href='#kt_table_widget_8_tab_1'
                  >
                    Brands
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                    data-bs-toggle='tab'
                    href='#kt_table_widget_8_tab_2'
                  >
                    Parent Categories
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary'
                    data-bs-toggle='tab'
                    href='#kt_table_widget_8_tab_3'
                  >
                    Product Groups
                  </a>
                </li>
              </ul>
              <span className="v-seperator mx-3"></span>
              <ul className='nav'>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                    data-bs-toggle='tab'
                    href='#kt_table_widget_8_tab_1'
                  >
                    Month
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                    data-bs-toggle='tab'
                    href='#kt_table_widget_8_tab_2'
                  >
                    Year
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-body">
            {isBrandLoader && <IsLoader />}
            {
              <EaChartBar {...usageBarChart} />
            }
          </div>
        </div> */}
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={(e: any) => handleFilterOptionChange(e, 'search')}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0 d-flex me-1'>
                  <CustomDateRangePicker />
                </div>
                <AsyncPaginate
                  loadOptions={fetchWarehouseList}
                  isSearchable
                  placeholder="All Warehouses"
                  className='react-select-container my-1 me-1 w-150px'
                  classNamePrefix="react-select"
                  name='select_team'
                  onChange={(e) => {
                    setWarehouseValueList(e)
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={warehouseValueList}
                  isDisabled={listLoadder}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchBrands}
                  isSearchable
                  styles={selectStyles}
                  className='react-select-container my-1 me-1 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Brands'
                  name='brand'
                  onChange={(e: any) => handleFilterOptionChange(e, 'brand')}
                  theme={(theme) => ({
                    ...theme,
                    height: 200,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategory}
                  isSearchable
                  className='react-select-container my-1 me-1 w-150px'
                  classNamePrefix='react-select'
                  styles={{
                    ...categoryStyle,
                    menuList: (base: any) => ({
                      ...base,
                      maxHeight: 200,
                    }),
                  }}
                  placeholder='All Categories'
                  name='category'
                  onChange={(e: any) => handleFilterOptionChange(e, 'category')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchGroups}
                  isSearchable
                  styles={selectStyles}
                  className='react-select-container my-1 w-170px'
                  classNamePrefix='react-select'
                  placeholder='All Product Groups'
                  onChange={(e: any) => handleFilterOptionChange(e, 'group')}
                  name='group'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div
              className='table-responsive expandable'
              style={{ transition: '.5s linear all', borderCollapse: 'collapse' }}
              id='expandTable'
            >
              {listLoadder ? (
                <>
                  <IsLoader />
                </>
              ) : (
                <>
                  {product.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <>
                      <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                        <thead>
                          <tr className=' text-muted fw-500'>
                            <th className='min-w-110px py-2'>Product SKU</th>
                            <th className='min-w-100px py-2'>Brand</th>
                            <th className='min-w-120px py-2'>Category</th>
                            <th className='min-w-130px py-2'>Product Group</th>
                            <th className='min-w-100px py-2'>Variant</th>
                            {/* <th className='min-w-100px py-2'>Size</th> */}
                            <th className='min-w-100px py-2'>Expired Qty.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {product.map((item: any) => {
                            let approxWeight = 0

                            if (item.variants && item.variants.approx_weight > 0) {
                              approxWeight = item.variants.approx_weight / 1000
                            }

                            return (
                              <>
                                <tr
                                  data-bs-toggle='collapse'
                                  data-bs-target={`.row-collapse${item.id}`}
                                  aria-controls={`.rowCollapse${item.id}`}
                                  className='cursor-pointer'
                                >
                                  <SkuThumbnail skuName={item.name} src={item.product_thumbnail} />
                                  <td>{item.brand_id}</td>
                                  <td>
                                    <div>
                                      <div className='text-muted font-13 mb-1 font-medium'>
                                        {item.category_id && item.category_id.parent_category.name}
                                      </div>
                                      {item.category_id.name}
                                    </div>
                                  </td>
                                  <td>{item.group_name}</td>
                                  <td>{item.variants.name}</td>
                                  {/* <td>
                                    {approxWeight > 0
                                      ? approxWeight + ' Kgs'
                                      : (item.measurement_unit.toLowerCase() === 'gram'
                                        ? item.size / 1000
                                        : item.size) + ' Kgs'}
                                  </td> */}
                                  <td>{`${numberFormat(item.total_qty).displayFormat}`}</td>
                                </tr>
                                <tr className='expandable-row'>
                                  <td className='reset-expansion-style' colSpan={11}>
                                    <div
                                      className={`row-expansion-style collapse row-collapse${item.id} collapsible`}
                                      id={`rowCollapse${item.id}`}
                                      data-bs-parent='#expandTable'
                                    >
                                      <div>
                                        <table className='table table-bordered gs-4 mb-0'>
                                          <thead>
                                            <tr className='text-muted fw-500 bg-light2'>
                                              <th className='w-200px py-2'>Batch Number</th>
                                              <th className='w-200px py-2'>Expired On</th>
                                              <th className='min-w-120px py-2'>Expired Qty.</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.report &&
                                              item.report.map((reportsData: any) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {reportsData.batch_number
                                                        ? reportsData.batch_number
                                                        : '-'}
                                                    </td>
                                                    <td>
                                                      {reportsData.expired_on
                                                        ? dateFormat(reportsData.expired_on)
                                                          .displayDate
                                                        : '-'}
                                                    </td>
                                                    <td>
                                                      {reportsData.expired_qty
                                                        ? `${numberFormat(reportsData.expired_qty)
                                                          .displayFormat
                                                        }`
                                                        : ''}
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                          {/* end first row expand */}
                        </tbody>
                      </table>
                    </>
                  )}
                </>
              )}
            </div>
            {/* end::Table container */}
          </div>
        </div>
        <CustomPaginate
          data={product}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisable={listLoadder}
          saveFilterName={'feedbackReport'}
          filterStates={''}
        />
      </div>
      {/* <Addwarehouse /> */}
    </>
  )
}

export default ExpiredProductsWM
