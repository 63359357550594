import { apiBaseURL } from "../../helpers"
import { postRequest } from "../../https/apiCall"
import { getEmail } from "../../localStorage/getItem/getLocalStorage"

export const changePassword = async (
    password: any, newpassword: any, cnfPassword: any
) => {

    const notify = {
        success: false,
        failed: false,
        res: '',
        err: '',
        errorField: ''
    }

    let body = {
        email: getEmail,
        current_password: password,
        new_password: newpassword,
        confirm_password: cnfPassword
    }

    await postRequest(`${apiBaseURL()}auth/change-password`, body, true)
        .then((res) => {
            if (res.status === 200) {
                notify.success = true
            }
            if (res.statusText === "Bad Request") {
                notify.failed = true
                notify.res = res
                notify.errorField = res.data && res.data.error.field 
            }

        })
    return notify;
}
