import React from "react"

interface ErrorHandler {
    errorMessage: string
}

const ErrorHandler = (props: any) => {
    return (
        <span className='text-danger' >{props.errorMessage}</span>
    )
}

export default ErrorHandler
