import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import tenantConfiguration from '../../../../TenantVariables'
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  fetchAsyncDropdownNew,
  fetchStaticAsyncDropdown,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {AsyncPaginate} from 'react-select-async-paginate'

const CustomerOrderLimitReport = () => {
  const intl = useIntl()
  const customersHeaderTitle = {
    title: 'Customers',
    subTitle: 'Customer Order Limit Report',
  }

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<number>(0)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState(tenantConfiguration.limit)
  const [warehouse, setWarehouse] = useState<any>({label: 'All Warehouse', value: ''})
  const [fetchingRecords, setFetchingRecords] = useState(false)
  const [orderLimits, setOrdersLimit] = useState([])
  const [count, setCount] = useState<string>('1')
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })
  const [customer, setCustomer] = useState<any>({label: 'All Customer', value: ''})
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [type, setType] = useState('customer')
  const [dropDownSearch, setDropDownSearch] = useState('')

  const userType: any = localStorage.getItem('user_type') || ''
  const debounceSearch = useDebounce(search, 500)

  const fetchRecords = async () => {
    setFetchingRecords(true)

    if (type === 'customer') {
      const url = `${apiBaseURL()}reports/customer-order-limit/list?limit=${limit}&offset=${currentOffset}&start_date=${
        apiDate.startDate
      }&end_date=${apiDate.endDate}`

      const response = await getRequest(url, true)

      if (response && response.results) {
        setOrdersLimit(response.results)
        setCount(response.count)
      } else {
        setOrdersLimit([])
        setCount('1')
      }
    } else {
      if (!isEmpty(customer.value)) {
        const url = `${apiBaseURL()}reports/customer-total-orders/list/${
          customer.value
        }?limit=${limit}&offset=${currentOffset}&start_date=${apiDate.startDate}&end_date=${
          apiDate.endDate
        }`

        const response = await getRequest(url, true)

        if (response && response.results) {
          setOrdersLimit(response.results)
          setCount(response.count)
        } else {
          setOrdersLimit([])
          setCount('1')
        }
      }
    }

    setFetchingRecords(false)
  }

  const fetchCustomer = (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `customers/drop-down?ordering=business_name&warehouse_id=${warehouse.value}`,
      search,
      setDropDownSearch,
      'business_name',
      false
    )
  }

  useEffect(() => {
    const fetchCustomer = async () => {
      const url = `${apiBaseURL()}customers/drop-down?ordering=business_name&warehouse_id=${
        warehouse.value
      }`

      const response = await getRequest(url, true)

      if (response && response.results && response.results.length > 0) {
        const {business_name, id} = response.results[0]
        setCustomer({label: business_name, value: id})
      }
    }

    fetchCustomer()
  }, [])

  useEffect(() => {
    fetchRecords()
  }, [currentOffset, warehouse, customer, apiDate, type])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={value}
            disabled={fetchingRecords}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Customer Order Limit Report' />
      <PageTitle breadcrumbs={[customersHeaderTitle]}>
        {intl.formatMessage({id: 'Customer Order Limit Report'})}
      </PageTitle>

      <div className={`card`}>
        <div className='card-header border-0 px-7'>
          <div className='card-toolbar row g-0 w-100'>
            <div className='col-auto ms-auto'>
              <form
                className=' card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <button
                  onClick={() => {
                    setType('customer')
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  type='button'
                  className={`btn ${type === 'customer' ? 'btn-primary' : 'btn-secondary'} me-2`}
                >
                  By Customers
                </button>
                <button
                  onClick={() => {
                    setType('date')
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  type='button'
                  className={`btn me-2 ${type === 'date' ? 'btn-primary' : 'btn-secondary'}`}
                >
                  By Date
                </button>
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                    setCurrentOffset(0)
                    setSelected(0)
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                />

                {type === 'date' && (
                  <AsyncPaginate
                    key={customerReset}
                    loadOptions={fetchCustomer}
                    isSearchable
                    placeholder='Select'
                    className={`react-select-container me-3 w-150px`}
                    onChange={(e) => {
                      setCustomer(e)
                      setCurrentOffset(0)
                      setSelected(0)
                    }}
                    value={customer}
                    isDisabled={fetchingRecords}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
              </form>
            </div>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {fetchingRecords ? (
              <FetchingRecords />
            ) : orderLimits.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
                <thead>
                  <tr className='text-muted fw-500'>
                    {type === 'customer' ? (
                      <>
                        <th className='min-w-80px'>Customer Name</th>
                        <th className='min-w-100px'>Total Below Limit</th>
                        <th className='min-w-150px'>Total Over Limit</th>
                      </>
                    ) : (
                      <>
                        <th className='min-w-80px'>Date</th>
                        <th className='min-w-100px'>Allow Limit</th>
                        <th className='min-w-150px'>Total Order</th>
                      </>
                    )}
                  </tr>
                </thead>

                {type === 'customer' ? (
                  <tbody>
                    {orderLimits.length > 0 &&
                      orderLimits.map((item: any) => {
                        return (
                          <tr>
                            <td className='py-3'>
                              {isEmpty(item.customer_name) ? '-' : `${item.customer_name}`}
                            </td>
                            <td className='py-3'>
                              {isEmpty(item.under_limit_orders) ? '-' : item.under_limit_orders}
                            </td>
                            <td className='py-3'>
                              {isEmpty(item.over_limit_orders) ? '-' : item.over_limit_orders}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    {orderLimits.length > 0 &&
                      orderLimits.map((item: any) => {
                        return (
                          <tr>
                            <td className='py-3'>
                              {isEmpty(item.order_date) ? (
                                '-'
                              ) : (
                                <>
                                  {dateFormat(item.order_date).displayDate}
                                  <div>{dateFormat(item.order_date, 'LT').displayDate}</div>
                                </>
                              )}
                            </td>

                            <td className='py-3'>
                              {isEmpty(item.order_limit) ? '-' : `${item.order_limit}`}
                            </td>
                            <td className='py-3'>
                              {isEmpty(item.total_orders) ? '-' : item.total_orders}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={orderLimits}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={fetchingRecords}
        saveFilterName={'feedbackReport'}
        filterStates={''}
      />
    </>
  )
}

export default CustomerOrderLimitReport
