import React, {useEffect, useState} from 'react'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
  toAbsoluteUrl,
} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

const SrDailyRoutesDetails = () => {
  const intl = useIntl()
  const headerTitle = {
    title: 'SRs Daily Routes',
    subTitle: 'SRs Daily Routes',
  }
  const date = localStorage.getItem('sr-routes-date') || ''
  const srId = localStorage.getItem('sr-routes-id') || ''

  const [srDetails, setSrDetails] = useState({
    srName: '',
    warehouseName: '',
  })
  const [gatePasses, setGatePasses] = useState([])
  const [gatePassesList, setGatePassesList] = useState([])
  const [gatePassesDetails, setGatePassesDetails] = useState<any>({})
  const [gatePassId, setGatePassId] = useState('')
  const [fetchDetails, setFetchDetails] = useState(true)

  const fetchSrDetails = async () => {
    setFetchDetails(true)

    if (!isEmpty(date) && !isEmpty(srId)) {
      const url = `${apiBaseURL()}reports/sr-routes-report/detail/${srId}?start_date=${date}&end_date=${date}`

      const response = await getRequest(url, true)

      if (response && response.result) {
        setGatePasses(response.result)

        if (response.result.length > 0) {
          setGatePassId(response.result[0].id)
        }

        setSrDetails({
          srName: response.sr_name,
          warehouseName: response.warehouse,
        })
      }
    }
  }

  const fetchSrListDetails = async () => {
    setFetchDetails(true)
    const url = `${apiBaseURL()}reports/sr-routes-report/gate-pass-orders/${srId}/${gatePassId}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      console.log(response.result)

      setGatePassesList(response.result)
    }

    const gatePassDetailsUrl = `${apiBaseURL()}reports/sr-routes-report/gate-pass-detail/${srId}/${gatePassId}`
    const gatePassDetailsResponse = await getRequest(gatePassDetailsUrl, true)

    gatePassDetailsResponse &&
      gatePassDetailsResponse.result &&
      setGatePassesDetails(gatePassDetailsResponse.result)

    setFetchDetails(false)
  }

  useEffect(() => {
    fetchSrDetails()
  }, [])

  useEffect(() => {
    gatePassId !== '' && fetchSrListDetails()
  }, [gatePassId])

  const findCoverVehicleImage = (item: any) => {
    if (item.vehicle_images && item.vehicle_images.length > 0) {
      let image = item.vehicle_images

      if (image && image.length > 0) {
        let findCoverImage = image.find((item: any) => item.is_cover === true)

        if (findCoverImage && findCoverImage.vehicle_thumbnail) {
          return findCoverImage.vehicle_thumbnail
        }
      }
    }

    return toAbsoluteUrl('/media/smiles/img_brand.png')
  }

  return (
    <>
      <EATitle title='SRs Daily Routes - ' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({id: 'SRs Daily Routes'})}
      </PageTitle>

      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {checkEmpty(srDetails.srName)}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
                      {checkEmpty(srDetails.warehouseName)}
                    </span>

                    <span className='d-flex align-items-center me-5 mb-2 '>
                      <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
                      {isEmpty(date) ? '-' : dateFormat(date).displayDate}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-50px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              {gatePasses &&
                gatePasses.length > 0 &&
                gatePasses.map((items: any) => {
                  return (
                    <li
                      className='nav-item'
                      onClick={() => {
                        setGatePassId(items.id)
                      }}
                    >
                      <Link
                        className={
                          `nav-link text-active-primary me-0 ` +
                          `${gatePassId === items.id && 'active'}`
                        }
                        to='#'
                      >
                        {`Gatepass #${items.gatepass_number}`}
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </div>

      {fetchDetails ? (
        <FetchingRecords />
      ) : (
        <>
          <div className='card p-5 my-4'>
            <div className='d-flex align-items-center'>
              <div className='me-3'>
                <span className='symbol-label'>
                  <img
                    alt=''
                    className='h-30px rounded-1'
                    src={findCoverVehicleImage(gatePassesDetails)}
                  />
                </span>
              </div>
              <div>
                {checkEmpty(gatePassesDetails.vehicle_model)} -{' '}
                {checkEmpty(gatePassesDetails.vehicle_number)}
                {/*<div className='text-danger'>Capacity 2000 KG (28a KG Overloaded)</div>*/}
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-md-3'>
                <div className='font-700'>Successful Calls</div>
                <div>{numberFormat(gatePassesDetails.total_orders).displayFormat}</div>
              </div>

              <div className='col-md-3'>
                <div className='font-700'>Qty. Sold</div>
                <div>{numberFormat(gatePassesDetails.total_deliverd_stock).displayFormat}</div>
              </div>

              <div className='col-md-3'>
                <div className='font-700'>Qty. Unsold</div>
                <div>{numberFormat(gatePassesDetails.total_return_stock).displayFormat}</div>
              </div>
            </div>
          </div>
          <div className='card p-5 my-5'>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-hover table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th>order Id</th>
                    <th>Dist. Travelled</th>
                    {/*<th>Approx. Travel Time</th>*/}
                    <th>Total Sales</th>
                  </tr>
                </thead>

                <tbody>
                  {gatePassesList.length > 0 &&
                    gatePassesList.map((item: any) => {
                      return (
                        <tr>
                          <td>{isEmpty(item.order_id) ? '-' : `#${item.order_id}`}</td>
                          <td>{checkEmpty(item.km_driven)}</td>
                          <td>
                            {
                              numberFormatWithCode(isEmpty(item.total_sales) ? 0 : item.total_sales)
                                .displayFormat
                            }
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SrDailyRoutesDetails
