import moment from 'moment'
import {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {connect} from 'react-redux'
import {dateFormat, isEmpty} from '../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import {
  setDate,
  setEndDate,
  setStartDate,
  setWarehouseValue,
} from '../../../_eaFruitsDms/redux/action/summaryFilters/summaryFilters'

const SummaryFilter = (props: any) => {
  const sDate = moment().format('YYYY-MM-DD')
  const eDate = moment().format('YYYY-MM-DD')

  const selectedWarehouse = isEmpty(localStorage.getItem('summary_report_warehouse'))
    ? ''
    : JSON.parse(localStorage.getItem('summary_report_warehouse') || '')

  const startDate = !isEmpty(localStorage.getItem('summary_report_start_date'))
    ? localStorage.getItem('summary_report_start_date')
    : moment().format('YYYY-MM-DD')

  const endDate = !isEmpty(localStorage.getItem('summary_report_end_date'))
    ? localStorage.getItem('summary_report_end_date')
    : moment().format('YYYY-MM-DD')

  const [warehouse, setWarehouse] = useState<any>(selectedWarehouse)
  const [apiDate, setApiDate] = useState<any>({
    startDate: !isEmpty(startDate) ? startDate : moment().format('YYYY-MM-DD'),
    endDate: !isEmpty(endDate) ? endDate : moment().format('YYYY-MM-DD'),
  })
  const [buttonDisable, setButtonDisable] = useState<any>(false)

  const handleFilterChange = (e: any) => {
    setWarehouse(e)
    props.setWarehouseValue(e.value)
    localStorage.setItem('summary_report_warehouse', JSON.stringify(e))

    if (isEmpty(e.value)) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }

  const handleChangeReset = () => {
    localStorage.removeItem('summary_report_warehouse')
    localStorage.removeItem('summary_report_start_date')
    localStorage.removeItem('summary_report_end_date')

    setApiDate({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    })
    setWarehouse('')
    setButtonDisable(false)

    props.setWarehouseValue('')
    props.setStartDate(moment().format('YYYY-MM-DD'))
    props.setEndDate(moment().format('YYYY-MM-DD'))

    props.setDate({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    if ((startDate !== sDate && endDate !== eDate) || !isEmpty(selectedWarehouse)) {
      setButtonDisable(true)
    } else {
      setButtonDisable(false)
    }
  }, [startDate, endDate, selectedWarehouse])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-250px'
            defaultValue={value}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <div className='d-flex ms-auto'>
      <WarehouseFilter
        warehouse={warehouse}
        handleFilterChange={handleFilterChange}
        isDisabled={false}
      />

      <CustomDatePickerComponent
        onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
          setApiDate({
            startDate: callBakeDate.startDate,
            endDate: callBakeDate.endDate,
          })

          props.setStartDate(callBakeDate.startDate)
          props.setEndDate(callBakeDate.endDate)

          props.setDate({
            startDate: callBakeDate.startDate,
            endDate: callBakeDate.endDate,
          })

          localStorage.setItem('summary_report_start_date', callBakeDate.startDate)
          localStorage.setItem('summary_report_end_date', callBakeDate.endDate)

          setButtonDisable(true)
        }}
        value={apiDate}
        startDate={apiDate.startDate}
        endDate={apiDate.endDate}
      />
      <div className='mt-2 me-2'>
        <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    warehouseId: state.supportRequestReport.warehouseId,
    startDate: state.supportRequestReport.startDate,
    endDate: state.supportRequestReport.endDate,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setWarehouseValue: (value: any) => {
      dispatch(setWarehouseValue(value))
    },
    setStartDate: (value: any) => {
      dispatch(setStartDate(value))
    },
    setEndDate: (value: any) => {
      dispatch(setEndDate(value))
    },
    setDate: (value: any) => {
      dispatch(setDate(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryFilter)
