import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, apiBaseURL, checkEmpty, isEmpty} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'

const RouteDetails = () => {
  const intl = useIntl()

  const headerTitle = {
    title: 'Route Details',
    subTitle: 'Route Details',
  }

  const initialRoute = {
    avg_ideal_time: '',
    route_no: '',
    avg_serving_time: '',
    name: '',
    sales_supervisor_name: '',
    streets: [],
    sales_team: null,
    created_by_type: '',
    created_by_name: '',
    manage_by_type: '',
    manage_by_name: '',
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [routeDetails, setRouteDetails] = useState<any>(initialRoute)
  const [isDetailsLoading, setDetailsLoading] = useState(false)

  const navigate = useNavigate()
  const {state}: any = useLocation()

  const routeId = state?.routeId ?? null

  const fetchRouteDetails = async () => {
    setDetailsLoading(true)

    const url = `${apiBaseURL()}routes/${routeId}`

    const response = await getRequest(url, true)

    setRouteDetails({
      avg_ideal_time: response?.avg_ideal_time ?? '',
      avg_serving_time: response?.avg_serving_time ?? '',
      route_no: response?.route_no ?? '',
      id: response?.id ?? '',
      name: response?.name ?? '',
      sales_team_name: response?.sales_team_name ?? '',
      sales_supervisor_name: response?.sales_supervisor_name ?? '',
      created_by_name: response?.created_by_name ?? '',
      created_by_type: response?.created_by_type ?? '',
      manage_by_name: response?.manage_by_name ?? '',
      manage_by_type: response?.manage_by_type ?? '',
      status: response?.status ?? '',
      streets: isEmpty(response?.streets) ? [] : response.streets,
      sales_team: isEmpty(response?.sales_team_id)
        ? null
        : {
            value: response?.sales_team_id,
            label: response?.sales_team_name,
          },
    })

    setDetailsLoading(false)
  }

  useEffect(() => {
    fetchRouteDetails()
  }, [routeId])

  return (
    <>
      <EATitle title='Route Details' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Route Details',
        })}
      </PageTitle>

      {isDetailsLoading ? (
        <div className='card'>
          <FetchingRecords />
        </div>
      ) : (
        <>
          <div className='card p-5'>
            <div className='d-flex'>
              <div className='w-100'>
                <h3 className='font-bold'>
                  {isEmpty(routeDetails?.route_no) ? '' : `#${routeDetails?.route_no}`}{' '}
                  {checkEmpty(routeDetails.name)}
                </h3>
              </div>
              <div>
                <div>
                  {routeDetails.status === 'approved' && (
                    <span className='badge badge-light-success text-dark'>Approved</span>
                  )}

                  {routeDetails.status === 'rejected' && (
                    <span className='badge badge-light-danger text-dark'>Rejected</span>
                  )}

                  {routeDetails.status === 'pending' && (
                    <span className='badge badge-light-warning text-dark'>Pending</span>
                  )}

                  {routeDetails.status === 'canceled' && (
                    <span className='badge badge-light-dark text-dark'>Canceled</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='p-5 mt-5 card'>
            <div className='font-bold fs-6 '>Route Details</div>
            <hr className='opacity-100 bg-light' />

            <div className='row'>
              <div className='col-sm-6 col-xl-4 mb-6'>
                <label className='font-14 text-muted mb-2'>Name</label>
                <div>
                  <span className='font-14 text-dark'>
                    {checkEmpty(routeDetails?.manage_by_name)}{' '}
                    {routeDetails?.manage_by_type &&
                      `(${routeDetails?.manage_by_type?.toUpperCase()})`}
                  </span>
                </div>
              </div>
              <div className='col-sm-6 col-xl-4 mb-6'>
                <label className='font-14 text-muted mb-2'>Sales Team</label>
                <div>
                  <span className='font-14 text-dark'>
                    {checkEmpty(routeDetails?.sales_team_name)}
                  </span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-6 col-xl-4 mb-6'>
                <label className='font-14 text-muted mb-2'>Avg. Serving Time</label>
                <div>
                  <span className='font-14 text-dark'>
                    {checkEmpty(routeDetails?.avg_serving_time)} Mins
                  </span>
                </div>
              </div>
              <div className='col-sm-6 col-xl-4 mb-6'>
                <label className='font-14 text-muted mb-2'>Avg. Idle Time</label>
                <div>
                  <span className='font-14 text-dark'>
                    {checkEmpty(routeDetails?.avg_ideal_time)} Mins
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='p-5 mt-5 card'>
            <div className='font-bold fs-6 '>Street Details</div>
            <hr className='opacity-100 bg-light' />

            {routeDetails.streets && routeDetails.streets.length === 0 ? (
              <NoRecords />
            ) : (
              <div className='col-md-6'>
                <table className='table table-row-bordered align-middle gs-0 gy-3 mb-0'>
                  <thead>
                    <tr>
                      <th>Streets Name</th>
                      <th>Total Customer</th>
                    </tr>
                  </thead>

                  <tbody>
                    {routeDetails.streets &&
                      routeDetails.streets.length > 0 &&
                      routeDetails.streets.map((i: any) => {
                        return (
                          <tr>
                            <td>{checkEmpty(i.street_name)}</td>
                            <td>{checkEmpty(i.customer_count)}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default RouteDetails
