import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  apiBaseURL,
  dateFormat,
  fetchAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  numberFormat
} from "../../../../_eaFruitsDms/helpers";
import { categoryStyle } from "../../../../_eaFruitsDms/helpers/components/categoryStyle";
import { getRequest } from "../../../../_eaFruitsDms/https/apiCall";
import IsLoader from "../../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import SkuThumbnail from "../../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail";
import EATitle from "../../../../_eaFruitsDms/layout/components/title/title";
import { PageTitle } from "../../../../_eaFruitsDms/layout/core";
import useDebounce from "../../../../_eaFruitsDms/helpers/components/useDebounce";

type Props = {
  className: string
}
type modalType = {
  startDateApi: string
  startDateView: string
  endDateApi: string
  endDateView: string
}

const ExpiredProducts: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const currentWarehouseId: any = localStorage.getItem('w_id')

  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Expired Products Report',
  }

  const [value, onChange] = useState(new Date())
  const [product, setProduct] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [listLoadder, setListLoader] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [limit, setLimit] = useState<number>(10)
  const [searchSelect, setSearchSelect] = useState('')
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [brandOffset, setBrandOffset] = useState<any>('')
  const [brandOption, setBrandOption] = useState<any>([])
  const [groupOffSet, setGroupOffSet] = useState<any>('')
  const [groupOption, setGroupOption] = useState<any>([])
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(0)
  const [selectedGroupId, setSelectedGroupId] = useState<any>('')
  const [selectedCategory, setSelectedCategory] = useState<any>('')
  const [selectedBrand, setSelectedBrand] = useState<any>('')
  const [searchByName, setSearchByName] = useState<string>('')

  const [dateRange, setDateRange] = useState<modalType>({
    startDateApi: '',
    startDateView: 'Select',
    endDateApi: '',
    endDateView: '',
  })
  const [initialSettings, setInitialSettings] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    autoUpdateInput: false,
    cancelClass: 'btn-secondary',
    drops: 'down',
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
    alwaysShowCalendars: true,
  })
  const [validation, setValidation] = useState<any>({
    dateRange: '',
    startDateView: '',
  })
  const [dateHandler, setDateHandler] = useState({
    firstDate: [moment().startOf('month').format('YYYY-MM-DD')],
    lastDate: [moment().endOf('month').format('YYYY-MM-DD')],
  })

  const debounceSearch = useDebounce(searchByName, 500); 

  const modalValueHandler = (e: any, checkBox: any) => {
    setDateRange((preValue: any) => ({
      ...preValue,
      startDateApi: e.startDateApi,
      endDateApi: e.endDateApi,
      startDateView: e.startDateView,
      endDateView: e.endDateView,
    }))

    setDateHandler({ firstDate: e.startDateApi, lastDate: e.endDateApi })
  }

  useEffect(() => {
    setTimeout(() => fetchExpiredProductList().then(), timeoutSeconds)
  }, [
    currentOffset,
    count,
    product.length,
    dateHandler.firstDate,
    dateHandler.lastDate,
    selectedBrand,
    selectedCategory,
    selectedGroupId,
    debounceSearch,
  ])

  const fetchExpiredProductList = async () => {
    setListLoader(true)
    const API = `${apiBaseURL()}reports/product_expiry/list/?warehouse_id=${currentWarehouseId}&ordering=name&search=${debounceSearch}&offset=${currentOffset}&brand_id=${selectedBrand}&category_id=${selectedCategory}&variants__group_name__id=${selectedGroupId}&start_date=${dateHandler.firstDate
      }&end_date=${dateHandler.lastDate}`
    const response = await getRequest(API, true)
    setProduct(response.results)
    setCount(response.count)
    setNext(response.next)
    setPrev(response.previous)
    setListLoader(false)
  }
  const CustomDateRangePicker = () => {
    return (
      <DateRangePicker
        initialSettings={initialSettings}
        onEvent={(e) => {
          e.preventDefault()
        }}
        onCallback={(start, end, label) => {
          setInitialSettings({
            startDate: start._d,
            endDate: end._d,
            cancelClass: 'btn-secondary',
            drops: 'down',
            autoUpdateInput: false,
            locale: {
              format: GLOBAL_DATE_FORMAT,
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
            alwaysShowCalendars: true,
          })

          let newStartDate = dateFormat(start._d)
          let newEndDate = dateFormat(end._d)

          let startDate = start._d
          let endDate = end._d

          let dateObj = {
            startDateView: newStartDate.displayDate,
            endDateView: newEndDate.displayDate,

            startDateApi: newStartDate.apiDate,
            endDateApi: newEndDate.apiDate,
          }
          modalValueHandler(dateObj, '')
        }}
        onCancel={(event: any, picker: any) => {
          setDateRange((preValue: any) => ({
            ...preValue,
            startDateView: 'Select',
          }))
        }}
      >
        <input
          id='datePickerFocus'
          type='text'
          onFocus={() => {
            // $('.daterangepicker').css('display', 'block')
          }}
          className={clsx('form-control calendar', validation.startDateView)}
          readOnly
          value={
            dateRange.startDateView === 'Select'
              ? [moment().startOf('month').format('DD MMM YYYY')] +
              '-' +
              [[moment().endOf('month').format('DD MMM YYYY')]]
              : dateRange.startDateView + ' - ' + dateRange.endDateView
          }
        />
      </DateRangePicker>
    )
  }
  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`,
      true
    )

    let hasMore: boolean = false

    if (categoriesResponse.next !== null) {
      const queryParams = new URLSearchParams(categoriesResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffset(newOffset)
      hasMore = true
    }

    if (categoryOption.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    if (categoriesResponse.results.length > 0) {
      categoriesResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          color: '#6d6e6f',
          bg: '#f7f7f7',
          transform: 'uppercase',
          boxSizing: 'borderBox',
          weight: '500',
          cursor: 'default',
          size: '.75rem !important',
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categoryOption.length > 0) {
      setCategoryOption(categoryOption.concat(options))
    } else {
      setCategoryOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }
  const fetchBrands = async (search?: any) => {
    return await fetchAsyncDropdown(
      brandOffset,
      setBrandOffset,
      brandOption,
      setBrandOption,
      `master/product/brand?`,
      search,
      setSearchSelect,
      '',
      true,
      'All Brands'
    )
  }
  const fetchGroups = async (search?: any) => {
    return await fetchAsyncDropdown(
      groupOffSet,
      setGroupOffSet,
      groupOption,
      setGroupOption,
      `master/product/group?`,
      search,
      setSearchSelect,
      '',
      true,
      'All Groups'
    )
  }
  const handleFilterOptionChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    switch (inputName) {
      case 'brand':
        setTimeoutSeconds(0)
        setSelectedBrand(inputValue)
        break
      case 'category':
        setTimeoutSeconds(0)
        setSelectedCategory(inputValue)
        break
      case 'group':
        setTimeoutSeconds(0)
        setSelectedGroupId(inputValue)
        break
      case 'search':
        setTimeoutSeconds(500)
        setSearchByName(inputValue)
        break
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }
  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const selectStyles = {
    menuList: (base: any) => ({
      ...base,
      maxHeight: 200,
    }),
  }

  return (
    <>
      <EATitle title='Expired Products -' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({ id: 'Expired Products Report' })}
      </PageTitle>

      <div>
        <div className='card'>
        {listLoadder && <IsLoader />}
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={(e: any) => handleFilterOptionChange(e, 'search')} />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0 d-flex me-3'>
                  <CustomDateRangePicker />
                </div>
                <AsyncPaginate
                    
                  loadOptions={fetchBrands}
                  isSearchable
                  styles={selectStyles}
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Brands'
                  name='brand'
                  onChange={(e: any) => handleFilterOptionChange(e, 'brand')}
                  theme={(theme) => ({
                    ...theme,
                    height: 200,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchCategory}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  // styles={categoryStyle}
                  styles={{
                    ...categoryStyle,
                    menuList: (base: any) => ({
                      ...base,
                      maxHeight: 200,
                    }),
                  }}
                  placeholder='All Categories'
                  name='category'
                  onChange={(e: any) => handleFilterOptionChange(e, 'category')}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchGroups}
                  isSearchable
                  styles={selectStyles}
                  className='react-select-container my-1 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Groups'
                  onChange={(e: any) => handleFilterOptionChange(e, 'group')}
                  name='group'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div
              className='table-responsive expandable'
              style={{ transition: '.5s linear all', borderCollapse: 'collapse' }}
              id='expandTable'
            >
              {product.length == 0 ? (
                    <NoRecords />
                  ) : (
                    <>
                      <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                        <thead>
                          <tr className=' text-muted fw-500'>
                            <th className='min-w-110px py-2'>Product SKU</th>
                            <th className='min-w-100px py-2'>Brand</th>
                            <th className='min-w-120px py-2'>Category</th>
                            <th className='min-w-130px py-2'>Product Group</th>
                            <th className='min-w-100px py-2'>Variant</th>
                            <th className='min-w-100px py-2'>Size</th>
                            <th className='min-w-100px py-2'>Expired Qty.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {product.map((item: any) => {
                            let approxWeight = 0

                            if (item.variants && item.variants.approx_weight > 0) {
                              approxWeight = item.variants.approx_weight / 1000
                            }

                            return (
                              <>
                                <tr
                                  data-bs-toggle='collapse'
                                  data-bs-target={`.row-collapse${item.id}`}
                                  aria-controls={`.rowCollapse${item.id}`}
                                  className='cursor-pointer'
                                >
                                  <SkuThumbnail skuName={item.name} src={item.product_thumbnail} />
                                  <td>{item.brand_id}</td>
                                  <td>
                                    <div>
                                      <div className='text-muted font-13 mb-1 font-medium'>
                                        {item.category_id && item.category_id.parent_category.name}
                                      </div>
                                      {item.category_id.name}
                                    </div>
                                  </td>
                                  <td>{item.group_name}</td>
                                  <td>{item.variants.name}</td>
                                  <td>
                                    {approxWeight > 0
                                      ? approxWeight + ' Kgs'
                                      : (item.measurement_unit.toLowerCase() === 'gram'
                                        ? item.size / 1000
                                        : item.size) + ' Kgs'}
                                  </td>
                                  <td>{`${numberFormat(item.total_qty).displayFormat}`}</td>
                                </tr>
                                <tr className='expandable-row'>
                                  <td className='reset-expansion-style' colSpan={11}>
                                    <div
                                      className={`row-expansion-style collapse row-collapse${item.id} collapsible`}
                                      id={`rowCollapse${item.id}`}
                                      data-bs-parent='#expandTable'
                                    >
                                      <div>
                                        <table className='table table-bordered gs-4 mb-0'>
                                          <thead>
                                            <tr className='text-muted fw-500 bg-light2'>
                                              <th className='w-200px py-2'>Batch Number</th>
                                              <th className='w-200px py-2'>Expired On</th>
                                              <th className='min-w-120px py-2'>Expired Qty.</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.report &&
                                              item.report.map((reportsData: any) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      {reportsData.batch_number
                                                        ? reportsData.batch_number
                                                        : '-'}
                                                    </td>
                                                    <td>
                                                      {reportsData.expired_on
                                                        ? dateFormat(reportsData.expired_on)
                                                          .displayDate
                                                        : '-'}
                                                    </td>
                                                    <td>
                                                      {reportsData.expired_qty
                                                        ? `${numberFormat(reportsData.expired_qty)
                                                          .displayFormat
                                                        }`
                                                        : ''}
                                                    </td>
                                                  </tr>
                                                )
                                              })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                          {/* end first row expand */}
                        </tbody>
                      </table>
                    </>
                  )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <div className='table-info py-5'>
          <div className='row g-0'>
            <div className='col'>
              {`Showing ${product.length == 0 ? 0 : selected * 10 + 1} to ${product.length == 0 ? 0 : product.length < 10 ? count : (selected + 1) * 10
                } of ${product.length == 0 ? 0 : count} entries`}
            </div>
            <div className='col'>
              <CustomPagination
                count={product.length == 0 ? 0 : count}
                selected={selected}
                numberClick={numberClick}
                prev={prevPage}
                next={nextPage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Addwarehouse /> */}
    </>
  )
}

export default ExpiredProducts
