
// app route file

import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { App } from "../App";
import { AuthPage, Logout } from "../modules/auth";
import { PrivateRoutes } from "./privateRoutes";

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const currentUser = localStorage.getItem("eafruitsLogin");  // login key


  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='logout' element={<Logout />} />
          {currentUser === "loginToEaFruits" ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={"/dashboard"} />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
