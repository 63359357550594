// all file imports

import {FC, lazy, Suspense, useEffect, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_eaFruitsDms/assets/ts/_utils'
import {checkPermissions, checkUserType, roleAndPermission} from '../../_eaFruitsDms/helpers'
import {MasterLayout} from '../../_eaFruitsDms/layout/MasterLayout'
import {getUserType} from '../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import Brands from '../pages/brands/brands'
import BlogAuthor from '../pages/cms/blogs/authors/authors'
import BlogCategory from '../pages/cms/blogs/category/category'
import AddPost from '../pages/cms/blogs/posts/addPost'
import BlogPosts from '../pages/cms/blogs/posts/posts'
import AddCurrentOpenings from '../pages/cms/career/currentOpenings/addCurrentOpenings'
import CurrentOpenings from '../pages/cms/career/currentOpenings/currentOpenings'
import ApplicationDetails from '../pages/cms/career/jobApplications/applicationDetailsPage'
import JobApplications from '../pages/cms/career/jobApplications/jobApplications'
import ContactUs from '../pages/cms/inquiries/contactUs/contactUs'
import InquiriesDetails from '../pages/cms/inquiries/contactUs/InquiriesDetails'
import Investors from '../pages/cms/inquiries/investors/investors'
import CmsTeam from '../pages/cms/ourTeam/team/team'
import CmsTeamMember from '../pages/cms/ourTeam/teamMember/teamMember'
import Products from '../pages/cms/products/products'
import UpdateProducts from '../pages/cms/products/updateProducts'
import PaymentIssues from '../pages/paymentIssues/paymentIssues'
import CreditIssues from '../pages/creditIssues/creditIssues'
import CreditNotes from '../pages/creditNotes/creditNotes'
import Targets from '../pages/targets/targets'
// import CmsTeam from '../pages/cms/ourTeam/team/team'
// import CmsTeamMember from '../pages/cms/ourTeam/teamMember/teamMember'
import AddSuccessStories from '../pages/cms/successStory.tsx/components/addSucessStory'
import EmployeesSuccessStories from '../pages/cms/successStory.tsx/employeeSuccessStory/employeeSuccessStory'
import FarmersSuccessStories from '../pages/cms/successStory.tsx/farmerSuccessStory/farmerSuccessStory'
import VendorsSuccessStories from '../pages/cms/successStory.tsx/vendorsSuccessStory/vendorsSuccessStory'
import AddTestimonials from '../pages/cms/testimonials/addTestimonials'
import Testimonials from '../pages/cms/testimonials/testimonials'
import {CurrentStockWrapper} from '../pages/currentStock/currentStockWrapper'
import TSCurrentStock from '../pages/currentStock/TScurrentStock'
import CustomerLocation from '../pages/customers/components/CustomerLocation'
import {CustomersWrapper} from '../pages/customers/customersWrapper'
import TSAddCustomer from '../pages/customers/TScustomers/addCustomer'
import AddOrderFeedback from '../pages/customers/TScustomers/components/addOrderFeedback'
import AddCustomer from '../pages/customers/WMcustomers/addCustomer'
import ApproveOnBoardingRequest from '../pages/customers/WMcustomers/approveOnBoardingRequest'
import CustomerReturnDetail from '../pages/customers/WMcustomers/components/customerReturnDetail'
import CreditRequest from '../pages/customers/WMcustomers/creditRequests'
import WMOnBoardingRequest from '../pages/customers/WMcustomers/OnBoardingRequest'
import SuspendedCustomers from '../pages/customers/WMcustomers/suspendedCustomers'
import VerificationRequest from '../pages/customers/WMcustomers/verificationRequest/verificationRequest'
import WMSingleCustomer from '../pages/customers/WMcustomers/WMsingleCustomer'
import {DashboardWrapper} from '../pages/dashboard/dashboardWrapper'
import LoadOutRequests from '../pages/loadOutRequests/loadOutRequets'
import LowStock from '../pages/lowStockSettings/lowStockSettings'
import AddressReason from '../pages/masters/addressReason/addressReason'
import Banks from '../pages/masters/banks/banks'
import BusinessDetailReason from '../pages/masters/businessDetailReason/businessDetailReason'
import CreditRejection from '../pages/masters/creditRejection/creditRejection'
import CreditScore from '../pages/masters/creditScore/creditScore'
import CustomerCategories from '../pages/masters/customerCategories/customerCategories'
import CustomerRejection from '../pages/masters/customerRejection/customerRejection'
import CustomerTags from '../pages/masters/customerTags/customerTags'
import DiscountRejection from '../pages/masters/discountRejection/discountRejection'
import District from '../pages/masters/district/district'
import GatePassRejection from '../pages/masters/gatePass/gatePassRejection'
import GrantAccessReason from '../pages/masters/grantAccessReason/grantAccessReason'
import LoadOutRequestCancel from '../pages/masters/LORequestCancel/loadOutRequestCancel'
import LoadOutRequestPartialApproval from '../pages/masters/LORequestPartialApproval/loadOutRequestPartialApproval'
import LoadOutRequestReject from '../pages/masters/LORequestReject/loadOutRequestReject'
import ManualStockUpdate from '../pages/masters/manualStockUpdate/manualStockUpdate'
import PackageMaterial from '../pages/masters/packageMaterial/packageMaterial'
import ProductCategoriesMaster from '../pages/masters/productCategories/productCategoriesMaster'
import ProductGroups from '../pages/masters/productGroups/productGroups'
import ProductVariants from '../pages/masters/productVariants/productVariants'
import RegionName from '../pages/masters/regions/component/regionName'
import Regions from '../pages/masters/regions/regions'
import RevokeAccessReason from '../pages/masters/revokeAccessReason/revokeAccessReason'
import StockinReason from '../pages/masters/stockinReason/stockinReason'
import Streets from '../pages/masters/street/streets'
import VehicleBreakdown from '../pages/masters/vehicleBreakdown/vehicleBreakdown'
import VehicleMake from '../pages/masters/vehicleMake/vehicleMake'
import VehicleModel from '../pages/masters/vehicleModel/vehicleModel'
import VehicleTypes from '../pages/masters/vehicleType/vehicleType'
import Wallets from '../pages/masters/wallets/wallets'
import Ward from '../pages/masters/ward/ward'
import BankAccounts from '../pages/paymentAccounts/bankAccounts'
import MobileWalletAccounts from '../pages/paymentAccounts/mobileWalletAccounts'
import {ProductSkuWrapper} from '../pages/prductSku/productSkuWrapper'
import WMProductSku from '../pages/prductSku/WMproductSku'
import WMProductSkuCatalog from '../pages/prductSku/WMproductSkuCatalog'
import ManagePreOrders from '../pages/preOrder/components/managePreOrders'
import PreOrderDetail from '../pages/preOrder/preOrderDetail'
import PreOrders from '../pages/preOrder/preOrders'
import CheckOrderDetail from '../pages/priceDiscount/components/checkOrderDetail'
import DiscountCapping from '../pages/priceDiscount/discountCapping'
import DiscountRequestList from '../pages/priceDiscount/discountRequest'
import ManagePrice from '../pages/priceDiscount/managePrice'
import PromotionalDiscount from '../pages/priceDiscount/promotionalDiscount'
import TSPromotionalDiscount from '../pages/priceDiscount/TSpromotionalDiscount'
import CreditIssueReports from '../pages/reports/creditIssueReport/creditIssueReports'
import CustomerReport from '../pages/reports/customerReport/customerReport'
import DailyStockInReport from '../pages/reports/dailyStockInReport/dailyStockInReport'
import DailyStockOutReport from '../pages/reports/dailyStockInReport/dailyStockOutReport'
import LowStockReport from '../pages/reports/lowStockReport/lowStockReport'
import ManualStockChangeReport from '../pages/reports/manualStockChangeReport/manualStockChangeReport'
import ProductSkuReports from '../pages/reports/productSkuReport/productSkuReport'
import RejectionReport from '../pages/reports/rejectionReport/rejectionReport'
import SalesReport from '../pages/reports/salesReport/salesReport'
import TeamReport from '../pages/reports/teamReport/teamReport'
import VehicalReport from '../pages/reports/vehicalReport/vehicalReport'
import VehicleBreakdownReport from '../pages/reports/vehicleBreakdownReport/vehicleBreakdownReport'
import VehicleUsageReport from '../pages/reports/vehicleUsageReport/vehicleUsageReport'
import FreshStockIn from '../pages/stockIn/freshStockIn'
import ReturnProductStockIn from '../pages/stockIn/returnProductStockIn'
import UnsoldProductStockIn from '../pages/stockIn/unsoldProductStockIn'
import WTransferStockIn from '../pages/stockIn/WTransferStockIn'
import LoadOutRequestsSR from '../pages/stockOut/loadOutRequests'
import WTransferStockOut from '../pages/stockOut/WTransferStockOut'
import RolesPermission from '../pages/superAdmin/rolesPermission/rolesPermission'
import UserAccounts from '../pages/superAdmin/userAccounts/userAccounts'
import SurveyFeedback from '../pages/surveyForms/surveyFeedback/surveyFeedback'
import {SurveyFormsWrapper} from '../pages/surveyForms/surveyFormsWrapper'
// import AddPost from '../pages/cms/blogs/posts/addPost'
import SurveyDetails from '../pages/surveyForms/surveyName/surveyDetails'
import SurveyName from '../pages/surveyForms/surveyName/surveyName'
import AddSurveyForm from '../pages/surveyForms/TSsurveyForms/components/addSurveyForms'
import TSSurveyFeedback from '../pages/surveyForms/TSsurveyForms/components/surveyFeedback'
import TSSurveyName from '../pages/surveyForms/TSsurveyForms/TSSurveyName'
import GeneralManager from '../pages/teamMembers/generalManager/GM'
import SalesRepresentative from '../pages/teamMembers/salesRepresentative/salesRepresentative'
import SalesSupervisor from '../pages/teamMembers/salesSupervisor/salesSupervisor'
import TeamMembersRedesign from '../pages/teamMembers/teamMembersRedesign'
import TeleSales from '../pages/teleSales/teleSales'
import SupportRequestTitle from '../pages/teleSales/teleSalesName/components/supportRequest/supportRequest'
import TeleSurveyName from '../pages/teleSales/teleSalesName/components/surveyFeedbackName/sureyName'
import TeleSalesName from '../pages/teleSales/teleSalesName/teleSalesName'
import {VehicleManagementWrapper} from '../pages/vehicleManagement/vehicleManagementWrapper'
import TSSingleWarehouse from '../pages/warehouse/TSWarehouses/singleWarehouse'
import TSWarehouses from '../pages/warehouse/TSWarehouses/TSWarehouses'
import SingleWareHouse from '../pages/warehouse/warehouseDC/singleWareHouse/singleWareHouse'
import WareHouseDc from '../pages/warehouse/warehouseDC/wareHouseDc'
import WareHouseRoles from '../pages/warehouse/warehouseRoles/wareHouseRoles'
import DiscountRequestRejection from '../pages/masters/discountRequestRejection/discountRequestRejection'
import DiscountRequestAmountUpdate from '../pages/masters/discountRequestAmounUpdate/discountRequestAmounUpdate'
import StockTransactions from '../pages/reports/stockTransactions/stockTransactions'
import CreditRequestDetail from '../pages/customers/WMcustomers/creditRequestsDetail'
import {ReturnedProductsWrapper} from '../pages/reports/returnedProducts/returnedProductsWrapper'
import DiscountUsageReports from '../pages/reports/discountUsageReport/discountUsageReport'
import BrandwiseSalesReport from '../pages/reports/brandwiseSalesReport/brandwiseSalesReport'
import ReportsBifurcation from '../pages/reports/reconciliationReport/components/ReportsBifurcation'
import {ReconciliationReportWrapper} from '../pages/reports/reconciliationReport/reconciliationReportWrapper'
import CreditUsageReports from '../pages/reports/creditUsageReport/creditUsageReports'
import PaymentIssueDetail from '../pages/paymentIssues/paymentIssueDetail'
import CreditIssuesDetail from '../pages/creditIssues/components/creditIssuesDetail'
import CreditNotesDetail from '../pages/creditNotes/components/creditNotesDetail'
import SalesInvoices from '../pages/salesInvoices/salesInvoices'
import SalesInvoiceDetail from '../pages/salesInvoices/components/salesInvoiceDetail'
import CaseSettlementReason from '../pages/masters/caseSettlementReason/caseSettlementReason'
import CreditRequestUpdateReason from '../pages/masters/creditRequestUpdateReason/creditRequestUpdateReason'
import CreditRequestRejectReason from '../pages/masters/creditRequestRejectReason/creditRequestRejectReason'
import CreditRequestCap from '../pages/customers/WMcustomers/creditRequestCap'
import OrderFeedback from '../pages/customers/WMcustomers/components/orderFeedback'
import CommissionDivision from '../pages/targetKPIsSettings/commissionDivision'
import GeneralKPI from '../pages/targetKPIsSettings/generalKPI'
import SupportRequestNewMessage from '../pages/supportRequest/newMessage'
import SupportRequestMyAssigned from '../pages/supportRequest/myAssigned'
import SupportRequestMyClosed from '../pages/supportRequest/myClosed'
import SupportRequestDetail from '../pages/supportRequest/supportRequestDetail'
import SupportRequestReason from '../pages/masters/supportRequest/supportRequestReason'
import NewMessages from '../pages/supportRequests/newMessages/newMessgaes'
import NewMessgaesDetail from '../pages/supportRequests/newMessages/newMessgaesDetail'
import MyAssigned from '../pages/supportRequests/myAssigned/myAssigned'
import MyAssignedDetail from '../pages/supportRequests/myAssigned/myAssignedDetail'
import MyClosed from '../pages/supportRequests/myClosed'
import {VehicleReportWrapper} from '../pages/reports/vehicalReport/vehicalReportWrapper'
import {ComparisonWrapper} from '../pages/reports/comparisonReport/comparisonWrapper'
import {SupportRequestWrapper} from '../pages/reports/supportRequestReport/supportRequestWrapper'
import OrderFeedbackReportWM from '../pages/reports/orderFeedbackReport/orderFeedbackReportWM'
import SalesRespTarget from '../pages/reports/salesRespTarget/salesRespTarget'
import {RejectionReportWrapper} from '../pages/reports/rejectionReport/rejectionReportWrapper'
import {ExpiredProductsWrapper} from '../pages/reports/expiredProducts/expiredProductsWrapper'
import CreditUsageReport from '../pages/reports/creditUsageReport/creditUsageReport'
import TargetReport from '../pages/reports/targetReport/targetReport'
import CreditRequestReport from '../pages/reports/creditRequestReport/creditRequestReport'
import IssuesFromSG from '../pages/reports/issuesFromSG/issuesFromSG'
import CreditCappingUpdate from '../pages/reports/creditCappingUpdates/creditCappingUpdate'
import DailySalesReport from '../pages/reports/dailySalesReport/dailySalesReport'
import SurveyFeedbackReport from '../pages/reports/surveyFeedbackReport/surveyFeedbackReport'
import SalesOrdersFeedbackReport from '../pages/reports/salesOrdersFeedbackReport/salesOrdersFeedbackReport'
import PreOrdersReport from '../pages/reports/preOrdersReport/preOrdersReport'
import NewCustomersReport from '../pages/reports/newCustomersReport/newCustomersReport'
import RealTimeSalesReport from '../pages/reports/realTimeSalesReport/realTimeSalesReport'
import GatePassReport from '../pages/reports/gatepassReport/gatePassReport'
import PayLaterTodayReport from '../pages/reports/payLaterTodayReport/payLaterTodayReport'
import OrderQuantityRequest from '../pages/customers/WMcustomers/orderQuantityRequest'
import OrderQuantityRequestDetail from '../pages/customers/WMcustomers/orderQuantityRequestDetail'
import OrderQuantityRequestReason from '../pages/masters/orderQuantityReason/orderQuantityRequestReason'
import OddoConfigure from '../pages/masters/odooConfigure/oddoConfigurationWrapper'
import SalesTeam from '../pages/masters/salesTeam/salesTeam'
import IncentiveComponents from '../pages/masters/incentiveComponents/incentiveComponents'
import TargetFlagReason from '../pages/masters/targetFlagReason/TargetFlagReason'
import VehicleToVehicleReason from '../pages/masters/vehicleToVehicleReason/VehicleToVehicleReason'
import WMHorecaContractDetail from '../pages/customers/WMcustomers/horecaContract/components/horecaContractDetail'
import {HorecaContractWrapper} from '../pages/customers/horecaContractWrapper'
import CreditCollectionReport from '../pages/creditCollectionReport/creditCollectionReport'
import VehicleToVehiclePartiallyReason from '../pages/masters/vehicleToVehiclePartiallyReason/VehicleToVehiclePartiallyReason'
import DormantCustomerReport from '../pages/dormantCustomerReport/dormantCustomerReport'
import NoOrderingCustomerReport from '../pages/noOrderingCustomerReport/NoOrderingCustomerReport'
import SupportRequestTypeReason from '../pages/masters/supportRequestTypeReason/SupportRequestTypeReason'
import Containers from '../pages/containers/containers'
import SummaryReport from '../pages/summaryReport/summaryReport'
import TempHumidityReport from '../pages/reports/tempHumidityReport/TempHumidityReport'
import TempHumidityAlertReport from '../pages/reports/tempHumidityAlertReport/TempHumidityAlertReport'
import WeatherBoxAlertLogsReason from '../pages/masters/weatherBoxAlertLogsReason/WeatherBoxAlertLogsReason'
import WholesalerQuantitySettings from '../pages/wholesalerQuantitySettings/wholesalerQuantitySettings'
import OrderQuantity from '../pages/customers/WMcustomers/orderQuantity/OrderQuantity'
import OrderLimitReason from '../pages/masters/orderLimitReason/OrderLimitReason'
import OrderLimitAccessRequest from '../pages/customers/WMcustomers/orderLimitAccessRequest/OrderLimitAccessRequest'
import OrderLimitAccessDetails from '../pages/customers/WMcustomers/orderLimitAccessRequest/components/orderLimitAccessDetails'
import OrderLimitRequestReport from '../pages/reports/orderLimitRequestReport/orderLimitRequestReport'
import AttendanceReport from '../pages/attendanceReport/attendanceReport'
import Complaints from '../pages/complaints/complaints'
import ComplaintsDetails from '../pages/complaints/complaintsDetails/complaintsDetails'
import CallNotes from '../pages/callNotes/callNotes'
import CustomerOrderLimitReport from '../pages/reports/customerOrderLimitReport/customerOrderLimitReport'
import SrDailyRoutesReport from '../pages/srDailyRoutesReport/srDailyRoutesReport'
import SrDailyRoutesDetails from '../pages/srDailyRoutesReport/routesDetails'
import BananaStockIn from '../pages/stockIn/bananastockIn'
import BananaOffLoad from '../pages/bananaOffLoad/bananaOffLoad'
import BananaTransfer from '../pages/bananaTransfer/bananaTransfer'
import OffloadReport from '../pages/reports/offloadReport/offloadReport'
import OrderReport from '../pages/reports/orderReport/order-report'
import RouteManagement from '../pages/routeManagement/routeManagement'
import RouteDetails from '../pages/routeManagement/routeDetails'
import RouteRequest from '../pages/routeManagement/routeRequest'
import RouteRequestDetails from '../pages/routeManagement/routeRequestDetails'
import RouteRequestReason from '../pages/masters/routeRequestReason/RouteRequestReason'
import CommonReasonMaster from '../pages/masters/commonReason/commonReason'
import RouteJumpReport from '../pages/reports/routeJumpReport/routeJumpReport'
import RouteJumpDetails from '../pages/reports/routeJumpReport/routeJumpDetails'
import SokoPoints from '../pages/masters/sokoPoints/sokoPoints'
import RewardOffers from '../pages/masters/sokoPoints/rewardOffers'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const savePermissions = localStorage.getItem('savePermissions')

  let checkSavePermissions = savePermissions ? JSON.parse(savePermissions) : false

  const managePermission = (param: string) => {
    return checkSavePermissions && checkSavePermissions[param] ? checkSavePermissions[param] : false
  }

  const [warehouseDC, setWarehouseDC] = useState(managePermission('warehouse_and_dc'))
  const [warehouseRoles, setWarehouseRoles] = useState(managePermission('warehouse_roles'))
  const [brands, setBrands] = useState(managePermission('brands'))
  const [customer, setCustomer] = useState(managePermission('customers'))
  const [productSkus, setProductSkus] = useState(managePermission('product_SKUs'))
  const [reportProductSkus, setReportProductSkus] = useState(
    managePermission('report_product_SKUs')
  )
  const [reportSales, setReportSales] = useState(managePermission('Report_sales'))
  const [reportCreditIssues, setReportCreditIssues] = useState(
    managePermission('report_credit_issues')
  )
  const [reportTeam, setReportTeam] = useState(managePermission('report_team'))
  const [reportVehicles, setReportVehicles] = useState(managePermission('report_vehicles'))
  const [reportComparisons, setReportComparisons] = useState(false)
  const [reportRejections, setReportRejections] = useState(managePermission('report_rejections'))
  const [teleSales, setTeleSales] = useState(managePermission('telesales'))
  const [surveyForms, setSurveyForms] = useState(managePermission('survey_forms'))
  const [saUserAccounts, setSaUserAccounts] = useState(
    managePermission('super_admin_user_accounts')
  )
  const [saRolesPermission, setSaRolesPermission] = useState(
    managePermission('super_admin_roles_and_permissions')
  )
  const [masterDb, setMasterDb] = useState(managePermission('masters_distribution_boundaries'))
  const [masterPm, setMasterPm] = useState(managePermission('masters_products'))
  const [masterCm, setMasterCm] = useState(managePermission('masters_customers'))
  const [masterRm, setMasterRm] = useState(managePermission('masters_rejection_options'))
  const [masterVb, setMasterVb] = useState(managePermission('masters_vehicle_breakdown_options'))
  const [cms, setCms] = useState(managePermission('cms'))
  const [showteam, setShowTeam] = useState(managePermission('team_member'))
  const [showCustomer, setShowCustomer] = useState(managePermission('customer'))
  const [showSuspCustomer, setShowSuspCustomer] = useState(managePermission('suspended_customer'))
  const [showVerCustomer, setShowVerCustomer] = useState(
    managePermission('customer_verification_request')
  )
  const [showProduct, setShowProduct] = useState(managePermission('product_SKUs'))
  const [showPriceManage, setShowPriceManage] = useState(
    managePermission('price_and_discount_price_management')
  )
  const [showPriceCapping, setShowPriceCapping] = useState(
    managePermission('price_and_discount_discount_capping_management')
  )
  const [showPriceDiscount, setShowPriceDiscount] = useState(
    managePermission('price_and_discount_promotional_discount_management')
  )
  const [showVehical, setShowVehical] = useState(managePermission('vehicle_management'))
  const [showLowSock, setShowLowSock] = useState(managePermission('stock_qty_settings'))
  const [showBank, setShowBank] = useState(managePermission('payment_account'))
  const [showKPIGeneral, setShowKPIGeneral] = useState(managePermission('sales_reps_target'))

  const fetchPermissions = async () => {
    let res: any

    res = await checkPermissions('warehouse_and_dc')
    if (res && (res.read || res.write)) {
      setWarehouseDC(true)
    }

    res = await checkPermissions('warehouse_roles')
    if (res && (res.read || res.write)) {
      setWarehouseRoles(true)
    }

    res = await checkPermissions('brands')
    if (res && (res.read || res.write)) {
      setBrands(true)
    }

    res = await checkPermissions('customers')
    if (res && (res.read || res.write)) {
      setCustomer(true)
    }

    res = await checkPermissions('report_product_SKUs')
    if (res && (res.read || res.write)) {
      setReportProductSkus(true)
    }

    res = await checkPermissions('Report_sales')
    if (res && (res.read || res.write)) {
      setReportSales(true)
    }

    res = await checkPermissions('report_credit_issues')
    if (res && (res.read || res.write)) {
      setReportCreditIssues(true)
    }

    res = await checkPermissions('report_team')
    if (res && (res.read || res.write)) {
      setReportTeam(true)
    }

    res = await checkPermissions('report_vehicles')
    if (res && (res.read || res.write)) {
      setReportVehicles(true)
    }

    res = await checkPermissions('report_rejections')
    if (res && (res.read || res.write)) {
      setReportRejections(true)
    }

    res = await checkPermissions('product_SKUs')
    if (res && (res.read || res.write)) {
      setProductSkus(true)
    }

    res = await checkPermissions('telesales')
    if (res && (res.read || res.write)) {
      setTeleSales(true)
    }

    res = await checkPermissions('survey_forms')
    if (res && (res.read || res.write)) {
      setSurveyForms(true)
    }

    res = await checkPermissions('super_admin_user_accounts')
    if (res && (res.read || res.write)) {
      setSaUserAccounts(true)
    }

    res = await checkPermissions('super_admin_roles_and_permissions')
    if (res && (res.read || res.write)) {
      setSaRolesPermission(true)
    }

    res = await checkPermissions('masters_distribution_boundaries')
    if (res && (res.read || res.write)) {
      setMasterDb(true)
    }

    res = await checkPermissions('masters_products')
    if (res && (res.read || res.write)) {
      setMasterPm(true)
    }

    res = await checkPermissions('masters_customers')
    if (res && (res.read || res.write)) {
      setMasterCm(true)
    }

    res = await checkPermissions('masters_rejection_options')
    if (res && (res.read || res.write)) {
      setMasterRm(true)
    }

    res = await checkPermissions('masters_vehicle_breakdown_options')
    if (res && (res.read || res.write)) {
      setMasterVb(true)
    }

    res = await checkPermissions('cms')
    if (res && (res.read || res.write)) {
      setCms(true)
    }

    // warehouse routes permission
    res = await checkPermissions('team_member')
    if (res && (res.read || res.write)) {
      setShowTeam(true)
    }

    res = await checkPermissions('customer')
    if (res && (res.read || res.write)) {
      setShowCustomer(true)
    }

    res = await checkPermissions('customer_verification_request')
    if (res && (res.read || res.write)) {
      setShowVerCustomer(true)
    }

    res = await checkPermissions('suspended_customer')
    if (res && (res.read || res.write)) {
      setShowSuspCustomer(true)
    }

    res = await checkPermissions('product_SKUs')
    if (res && (res.read || res.write)) {
      setShowProduct(true)
    }

    res = await checkPermissions('price_and_discount_price_management')
    if (res && (res.read || res.write)) {
      setShowPriceManage(true)
    }

    res = await checkPermissions('price_and_discount_discount_capping_management')
    if (res && (res.read || res.write)) {
      setShowPriceCapping(true)
    }

    res = await checkPermissions('price_and_discount_promotional_discount_management')
    if (res && (res.read || res.write)) {
      setShowPriceDiscount(true)
    }

    res = await checkPermissions('vehicle_management')
    if (res && (res.read || res.write)) {
      setShowVehical(true)
    }

    res = await checkPermissions('stock_qty_settings')
    if (res && (res.read || res.write)) {
      setShowLowSock(true)
    }

    res = await checkPermissions('payment_account')
    if (res && (res.read || res.write)) {
      setShowBank(true)
    }

    res = await checkPermissions('sales_reps_target')
    if (res && (res.read || res.write)) {
      setShowKPIGeneral(true)
    }
  }

  useEffect(() => {
    permissionHandler()
  }, [])

  const permissionHandler = async () => {
    const res = await roleAndPermission()
    if (res.length > 0) {
      fetchPermissions().then()
    }
  }

  return (
    <Routes>
      <Route path='customers/location' element={<CustomerLocation />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={'/dashboard'} />} />
        <Route path='*' element={<Navigate to={'/dashboard'} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} /> {/*  DASHBOARD */}
        {warehouseDC == true || warehouseRoles == true ? (
          <Route path='warehouse/*'>
            {' '}
            {/*  WAREHOUSE WITH SUBMENU */}
            {warehouseDC == true && (
              <Route path='DC/*' element={<WareHouseDc className='warehousedc' />} />
            )}
            <Route path='DC/singlewarehousename/*' element={<SingleWareHouse />} />
            {/* {warehouseRoles == true && ( */}
            <Route path='roles' element={<WareHouseRoles className='Warehouseroles' />} />
            {/* // )} */}
            {warehouseDC == true ? (
              <Route index element={<Navigate to='/warehouse/DC' />} />
            ) : (
              <Route index element={<Navigate to='/warehouse/roles' />} />
            )}
            {warehouseDC ? (
              <Route path='*' element={<Navigate to='/warehouse/DC' />} />
            ) : (
              <Route path='*' element={<Navigate to='/warehouse/DC' />} />
            )}
          </Route>
        ) : null}
        {/* <Route path='team-members/*' element={<TeamMembers className='teammember-page' />} />{' '} */}
        {showteam && <Route path='team-members/*' element={<TeamMembersRedesign />} />}
        {/* <Route path='team-members-redesign/*' element={<TeamMembersRedesign />} />{' '} */}
        {/*  Teammembers PAGE */}
        {showteam && (
          <Route path='team-members/member-detail/*' element={<GeneralManager className='gm' />} />
        )}
        {/*  Teammembers /GM */}
        {showteam && (
          <Route
            path='team-members/sales-representative/*'
            element={<SalesRepresentative className='sw' />}
          />
        )}
        {showteam && (
          <Route
            path='team-members/sales-supervisor/*'
            element={<SalesSupervisor className='ss' />}
          />
        )}
        {/* WM Price and discounts menus */}
        {showPriceCapping || showPriceManage || showPriceDiscount ? (
          <Route path='price/*'>
            {showPriceManage && (
              <Route path='manage-price' element={<ManagePrice className='manage-price' />} />
            )}
            {/*  Manage Price PAGE */}
            <Route
              path='discount-request/*'
              element={<DiscountRequestList className='discount-request' />}
            />
            {/*  Discount Request PAGE */}
            <Route
              path='discount-request/check-order-detail'
              element={<CheckOrderDetail className='discount-request' />}
            />
            {/*  Discount Request PAGE */}
            {showPriceCapping && (
              <Route
                path='discount-capping'
                element={<DiscountCapping className='discount-capping' />}
              />
            )}
            {/*  Discount Capping PAGE */}
            {showPriceDiscount && (
              <Route
                path='promotional-discount'
                element={<PromotionalDiscount className='promotional-discount' />}
              />
            )}
            {/*Promotional  Discount PAGE */}
            <Route path='*' element={<Navigate to='manage-price' />} />
          </Route>
        ) : null}
        {/* TS promotional discount */}
        <Route
          path='promotional-discount'
          element={<TSPromotionalDiscount className='promotional-discount' />}
        />{' '}
        {/*Promotional  Discount PAGE */}
        {/* low stock menu */}
        {showLowSock && <Route path='low-stock/' element={<LowStock className='low-stock' />} />}
        <Route path='wholesaler-quantity-settings' element={<WholesalerQuantitySettings />} />
        {/* low stock menu PAGE */}
        {/* OO currrent stock */}
        <Route
          path='current-stock'
          element={<CurrentStockWrapper className='current-stock' />}
        />{' '}
        {/*  oo user currrent stock  */}
        <Route path='banana-group-stock-in' element={<BananaStockIn />} />
        <Route path='banana-off-load' element={<BananaOffLoad />} />
        <Route path='banana-transfer' element={<BananaTransfer />} />
        {/* TS currrent stock */}
        <Route
          path='current-stock-and-pricing'
          element={<TSCurrentStock className='telesales-cs' />}
        />
        <Route path='orders' element={<PreOrders className='orders' />} />
        <Route path='orders/order-detail' element={<PreOrderDetail className='orders' />} />
        <Route path='orders/manage-order' element={<ManagePreOrders className='orders' />} />
        {/* OO stock in pages */}
        <Route
          path='fresh-product-stock-in'
          element={<FreshStockIn className='fresh-product-stock-stockin' />}
        />{' '}
        {/*oo user fresh-product-stock-in*/}
        <Route
          path='warehouse-transfer-stock-in'
          element={<WTransferStockIn className='warehouse-transfer-stockin' />}
        />{' '}
        {/*oo user fresh-product-stock-in*/}
        <Route
          path='unsold-product-stock-in'
          element={<UnsoldProductStockIn className='unsold-pro-stockin' />}
        />{' '}
        {/*oo user fresh-product-stock-in*/}
        <Route
          path='returned-product-stock-in'
          element={<ReturnProductStockIn className='return-pro-stockin' />}
        />{' '}
        {/*oo user return-product-stock-in*/}
        {/* OO stock out pages*/}
        <Route
          path='load-out-requests-from-SR'
          element={<LoadOutRequestsSR className='load-out-request' />}
        />{' '}
        {/*OO load-out-request*/}
        <Route
          path='warehouse-transfer-stock-out'
          element={<WTransferStockOut className='warehouse-transfer-stock-out' />}
        />{' '}
        {/*OO load-out-request*/}
        {/* OO load request page */}
        <Route
          path='load-out-requests'
          element={<LoadOutRequests className='load-out-requests' />}
        />{' '}
        {/*OO load-out-request*/}
        {/*OO containers*/}
        <Route path='containers' element={<Containers />} /> {/*OO containers*/}
        {getUserType == 'WM' || getUserType == 'TS' ? (
          <>
            <Route path='customers/*' element={<CustomersWrapper className='customer-page' />} />
            {/*  CUSTOMER PAGE */}
            <Route path='customers/singleCustomer/*' element={<WMSingleCustomer />} />
            <Route
              path='customers/singleCustomer/sales-order/order-details'
              element={<SalesInvoiceDetail />}
            />
            <Route path='customers/suspended-customers/*' element={<WMSingleCustomer />} />
            {/* CUSTOMER / SINGLE CUSTOMER */}
            <Route
              path='customers/return/customer-return-detail'
              element={<CustomerReturnDetail />}
            />
            {/* CUSTOMER / SINGLE CUSTOMER / RETURN / RETURN DETAIL*/}
            <Route path='customers/manage-customer' element={<AddCustomer />} />
            {/* CUSTOMER / CREAT CUSTOMER */}
            <Route
              path='customers/suspended-customers'
              element={<SuspendedCustomers className='suspended-customer' />}
            />
            <Route
              path='customers/credit-request-cap'
              element={<CreditRequestCap className='credit-request-cap' />}
            />
          </>
        ) : (
          <>
            {customer == true && (
              <Route path='customers/*' element={<CustomersWrapper className='customer-page' />} />
            )}{' '}
            {/*CUSTOMER PAGE */}
            {customer == true && (
              <>
                <Route path='customers/singleCustomer/*' element={<WMSingleCustomer />} />
                <Route path='customers/suspended-customers/*' element={<WMSingleCustomer />} />
              </>
            )}{' '}
            {/* CUSTOMER / SINGLE CUSTOMER */}
            {customer == true && (
              <Route
                path='customers/return/customer-return-detail'
                element={<CustomerReturnDetail />}
              />
            )}{' '}
            {/* CUSTOMER / SINGLE CUSTOMER / RETURN / RETURN DETAIL*/}
            {customer == true && (
              <Route path='customers/add-customer' element={<AddCustomer />} />
            )}{' '}
            {/* CUSTOMER / CREAT CUSTOMER */}
          </>
        )}
        {getUserType == 'TS' ? (
          <>
            <Route path='customers/*' element={<CustomersWrapper className='customer-page' />} />{' '}
            {/*  CUSTOMER PAGE */}
            <Route path='customers/manage-customer' element={<AddCustomer />} />{' '}
            {/* CUSTOMER / CREAT CUSTOMER */}
            <Route path='customers/singleCustomer/*' element={<WMSingleCustomer />} />{' '}
            <Route path='customers/suspended-customers/*' element={<WMSingleCustomer />} />
            {/* CUSTOMER / SINGLE CUSTOMER */}
            <Route
              path='customers/sales-order/order-feedback'
              element={<AddOrderFeedback />}
            />{' '}
            {/* CUSTOMER / SINGLE CUSTOMER / RETURN / RETURN DETAIL*/}
          </>
        ) : (
          <>
            {customer == true && (
              <Route path='customers/*' element={<CustomersWrapper className='customer-page' />} />
            )}{' '}
            {/*CUSTOMER PAGE */}
            {customer == true && (
              <Route path='customers/add-customer' element={<TSAddCustomer />} />
            )}{' '}
            {/*CUSTOMER PAGE */}
          </>
        )}
        <Route
          path='customers/verification-requests'
          element={<WMOnBoardingRequest className='no-boarding' />}
        />
        <Route path='customers/verification-requests/*' element={<VerificationRequest />} />
        <Route
          path='customers/on-boarding-requests/approve-on-boarding-request'
          element={<ApproveOnBoardingRequest />}
        />
        <Route
          path='customers/credit-requests/'
          element={<CreditRequest className='credi-requets' />}
        ></Route>
        <Route
          path='customers/credit-requests/credit-request-detail'
          element={<CreditRequestDetail className='credit' />}
        ></Route>
        <Route
          path='customers/credit-request-cap'
          element={<CreditRequestCap className='credit' />}
        ></Route>
        <Route
          path='customers/suspended-customers'
          element={<SuspendedCustomers className='suspended-customer' />}
        />
        <Route
          path='customers/order-quantity-requests'
          element={<OrderQuantityRequest className='order-quantity' />}
        ></Route>
        <Route path='customers/special-order-limit' element={<OrderQuantity />}></Route>
        <Route
          path='customers/order-limit-access-request'
          element={<OrderLimitAccessRequest />}
        ></Route>
        <Route
          path='customers/order-limit-access-request-details'
          element={<OrderLimitAccessDetails />}
        ></Route>
        <Route
          path='customers/horeca-contracts'
          element={<HorecaContractWrapper className='horeca' />}
        ></Route>
        <Route
          path='customers/horeca-contracts/horeca-contract-detail'
          element={<WMHorecaContractDetail />}
        ></Route>
        <Route
          path='customers/order-quantity-requests/quantity-request-detail'
          element={<OrderQuantityRequestDetail />}
        ></Route>
        {teleSales == true && (
          <Route path='tele-sales/*'>
            {' '}
            {/*  TELE SALES */}
            <Route index element={<TeleSales />} />
            <Route path='tele-sales-name/*' element={<TeleSalesName />} />
            <Route path='complaint-title/*' element={<SupportRequestTitle />} />
            <Route path='survey-name' element={<TeleSurveyName />} />
            <Route path='*' element={<Navigate to='/tele-sales' />} />
          </Route>
        )}
        {brands == true && <Route path='brands' element={<Brands className='brands' />} />}{' '}
        {/*  BRANDS */}
        {productSkus == true && <Route path='products' element={<ProductSkuWrapper />} />}{' '}
        {/*  PRODUCTS SKUS */}
        {/* TS support requests */}
        <Route path='complaints/*'>
          <Route path='new-messages/' element={<NewMessages />} />
          <Route path='new-messages/*' element={<NewMessgaesDetail />} />
          <Route path='my-assigned/' element={<MyAssigned />} />
          <Route path='my-assigned/*' element={<MyAssignedDetail />} />
          <Route path='my-closed' element={<MyClosed />} />
        </Route>
        {/* wm - product skus  */}
        {getUserType === 'WM' && showProduct && (
          <Route path='products/*'>
            <Route
              path='current-stock'
              element={<WMProductSku className='wm-admin-productsku' />}
            />
            <Route
              path='product-catalog'
              element={<WMProductSkuCatalog className='wm-admin-productsku' />}
            />
            <Route path='*' element={<Navigate to='/products/current-stock' />} />
          </Route>
        )}
        {/* {surveyForms == "true" &&
          <Route path='survey-forms/*'>

            <Route index element={<SurveyForms />} />
            <Route path='*' element={<Navigate to="/dashboard" />} />
          </Route>
          } */}
        {getUserType === 'SA' && (
          <Route path='survey-forms/*'>
            <Route path='survey-feedback' element={<SurveyFeedback />} />
            <Route
              path='survey-feedback-name'
              element={<SurveyName className='survey-feedback-name' />}
            />
            <Route path='survey-detail' element={<SurveyDetails />} />
            <Route index element={<SurveyFormsWrapper />} />
            <Route path='*' element={<Navigate to={'/survey-forms'} />} />
          </Route>
        )}
        {checkUserType(['ts', 'wm']) && (
          <Route path='survey-forms/*'>
            <Route path='survey-feedback' element={<TSSurveyFeedback />} />
            <Route
              path='survey-feedback/survey-feedback-detail'
              element={<TSSurveyName className='telesales' />}
            />
            <Route path='add-survey-form' element={<AddSurveyForm />} />
            <Route index element={<SurveyFormsWrapper />} />
          </Route>
        )}
        {/*  */}
        {checkUserType(['wm']) && (
          <>
            <Route path='route/*'>
              <Route path='all-routes' element={<RouteManagement />} />
              <Route path='all-route-details' element={<RouteDetails />} />

              <Route path='route-requests' element={<RouteRequest />} />
              <Route path='route-request-details' element={<RouteRequestDetails />} />

              <Route path='*' element={<Navigate to={'/'} />} />
            </Route>
          </>
        )}
        {/*  PRODUCTS SKUS */}
        {/* <Route path='survey-forms/*'>



        </Route> */}
        {/* support Request in ts */}
        <Route path='complaint/*'>
          <Route
            path='new-message/complaint-detail/*'
            element={<SupportRequestDetail className='complaint-detail' />}
          />
          <Route
            path='my-assigned/complaint-detail/*'
            element={<SupportRequestDetail className='complaint-detail' />}
          />
          <Route
            path='my-closed/complaint-detail/*'
            element={<SupportRequestDetail className='complaint-detail' />}
          />
          <Route path='new-message' element={<SupportRequestNewMessage />} />
          <Route path='my-assigned' element={<SupportRequestMyAssigned />} />
          <Route path='my-closed' element={<SupportRequestMyClosed />} />
          <Route path='*' element={<Navigate to='/complaint/new-message' />} />
        </Route>
        {/* <Route path='*' element={<Navigate to="/" />} /> */}
        {/* </Route> */}
        <Route path='brands' element={<Brands className='brands' />} /> {/*  BRANDS */}
        <Route path='all-call-notes' element={<CallNotes />} /> {/*  call notes */}
        <Route path='products' element={<ProductSkuWrapper />} /> {/*  PRODUCTS SKUS */}
        {/* LO user vehicle management menu start*/}
        <Route path='vehicle-management' element={<VehicleManagementWrapper />} />
        {/* LO user vehicle management menu end*/}
        {/* TS & WM complaints */}
        <Route path='all-complaints/*' element={<Complaints />} />
        <Route path='all-complaints-details/*' element={<ComplaintsDetails />} />
        {showBank && (
          <Route path='payment-accounts/*'>
            <Route path='bank-accounts' element={<BankAccounts className='banks' />} />
            <Route
              path='mobile-wallet-accounts'
              element={<MobileWalletAccounts className='wallets' />}
            />
            <Route path='*' element={<Navigate to='/payment-accounts/bank-accounts' />} />
          </Route>
        )}
        {showKPIGeneral && (
          <Route path='target-kpi-settings/*'>
            <Route path='general-kpi' element={<GeneralKPI className='general-kpi' />} />
            <Route
              path='commission-divisions'
              element={<CommissionDivision className='commission-divisions' />}
            />
            <Route path='*' element={<Navigate to='/target-kpi-settings/commission-divisions' />} />
          </Route>
        )}
        {/* AM menus */}
        <Route path='payment-issues/*' element={<PaymentIssues />} />
        <Route
          path='payment-issues/payment-issue-detail/*'
          element={<PaymentIssueDetail className='payment-detail' />}
        />
        <Route path='credit-issue/order-detail' element={<PreOrderDetail className='orders' />} />
        <Route path='credit-issues' element={<CreditIssues />} />
        <Route path='credit-issues/credit-issues-detail' element={<CreditIssuesDetail />} />
        <Route path='credit-issue/credit-issues-detail' element={<CreditIssuesDetail />} />
        <Route path='credit-issues/detail' element={<CreditIssuesDetail />} />
        <Route path='credit-notes' element={<CreditNotes />} />
        <Route path='credit-notes/credit-notes-detail' element={<CreditNotesDetail />} />
        <Route path='sales-invoices' element={<SalesInvoices />} />
        <Route path='sales-invoices/sales-invoice-detail' element={<SalesInvoiceDetail />} />
        <Route path='sales-invoices/sales-invoice-details' element={<SalesInvoiceDetail />} />
        <Route path='targets' element={<Targets />} />
        <Route path='sales-invoices/order-feedback' element={<OrderFeedback />} />
        {/* CreditIssuesDetail */}
        <Route path='odoo-configuration/*' element={<OddoConfigure />} />
        <Route path='reports/*'>
          {' '}
          {/* Super admin REPORTS WITH SUBMENU */}
          <Route
            path='product-skus-report'
            element={<ProductSkuReports className='product-skus-report' />}
          />
          <Route path='credit-issues' element={<CreditIssues />} />
          <Route path='credit-collection-report' element={<CreditCollectionReport />} />
          <Route path='credit-issues/credit-issues-detail' element={<CreditIssuesDetail />} />
          <Route
            path='credit-collection-report/credit-issues-detail'
            element={<CreditIssuesDetail />}
          />
          <Route path='payment-issues' element={<PaymentIssues />} />
          <Route
            path='payment-issues/payment-issue-detail/*'
            element={<PaymentIssueDetail className='payment-detail' />}
          />
          <Route path='sales-report' element={<SalesReport className='sales-report' />} />
          <Route
            path='credit-issues-report'
            element={<CreditIssueReports className='credit-issue-reports' />}
          />
          <Route
            path='credit-usage-report'
            element={<CreditUsageReports className='credit-usage-reports' />}
          />
          <Route path='team-report' element={<TeamReport className='team-reports' />} />
          <Route
            path='vehicle-usage-report'
            element={<VehicleReportWrapper className='vehical-reports' />}
          />
          <Route
            path='comparison-report'
            element={<ComparisonWrapper className='comparison-reports' />}
          />
          <Route
            path='complaint-report'
            element={<SupportRequestWrapper className='SUpportRequestWrapper' />}
          />
          <Route path='sr-daily-routes-report' element={<SrDailyRoutesReport />} />
          <Route path='sr-daily-routes' element={<SrDailyRoutesDetails />} />
          <Route path='order-feedback-report' element={<OrderFeedbackReportWM />} />
          <Route path='sales-resp-target' element={<SalesRespTarget className='test' />} />
          <Route
            path='rejection-report/*'
            element={<RejectionReportWrapper className='rejection-reports' />}
          />
          {/* OO user REPORTS SUBMENU*/}
          <Route
            path='daily-stock-in-report'
            element={<DailyStockInReport className='daily-stock-in-report' />}
          />
          <Route path='temperature-humidity-report' element={<TempHumidityReport />} />
          <Route
            path='daily-stock-out-report'
            element={<DailyStockOutReport className='daily-stock-out-report' />}
          />
          <Route
            path='manual-stock-change-report'
            element={<ManualStockChangeReport className='manual-stock-change-report' />}
          />
          <Route path='reconciliation-report' element={<ReconciliationReportWrapper />} />
          <Route
            path='reconciliation-report/reports-bifurcation'
            element={<ReportsBifurcation className='report-bifurcation' />}
          />
          <Route path='low-stock-report' element={<LowStockReport className='low-stock' />} />
          <Route
            path='brandwise-sales'
            element={<BrandwiseSalesReport className='brandwise-sales' />}
          />
          <Route
            path='discount-usage-report'
            element={<DiscountUsageReports className='discount-usage-report' />}
          />
          <Route path='expired-products' element={<ExpiredProductsWrapper />} />
          <Route path='returned-products' element={<ReturnedProductsWrapper />} />
          <Route
            path='stock-transactions'
            element={<StockTransactions className='stock-transactions' />}
          />
          {/* LO user REPORTS SUBMENU*/}
          <Route
            path='vehicle-usage-report'
            element={<VehicleUsageReport className='vehicle-usage' />}
          />
          <Route
            path='vehicle-breakdown-report'
            element={<VehicleBreakdownReport className='vehicle-breakdown-products' />}
          />
          <Route path='credit-usage-report' element={<CreditUsageReport />} />
          <Route path='target-report' element={<TargetReport />} />
          <Route
            path='credit-issue-report'
            element={<CreditIssueReports className='credit-issue-report' />}
          />
          <Route
            path='credit-request-report'
            element={<CreditRequestReport className='credit-request-reports' />}
          />
          <Route
            path='daily-sales-real-time'
            element={<RealTimeSalesReport className='RealTimeSalesReport' />}
          />
          <Route
            path='banana-offloading-report'
            element={<OffloadReport className='OffloadReport' />}
          />
          <Route path='order-report' element={<OrderReport className='OrderReport' />} />
          <Route
            path='order-report/order-detail'
            element={<PreOrderDetail className='OrderReportDetail' />}
          />
          <Route path='gatepass-report' element={<GatePassReport className='GatepassReport' />} />
          <Route path='route-jump-report' element={<RouteJumpReport />} />
          <Route path='summary-report' element={<SummaryReport />} />
          <Route path='temperature-humidity-alert-report' element={<TempHumidityAlertReport />} />
          <Route path='issues-from-sg' element={<IssuesFromSG className='issues-from-sg' />} />
          <Route
            path='credit-capping-update'
            element={<CreditCappingUpdate className='credit-capping-update' />}
          />
          <Route
            path='order-quantity-requests'
            element={<OrderQuantityRequest className='order-quantity-request' />}
          />
          <Route
            path='order-quantity-requests/quantity-request-detail'
            element={<OrderQuantityRequestDetail />}
          />
          <Route
            path='order-quantity-requests/order-detail'
            element={<PreOrderDetail className='orders' />}
          />
          {/* WM customer report MENU */}
          <Route
            path='daily-sales-report'
            element={<DailySalesReport className='daily-sales-report' />}
          />
          <Route path='customer-report' element={<CustomerReport className='customer-report' />} />
          <Route path='dormant-customer-report' element={<DormantCustomerReport />} />
          <Route path='attendance-report' element={<AttendanceReport />} />
          <Route path='order-limit-request-report' element={<OrderLimitRequestReport />} />
          <Route path='customer-order-limit-report' element={<CustomerOrderLimitReport />} />
          <Route path='no-ordering-customer-report' element={<NoOrderingCustomerReport />} />
          <Route
            path='sales-invoice-report'
            element={<SalesInvoices className='sales-invoice-report' />}
          />
          <Route path='pay-later-today-report' element={<PayLaterTodayReport />} />
          <Route
            path='sales-invoice-report/sales-invoice-details'
            element={<SalesInvoiceDetail className='sales-invoice-detail-report' />}
          />
          {/*<Route path='*' element={<Navigate to='/reports/product-skus-report' />} />*/}
        </Route>
        {/* TS reports menu routing*/}
        <Route path='reports/*'>
          <Route
            path='survey-feedback-report'
            element={<SurveyFeedbackReport className='survey-feedback-report' />}
          />
          <Route
            path='sales-order-feedback-report'
            element={<SalesOrdersFeedbackReport className='sales-order-feedback-report' />}
          />
          <Route
            path='pre-orders-report'
            element={<PreOrdersReport className='pre-orders-report' />}
          />
          <Route path='new-customers' element={<NewCustomersReport className='new-customers' />} />
        </Route>
        {reportProductSkus == true ||
        reportSales == true ||
        reportCreditIssues == true ||
        reportTeam == true ||
        reportVehicles == true ||
        reportComparisons == true ||
        reportRejections == true ? (
          <Route path='reports/*'>
            {' '}
            {/*  REPORTS WITH SUBMENU */}
            {reportProductSkus == true && (
              <Route
                path='product-skus-report'
                element={<ProductSkuReports className='product-skus-report' />}
              />
            )}
            {reportSales == true && (
              <Route path='sales-report' element={<SalesReport className='sales-report' />} />
            )}
            {reportCreditIssues == true && (
              <Route
                path='credit-issues-report'
                element={<CreditIssueReports className='credit-issue-reports' />}
              />
            )}
            {reportTeam == true && (
              <Route path='team-report' element={<TeamReport className='team-reports' />} />
            )}
            {reportVehicles == true && (
              <Route
                path='vehicle-usage-report'
                element={<VehicalReport className='vehical-reports' />}
              />
            )}
            {reportComparisons == true && (
              <Route
                path='comparison-report'
                element={<ComparisonWrapper className='comparison-reports' />}
              />
            )}
            {reportRejections == true && (
              <Route
                path='rejection-report/*'
                element={<RejectionReport className='rejection-reports' />}
              />
            )}
            {reportProductSkus == true && (
              <Route path='*' element={<Navigate to='/reports/product-skus-report' />} />
            )}
            {reportSales == true && (
              <Route path='*' element={<Navigate to='/reports/sales-report' />} />
            )}
            {reportCreditIssues == true && (
              <Route path='*' element={<Navigate to='/reports/credit-issues-report' />} />
            )}
            {reportTeam == true && (
              <Route path='*' element={<Navigate to='/reports/team-report' />} />
            )}
            {reportVehicles == true && (
              <Route path='*' element={<Navigate to='/reports/vehicle-usage-report' />} />
            )}
            {reportComparisons == true && (
              <Route path='*' element={<Navigate to='/reports/comparison-report' />} />
            )}
            {reportRejections == true && (
              <Route path='*' element={<Navigate to='/reports/rejection-report' />} />
            )}
            {/* <Route path='*' element={<Navigate to="/dashboard" />} /> */}
          </Route>
        ) : null}
        {saUserAccounts == true || saRolesPermission == true ? (
          <Route path='superadmin/*'>
            {' '}
            {/*  SUPER ADMIN WITH SUBMENU */}
            {saUserAccounts == true && (
              <Route path='user-accounts' element={<UserAccounts className='UserAccounts' />} />
            )}
            {saRolesPermission == true && (
              <Route path='roles' element={<RolesPermission className='RolesPermission' />} />
            )}
            {saUserAccounts == true && (
              <Route path='*' element={<Navigate to={'/superadmin/user-accounts'} />} />
            )}
            {saRolesPermission == true && (
              <Route path='*' element={<Navigate to={'/superadmin/roles'} />} />
            )}
          </Route>
        ) : null}
        {masterDb == true ||
        masterPm == true ||
        masterCm == true ||
        masterRm == true ||
        masterVb == true ? (
          <Route path='master/*'>
            {' '}
            {/*  MASTER WITH SUBMENU */}
            <Route path='sales-team' element={<SalesTeam />} />
            <Route path='incentive-components' element={<IncentiveComponents />} />
            {/*<Route path='weather-box-configuration' element={<WeatherBoxConfig />} />*/}
            {masterDb == true && (
              <Route path='distribution-boundaries/*'>
                <Route path='regions' element={<Regions className='regions' />} />
                <Route path='regionname' element={<RegionName className='region-name' />} />
                <Route path='district' element={<District className='district' />} />
                <Route path='ward' element={<Ward className='ward' />} />
                <Route path='street' element={<Streets className='streets' />} />
                <Route
                  path='*'
                  element={<Navigate to='/master/distribution-boundaries/regions' />}
                />
              </Route>
            )}
            {masterPm == true && (
              <Route path='product-masters/*'>
                <Route
                  path='product-categories'
                  element={<ProductCategoriesMaster className='product-categories' />}
                />
                <Route
                  path='product-groups'
                  element={<ProductGroups className='product-group' />}
                />
                <Route
                  path='product-variants'
                  element={<ProductVariants className='product-variant' />}
                />
                <Route
                  path='package-material'
                  element={<PackageMaterial className='package-material' />}
                />
                <Route
                  path='*'
                  element={<Navigate to='/master/product-masters/product-categories' />}
                />
              </Route>
            )}
            {masterCm == true && (
              <Route path='customer-masters/*'>
                <Route
                  path='customer-categories'
                  element={<CustomerCategories className='customer-categories' />}
                />
                <Route path='customer-tags' element={<CustomerTags className='add-tags' />} />
                <Route path='credit-score' element={<CreditScore className='CreditScore' />} />
                <Route
                  path='*'
                  element={<Navigate to='/master/customer-masters/customer-categories' />}
                />
              </Route>
            )}
            {checkUserType('sa') && (
              <Route path='soko-points/*'>
                <Route path='earning-spending-values' element={<SokoPoints />} />
                <Route path='reward-offers' element={<RewardOffers />} />
                <Route
                  path='*'
                  element={<Navigate to='/master/soko-points/earning-spending-values' />}
                />
              </Route>
            )}
            {masterRm == true && (
              <Route path='reason-masters/*'>
                <Route
                  path='customer-rejection'
                  element={<CustomerRejection className='CustomerRejection' />}
                />
                <Route
                  path='credit-rejection'
                  element={<CreditRejection className='CustomerRejection' />}
                />
                <Route
                  path='discount-rejection'
                  element={<DiscountRejection className='CustomerRejection' />}
                />
                <Route
                  path='stockin-reason'
                  element={<StockinReason className='StockinReason' />}
                />
                <Route path='manual-stock-update' element={<ManualStockUpdate />} />
                <Route path='address-reason' element={<AddressReason />} />
                <Route path='credit-request-update' element={<CreditRequestUpdateReason />} />
                <Route path='credit-request-reject' element={<CreditRequestRejectReason />} />
                <Route
                  path='revoke-access-reason'
                  element={<RevokeAccessReason className='RevokeAccessReason' />}
                />
                <Route
                  path='grant-access-reason'
                  element={<GrantAccessReason className='GrantAccessReason' />}
                />
                <Route
                  path='business-detail-reason'
                  element={<BusinessDetailReason className='BusinessDetailReason' />}
                />
                <Route
                  path='load-out-rejection-reason'
                  element={<LoadOutRequestReject className='LoadOutRequestReject' />}
                />
                <Route
                  path='load-out-approval-reason'
                  element={<LoadOutRequestPartialApproval className='LoadOutRequestReject' />}
                />
                <Route
                  path='load-out-cancel-reason'
                  element={<LoadOutRequestCancel className='LoadOutRequestReject' />}
                />
                <Route
                  path='target-flag-reason'
                  element={<TargetFlagReason className='LoadOutRequestReject' />}
                />
                <Route path='vehicle-to-vehicle-reason' element={<VehicleToVehicleReason />} />
                <Route
                  path='vehicle-to-vehicle-partially-reason'
                  element={<VehicleToVehiclePartiallyReason />}
                />
                <Route path='route-request-reason' element={<RouteRequestReason />} />
                <Route path='order-limit-reason' element={<OrderLimitReason />} />
                <Route
                  path='order-rejection-reason'
                  element={
                    <CommonReasonMaster
                      heading={'Order Rejection Reason'}
                      type={'order_rejection_reason'}
                    />
                  }
                />
                <Route
                  path='weather-box-alert-log-reason'
                  element={<WeatherBoxAlertLogsReason />}
                />
                <Route
                  path='discount-request-rejection'
                  element={<DiscountRequestRejection className='DiscountRequestRejection' />}
                />
                <Route
                  path='discount-request-amount-update'
                  element={<DiscountRequestAmountUpdate className='DiscountRequestAmounUpdate' />}
                />
                <Route
                  path='gatepass-reason'
                  element={<GatePassRejection className='LoadOutRequestReject' />}
                />
                <Route
                  path='case-settlement-reason'
                  element={<CaseSettlementReason className='CaseSettlementReason' />}
                />
                <Route
                  path='order-quantity-reason'
                  element={<OrderQuantityRequestReason className='OrderQuantityRequestReason' />}
                />
                <Route
                  path='complaint-reason'
                  element={<SupportRequestReason className='SupportRequestReason' />}
                />
                <Route
                  path='*'
                  element={<Navigate to='/master/reason-masters/customer-rejection' />}
                />
              </Route>
            )}
            <Route path='complaint-type' element={<SupportRequestTypeReason />} />
            <Route path='vehicle-masters/*'>
              <Route path='vehicle-types' element={<VehicleTypes className='vehicle-types' />} />
              <Route path='vehicle-make' element={<VehicleMake className='vehicle-make' />} />
              <Route path='vehicle-model' element={<VehicleModel className='vehicle-model' />} />
              {masterVb == true && (
                <Route
                  path='vehicle-breakdown'
                  element={<VehicleBreakdown className='vehicle-model' />}
                />
              )}
              <Route path='*' element={<Navigate to='/master/vehicle-masters/vehicle-types' />} />
            </Route>
            <Route path='payment-masters/*'>
              <Route path='banks' element={<Banks className='banks' />} />
              <Route path='wallets' element={<Wallets className='wallets' />} />
              <Route path='*' element={<Navigate to='/master/payment-masters/banks' />} />
            </Route>
            <Route path='*' element={<Navigate to='/dashboard' />} />
          </Route>
        ) : null}
        {cms == true && (
          <Route path='cms/*'>
            <Route path='blogs/*'>
              <Route path='categories' element={<BlogCategory className='BlogAuthor' />} />
              <Route path='authors' element={<BlogAuthor className='BlogAuthor' />} />
              <Route path='posts/*'>
                <Route path='add-post' element={<AddPost />} />
                <Route index element={<BlogPosts className='BogPost' />} />
              </Route>
              <Route path='*' element={<Navigate to='/cms/blogs/categories' />} />
            </Route>

            <Route path='our-team/*'>
              <Route path='teams' element={<CmsTeam />} />
              <Route path='team-member' element={<CmsTeamMember />} />
              <Route path='*' element={<Navigate to='/cms/our-team/teams' />} />
            </Route>

            {/* <Route path='career/*'>
          <Route
            path='current-openings'
            element={<CurrentOpenings className='CurrentOpenings' />}
          />
          <Route path='application' element={<JobApplications className='JobApplications' />} />
          <Route path='*' element={<Navigate to='/cms/career/scuccess-stories' />} />
        </Route> */}
            <Route path='career/*'>
              <Route path='current-openings/*'>
                <Route path='add-openings' element={<AddCurrentOpenings />} />
                <Route index element={<CurrentOpenings className='CurrentOpenings' />} />
              </Route>

              <Route path='application/*'>
                <Route path='application-details' element={<ApplicationDetails />} />
                <Route index element={<JobApplications className='JobApplications' />} />
              </Route>
              <Route path='*' element={<Navigate to='/cms/career/current-openings' />} />
            </Route>

            <Route path='scuccess-stories/*'>
              <Route path='farmers/*'>
                <Route path='add-stories' element={<AddSuccessStories />} />
                <Route
                  index
                  element={<FarmersSuccessStories className='FarmersSuccessStories' />}
                />
              </Route>
              <Route path='vendors/*'>
                <Route path='add-stories' element={<AddSuccessStories />} />
                <Route
                  index
                  element={<VendorsSuccessStories className='VendorsSuccessStories' />}
                />
              </Route>
              <Route path='employees/*'>
                <Route path='add-stories' element={<AddSuccessStories />} />
                <Route
                  index
                  element={<EmployeesSuccessStories className='EmployeesSuccessStories' />}
                />
              </Route>
              <Route path='*' element={<Navigate to='/cms/scuccess-stories/employees-stories' />} />
            </Route>
            <Route path='testimonials/*'>
              <Route path='add-testimonials' element={<AddTestimonials />} />
              <Route index element={<Testimonials className='testimonials' />} />
            </Route>
            <Route path='products/*'>
              <Route path='edit-products' element={<UpdateProducts />} />
              <Route index element={<Products className='products' />} />
            </Route>
            <Route path='inquiries/*'>
              <Route path='contact/*'>
                <Route path='details' element={<InquiriesDetails />} />
                <Route index element={<ContactUs className='contactUs' />} />
              </Route>
              <Route path='investors/*'>
                <Route path='details' element={<InquiriesDetails />} />
                <Route index element={<Investors className='investors' />} />
              </Route>
              <Route path='*' element={<Navigate to='/cms/inquiries/contact' />} />
            </Route>
          </Route>
        )}
        {/* <Route path='product-masters/*'>
          <Route path='product-categories' element={<ProductCategoriesMaster className="product-categories" />} />
          <Route path='product-groups' element={<ProductGroups className="product-group" />} />
          <Route path='product-variants' element={<ProductVariants className="product-variant" />} />
          <Route path='package-material' element={<PackageMaterial className="package-material" />} />
          <Route path='*' element={<Navigate to="/master/product-masters/product-categories" />} />
        </Route>

        <Route path='customer-masters/*'>
          <Route path='customer-categories' element={<CustomerCategories className="customer-categories" />} />
          <Route path='customer-tags' element={<CustomerTags className='add-tags' />} />
          <Route path='credit-score' element={<CreditScore className='CreditScore' />} />
          <Route path='*' element={<Navigate to="/master/customer-masters/customer-categories" />} />
        </Route>
        <Route path='rejection-masters/*' >
          <Route path='customer-rejection' element={<CustomerRejection className='CustomerRejection' />} />
          <Route path='credit-rejection' element={<CreditApprove className='CustomerRejection' />} />
          <Route path='discount-rejection' element={<DiscountRejection className='CustomerRejection' />} />
          <Route path='*' element={<Navigate to="/master/rejection-masters/customer-rejection" />} />
        </Route>

        <Route path='vehicle-breakdown' element={<VehicleBreakdown className='CustomerRejection' />} />
        <Route path='*' element={<Navigate to="/master/distribution-boundaries/regions" />} /> */}
        {/* </Route> */}
        {/* TS user wareouse routing */}
        <Route path='warehouses' element={<TSWarehouses className='ts-warehouses' />} />
        <Route path='warehouses/*' element={<TSSingleWarehouse />} />
        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
