/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl'
import { getUserType } from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage';
import ReconciliationReport from './reconciliationReport';
import ReconciliationReportAM from './reconciliationReportAM';
// import { OO, superAdmin, w_manager } from '../../../_eaFruitsDms/localstorage/setitemStore';

const superAdmin = localStorage.getItem('superAdmin');
const w_manager = localStorage.getItem('w_manager');
const OO = localStorage.getItem('OO');

const ReconciliationReportWrapper: FC = () => {
    const intl = useIntl()
    const headerTitle = {
        title: "ProductSku"
    }
    return (
        <>
            {/* {getUserType === "SA" && <ProductSku className='super-admin-productsku' />} */}
            {getUserType === "OO" && <ReconciliationReport className='oo-report' />}
            {getUserType === "AM" && <ReconciliationReportAM className='oo-report' />}
        </>
    )
}

export { ReconciliationReportWrapper }

