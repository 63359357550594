import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {AsyncPaginate} from 'react-select-async-paginate'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  isAdmin,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import tenantConfiguration from '../../../../TenantVariables'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {offloadStatus} from './offload-constant'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

type Props = {
  className: string
}
const OffloadReport: React.FC<Props> = () => {
  const intl = useIntl()
  const reportHeaderTitle = {
    title: 'Reports',
    subTitle: 'Banana Offloading Report',
  }
  const currentWarehouseId = isAdmin() ? '' : localStorage.getItem('w_id')

  const [warehouses, setWarehouses] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [currentOffSet, setCurrentOffSet] = useState<any>('0')
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [displayDetailsLoader, setDisplayDetailsLoader] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [warehouseOffSet, setWarehouseOffSet] = useState<number>(0)
  const [filteredDate, setFilteredDate] = useState<any>({
    startDate: dateFormat(moment()).apiDate,
    endDate: dateFormat(moment()).apiDate,
  })
  const [warehouseId, setWarehouseId] = useState<any>({
    label: 'All Warehouses',
    value: '',
  })
  const [containers, setContainers] = useState<any>([])
  const [selectedContainer, setSelectedContainer] = useState<any>({
    label: 'All Containers',
    value: '',
  })
  const [offloadingData, setOffloadingData] = useState<any>([])

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchOffloading().then()
  }, [debounceSearch, warehouseId, selected, currentOffSet, filteredDate, selectedContainer])

  useEffect(() => {
    setWarehouseOffSet(0)
  }, [searchSelect])

  const fetchContainers = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }
    const containerResponse = await getRequest(
      `${apiBaseURL()}weather-boxes/available-containers-list?warehouse_id=${currentWarehouseId}`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (containers.length === 0) {
      options.push({
        label: 'All Containers',
        value: '',
      })
    }

    if (containerResponse.length > 0) {
      containerResponse.map((option: any) => {
        options.push({
          label: option.container_name,
          value: option.id,
          ...option,
        })
      })
    }

    if (containers.length > 0) {
      setContainers(containers.concat(options))
    } else {
      setContainers(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchOffloading = async () => {
    setDisplayLoader(true)

    const startDate = filteredDate.startDate
    const endDate = filteredDate.endDate

    let selectedWarehouseId = isEmpty(warehouseId.value) ? '' : warehouseId.value

    if (checkUserType('oo')) {
      selectedWarehouseId = currentWarehouseId
    }

    const response = await getRequest(
      `${apiBaseURL()}reports/ripening-product-offload/report?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&warehouse_id=${selectedWarehouseId}&start_date=${startDate}&end_date=${endDate}&container=${selectedContainer.value}`,
      true
    )

    const reportData: any = []

    if (response.results) {
      setCount(response.count)

      response.results.map((data: any) => {
        return reportData.push({
          id: data.id,
          container_name: checkEmpty(data.container_name, '-'),
          warehouse_name: checkEmpty(data.warehouse_name, '-'),
          product_name: checkEmpty(data.product_name, '-'),
          batch_number: checkEmpty(data.batch_number, '-'),
          offload_quantity: isEmpty(data.offload_qty, true) ? '-' : data.offload_qty + " Crates",
          offload_date: isEmpty(data.offload_date) ? '-' : dateFormat(data.offload_date).displayDate,
          offload_time: isEmpty(data.offload_date) ? '-' : dateFormat(data.offload_date, 'LT').displayDate,
          offload_by_name: checkEmpty(data.offload_by_name, '-'),
          offload_status: checkEmpty(data.offload_status, '-')
        })
      })
    }
    setOffloadingData(reportData)
    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            maxDate: moment(),
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-1 w-auto'
            defaultValue={value}
            disabled={displayLoader || displayDetailsLoader}
          />
        </DateRangePicker>
      </>
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    switch (inputName) {
      case 'warehouse':
        setWarehouseId(e)
        break
      case 'search':
        setSearchByName(e.target.value)
        break
    }
  }

  return (
    <>
      <EATitle title='Banana sOffloading Report' />
      <PageTitle breadcrumbs={[reportHeaderTitle]}>
        {intl.formatMessage({id: 'Banana Offloading Report'})}
      </PageTitle>
      <div>
        <div className='card'>
          {/* begin::Header */}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar
              onChange={handleFilterChange}
              isDisable={displayLoader || displayDetailsLoader}
            />
            <div className='ms-auto'>
              <div className='card-toolbar d-flex my-0'>
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setFilteredDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={filteredDate}
                  startDate={filteredDate.startDate}
                  endDate={filteredDate.endDate}
                />
                {checkUserType(['sa', 'wm']) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    isDisabled={displayLoader || displayDetailsLoader}
                    isSearchable
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    placeholder='All Warehouses'
                    value={warehouseId}
                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}

                <AsyncPaginate
                  loadOptions={fetchContainers}
                  placeholder='Select Container'
                  isSearchable
                  className={'react-select-container w-160px ms-3'}
                  classNamePrefix='react-select'
                  menuPosition='fixed'
                  onChange={(e: any) => {
                    setSelectedContainer(e)
                  }}
                  name='product_sku_id'
                  value={selectedContainer}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div
              className='table-responsive expandable'
              style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
              id='expandTable'
            >
              {displayLoader ? (
                <FetchingRecords />
              ) : offloadingData.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th>Container</th>
                      <th className='me-2'>Warehouse</th>
                      <th>Product</th>
                      <th>Batch No.</th>
                      <th>Offload Quantity</th>
                      <th>Offload By</th>
                      <th>Offload At</th>
                      <th>Offload Status</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {offloadingData.map((offload: any) => {
                      const badge = offload.offload_status === 'late' ? 'badge-light-danger' : offload.offload_status === 'before' ? 'badge-warning' : 'badge-light-success'

                      return (
                        <>
                          <tr>
                            <td>{offload.container_name}</td>
                            <td className='me-2'>{offload.warehouse_name}</td>
                            <td>{offload.product_name}</td>
                            <td>{offload.batch_number}</td>
                            <td>{offload.offload_quantity}</td>
                            <td>{offload.offload_by_name}</td>
                            <td>
                              <div>
                                {offload.offload_date}
                              </div>
                              {offload.offload_time}
                            </td>
                            <td>
                              <span className={`badge ${badge} text-dark`}>
                                {offloadStatus[offload.offload_status]}
                              </span>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={offloadingData}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffSet}
          totalRecords={count}
          limit={limit}
          isDisabled={displayLoader}
        />
      </div>
    </>
  )
}

export default OffloadReport
