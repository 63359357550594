import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import PaymentIssueHeader from './components/paymentIssueHeader'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import Overview from './components/overview'
import Transactions from './components/transactions'
import {apiBaseURL, isAdmin, isEmpty} from '../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {useEffect, useState} from 'react'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'

type Props = {
  className?: string
}
const PaymentIssueDetail = (props: any) => {
  const payementIssuesTitle = {
    title: 'Payment Issues',
    subTitle: 'Payment Issues',
  }
  const location = useLocation();
  const paymentIssueId = localStorage.getItem('paymentIssueId')
  const reportBackNavigate = localStorage.getItem('reportBackNavigate')

  const { state }: any = location;

  const [transactions, setTransactions] = useState<any>([])
  const [transactionsMeta, setTransactionsMeta] = useState<any>([])
  const [overviewMeta, setOverviewMeta] = useState<any>([])
  const [collection , setCollection] = useState<any>([])
  const [business, setBusiness] = useState<any>([])
  const [transactionFlag, setTransactionFlag] = useState<boolean>(false)

  useEffect(() =>{
    fetchTransactions()
  },[transactionFlag])

  const fetchTransactions = async () => {
    const transactionsApi = `${apiBaseURL()}payment/issues/${paymentIssueId}/transactions?&limit=1000`
    const transactionsResponse = await getRequest(transactionsApi, true)
      setTransactions(transactionsResponse.results)
      setTransactionsMeta(transactionsResponse.meta)

    const overviewApi = `${apiBaseURL()}payment/issues/${paymentIssueId}/overview`
    const overviewResponse = await getRequest(overviewApi, true)
      setOverviewMeta(overviewResponse.meta)
      setBusiness(overviewResponse.results.payment_summary)
      setCollection(overviewResponse.results.collection_summary)
  }

  const transactionDetail = (value : any) => {
    setTransactionFlag(value)
 }

 const backURL = () => {
  let URL = "/payment-issues"
  if (isAdmin()) {
    if (reportBackNavigate === 'payment-issue-report') {
      URL = "/reports/payment-issues"
    }
    else {
      URL = "/warehouse/DC/singlewarehousename/payment-issues"
    }
  } else {
     URL = "/payment-issues"
  }

  return URL
}

  return (
    <>
      <EATitle title={(isAdmin() && reportBackNavigate === 'payment-issue-report') ? 'Payment Issues Report': 'Payment Issues'} />
      <PageTitle breadcrumbs={[payementIssuesTitle]} backNavigate={backURL}>{!transactionsMeta || isEmpty(transactionsMeta.sr_user_name) ? "-" : transactionsMeta.sr_user_name}</PageTitle>
      <PaymentIssueHeader transactionsMeta = {transactionsMeta} overviewMeta = {overviewMeta}  />
      <Routes>
        <Route path='transactions' element={<Transactions transactionDetail={transactionDetail}/>} />
        <Route path='overview' element={<Overview business = {business} collection  = {collection  }/>} />
        <Route index element={<Navigate to='/payment-issues/payment-issue-detail/transactions' />} />
      </Routes>
    </>
  )
}

export default PaymentIssueDetail
