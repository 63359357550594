/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {
  apiBaseURL,
  IMPERSONATE_FAILED,
  KTSVG,
  removeFilterImpersonate,
} from '../../../../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../../../../_eaFruitsDms/helpers/components/FormateMessage'
import {postRequest} from '../../../../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger} from '../../../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../../../../_eaFruitsDms/layout/components/button/eaButton'
import {PageLink, PageTitle} from '../../../../../../_eaFruitsDms/layout/core'
import {getImpersonationUserDetails} from '../../../../../../_eaFruitsDms/localStorage/setItem/setLocalStorage'

let shoeEditBtn = true
const WarehouseHeader = (props: any) => {
  const location = useLocation()
  if (location.pathname === '/warehouse/DC/singlewarehousename/credit-issues') {
    shoeEditBtn = false
  } else {
    shoeEditBtn = true
  }
  if (location.pathname === '/warehouse/DC/singlewarehousename/vehicles') {
    shoeEditBtn = false
  }

  const warehouseBreadCrumbs: Array<PageLink> = [
    {
      title: 'Warehouse',
      path: '/warehouse/DC',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Warehouses & DC',
      path: '/warehouse/DC',
      isSeparator: false,
      isActive: false,
    },
  ]
  const {
    showOverview,
    showArea,
    showTeam,
    showSales,
    showTargets,
    showStock,
    showCustomers,
    showCredit,
    showVehicles,
  } = props

  const navigate = useNavigate()
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const splashScreen = document.getElementById('splashScreenStyle')
  const [isButtonLoader, setIsButtonLoader] = useState<boolean>(false)
  const [navigateOption, setNavigateOption] = useState<any>('')

  useEffect(() => {
    let type = localStorage.getItem('navigateBackToReport')
    if (type == 'true') {
      setNavigateOption('/reports/team-report')
    } else {
      setNavigateOption('/warehouse/DC')
    }
  }, [])

  const handleImpersonation = async () => {
    localStorage.removeItem('sa_dashboard_warehouse')
    localStorage.removeItem('sa_dashboard_month')
    localStorage.removeItem('support_request_warehouse')
    localStorage.removeItem('support_request_start_date')
    localStorage.removeItem('support_request_end_date')
    setIsButtonLoader(true)
    if (!props.impersonate) {
      setErrorMessage(IMPERSONATE_FAILED)
      setIsAlertFailed(true)

      setTimeout(() => {
        setIsAlertFailed(false)
      }, 3000)
    } else {
      // remove filter's data from localstorage
      removeFilterImpersonate()

      let body = {
        user_id_to_impersonate: props.warehouseManager.id,
      }

      const response = await postRequest(`${apiBaseURL()}auth/impersonate`, body, true)
      setIsButtonLoader(false)
      if (response.status == 200) {
        if (response.data.error) {
          setIsAlertFailed(true)

          setTimeout(() => {
            setIsAlertFailed(false)
          }, 3000)
        } else {
          if (splashScreen) {
            splashScreen.style.setProperty('display', 'flex')
          }
          let impersonationUser = {
            role: response.data.role,
            warehouse: response.data.warehouse,
            warehouse_name: response.data.warehouse_name,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            profile_thumbnail: response.data.profile_thumbnail,
            base_url: response.data.base_url,
            user_type: response.data.type,
            id: response.data.request_to,
            token: response.data.token,
            email: response.data.email,
            role_name: response.data.role_name,
          }

          // NOTE : here set the details of login user
          localStorage.setItem('isImpersonationLogin', 'true')
          localStorage.removeItem('permissions')
          localStorage.removeItem('api_process')
          getImpersonationUserDetails(impersonationUser)

          // redirect to user panel
          navigate('/dashboard')
          window.location.reload()
        }
      } else {
        setErrorMessage('Something went wrong.! Please try again after sometime.!')
        setIsAlertFailed(true)

        setTimeout(() => {
          setIsAlertFailed(false)
        }, 3000)
      }
    }
  }

  const isAlertSHow = (val: any) => {
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={warehouseBreadCrumbs} backNavigate={navigateOption}>
        {props.WarehouseName}
      </PageTitle>
      <div className='card mb-4 mb-xl-5'>
        <div className='card-body pt-5 pb-0'>
          <EaAlertDanger
            onClick={() => isAlertSHow('failed')}
            show={isAlertFailed}
            message={
              errorMessage === ''
                ? FormatMessage(
                    'IMPERSONATE_FAILED',
                    props.warehouseManager != null
                      ? props.warehouseManager.first_name + ' ' + props.warehouseManager.last_name
                      : 'Branch manager'
                  )
                : errorMessage
            }
          />
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-dark font-20 fw-bold me-1'>
                      {/* Warehouse Name */}
                      {props.WarehouseName}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                    <span className='d-flex align-items-center me-6 mb-2'>
                      <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
                      {/* Headquarter */}
                      {props.WarehouseType === 'DistributionCenter'
                        ? 'Distribution Center'
                        : props.WarehouseType}
                    </span>
                    {props.WarehouseType === 'DistributionCenter' ? null : (
                      <span className='d-flex align-items-center me-5 mb-2 '>
                        <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
                        {/* Dar es Salaam */}
                        {props.WarehouseRegion}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {props.showWrite && (
                <>
                  {shoeEditBtn && (
                    <a
                      href='#'
                      data-bs-toggle='modal'
                      data-bs-target='#ea_modal_warehouse_edit'
                      className='btn btn-secondary font-13'
                      onClick={() => props.editClick()}
                    >
                      Edit
                    </a>
                  )}
                </>
              )}

              {/* {props.showDelete &&
                <a href="#"
                  onClick={() => {
                    props.deleteMessage()
                  }}
                  data-bs-toggle='modal'
                  data-bs-target='#deleteWarehouse'
                  className="btn btn-secondary font-13 mx-3">Delete Warehouse</a>
              } */}

              {props.impersonate && (
                <>
                  {props.WarehouseType === 'Headquarter' && props.warehouseManager != null && (
                    <EaButton
                      isModal={false}
                      isDisable={isButtonLoader}
                      btnName={isButtonLoader ? 'Please Wait...' : 'Login to Warehouse'}
                      onClick={handleImpersonation}
                      className='btn btn-primary btn-sm font-13 my-1 ms-3'
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className='d-flex overflow-auto h-40px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
              {showOverview && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/overview' &&
                        'active')
                    }
                    to='/warehouse/DC/singlewarehousename/overview'
                  >
                    Overview
                  </Link>
                </li>
              )}

              {showArea && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/area-coverage' &&
                        'active')
                    }
                    to={`/warehouse/DC/singlewarehousename/area-coverage`}
                  >
                    Area Coverage
                  </Link>
                </li>
              )}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouse/DC/singlewarehousename/billing-info' &&
                      'active')
                  }
                  to={`/warehouse/DC/singlewarehousename/billing-info`}
                >
                  Billing Info
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname ===
                      '/warehouse/DC/singlewarehousename/incentive-components' && 'active')
                  }
                  to={`/warehouse/DC/singlewarehousename/incentive-components`}
                >
                  Incentive Components
                </Link>
              </li>
              {showTeam && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/team' && 'active')
                    }
                    to='/warehouse/DC/singlewarehousename/team'
                  >
                    Team
                  </Link>
                </li>
              )}

              {showSales && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/sales' && 'active')
                    }
                    to='/warehouse/DC/singlewarehousename/sales'
                  >
                    Sales
                  </Link>
                </li>
              )}

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouse/DC/singlewarehousename/orders' && 'active')
                  }
                  to='/warehouse/DC/singlewarehousename/orders'
                >
                  Orders
                </Link>
              </li>
              {/*{showTargets &&*/}
              {/*  <li className='nav-item'>*/}
              {/*    <Link*/}
              {/*      className={*/}
              {/*        `nav-link text-active-primary me-0 ` +*/}
              {/*        (location.pathname === '/warehouse/DC/singlewarehousename/targets' && 'active')*/}
              {/*      }*/}
              {/*      to='/warehouse/DC/singlewarehousename/targets'*/}
              {/*    >*/}
              {/*      Targets*/}
              {/*    </Link>*/}
              {/*  </li>}*/}
              {showStock && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/stock' && 'active')
                    }
                    to='/warehouse/DC/singlewarehousename/stock'
                  >
                    Stock
                  </Link>
                </li>
              )}

              {/*{showCustomers &&*/}
              {showCustomers && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname ===
                        '/warehouse/DC/singlewarehousename/warehouse-customers' && 'active')
                    }
                    to='/warehouse/DC/singlewarehousename/warehouse-customers'
                  >
                    Customers
                  </Link>
                </li>
              )}
              {/* } */}

              {showCredit && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      ([
                        '/warehouse/DC/singlewarehousename/credit-issues',
                        '/warehouse/DC/singlewarehousename/credit-issues/credit-issues-detail',
                      ].includes(location.pathname) && 'active')
                    }
                    to='/warehouse/DC/singlewarehousename/credit-issues'
                  >
                    Credit Issues
                  </Link>
                </li>
              )}

              {showCredit && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/payment-issues' &&
                        'active')
                    }
                    to='/warehouse/DC/singlewarehousename/payment-issues'
                  >
                    Payment Issues
                  </Link>
                </li>
              )}
              {showVehicles && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/warehouse/DC/singlewarehousename/vehicles' &&
                        'active')
                    }
                    to='/warehouse/DC/singlewarehousename/vehicles'
                  >
                    Vehicles
                  </Link>
                </li>
              )}
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouse/DC/singlewarehousename/complaint' &&
                      'active')
                  }
                  to='/warehouse/DC/singlewarehousename/complaint'
                >
                  Complaint
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-0 ` +
                    (location.pathname === '/warehouse/DC/singlewarehousename/mnada-location' &&
                      'active')
                  }
                  to='/warehouse/DC/singlewarehousename/mnada-location'
                >
                  Mnada Location
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export {WarehouseHeader}
