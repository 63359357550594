import clsx from 'clsx'
import $ from 'jquery'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  checkEmpty,
  checkPermissions,
  dateFormat,
  checkUserType,
  isEmpty,
  KTSVG,
  measurementLabel,
} from '../../../_eaFruitsDms/helpers'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest, patchRequest, postRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {useNavigate} from 'react-router-dom'
import MobileNumberInput from '../../../_eaFruitsDms/layout/components/MobileNumberInput/MobileNumberInput'

const Containers = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const initialValue = {
    container_name: '',
    container_number: '',
    container_capacity: '',
    current_quantity: '',
  }

  const initialValidation = {
    container_name: '',
    container_number: '',
    container_capacity: '',
    container_capacity_exceed: '',
  }

  const initialConfigure = {
    contact: '',
    contactValid: '',
  }

  const [container, setContainer] = useState<any>([])
  const [containerId, setContainerId] = useState<any>(null)
  const [detailFetching, setDetailFetching] = useState<boolean>(false)
  const [containerDetails, setContainerDetails] = useState<any>([])
  const [count, setCount] = useState('1')
  const [selected, setSelected] = useState(0)
  const [fetchingRecords, setFetchingRecords] = useState<any>(true)
  const [currentOffset, setCurrentOffset] = useState<any>('0')
  const [search, setSearch] = useState<any>('')
  const [isAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [message, setMessage] = useState('')
  const [messageHandler, setMessageHandler] = useState('successAdd')
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [id, setId] = useState('')
  const [requestHandler, setRequest] = useState('')
  const [containerState, setContainerState] = useState(initialValue)
  const [validation, setValidation] = useState<any>(initialValidation)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorField, setErrorField] = useState('')
  const [isLoading, setIsLoading] = useState('Submit')
  const [disableBtn, setDisable] = useState(false)
  const [configureSms, setConfigureSms] = useState([initialConfigure])
  const [permission, setPermission] = useState({
    read: true,
    write: true,
    delete: true,
  })
  const [modalLoader, setModalLoader] = useState<any>(false)
  const [show, setShow] = useState(false)
  const [fetchConfigure, setFetchConfigure] = useState(false)
  const [submit, setSubmit] = useState('Submit')
  const [configureAlert, setConfigureAlert] = useState(false)
  const [isExpand, setIsExpand] = useState<boolean>(false)

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    const transferMessage = localStorage.getItem('transfer_message') || ''

    if (!isEmpty(transferMessage)) {
      setMessage(transferMessage)
      setIsAlertSuccess(true)

      localStorage.removeItem('transfer_message')
    }

    const stockInMessage = localStorage.getItem('stock_in_message') || ''

    if (!isEmpty(stockInMessage)) {
      setMessage(stockInMessage)
      setIsAlertSuccess(true)

      localStorage.removeItem('stock_in_message')
    }

    setTimeout(() => {
      setIsAlertSuccess(false)
    }, 2500)

    return () => {
      setMessage('')
    }
  }, [])

  useEffect(() => {
    fetchRecords()
  }, [currentOffset, debounceSearch])

  useEffect(() => {
    if (!isEmpty(containerId)) fetchContainerised().then()
  }, [containerId])

  const fetchContainerised = async () => {
    setDetailFetching(true)
    const response = await getRequest(
      `${apiBaseURL()}inventory/ripening-container-batch-list/${containerId}`,
      true
    )
    if (!isEmpty(response)) {
      setContainerDetails(response.results)
    }
    setDetailFetching(false)
  }

  const fetchPermission = async () => {
    let res = await checkPermissions('masters_rejection_options')
    setPermission(res)
  }

  const fetchConfigureSms = async () => {
    setShow(true)
    setFetchConfigure(true)

    const url = `${apiBaseURL()}weather-boxes/configuration/contacts?warehouse_id=${getW_Id}`
    const response = await getRequest(url, true)

    let updatedConfiguration: any = []

    if (response && response.length > 0) {
      response.forEach((item: any) => {
        updatedConfiguration.push({
          contact: item.contact,
          contactValid: '',
        })
      })
    } else {
      updatedConfiguration.push(initialConfigure)
    }

    setConfigureSms(updatedConfiguration)
    setFetchConfigure(false)
  }

  const fetchRecords = async () => {
    setFetchingRecords(true)
    const API = `${apiBaseURL()}weather-boxes/container-list?limit=${limit}&search=${debounceSearch}&offset=${currentOffset}&warehouse_id=${getW_Id}`
    const response = await getRequest(API, true)
    if (response && response.results) {
      setContainer(response.results)
      setCount(response.count)
      setFetchingRecords(false)
    }
  }

  const handleChangeSearch = (e: any) => {
    let inputValue = e.target ? e.target.value : e.value
    setSelected(0)
    setCurrentOffset(0)
    setSearch(inputValue)
  }

  const fetchSingleRecords = async (item: any) => {
    setModalLoader(true)
    setId(item.id)
    setContainerState({
      container_name: item.container_name,
      container_number: item.container_number,
      container_capacity: item.capacity,
      current_quantity: item.filled_capacity,
    })
    setModalLoader(false)
  }

  const clearFunctionAdd = () => {
    setContainerState(initialValue)
    setValidation(initialValidation)
    setErrorMessage('')
    setIsLoading('Submit')
    setDisable(false)
    setModalLoader(false)
  }

  const handleChange = (e: any, name: string) => {
    const updateValidation = {...validation}
    const inputName = e.target ? e.target.name : name
    let inputValue = e.target ? e.target.value : e.value

    if (inputName === 'container_capacity') {
      inputValue = inputValue.replace(/[^0-9]/g, '')
      if (parseInt(inputValue) < parseInt(containerState.current_quantity)) {
        updateValidation[inputName] = 'is-invalid'
        updateValidation[
          `${inputName}_exceed`
        ] = `This container is already holding ${containerState.current_quantity} Creates! You cannot set a capacity lower than its current occupancy`
      } else {
        updateValidation[inputName] = ''
        updateValidation[`${inputName}_exceed`] = ''
      }
    }

    setValidation(updateValidation)
    setContainerState({...containerState, [name]: inputValue})
  }

  const handleContactNumber = (e: any, index: number) => {
    const re = /^[0-9\b]+$/

    if (e.target.value === '' || re.test(e.target.value)) {
      let updatedContact = [...configureSms]
      updatedContact[index].contact = e.target.value
      updatedContact[index].contactValid = ''

      setConfigureSms(updatedContact)
    }
  }

  const validateForm = () => {
    let validations = {...validation}
    let isValid = true

    if (isEmpty(containerState.container_name.trim())) {
      validations.container_name = 'is-invalid'
      isValid = false
    }

    if (isEmpty(containerState.container_number.trim())) {
      validations.container_number = 'is-invalid'
      isValid = false
    }

    if (isEmpty(containerState.container_capacity)) {
      validations.container_capacity = 'is-invalid'
      isValid = false
    } else {
      if (parseInt(containerState.container_capacity) < parseInt(containerState.current_quantity)) {
        validations.container_capacity = 'is-invalid'
        validations.container_capacity_exceed = `This container is already holding ${containerState.current_quantity} Creates! You cannot set a capacity lower than its current occupancy`
        isValid = false
      }
    }

    setValidation(validations)
    return isValid
  }

  const submitFunction = async () => {
    const isValid = validateForm()

    if (isValid) {
      const body = {
        container_name: containerState.container_name,
        container_number: containerState.container_number,
        capacity: containerState.container_capacity,
        warehouse: getW_Id,
      }

      setDisable(true)
      setIsLoading('Please Wait...')

      if (requestHandler === 'add') {
        postRequest(`${apiBaseURL()}weather-boxes/create`, body, true).then((response: any) => {
          handleResponse(response, body)
        })
      } else {
        patchRequest(`${apiBaseURL()}weather-boxes/${id}/update`, body, true).then(
          (response: any) => {
            handleResponse(response, body)
          }
        )
      }
    }
  }

  const handleResponse = (response: any, body: any) => {
    const notify = {
      success: false,
      failed: false,
      errorField: '',
    }
    if (response.status === 200 || response.status === 201) {
      notify.success = true
    }
    if (!isEmpty(response?.data?.error)) {
      notify.failed = true
      notify.errorField = response.data.error.field
    }

    if (notify.success) {
      fetchRecords()
      $('#closeEaModalOO').trigger('click')
      setIsAlertSuccess(true)
    } else {
      if (notify.errorField === 'container_name_exist') {
        setErrorField('container_name')
        setErrorMessage(
          `${body.container_name} is already exist in this system! Please try again with unique data`
        )
      }
      if (notify.errorField === 'container_number_exist') {
        setErrorField('container_number')
        setErrorMessage(
          `${body.container_number} is already exist in this system! Please try again with unique data`
        )
      }
    }

    if (response.status === 500) {
      setIsAlertFailed(true)
    }
    setTimeout(() => {
      setIsAlertFailed(false)
      setIsAlertSuccess(false)
    }, 2000)
    setIsLoading('Submit')
    setDisable(false)
  }

  const isAlertSHow = (val: any) => {
    if (val === 'success') {
      setIsAlertSuccess(false)
    }
    if (val === 'failed') {
      setIsAlertFailed(false)
    }
  }

  const addRow = () => {
    let updatedContact = [...configureSms]
    let valid = true

    updatedContact.forEach((item, index) => {
      if (isEmpty(item.contact)) {
        updatedContact[index].contactValid = 'is-invalid'
        valid = false
      }
    })

    if (valid) {
      updatedContact.push(initialConfigure)
    }

    setConfigureSms(updatedContact)
  }

  const removeRow = (index: number) => {
    let updatedContact = [...configureSms]

    let filterRow = updatedContact.filter((i, ind) => ind !== index)

    setConfigureSms(filterRow)
  }

  const isClear = () => {
    setShow(false)
    setConfigureSms([initialConfigure])
  }

  const isSubmit = async () => {
    const url = `${apiBaseURL()}weather-boxes/configuration/configure-contacts`
    let contact: any = []
    let valid = true

    let updatedContact = [...configureSms]

    updatedContact.forEach((item, index) => {
      if (isEmpty(item.contact)) {
        updatedContact[index].contactValid = 'is-invalid'
        valid = false
      } else {
        contact.push(parseInt(item.contact))
      }
    })

    setConfigureSms(updatedContact)

    if (valid) {
      setSubmit('Please Wait')

      let body = {
        warehouse_id: getW_Id,
        contacts: contact,
      }

      const response = await postRequest(url, body, true)
      if (response.status === 200) {
        isClear()
        setConfigureAlert(true)
        setTimeout(() => {
          setConfigureAlert(false)
        }, 2000)
      }
      setSubmit('Submit')
    }
  }

  const calculateInwardQuantity = (product: any) => {
    if (product.fix_size) {
      if (product.measurement_unit.toLowerCase() === 'gram') {
        return product.qty * (product.size / 1000)
      } else {
        return product.qty * product.size
      }
    } else {
      if (product.variants && product.variants.approx_weight > 0) {
        return (product.qty * product.variants.approx_weight) / 1000
      } else {
        return product.qty
      }
    }
  }

  return (
    <>
      <EATitle title='Containers - ' />
      <div>
        <PageTitle breadcrumbs={[{title: 'Containers'}]}>
          {intl.formatMessage({id: 'Containers'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={isAlertSuccess}
          message={
            !isEmpty(message)
              ? message
              : messageHandler === 'successAdd'
              ? FormatMessage('Your container has been added successfully.')
              : messageHandler === 'successEdit'
              ? FormatMessage('Your container has been updated successfully.')
              : messageHandler === 'successDelete' &&
                FormatMessage('Your container has been deleted successfully.')
          }
        />

        <EaAlertSuccess
          onClick={() => setConfigureAlert(false)}
          show={configureAlert}
          message={'Configure SMS for Alert is updated.'}
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='Something went wrong. Please try again later.'
        />

        <div className='card'>
          <div className='card-header border-0 px-7'>
            <div className='card-toolbar row g-0 w-100'>
              <CustomSearchBar onChange={handleChangeSearch} />
              <div className='col-12 col-md-auto ms-auto mt-3 mt-md-0'>
                {checkUserType(['oo']) && (
                  <EaButton
                    isModal={false}
                    btnName='Configure SMS for Alert'
                    className='btn-secondary me-3'
                    onClick={() => {
                      fetchConfigureSms()
                    }}
                  />
                )}

                {permission.write && (
                  <EaButton
                    isModal={true}
                    dataTarget='#ea_modal_'
                    btnName='Add Container'
                    onClick={() => {
                      setRequest('add')
                      setMessageHandler('successAdd')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {fetchingRecords ? (
                <FetchingRecords />
              ) : container.length === 0 ? (
                <NoRecords />
              ) : (
                <div
                  className='table-responsive expandable container-table'
                  style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
                  id='expandTable'
                >
                  <table className='table table-row-bordered table-hover align-middle gs-0 gy-2'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className=' text-muted fw-500'>
                        <th className='min-w-100px py-2'>Container Name</th>
                        <th className='min-w-100px py-2'>Container Number</th>
                        <th className='min-w-100px py-2'>Associated Weather Box</th>
                        <th className='min-w-100px py-2'>Capacity</th>
                        <th className='min-w-100px py-2'>Status</th>
                        <th className='min-w-80px action-th py-2'>
                          {permission.write && 'Actions'}
                        </th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {container.map((item: any) => {
                        return (
                          <>
                            <tr
                              data-bs-toggle={`${isExpand ? `collapse` : ``}`}
                              data-bs-target={`${isExpand ? `.row-collapse${item.id}` : ``}`}
                              aria-controls={`${isExpand ? `rowCollapse${item.id}` : ``}`}
                              className={`${isExpand ? `cursor-pointer` : `not-expanded`}`}
                              aria-expanded={`${!isExpand}`}
                              onClick={() => {
                                setContainerId(item.id)
                              }}
                            >
                              <td onClick={() => setIsExpand(true)}>
                                {isEmpty(item.container_name) ? '-' : item.container_name}
                              </td>
                              <td onClick={() => setIsExpand(true)}>
                                {isEmpty(item.container_number) ? '-' : item.container_number}
                              </td>
                              <td onClick={() => setIsExpand(true)}>
                                {isEmpty(item.associate_weather_box_name)
                                  ? '-'
                                  : item.associate_weather_box_name}
                              </td>
                              <td onClick={() => setIsExpand(true)}>
                                <div>
                                  {item.filled_capacity} Crates
                                  <div className='text-muted font-13 mb-1 font-medium'>
                                    {item.capacity} Crates
                                  </div>
                                </div>
                              </td>
                              <td onClick={() => setIsExpand(true)}>
                                {isEmpty(item.occupancy_status) ? (
                                  '-'
                                ) : (
                                  <>
                                    {}
                                    {item.occupancy_status === 'empty' ? (
                                      <span className='badge badge-light-success text-dark'>
                                        Empty
                                      </span>
                                    ) : item.occupancy_status === 'occupied' ? (
                                      <span className='badge badge-secondary text-dark'>
                                        Occupied
                                      </span>
                                    ) : (
                                      <span className='badge badge-light-danger text-dark'>
                                        Full
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className='text-end min-w-150px'>
                                {permission.write && item.id !== '' && (
                                  <>
                                    {!isEmpty(item.filled_capacity, true) && (
                                      <>
                                        <a
                                          href='javascript:void(0)'
                                          className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                          onClick={() => {
                                            navigate('/banana-transfer', {
                                              state: {
                                                container_id: item.id,
                                                container_name: item.container_name,
                                                minimum_temperature:
                                                  item.associate_weather_min_temp,
                                                maximum_temperature:
                                                  item.associate_weather_max_temp,
                                                minimum_humidity:
                                                  item.associate_weather_min_humidity,
                                                maximum_humidity:
                                                  item.associate_weather_max_humidity,
                                              },
                                            })
                                          }}
                                        >
                                          <KTSVG
                                            path='/media/icons/ic_transfer.svg'
                                            className='ic mr-0'
                                          />
                                        </a>

                                        <a
                                          href='javascript:void(0)'
                                          className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                          onClick={(event) => {
                                            navigate('/banana-off-load', {
                                              state: {
                                                container_id: item.id,
                                                container_name: item.container_name,
                                              },
                                            })
                                          }}
                                        >
                                          <KTSVG
                                            path='/media/icons/ic_offload.svg'
                                            className='ic mr-0'
                                          />
                                        </a>
                                      </>
                                    )}
                                    <a
                                      href='javascript:void(0)'
                                      className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-5'
                                      data-bs-toggle='modal'
                                      data-bs-target='#ea_modal_'
                                      onClick={(event) => {
                                        fetchSingleRecords(item)
                                        setRequest('edit')
                                        setMessageHandler('successEdit')
                                      }}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className='ic mr-0'
                                      />
                                    </a>
                                  </>
                                )}
                              </td>
                            </tr>
                            <tr className='expandable-row'>
                              <td className='reset-expansion-style' colSpan={11}>
                                <div
                                  className={
                                    isExpand
                                      ? `row-expansion-style collapse row-collapse${item.id} collapsible`
                                      : `row-expansion-style collapse collapsible row-collapse`
                                  }
                                  id={`rowCollapse${item.id}`}
                                  data-bs-parent='#expandTable'
                                >
                                  <div className='p-2'>
                                    {detailFetching ? (
                                      <FetchingRecords />
                                    ) : containerDetails.length === 0 ? (
                                      <NoRecords />
                                    ) : (
                                      <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                        <thead>
                                          <tr className='text-muted fw-500'>
                                            <th className='min-w-120px py-2'>Product</th>
                                            <th className='min-w-120px py-2'>Batch</th>
                                            <th className='min-w-120px py-2'>Offload Days</th>
                                            <th className='min-w-120px py-2'>Offload Date</th>
                                            <th className='min-w-120px py-2'>Quantity</th>
                                            <th className='min-w-120px py-2'>Inward Quantity</th>
                                            <th className='min-w-120px py-2'>
                                              Min/Max Temperature
                                            </th>
                                            <th className='min-w-120px py-2'>Min/Max Humidity</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {!isEmpty(containerDetails) &&
                                            containerDetails.length > 0 &&
                                            containerDetails.map((item: any) => {
                                              const quantityLabel = measurementLabel(
                                                item.fix_size
                                                  ? item.material_name
                                                  : item.approx_weight > 0
                                                  ? item.material_name
                                                  : item.measurement_unit
                                              )

                                              const inwardQuantity = calculateInwardQuantity(item)

                                              return (
                                                <tr>
                                                  <td>{checkEmpty(item.product_name, '-')}</td>
                                                  <td>{checkEmpty(item.batch_number, '-')}</td>
                                                  <td>{checkEmpty(item.offload_days, '0')} Days</td>
                                                  <td>
                                                    {isEmpty(item.offload_date)
                                                      ? '-'
                                                      : dateFormat(item.offload_date).displayDate}
                                                  </td>
                                                  <td>
                                                    {checkEmpty(item.qty, '0')} {quantityLabel}
                                                  </td>
                                                  <td>{inwardQuantity} Kgs</td>
                                                  <td>
                                                    {checkEmpty(
                                                      item.associate_weather_min_temp,
                                                      '0'
                                                    )}{' '}
                                                    /{' '}
                                                    {checkEmpty(
                                                      item.associate_weather_max_temp,
                                                      '0'
                                                    )}{' '}
                                                    ℃
                                                  </td>
                                                  <td>
                                                    {checkEmpty(
                                                      item.associate_weather_min_humidity,
                                                      '0'
                                                    )}{' '}
                                                    /{' '}
                                                    {checkEmpty(
                                                      item.associate_weather_max_humidity,
                                                      '0'
                                                    )}{' '}
                                                    g/m3
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </div>
              )}
            </div>
          </div>
          {/* begin::Body */}
        </div>
        <CustomPaginate
          data={container}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          isDisabled={fetchingRecords}
        />
      </div>
      <EaModal
        id='ea_modal_'
        modalTitle={requestHandler === 'add' ? 'Add Container' : 'Update Container'}
        btnDisabled={disableBtn}
        closeAuto='closeEaModalOO'
        onSubmit={submitFunction}
        cancel={clearFunctionAdd}
        actionBtnName={isLoading}
        loader={modalLoader}
      >
        <div className='mb-4'>
          <label className='form-label'>
            Container Name<span style={{color: '#FF0000'}}>*</span>
          </label>
          <input
            type='text'
            id='container_name'
            className={clsx('form-control', validation.container_name)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'container_name')}
            name='container_name'
            value={containerState && containerState.container_name}
          />
          {!isEmpty(errorField) && errorField === 'container_name' && !isEmpty(errorMessage) && (
            <div className='mb-4'>
              {errorMessage && <ErrorHandler errorMessage={errorMessage} />}
            </div>
          )}
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Container Number<span style={{color: '#FF0000'}}>*</span>
          </label>
          <input
            type='text'
            id='container_number'
            className={clsx('form-control', validation.container_number)}
            placeholder='Type here...'
            onChange={(e) => handleChange(e, 'container_number')}
            name='container_number'
            value={containerState && containerState.container_number}
          />
          {!isEmpty(errorField) && errorField === 'container_number' && !isEmpty(errorMessage) && (
            <div className='mb-4'>
              {errorMessage && <ErrorHandler errorMessage={errorMessage} />}
            </div>
          )}
        </div>
        <div className='mb-4'>
          <label className='form-label'>
            Container Capacity<span style={{color: '#FF0000'}}>*</span>
          </label>
          <div
            className='input-group'
            style={{
              borderColor: validation.container_capacity != '' ? 'Red' : '',
            }}
          >
            <input
              type='text'
              id='container_capacity'
              className={clsx('form-control', validation.container_capacity)}
              placeholder='Type here...'
              onChange={(e) => handleChange(e, 'container_capacity')}
              name='container_capacity'
              value={containerState && containerState.container_capacity}
            />
            <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
              Crates
            </span>
          </div>
          {!isEmpty(validation.container_capacity_exceed) && (
            <label className='text-danger'>{validation.container_capacity_exceed}</label>
          )}
        </div>
      </EaModal>

      <Modal
        show={show}
        onHide={() => {
          isClear()
        }}
        centered
        backdrop='static'
        dialogClassName='h-auto mw-570px'
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className='modal-title fw-bold'>Configure SMS for Alert</h2>
          </Modal.Title>
          <div
            className='btn p-1 mh-24 btn-active-light-primary ms-2'
            onClick={() => {
              isClear()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            {fetchConfigure ? (
              <FetchingRecords />
            ) : (
              <div className='row p-1'>
                <label className='mb-3' htmlFor=''>
                  Mobile Number
                </label>
                {configureSms.length > 0 &&
                  configureSms.map((item, index) => {
                    return (
                      <>
                        <div className='col-md-12 mb-5'>
                          <div className='d-flex align-content-center justify-content-between'>
                            <MobileNumberInput
                              label={' '}
                              isOptional={true}
                              validation={item.contactValid}
                              onChange={(e: any) => {
                                handleContactNumber(e, index)
                              }}
                              numberValue={item.contact}
                              name='contact_no'
                              contactNoVerify={false}
                            />
                            {configureSms.length === 1 ? (
                              <div
                                onClick={() => {
                                  addRow()
                                }}
                                className='cursor-pointer ms-1 mt-1'
                              >
                                <KTSVG
                                  path={'/media/icons/duotune/abstract/abs011.svg'}
                                  className='ic me-0'
                                />
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  removeRow(index)
                                }}
                                className='ms-1 mt-1 cursor-pointer'
                              >
                                <KTSVG
                                  path={'/media/icons/duotune/abstract/abs012.svg'}
                                  className='ic me-0'
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  })}
                <div>
                  {configureSms.length > 1 && (
                    <div
                      onClick={() => {
                        addRow()
                      }}
                      className='ms-1 mt-1 cursor-pointer'
                    >
                      <KTSVG
                        path={'/media/icons/duotune/abstract/abs011.svg'}
                        className='ic me-0'
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-center'>
            <button
              className='btn btn-secondary me-3'
              onClick={() => {
                isClear()
              }}
              disabled={submit !== 'Submit' ? true : false}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                isSubmit()
              }}
              disabled={submit !== 'Submit' ? true : false}
            >
              {submit}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Containers
