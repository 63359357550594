/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl'
import { getUserType } from '../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage';
import ReturnedProducts from './returnedProducts';
import ReturnedProductsAM from './returnedProductsAM';

const superAdmin = localStorage.getItem('superAdmin');
const w_manager = localStorage.getItem('w_manager');
const OO = localStorage.getItem('OO');

const ReturnedProductsWrapper: FC = () => {
    const intl = useIntl()
    const headerTitle = {
        title: "ProductSku"
    }
    return (
        <>
            {getUserType === "OO" && <ReturnedProducts className='oo-report' />}
            {getUserType === "AM" && <ReturnedProductsAM className='am-report' />}
        </>
    )
}

export { ReturnedProductsWrapper }

