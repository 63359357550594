import clsx from 'clsx'
import moment from 'moment'
import {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import DatePicker from 'react-date-picker'
import ReactDOM from 'react-dom'
import {KTSVG, apiBaseURL, checkRoleName, dateFormat, isEmpty} from '../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaChartBar} from '../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {logisticsManager} from '../../../_eaFruitsDms/utils/roleNames'

const LoDashboard = () => {
  const loFilterId = document.getElementById('lo-dashboard-filter')

  // for bar chart
  let app = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
  }

  // for bar chart
  const labelOption = {
    show: true,
    position: app.position,
    distance: app.distance,
    align: app.align,
    verticalAlign: app.verticalAlign,
    rotate: app.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 10,
    rich: {
      name: {},
    },
  }

  const [metaCounts, setMetaCounts] = useState({
    total_vehicles: 0,
    available_vehicles: 0,
    breakdown_vehicles: 0,
    in_service_vehicles: 0,
    breakdown_vehicles_today: 0,
    in_service_vehicles_today: 0,
  })
  const [isBrandLoader, setIsChartLoading] = useState(true)
  const [usageBarChart, setUsageBarChart] = useState({
    xAxisData: [],
    seriesData: [],
    yAxisZoom: false,
    xAxisZoom: true,
  })
  const [brandUsageBarChart, setBrandUsageBarChart] = useState({
    xAxisData: [],
    seriesData: [],
    yAxisZoom: false,
    xAxisZoom: true,
  })
  const [isBrandChartLoader, setIsBrandChartLoading] = useState(true)
  const [isFromCalenderOpen, setIsFromCalenderOpen] = useState<boolean>(false)
  const [isToCalenderOpen, setIsToCalenderOpen] = useState<boolean>(false)
  const [fromMonth, setFromMonth] = useState<any>(moment().toDate())
  const [toMonth, setToMonth] = useState<any>(moment().toDate())
  const [vehicleType, setVehicleType] = useState('make')
  const [isChartLoading, setChartLoading] = useState(true)
  const [barChart, setBarChart] = useState({})
  const [warehouse, setWarehouse] = useState({label: 'All Warehouse', value: ''})
  const [apiDate, setApiDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [brandVehicleDate, setBrandVehicleDate] = useState<any>({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  })

  const fetchMetaCounts = async () => {
    const url = `${apiBaseURL()}reports/lm-lo-dashboard/meta?warehouse_id=${warehouse.value}`
    const response = await getRequest(url, true)
    if (response && response.result) {
      setMetaCounts(response.result)
    }
  }

  const fetchUsageOverBarChart = async () => {
    setIsChartLoading(true)
    const url = `${apiBaseURL()}reports/lm-lo-dashboard/vehicle-trips/graph?start_date=${
      apiDate.startDate
    }&end_date=${apiDate.endDate}&warehouse_id=${warehouse.value}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      let xAxisData: any = []
      let seriesData: any = []

      response.result.map((item: any) => {
        if (item.total_trips !== 0) {
          xAxisData.push(item.vehicle_number)
          seriesData.push({value: item.total_trips})
        }
      })

      let barChartUsage = {...usageBarChart}
      barChartUsage.seriesData = seriesData
      barChartUsage.xAxisData = xAxisData

      setUsageBarChart(barChartUsage)
    }
    setIsChartLoading(false)
  }

  const fetchBrandUsageOverBarChart = async () => {
    setIsBrandChartLoading(true)

    const url = `${apiBaseURL()}reports/lm-lo-dashboard/vehicle-brand-trips/graph?start_date=${
      brandVehicleDate.startDate
    }&end_date=${brandVehicleDate.endDate}&warehouse_id=${warehouse.value}`
    const response = await getRequest(url, true)

    if (response && response.result) {
      let xAxisData: any = []
      let seriesData: any = []

      response.result.map((item: any, index: any) => {
        if (item.total_trips !== 0) {
          xAxisData.push({value: item.vehicle_brand_name, itemStyle: {color: '#a900c1'}})
          seriesData.push({value: item.total_trips})
        }
      })

      let brandData = {...brandUsageBarChart}
      brandData.seriesData = seriesData
      brandData.xAxisData = xAxisData

      setBrandUsageBarChart(brandData)
    }

    setIsBrandChartLoading(false)
  }

  const vehicleChartReport = async () => {
    setChartLoading(true)

    const url = `${apiBaseURL()}reports/vehicle-breakdown/${vehicleType}?warehouse_id=${
      warehouse.value
    }&start_month=${dateFormat(fromMonth, 'MM-yyyy', true).displayDate}&end_month=${
      dateFormat(toMonth, 'MM-yyyy', true).displayDate
    }`

    const response = await getRequest(url, true)
    if (response) handleChartResponse(response)

    setChartLoading(false)
  }

  const handleChartResponse = (response: any) => {
    let initialChart: any = {}
    let xAxisData: string[] = []
    let seriesData: any = []
    let legend: any = []
    let type: any = []
    let key = vehicleType === 'make' ? 'vehicle_make' : 'vehicle_type'

    if (response.length > 0) {
      response[0].data.forEach((item: any) => {
        legend.push(item[key])
        type.push({name: item[key]})
      })

      response.forEach((item: any) => xAxisData.push(item.month))

      type.forEach((i: any) => {
        let data: any = []
        response.forEach((item: any) => {
          item.data.length > 0 &&
            item.data.forEach((item: any, index: any) => {
              if (i.name === item[key]) {
                data.push(item.count)
              }
            })
        })

        seriesData.push({
          name: i.name,
          type: 'bar',
          width: '100%',
          label: labelOption,
          emphasis: {
            focus: 'series',
          },
          data: data,
        })
      })
    }

    initialChart.xAxisData = xAxisData
    initialChart.series = seriesData
    initialChart.legend = legend

    setBarChart(initialChart)
  }

  useEffect(() => {
    fetchMetaCounts()
  }, [warehouse])

  useEffect(() => {
    vehicleChartReport()
  }, [vehicleType, fromMonth, toMonth, warehouse])

  useEffect(() => {
    fetchBrandUsageOverBarChart()
  }, [brandVehicleDate, warehouse])

  useEffect(() => {
    fetchUsageOverBarChart()
  }, [apiDate, warehouse])

  const CustomDatePickerComponent = (props: any) => {
    const {onCallback, startDate, endDate, value, fetchingRecords} = props
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            const callBakeDate = {
              startDate: dateFormat(start._d).apiDate,
              endDate: dateFormat(end._d).apiDate,
            }
            const callBakeDateDisplay = {
              startDate: dateFormat(start._d).displayDate,
              endDate: dateFormat(end._d).displayDate,
            }
            onCallback(callBakeDate, callBakeDateDisplay)
          }}
          initialSettings={{
            startDate: moment(startDate).format('DD MMM YYYY'),
            endDate: moment(endDate).format('DD MMM YYYY'),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-220px'
            defaultValue={value}
            disabled={fetchingRecords}
          />
        </DateRangePicker>
      </>
    )
  }

  const Filters = () => {
    return (
      <WarehouseFilter
        warehouse={warehouse}
        handleFilterChange={(e: any) => setWarehouse(e)}
        isDisabled={false}
      />
    )
  }

  return (
    <>
      <EATitle title='Dashboard' />
      <PageTitle
        breadcrumbs={[
          {
            title: 'Dashboard',
            subTitle: 'Dashboard',
          },
        ]}
      >
        Dashboard
      </PageTitle>

      {checkRoleName([logisticsManager]) &&
        loFilterId &&
        ReactDOM.render(<Filters />, document.getElementById('lo-dashboard-filter'))}

      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={isEmpty(metaCounts.total_vehicles) ? '0' : metaCounts.total_vehicles.toString()}
            path='/'
            description='Total Vehicle'
            label='Lifetime'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={
              isEmpty(metaCounts.available_vehicles)
                ? '0'
                : metaCounts.available_vehicles.toString()
            }
            description='Total Available'
            path='/'
            label='Current'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='danger'
            title={
              isEmpty(metaCounts.breakdown_vehicles)
                ? '0'
                : metaCounts.breakdown_vehicles.toString()
            }
            description='Total Breakdown'
            path='/'
            label='Current'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={
              isEmpty(metaCounts.in_service_vehicles)
                ? '0'
                : metaCounts.in_service_vehicles.toString()
            }
            description='Total In-Service'
            path='/'
            label='Current'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <div className='bg-light-primary d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
            <span className='svg-icon-dark'>
              <KTSVG path='/media/icons/duotune/general/gen007.svg' className='ic me-3' />
            </span>
            <div className='text-dark pe-2'>
              {isEmpty(metaCounts.breakdown_vehicles_today)
                ? '0'
                : metaCounts.breakdown_vehicles_today.toString()}{' '}
              vehicle breakdown reported today
            </div>
            <a
              href='reports/vehicle-breakdown-report'
              className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'
            >
              View All
            </a>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='bg-light-warning d-flex flex-sm-row py-3 px-5 align-items-center mb-5 rounded'>
            <span className='svg-icon-dark'>
              <KTSVG path='/media/icons/duotune/general/gen007.svg' className='ic me-3' />
            </span>
            <div className='text-dark pe-2'>
              {isEmpty(metaCounts.in_service_vehicles_today)
                ? '0'
                : metaCounts.in_service_vehicles_today.toString()}{' '}
              vehicles are In-service today
            </div>
            <a
              href='vehicle-management'
              className='text-dark text-hover-decoration-underline text-hover-dark ms-auto fw-500'
            >
              View All
            </a>
          </div>
        </div>
      </div>

      <div className='row mt-3 mt-md-0'>
        <div className='col-12 mt-md-0'>
          <div className='card card-xl-stretch mb-5'>
            <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
              <h3 className='card-title align-items-start flex-column my-0'>
                <span className='card-label fw-bold font-17 mb-1'>Vehicle Usage Overview</span>
              </h3>

              <div className='card-toolbar d-flex g-3 my-0'>
                <CustomDatePickerComponent
                  onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                    setApiDate({
                      startDate: callBakeDate.startDate,
                      endDate: callBakeDate.endDate,
                    })
                  }}
                  value={apiDate}
                  startDate={apiDate.startDate}
                  endDate={apiDate.endDate}
                  fetchingRecords={isBrandLoader}
                />
              </div>
            </div>

            <div className='card-body'>
              {isBrandLoader ? (
                <FetchingRecords />
              ) : usageBarChart.seriesData.length === 0 ? (
                <NoRecords />
              ) : (
                <EaChartBar {...usageBarChart} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Brand wise Vehicle Usage Overview</span>
          </h3>
          <div className='card-toolbar d-flex g-3 my-0'>
            <CustomDatePickerComponent
              onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                setBrandVehicleDate({
                  startDate: callBakeDate.startDate,
                  endDate: callBakeDate.endDate,
                })
              }}
              value={brandVehicleDate}
              startDate={brandVehicleDate.startDate}
              endDate={brandVehicleDate.endDate}
              fetchingRecords={isBrandChartLoader}
            />
          </div>
        </div>
        <div className='card-body'>
          {isBrandChartLoader ? (
            <FetchingRecords />
          ) : brandUsageBarChart.seriesData.length === 0 ? (
            <NoRecords />
          ) : (
            <EaChartBar {...brandUsageBarChart} />
          )}
        </div>
      </div>
      <div className={`card mb-5`}>
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Breakdown Overview</span>
          </h3>
          <div className='card-toolbar d-flex g-3 my-0'>
            <ul className='nav'>
              <li className='nav-item me-3 mt-1'>
                <ul className='nav'>
                  <li className='nav-item me-3'>
                    <label className='font-bold mb-1 form-label'>From Month:</label>
                    <DatePicker
                      value={fromMonth}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isFromCalenderOpen}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setIsFromCalenderOpen(true)
                        setFromMonth(e)
                        setToMonth(e)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                  <li>
                    <label className='font-bold mb-1 form-label'>To Month:</label>
                    <DatePicker
                      value={toMonth}
                      className={clsx('calendar react-datepicker form-control me-3')}
                      clearIcon={null}
                      calendarIcon={null}
                      closeCalendar
                      defaultView={'month'}
                      format={'MM yyyy'}
                      isOpen={isToCalenderOpen}
                      minDate={fromMonth}
                      maxDate={moment().toDate()}
                      onClickMonth={(e: any) => {
                        setToMonth(e)
                        setIsToCalenderOpen(true)
                      }}
                      monthAriaLabel={'Month'}
                      monthPlaceholder='MM'
                      yearAriaLabel={'Year'}
                      yearPlaceholder={'YYYY'}
                      view={'year'}
                    />
                  </li>
                </ul>
              </li>

              <li className='nav-item mt-6'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_8_tab_1'
                  onClick={() => setVehicleType('make')}
                >
                  By Vehicle Make
                </a>
              </li>
              <li className='nav-item mt-6'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_8_tab_2'
                  onClick={() => setVehicleType('type')}
                >
                  By Vehicle Type
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='card-body'>
          {isChartLoading ? <FetchingRecords /> : <EaChartBar {...barChart} />}
        </div>
      </div>
    </>
  )
}

export default LoDashboard
