import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  fetchAsyncDropdown,
  fetchStaticAsyncDropdown,
  isEmpty,
  retrieveFilter,
  saveFilter,
} from '../../../../../_eaFruitsDms/helpers/CommonHelpers'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPagination from '../../../../../_eaFruitsDms/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import {PageTitle} from '../../../../../_eaFruitsDms/layout/core'
import CustomPaginate from '../../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import ResetButton from '../../../../../_eaFruitsDms/layout/components/button/resetButton'
import useDebounce from '../../../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../../../_eaFruitsDms/utils/FilterConstants'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {getW_Id} from '../../../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'

const TSSurveyFeedback = () => {
  const location: any = useLocation()
  const intl = useIntl()
  let feedbackFormId: any = localStorage.getItem('surveyform-id-back-navigate')

  const adminHeaderTitle = {
    title: 'Survey Feedback',
  }
  let SurveyFiltered = retrieveFilter(filters.ts_survey_feedback)

  const initialState = {
    // feedBackId: SurveyFiltered?.feedBackId || '',
    warehouseId: SurveyFiltered?.warehouseId || '',
    customer: SurveyFiltered?.customer || '',
    search: SurveyFiltered?.search || '',
    selected: SurveyFiltered?.selected || '',
    currentOffSet: SurveyFiltered?.currentOffset || '',
    buttonDisable: true,
  }

  const customerType = [
    {label: 'All Customer', value: ''},
    {label: 'Existing Customer', value: 'true'},
    {label: 'Non-Existing Customer', value: 'false'},
  ]

  const limit = '10'
  const [feedBackId, setFeedBackId] = useState(feedbackFormId)
  const [list, setList] = useState([])
  const [search, setSearch] = useState(SurveyFiltered?.search || '')
  const [count, setCount] = useState('')
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [timer, setTimer] = useState<any>(null)
  const [selected, setSelected] = useState<number>(SurveyFiltered?.selected || 0)
  const [currentOffset, setCurrentOffset] = useState<number>(SurveyFiltered?.currentOffset || '0')
  const [isLoading, setIsLoading] = useState(true)
  // const [warehouseId, setWarehouseId] = useState('')
  const [warehouseId, setWarehouseId] = useState(
    SurveyFiltered?.warehouseId || {label: 'All Warehouses', value: ''}
  )
  const [customer, setCustomer] = useState(
    SurveyFiltered?.customer || {label: 'All Customer', value: ''}
  )
  const [offset, setOffset] = useState(0)
  const [warehouseList, setWarehouseList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    SurveyFiltered?.buttonDisable || false
  )

  const debounceSearch = useDebounce(search, 500)

  const navigate = useNavigate()

  useEffect(() => {
    // if (location.state) {
    //   setFeedBackId(location.state.feedBackId)
    // } else {
    //   navigate('/survey-forms')
    // }
    if (!feedBackId) {
      navigate('/survey-forms')
    }
  }, [])

  useEffect(() => {
    if (feedBackId !== '') {
      getSurveyFeedBack()

      let updatedState: any = {...filterStates}
      updatedState['selected'] = selected
      updatedState['currentOffSet'] = currentOffset
      updatedState['buttonDisable'] = buttonDisable
      // updatedState['feedBackId'] = feedBackId
      setFilterStates(updatedState)

      saveFilter(filters.ts_survey_feedback, filterStates)

      if (search == '' && warehouseId.value == '' && customer.value == '') {
        setButtonDisable(false)
      }
    }
  }, [feedBackId, currentOffset, debounceSearch, warehouseId.value, customer.value])

  const getSurveyFeedBack = async () => {
    setIsLoading(true)
    const surveyFeedBackApi =
      apiBaseURL() +
      'surveyForms/answer/list?surveyform_id=' +
      feedBackId +
      `&oredering=name&limit=${limit}&offset=${currentOffset}&search=${debounceSearch}`
    const warehouseUrl = '&warehouse_id=' + warehouseId.value
    const customerUrl = `${
      isEmpty(customer.value) ? '' : `&is_existing_customer=${customer.value}`
    }`
    const response = await getRequest(surveyFeedBackApi + warehouseUrl + customerUrl, true)
    setNext(response.next)
    setList(response.results)
    setCount(response.count)
    setPrev(response.previous)
    if (response) {
      setIsLoading(false)
    }
  }

  const numberClick = (val: any, index: any) => {
    if (val) {
      setSelected(val - 1)
      let newOffset = index * 10
      setCurrentOffset(newOffset)
    }
  }

  const prevPage = () => {
    if (prev !== null) {
      const queryParams = new URLSearchParams(prev)
      const newOffset = queryParams.get('offset')
      // setCurrentOffset(newOffset)
      setSelected(selected - 1)
    }
  }

  const nextPage = () => {
    if (next !== null) {
      const queryParams = new URLSearchParams(next)
      const newOffset = queryParams.get('offset')
      // setCurrentOffset(newOffset)
      setSelected(selected + 1)
    }
  }

  const searchList = (e: any) => {
    const inputValue = e.target ? e.target.value : e.value
    setButtonDisable(true)
    setSearch(e.target.value)
    setFilterStates({...filterStates, ['search']: inputValue})
    setCurrentOffset(0)
    setSelected(0)
  }

  const fetchWarehouse = async (search: string) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      warehouseList,
      setWarehouseList,
      `warehouse/list?ordering=name${
        checkUserType(['wm']) ? `&parent_warehouse__id=${getW_Id}&is_parent=True` : ''
      }`,
      search,
      setDropDownSearch,
      false,
      true,
      'All Warehouses'
    )
  }

  const fetchCustomerType = async (search: any) => {
    return fetchStaticAsyncDropdown(customerType, search)
  }

  const onChangeWarehouse = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setWarehouseId(e)
    setFilterStates({...filterStates, ['warehouseId']: e})
  }

  const onChangeCustomer = (e: any) => {
    setButtonDisable(true)
    setCurrentOffset(0)
    setSelected(0)
    setCustomer(e)
    setFilterStates({...filterStates, ['customer']: e})
  }

  useEffect(() => {
    setOffset(0)
  }, [dropDownSearch])

  const handleChangeReset = () => {
    setSearch('')
    setCurrentOffset(0)
    setSelected(0)
    setWarehouseId({label: 'All Warehouses', value: ''})
    setCustomer({label: 'All Customer', value: ''})

    let updatedState: any = {...filterStates}
    updatedState['warehouseId'] = ''
    updatedState['customer'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={[adminHeaderTitle]} backNavigate='/survey-forms'>
        {intl.formatMessage({id: 'Survey Feedback'})}
      </PageTitle>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={searchList} value={search} isDisabled={isLoading} />
          <div className='ms-md-auto'>
            {/* <div className='card-toolbar d-flex my-0'> */}
            <form
              className='card-toolbar d-flex my-0'
              onSubmit={(e) => e.preventDefault()}
              autoComplete='off'
            >
              <AsyncPaginate
                loadOptions={fetchCustomerType}
                isSearchable
                placeholder='All Customer'
                className='react-select-container my-1 me-3 w-200px'
                classNamePrefix='react-select'
                onChange={onChangeCustomer}
                isDisabled={isLoading}
                value={customer}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <AsyncPaginate
                loadOptions={fetchWarehouse}
                isSearchable
                placeholder='All Warehouses'
                className='react-select-container my-1 me-3 w-160px'
                classNamePrefix='react-select'
                onChange={onChangeWarehouse}
                isDisabled={isLoading}
                value={warehouseId}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              {/* </div> */}
            </form>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {isLoading ? (
            <FetchingRecords />
          ) : (
            <>
              {list.length === 0 ? (
                <NoRecords />
              ) : (
                <FeedBackTable list={list} customer={customer} />
              )}
            </>
          )}
        </div>
        {/* begin::Body */}
      </div>
      {/* <div className="table-info py-5">
        <div className="row g-0">
          <div className="col">
            {`Showing ${list.length == 0 ? 0 : selected * 10 + 1} to ${list.length == 0 ? 0 : list.length < 10 ? count : (selected + 1) * 10} of ${list.length == 0 ? 0 : count} entries`}
          </div>
          <div className="col">
            <CustomPagination numberClick={numberClick} prev={prevPage} next={nextPage} count={list.length == 0 ? 0 : count} selected={selected} />
          </div>
        </div>
      </div> */}
      <CustomPaginate
        data={list}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={isLoading}
        saveFilterName={filters.ts_survey_feedback}
        filterStates={filterStates}
      />
    </>
  )
}

export default TSSurveyFeedback

export const FeedBackTable = (props: any) => {
  const navigate = useNavigate()
  const {customer} = props

  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table
        className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2
            '
      >
        {/* begin::Table head */}
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-150px py-2'>Date & Time</th>
            <th className='min-w-150px py-2'>Customer Name</th>
            {customer.label !== 'Non-Existing Customer' && (
              <>
                <th className='min-w-150px py-2'>Warehouse Name</th>
              </>
            )}
            <th className='min-w-120px py-2'>Feedback Captured By</th>
          </tr>
        </thead>
        <tbody>
          {props.list.map((item: any) => {
            return (
              <tr
                onClick={() =>
                  navigate('/survey-forms/survey-feedback/survey-feedback-detail', {
                    state: {
                      feedBackAnswerId: item.id,
                    },
                  })
                }
                className='cursor-pointer'
              >
                <td>
                  {dateFormat(item.submited_at).displayDate}
                  <p className='font-13 text-muted mb-0'>
                    {dateFormat(item.submited_at, 'LT').displayDate}
                  </p>
                </td>
                <td>
                  {isEmpty(item.customer_id)
                    ? isEmpty(item.participant_name)
                      ? '-'
                      : item.participant_name
                    : item.customer_id.business_name}
                </td>
                {customer.label !== 'Non-Existing Customer' && (
                  <td>
                    {isEmpty(item.customer_id)
                      ? '-'
                      : item.customer_id.warehouse.length !== 0
                      ? item.customer_id.warehouse[0].name
                      : '-'}
                  </td>
                )}
                <td>
                  {!isEmpty(item.feedback_by) ? (
                    <>
                      {item.feedback_by.first_name + ' ' + item.feedback_by.last_name}{' '}
                      {`(${item.feedback_by.type.toUpperCase()})`}
                    </>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
        {/* end::Table body */}
      </table>

      {/* end::Table */}
    </div>
  )
}
